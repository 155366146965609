import React, { useState, useEffect } from "react";
import HeaderWithMenu from "../../components/patientComponents/component/headerWithMenu";
import {
  HeaderLogo,
  InnerContainer,
  Title,
} from "../../components/patientComponents/styled-components/register/styled";
/* import PolicyText from '../components/policyText' */
import styles from "../../styles/patientStyles/PrivacyPolicy.module.css";
import Footer from "../../components/patientComponents/component/footer";
import { getUserInfo } from "../../services/patientServices/authService";
import { getCountry } from "../../services/doctorServices/userService";
import CountrySwitch from "../../components/components/countrySwitch";

const PatientTermsAndConditions = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [currentCountry, setCurrentCountry] = useState(null);

  const handleCountryChange = (newCountry) => {
    setCurrentCountry(newCountry);
  };

  useEffect(() => {
    const getcountry = getCountry();

    if (getcountry && Object.keys(getcountry).length !== 0) {
      setCurrentCountry(getcountry);
    } else {
      setCurrentCountry("CO");
    }

    if (getUserInfo() !== null) {
      setIsLogged(true);
    }
  }, []);

  useEffect(() => {
    if (getUserInfo() !== null) {
      setIsLogged(true);
    }
  }, []);

  return (
    <div>
      {isLogged && <HeaderWithMenu />}
      <HeaderLogo>
        <InnerContainer>
          <br></br>
          <br></br>
          <Title>Términos y condiciones</Title>
          <p className={styles.subTitle}>
            A continuación puede encontrar los términos y condiciones del
            aplicativo ialurilApp.
          </p>
        </InnerContainer>
      </HeaderLogo>
      <div className={styles.container}>
        <CountrySwitch
          currentCountry={currentCountry}
          onCountryChange={handleCountryChange}
        />
        <div className={styles.containerText}>
          <p className={styles.text}>
            Los presentes términos y condiciones generales (en adelante, los “
            <b className={styles.gb}>Términos y Condiciones</b>”) rigen la
            relación entre los individuos que utilicen el sitio web
            app.ialuril.co (en adelante, los “
            <b className={styles.gb}>Usuarios</b>”) y SerAnest Pharma Ltda. ,
            con domicilio ,{" "}
            {currentCountry == "CO"
              ? "Bogotá calle 134 a No. 19-34 of 203"
              : "CR-CONTENT"}{" "}
            (en adelante, “<b className={styles.gb}>SerAnest Pharma</b>”).
          </p>
          <p className={styles.text}>
            El registro del Usuario en el sitio web app.ialuril.co (en adelante,
            el “Sitio”) implica la aceptación inmediata y sin reserva de los
            presentes Términos y Condiciones. En consecuencia, el Usuario
            manifiesta haber leído y aceptado en su totalidad los Términos y
            Condiciones; dicha aceptación es indispensable para la utilización
            del Sitio. Si no está de acuerdo con los presentes Términos y
            Condiciones rogamos no utilizar el Sitio. Los Usuarios declaran y
            aceptan que los Términos y Condiciones del Sitio Web podrán variar
            en cualquier momento según lo determine SerAnest Pharma, y entienden
            que cada vez que hagan uso del Sitio Web se adhieren a dichos
            Términos y Condiciones del Sitio Web y a los cambios que se efectúen
            en los mismos.
          </p>
          <p className={styles.titleII}>1) Descripción del Sitio</p>
          <p className={styles.text}>
            El Sitio tiene como finalidad que los Usuarios accedan a información
            de carácter informativo y el procedimiento instilación intravesical
            para el tratamiento de su patología cistitis crónica de diferente
            etiología y síndrome de vejiga dolorosa además, SerAnest pharma , no
            se hace responsable del mal uso o uso indebido de la información o
            contenido del sitio web por parte de los usuarios, ni ninguna
            consecuencia que de ello se desprenda, incluyéndose pero sin
            limitarse a lesiones y/o daños a personas o propiedades que surjan
            por responsabilidad de un producto, negligencia u otro motivo.
          </p>
          <p className={styles.text}>
            El contenido del Sitio Web y del APP no es una guía para la
            automedicación ni un reemplazo del asesoramiento, el diagnóstico o
            el tratamiento especializado. El contenido del Sitio Web y el APP es
            meramente informativo, y no debe usarse para determinar el
            tratamiento de ningún tipo de afección o enfermedad o similares.
          </p>
          <p className={styles.text}>
            Esta App, la información y los recursos relacionados con la salud no
            tienen como objetivo ni deben interpretarse como el asesoramiento o
            la prestación de servicios médicos, de enfermería o de cuidado de la
            salud profesional ni como la práctica de la medicina, la enfermería
            o el cuidado de la salud profesional en ninguna jurisdicción.
          </p>
          <p className={styles.text}>
            Es deber de los Usuarios, recibir la asesoría de un profesional de
            acuerdo con su patología y la prescripción del dispositivo medico
            ialuril ®Prefill{" "}
          </p>
          <p className={styles.text}>
            Así mismo, el Usuario y el Usuario Adquirente se obligan a verificar
            la información del Producto (incluso los prospectos del empaque) con
            respecto a dosificación, precauciones, advertencias, interacciones y
            contraindicaciones antes de administrar dispositivo, medicamento,
            hierba, vitamina o suplemento mencionado en el APP
          </p>

          <p className={styles.titleII}>
            2) Requisitos que deben cumplir los Usuarios
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>2.1.</b> Podrán registrarse y/o utilizar el
            Sitio los usuarios interesados en conocer acerca del procedimiento a
            realizar con ialuril ®Prefill.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>2.2.</b> La utilización del Sitio implica
            que el Usuario declara y garantiza a SerAnest Pharma que cumple con
            el requisito previsto en la presente cláusula, por lo que el Usuario
            asume total responsabilidad por cualquier daño y/o perjuicio que
            pudiera ocasionarse a SerAnest Pharma y/o a terceros por la falsedad
            de tal declaración.
          </p>
          <p className={styles.titleII}>3) Requisitos de acceso al Sitio</p>
          <p className={styles.text}>
            <b className={styles.gb}>3.1.</b> Podrán acceder al Sitio los
            Usuarios que cumplan los requisitos descriptos en el punto 2 de
            estos Términos y Condiciones y que hayan completado el proceso de
            registro.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>3.2.</b> El Usuario declara, acepta y
            garantiza que todos los datos personales suministrados en el proceso
            de registro, incluyendo, son verdaderos, completos y se encuentran
            actualizados. En tal sentido, el Usuario se compromete a mantener
            indemne a SerAnest Phrama y libre y exento de toda responsabilidad
            por cualquier sanción y/o reclamo y/o daño y/o perjuicio que pudiera
            suscitarse como consecuencia del incumplimiento de la declaración y
            garantía manifestada precedentemente.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}> 3.3.</b> Al registrarse, el Usuario
            recibirá un correo electrónico con el usuario y contraseña que le
            permitirán acceder al Sitio. Esta información es personal e
            intransferible, debiendo el Usuario evitar que terceras personas
            puedan acceder a ella, quedando entendido que cualquier acción
            realizada en el Sitio mediante el nombre de usuario y contraseña
            elegidos por el Usuario será imputada a dicho Usuario. Si el Usuario
            advirtiera que su nombre de usuario y contraseña perdieron su
            condición de confidenciales y/o están siendo utilizados por
            terceros, deberá informar en forma inmediata a SerAnest Pharma al
            siguiente correo electrónico: equipovirtual@seranestpharma.com
          </p>
          <p className={styles.text}>
            <b className={styles.gb}> 3.4.</b> Será responsabilidad del Usuario
            mantener actualizada su información personal.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}> 3.5.</b> SerAnest Pharma utilizará la
            información suministrada por el Usuario exclusivamente con el objeto
            de permitir al Usuario utilizar el Sitio.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}> 3.6.</b> El registro del Usuario y la
            posterior utilización del Sitio es gratuito, lo que implica que no
            deberá pagar por ello ningún arancel o retribución a SerAnest
            Pharma.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>3.7.</b> Para poder utilizar el Sitio se
            requiere conexión a Internet. La libre descarga y la gratuidad del
            acceso no comprenden las facilidades de conexión a Internet. En
            ningún caso SerAnest proveerá a los Usuarios la conectividad
            necesaria para que puedan utilizar el Sitio. La conectividad a
            Internet será por exclusiva cuenta y cargo de cada Usuario.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}> 3.8.</b> SerAnest Pharma podrá rechazar
            cualquier solicitud de registro o impedir, en cualquier momento, que
            un Usuario ingrese al Sitio sin que tal decisión deba ser
            justificada, y sin que ello genere derecho alguno en beneficio del
            Usuario.
          </p>
          <p className={styles.titleII}>4) Información médica</p>
          <p className={styles.text}>
            <b className={styles.gb}>4.1.</b> SerAnest Pharma no realizará
            ninguna recomendación, explícita o implícita, sobre fármacos,
            técnicas, productos, etc. En caso de ser citados, será únicamente
            con finalidad informativa.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>4.2.</b> El Contenido puede referirse
            directa o indirectamente a productos, programas y/o servicios de
            SerAnest Pharma que están disponibles en todo el territorio{" "}
            {currentCountry == "CO" ? "colombiano" : "costarricense"}. Dichas
            referencias no implican ninguna intención por parte de SerAnest
            Pharma de vender productos, programas y/o servicios. Para más
            información el usuario deberá contactarse con un represente
            comercial de SerAnest Pharma.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>4.3.</b> El Contenido se refiere
            exclusivamente a personas humanas y su finalidad es meramente
            orientativa y no pretende sustituir datos con carácter
            especializado. Por lo tanto, debe utilizarse únicamente con fines
            informativos.
          </p>
          <p className={styles.titleII}>5) Utilización del Sitio</p>

          <p className={styles.text}>
            <b className={styles.gb}>5.1.</b> Los Usuarios deberán utilizar el
            Sitio de conformidad con las disposiciones establecidas en estos
            Términos y Condiciones; con el ordenamiento jurídico al que se
            encuentren sometidos debido al lugar, de las personas, o de la
            materia de la cual se trate, considerado en su conjunto; y según las
            pautas de conducta impuestas por la moral, las buenas costumbres y
            el debido respeto a los derechos de terceros.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>5.2.</b> Queda terminante prohibido: (i)
            copiar, modificar, adaptar, traducir, realizar ingeniería inversa,
            descompilar o desensamblar cualquier parte del Contenido y/o del
            Sitio; (ii) hacer uso del Contenido en otro sitio web o entorno
            informático para cualquier propósito sin la autorización previa y
            por escrito de SerAnest Pharma; (iii) reproducir y/o comunicar por
            cualquier medio el contenido, dado que el acceso se otorga en forma
            personal y para uso exclusivo del Usuario; (iv) interferir o
            interrumpir el funcionamiento del Sitio; (v) vender, licenciar o
            explotar el Contenido y/o cualquier tipo de acceso y/o uso del
            Sitio; (vi) utilizar el Sitio con fines ilícitos o inmorales; e
            (vii) infringir de cualquier modo los presentes Términos y
            Condiciones.
          </p>
          <p className={styles.titleII}>6) Protección de Datos Personales</p>
          <p className={styles.text}>
            <b className={styles.gb}>6.1.</b> SerAnest Pharma cumple con lo
            establecido en la Ley 1581 de 2012 y normativa complementaria (en
            adelante, la “
            <b className={styles.gb}>Normativa de Datos Personales</b>”). En
            particular, SerAnest Pharma se compromete a no utilizar y/o divulgar
            a terceros los datos personales del Usuario –salvo mediando su
            consentimiento o dentro de alguna de las excepciones legales para la
            obtención de dicho consentimiento-, y a cumplir con los principios
            de calidad, finalidad, confidencialidad y seguridad de la Normativa
            de Protección de Datos Personales.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>6.2.</b> SerAnest Pharma se compromete a
            adoptar todos los recaudos que sean necesarios para garantizar la
            seguridad de los datos personales del Usuario, inclusive la
            prevención de procesamientos no autorizados o ilegítimos, pérdida
            accidental, destrucción o daños a dichos datos personales. Asimismo,
            SerAnest Pharma se compromete a adoptar todas las medidas que sean
            necesarias para garantizar que toda persona que tenga acceso a los
            datos personales del Usuario les dé el tratamiento detallado en esta
            cláusula.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>6.3.</b> Adicionalmente a lo previsto en
            esta cláusula, SerAnest Pharma asume las obligaciones descriptas en
            la Política de Privacidad del Sitio, la que resulta complementaria
            de estos Términos y Condiciones y ha sido puesta en conocimiento del
            Usuario antes de su primer ingreso.
          </p>

          <p className={styles.titleII}>7) Responsabilidad</p>
          <p className={styles.text}>
            <b className={styles.gb}>7.1.</b> SerAnest Pharma en ningún caso
            será responsable por lucro cesante ni por cualquier consecuencia
            mediata y/o causal, pérdida de datos o información, pérdida de
            chance, daños punitivos ni de cualquier otra naturaleza.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>7.2.</b> SerAnest Pharma no será
            responsable por ningún mal funcionamiento, imposibilidad de acceso o
            malas condiciones de uso del Sitio debido al uso de equipos
            inadecuados, interrupciones relacionadas con proveedores de servicio
            de internet, la saturación de la red de internet y/o por cualquier
            otra razón.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>7.3.</b> SerAnest Pharma no será
            responsable por la utilización del Contenido. El Usuario será el
            único responsable por las decisiones que tome y/o las acciones que
            realice como resultado del uso del Contenido y/o del Sitio.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>7.4.</b> SerAnest Pharma no garantiza la
            exactitud, precisión, actualización o exhaustividad del Contenido.
            En consecuencia, SerAnest Pharma no será responsable por cualquier
            imprecisión, inexactitud, falta de actualización u omisión relativa
            al Contenido.
          </p>

          <p className={styles.titleII}>8) Enlaces a otros sitios</p>
          <p className={styles.text}>
            En el Sitio podrán encontrarse enlaces a sitios web de terceros. En
            tal caso, Seranest no es responsable del contenido de dichos sitios
            web. Además, la existencia de un vínculo entre el Sitio y cualquier
            sitio de terceros de ninguna manera implica que SerAnest Pharma
            apruebe el contenido de dicho sitio, o, asimismo, cualquier uso que
            dicho contenido pueda tener.
          </p>

          <p className={styles.titleII}>9) Modificaciones del Sitio</p>
          <p className={styles.text}>
            SerAnest Pharma podrá introducir todos los cambios y modificaciones
            que estime convenientes al Sitio, lo que incluye, pero no se limita
            a agregar, alterar, sustituir o suprimir cualquier contenido del
            Sitio en todo momento.
          </p>

          <p className={styles.titleII}>10) Propiedad Intelectual</p>
          <p className={styles.text}>
            <b className={styles.gb}>10.1.</b> El Sitio y el Contenido son de
            titularidad exclusiva de SerAnest Pharma. A título meramente
            enunciativo, se entenderán incluidos las imágenes, fotografías,
            diseños, gráficos, sonidos, compilaciones de datos, marcas, nombres,
            títulos, designaciones, signos distintivos, y todo otro material
            accesible a través del Sitio.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}> 10.2.</b> SerAnest se reserva todos los
            derechos sobre el Sitio y el Contenido, no cede ni transfiere a
            favor del Usuario ningún derecho sobre su propiedad intelectual. En
            consecuencia, su reproducción, distribución, y/o modificación deberá
            ser expresamente autorizada por SerAanest Pharma. Cualquier
            actividad de recolección automática de información del Sitio
            (incluyendo, pero sin limitarse a web scrapping) será considerada
            una violación a los presentes Términos y Condiciones
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>10.3.</b> Los derechos de propiedad
            intelectual respecto de los criterios de selección y/o disposición
            del Contenido en el Sitio corresponden exclusivamente a SerAnest
            Pharma, quedando estrictamente prohibido al Usuario utilizar los
            Contenidos, las categorías y/o cualquier información del Sitio con
            otra finalidad distinta a la indicada en los presentes Términos y
            Condiciones.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>10.4.</b> En el caso de que el Sitio
            permita descargar Contenido será únicamente para su posterior
            lectura por el Usuario, Seranest otorga al Usuario una licencia de
            uso temporal, limitada, no transferible, no exclusiva y para uso
            estrictamente personal, que estará vigente mientras el Usuario se
            encuentre registrado en el Sitio.
          </p>

          <p className={styles.titleII}>11) Operatividad del Sitio</p>
          <p className={styles.text}>
            <b className={styles.gb}>11.1.</b> SerAnest Pharma no garantiza el
            pleno acceso y operatividad del Sitio en forma ininterrumpida.
          </p>
          <p className={styles.text}>
            {" "}
            <b className={styles.gb}>11.2.</b> SerAnest Pharma podrá suspender
            el acceso al Sitio y/o a determinado contenido por motivos de
            mantenimiento o de seguridad en cualquier momento.
          </p>
          <p className={styles.titleII}>12) Notificaciones y comunicaciones</p>
          <p className={styles.text}>
            Las notificaciones y comunicaciones cursadas por SerAnest Pharma la
            casilla de correo electrónico que haya provisto el Usuario al
            momento del registro se considerarán eficaces y plenamente válidas.
            Asimismo, se considerarán eficaces las comunicaciones que consistan
            en avisos y mensajes insertos en el Sitio que tengan por finalidad
            informar al Usuario sobre determinada circunstancia.
          </p>
          <p className={styles.titleII}>
            13) Legislación aplicable y jurisdicción
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>13.1.</b> A todos los efectos legales en
            relación con el Sitio, será aplicable la legislación vigente en
            {currentCountry == "CO" ? "Colombia" : "Costa Rica"}.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>13.2.</b> Cualquier controversia que se
            suscite entre las partes con relación a estos Términos y
            Condiciones, su existencia, validez, calificación, interpretación,
            alcance, cumplimiento o resolución, se resolverá en primera
            instancia de manera directa entre las partes. De no lograrse un
            acuerdo entre las mismas, se resolverá de manera definitiva y
            exclusivamente por los Tribunales Ordinarios en los Comercial con
            asiento en la Ciudad de{" "}
            {currentCountry == "CO" ? "Bogotá-Colombia" : "Costa Rica"}.”
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PatientTermsAndConditions;
