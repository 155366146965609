import ChartComponent from "../../doctorComponents/highcharts";
import Button3 from "../../doctorComponents/buttons/button-3";
import { Link } from "react-router-dom";
import { pieOptions } from "../../doctorComponents/highcharts/optionChart";
import { isRole } from "../../../services/doctorServices/userService";
import { RoleEnum } from "../../../constants/doctorConstants/enums";
import { useForm } from "react-hook-form";

const getPieOptions = (dashboardInfo) => {
  const optionss = {
    ...pieOptions,
    title: { text: "" },
  };

  if (!dashboardInfo) return optionss;

  const data = dashboardInfo.topPathologies.map((x) => ({
    y: Number(x.average),
    name: x.pathology,
  }));
  if (data[0]) {
    data[0].sliced = true;
    data[0].selected = true;
  }

  const series = [
    {
      name: "",
      colorByPoint: true,
      data: data,
    },
  ];

  return { ...optionss, series };
};

const RepresentativeResume = ({ doctors, registered, noRegistered }) => {
  const isRepresentative = isRole(RoleEnum.representative);

  return (
    <div className="file">
      <div className="icon-container">
      <div className="file-segment">
          <div className="icon-background-A">
            <img className="file-icon" src="/doctor/name-icon.svg" alt="icon" />
          </div>
          <div className="file-info">
            <h1 className="file-title">Mis médicos:</h1>
            <p className="file-name">{doctors}</p>
          </div>
        </div>
        <div className="file-segment">
          <div className="icon-background-A">
            <img className="file-icon" src="/patient/tick-green.svg" alt="icon" />
          </div>
          <div className="file-info">
            <h1 className="file-title">Médicos registrados:</h1>
            <p className="file-name">{registered}</p>
          </div>
        </div>
        <div className="file-segment">
          <div className="icon-background-A">
            <img className="file-icon" src="/patient/cross-red.svg" alt="icon" />
          </div>
          <div className="file-info">
            <h1 className="file-title">Médicos no registrados:</h1>
            <p className="file-name">{noRegistered}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepresentativeResume;
