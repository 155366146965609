import TagManager from "react-gtm-module";

/* HEADER */
export const topMenuGTM = (path, label) => {

  TagManager.dataLayer({
    dataLayer: {
      event: "GAEvent",
      category: "navigation",
      action: "click",
      path: path,
      label: `top-menu:${label}`,
    },
  });
};

/* ARTICLES */
export const articlesGTM = (module, label) => {

  TagManager.dataLayer({
    dataLayer: {
      event: "GAEvent",
      category: "engagement",
      action: "click",
      label: `articles:${module}:${label}`,
    },
  });
};

export const articlesAccordionGTM = (article, label) => {

  TagManager.dataLayer({
    dataLayer: {
      event: "GAEvent",
      category: "engagement",
      action: "click",
      label: `accordion:${article}:${label.toLowerCase().replace(/\s/g, "-")}`,
    },
  });
};
