import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button2 from "../../../components/doctorComponents/buttons/button-2";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import { saveAs } from "file-saver";
import { PDFDocument, StandardFonts, PDFImage } from "pdf-lib";
import domtoimage from "dom-to-image";
import ColumnChart from "../../../components/doctorComponents/highcharts/columChart";
import BasicLine from "../../doctorComponents/highcharts/basicLine";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "black,",
    "& .MuiDialog-paper": {
      margin: "16px",
    },
    "& .MuiDialogContent-root": {
      padding: "10px 10px 40px 10px",
    },
    "& .MuiTypography-h6": {
      fontFamily: " Source Sans Pro",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "22px",
      lineHeight: "28px",
      padding: "20px 0 0 10px",
      color: "#343A40",
    },
  },
  modalLabel: {
    width: "100%",
    maxWidth: "290px",
    margin: "0 auto 0 auto",
  },
}));

const getQuestionTitle = (questionNumber) => {
  switch (questionNumber) {
    case 1:
      return "1. ¿Presenta dolor pélvico y/o ardor al orinar?";
    case 11:
      return "1.1 Nivel de dolor según la Escala Visual Análoga (EVA)";
    case 2:
      return "2. ¿Cuántas veces va a orinar durante el día?";
    case 3:
      return "3. ¿Cuántas veces va a orinar en la noche luego de haber conciliado el sueño?";
    case 4:
      return "4. ¿Cuándo siente el deseo de orinar debe ir rápidamente o puede aguantar?";
    case 5:
      return "5. ¿Ha presentado usted fiebre superior a 38 °C, asociado con alguno de los síntomas anteriores?";
    case 6:
      return "6. ¿Ha presentado usted sangrado en la orina asociado con alguno de los síntomas anteriores?";
    default:
      return "";
  }
};

const PreviewReportPatient = ({
  open,
  onClose,
  currentCycleId,
  namePdf,
  patientInfo,
  isTotalTab,
  cycleName,
}) => {
  const classes = useStyles();
  const chartRef1 = useRef(null);
  const chartRef11 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const chartRef4 = useRef(null);
  const chartRef5 = useRef(null);
  const chartRef6 = useRef(null);

  const getPropArray = (obj, prop, question) => {
    if (!obj) return [];
    return obj[question - 1][prop];
  };
  const getLineOptions = (patientInfo, questionNumber, cycleId, isTotalTab) => {
    const optionsBase = {
      chart: {
        type: "line",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: [],
      },
      legend: {
        enabled: false,
        reversed: false,
      },
      yAxis: {
        labels: {
          formatter: function () {
            return this.value;
          },
        },
        title: {
          text: "",
        },
      },
      plotOptions: {
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      series: [
        {
          name: "",
          data: [],
        },
      ],
      credits: {
        enabled: false,
      },
    };

    cycleId = currentCycleId;
    if (!cycleId) return optionsBase;
    const answer = patientInfo.chartLineInfo.find(
      (x) => x.cycleId === cycleId && x.questionNumber === questionNumber
    );

    if (!answer) return optionsBase;
    const data = answer.data.map((x) => ({
      y: x ? x : 0,
    }));
    const options = {
      ...optionsBase,
      xAxis: {
        title: {
          text: "Instilaciones",
        },
        categories: answer.xCategories,
      },
      yAxis: {
        labels: {
          formatter: function () {
            return answer.yCategories[this.value];
          },
        },
        title: {
          text: "",
        },
      },
      tooltip: {
        formatter: function () {
          return (
            "La respuesta en la instilación <b>" +
            this.x +
            "<br><br/></b> fue <b>" +
            answer.yCategories[this.y] +
            "</b>"
          );
        },
      },
      series: [
        {
          name: "",
          data: data,
        },
      ],
    };

    return options;
  };

  const getYesNoOptions = (
    patientInfo,
    questionNumber,
    cycleId,
    isTotalTab
  ) => {
    var optionsBase = {
      chart: {
        type: "line",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: [],
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
        reversed: false,
      },
      yAxis: {
        labels: {
          formatter: function () {
            return this.value;
          },
        },
        title: {
          text: "",
        },
      },
      plotOptions: {
        // column: {
        //   pointPadding: 0.2,
        //   borderWidth: 0,
        // },
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      series: [
        {
          name: "",
          data: [],
        },
      ],
      credits: {
        enabled: false,
      },
    };
    cycleId = currentCycleId;
    if (!cycleId) return optionsBase;
    const answer = patientInfo.chartLineInfo.find(
      (x) => x.cycleId === cycleId && x.questionNumber === questionNumber
    );

    if (!answer) return optionsBase;
    const rtaSi = answer.dataYN.map((x) => (x ? 1 : 0));
    //const rtaNo = answer.dataYN.map((x) => (!x ? 1 : 0));
    const options = {
      ...optionsBase,
      xAxis: {
        title: {
          text: "Instilaciones",
        },
        categories: answer.xCategories,
      },
      yAxis: {
        title: {
          text: "",
        },
        tickInterval: 1,
        range: 1,
        max: 1,
        labels: {
          formatter: function () {
            return this.value === 0 ? "NO" : "SI";
          },
        },
      },
      tooltip: {
        formatter: function () {
          return (
            "Instilación: <b>" +
            this.x +
            "<br><br/></b> EVA: <b>" +
            this.y +
            "</b>"
          );
        },
      },
      series: [
        {
          name: "Si",
          data: rtaSi,
        },
      ],
    };

    return options;
  };

  const onCloseHandle = () => {
    onClose();
  };

  const downloadPDF = async () => {
    const image1 = await domtoimage.toPng(chartRef1.current);
    const image11 = await domtoimage.toPng(chartRef11.current);
    const image2 = await domtoimage.toPng(chartRef2.current);
    const image3 = await domtoimage.toPng(chartRef3.current);
    const image4 = await domtoimage.toPng(chartRef4.current);
    const image5 = await domtoimage.toPng(chartRef5.current);
    const image6 = await domtoimage.toPng(chartRef6.current);

    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const pdfImage1 = await pdfDoc.embedPng(image1);
    const pdfImage11 = await pdfDoc.embedPng(image11);
    const pdfImage2 = await pdfDoc.embedPng(image2);
    const pdfImage3 = await pdfDoc.embedPng(image3);
    const pdfImage4 = await pdfDoc.embedPng(image4);
    const pdfImage5 = await pdfDoc.embedPng(image5);
    const pdfImage6 = await pdfDoc.embedPng(image6);

    page.drawImage(pdfImage1, {
      x: 40,
      y: page.getSize().height - 180,
      width: 250,
      height: 160,
    });

    page.drawImage(pdfImage11, {
      x: 310,
      y: page.getSize().height - 180,
      width: 250,
      height: 160,
    });

    page.drawImage(pdfImage2, {
      x: 40,
      y: page.getSize().height - 360,
      width: 250,
      height: 160,
    });

    page.drawImage(pdfImage3, {
      x: 310,
      y: page.getSize().height - 360,
      width: 250,
      height: 160,
    });

    page.drawImage(pdfImage4, {
      x: 40,
      y: page.getSize().height - 540,
      width: 250,
      height: 160,
    });

    page.drawImage(pdfImage5, {
      x: 310,
      y: page.getSize().height - 540,
      width: 250,
      height: 160,
    });

    page.drawImage(pdfImage6, {
      x: 40,
      y: page.getSize().height - 720,
      width: 250,
      height: 160,
    });

    const pdfBytes = await pdfDoc.save();
    saveAs(new Blob([pdfBytes], { type: "application/pdf" }), getPdfName());
  };

  const getPdfName = () => {
    const currentDate = new Date();
    const dia = String(currentDate.getDate()).padStart(2, "0");
    const mes = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    const fechaConcatenada = `${year}${mes}${dia}`;
    const cycleNameF = cycleName.replace(" ", "");
    const msj = `${namePdf}-${cycleNameF}-${fechaConcatenada}.pdf`;
    return msj;
  };

  return (
    <Dialog
      className={classes.root}
      open={open}
      keepMounted
      onClose={onCloseHandle}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Previsualización descargable"}
      </DialogTitle>
      <DialogContent>
        <div>
          <div className="div-center">
            <Box p={3}>
              <div ref={chartRef1}>
                <ColumnChart
                  questionTitle={getQuestionTitle(1)}
                  options={getYesNoOptions(
                    patientInfo,
                    1,
                    currentCycleId,
                    isTotalTab
                  )}
                />
              </div>
              <div ref={chartRef11}>
                <BasicLine
                  questionTitle={getQuestionTitle(11)}
                  options={getLineOptions(
                    patientInfo,
                    11,
                    currentCycleId,
                    isTotalTab
                  )}
                />
              </div>
              <div className="mt-3" ref={chartRef2}>
                <BasicLine
                  questionTitle={getQuestionTitle(2)}
                  options={getLineOptions(
                    patientInfo,
                    2,
                    currentCycleId,
                    isTotalTab
                  )}
                />
              </div>
              <div className="mt-3" ref={chartRef3}>
                <BasicLine
                  questionTitle={getQuestionTitle(3)}
                  options={getLineOptions(
                    patientInfo,
                    3,
                    currentCycleId,
                    isTotalTab
                  )}
                />
              </div>
              <div className="mt-3" ref={chartRef4}>
                <BasicLine
                  questionTitle={getQuestionTitle(4)}
                  options={getLineOptions(
                    patientInfo,
                    4,
                    currentCycleId,
                    isTotalTab
                  )}
                />
              </div>
              <div ref={chartRef5}>
                <ColumnChart
                  questionTitle={getQuestionTitle(5)}
                  options={getYesNoOptions(
                    patientInfo,
                    5,
                    currentCycleId,
                    isTotalTab
                  )}
                />
              </div>
              <div ref={chartRef6}>
                <ColumnChart
                  questionTitle={getQuestionTitle(6)}
                  options={getYesNoOptions(
                    patientInfo,
                    6,
                    currentCycleId,
                    isTotalTab
                  )}
                />
              </div>
            </Box>
          </div>
          <div className={"div-horizontal"}>
            <Button2 label="Cerrar" onClick={onCloseHandle} />
            <Button2 label="Descargar" onClick={downloadPDF} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewReportPatient;
