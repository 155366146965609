import React from "react";
import EducationCard1 from "../../../components/doctorComponents/articleCard/educationCard";

const Education1 = () => {
  return (
    <>
      <div className="educationInfo-innerContainer1">
        <div className="educationInfo-imgContainer">
          <EducationCard1 />
        </div>
      </div>
    </>
  );
};

export default Education1;
