import React from "react";

const RegisterHeader = (props) => {
  return (
    <div
      style={{
        background: "linear-gradient(to bottom left, #CF63CF, #9DE5FF)",
      }}
    >
      <div
        style={{
          backgroundImage: `url("${props.img}")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className="header-1"
      >
        <img
          className="ialurilLogoWhite"
          src="/doctor/logo-white-menu.svg"
          alt="IalurilLogoWhite"
        />

        <p className="header-1-title">{props.title}</p>

        <p className="header-1-text">{props.subtitle}</p>
      </div>
    </div>
  );
};

export default RegisterHeader;
