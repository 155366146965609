import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
`

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 14px;
    position: relative;
    z-index: 10;
    background: #894381;
    box-shadow: 0px 2px 4px 0px #343a4066;
`

export const Logo = styled.img`
    max-width: 102px;
    width: 100%;
`

export const ButtonMenu = styled.div`
    display: block;
    max-width: 16px;
    width: 100%;

    &.menu-open {
        span.first {
            top: 4px;
            transform: rotate(-45deg);
            transition: 0.32s;
        }

        span.last {
            bottom: 4px;
            transform: rotate(45deg);
            transition: 0.4s;
        }
    }
`

export const IconLine = styled.span`
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 32px;
    background-color: #ffffff;
    position: relative;
    transition: 0.3s;

    &.first {
        margin-bottom: 6px;
    }
`

export const MenuContainer = styled.div`
    display: block;
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    transform: translateX(200vw);
    transition: .3s;
    
    &.menu-open {
        background: #343a4059;
        transform: translateX(0);
        transition: .3s;
    }
`

export const Menu = styled.ul`
    display: block;
    max-width: 290px;
    width: 100%;
    padding: 50px 0;
    position: fixed;
    top: 50px;
    right: 0;
    bottom: 0;
    margin: 0;
    list-style: none;   
    background: #ffffff;
    box-sizing: border-box;
`

export const MenuItem = styled.li`

    a {
        display: block;
        width: 100%;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #343A40;
        text-decoration: none;
        padding: 12px 30px;
        box-sizing: border-box;
    }
`

export const MenuSeparator = styled.hr`
    max-width: 230px;
    width: 100%;
    margin: 30px auto;
    background: #B16DB3;
    border: 1px solid #B16DB3;
`
