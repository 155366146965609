import React from 'react'
import styles from '../../../styles/patientStyles/EducationInfo.module.css'
import HeaderWithMenu from '../../../components/patientComponents/component/headerWithMenu'
import BackButton from '../../../components/patientComponents/component/backButton'
import CautionCard from '../../../components/patientComponents/component/cautionCard'
import Footer from '../../../components/patientComponents/component/footer'
import RecommendationCard2 from '../../../components/patientComponents/component/Recommendations/recommendationCard2'

const Recommendation2 = () => {
  return (
    <>
      <div className={styles.innerContainer}>
        <HeaderWithMenu />
        <BackButton />
        <div className={`${styles.title}`}>
          El día de la instilación intravesical
        </div>
        <div className={styles.imgContainer}>
          <RecommendationCard2 />
        </div>
        <CautionCard text="El procedimiento de instilación dura pocos minutos y es ambulatorio, no presenta efectos secundarios." />
      </div>
      <Footer />
    </>
  )
}

export default Recommendation2
