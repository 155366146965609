import React from 'react'
import styled from 'styled-components'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from '@material-ui/core'

const RadioOutlinedInput = ({
  name,
  label,
  options,
  required,
  register,
  registerOptions,
  errors,
  onChange,
  ...remaining
}) => {
  const registerOption =
    registerOptions && registerOptions[name] ? registerOptions[name] : undefined

  let isError = false
  let errorMessage = ''
  if (errors && errors.hasOwnProperty(name)) {
    isError = true
    errorMessage = errors[name].message
  }

  const radioInput = register(name, registerOption)
  return (
    <StyledFormControl component="fieldset" fullWidth={true}>
      <StyledFormLabel component="legend">
        {label}
        {required ? <span style={{ color: 'red' }}> *</span> : ''}
      </StyledFormLabel>
      <RadioGroup
        aria-label={name}
        name={name}
        defaultValue=""
        error={isError ? 1 : 0}
        onChange={(e) => {
          radioInput.onChange(e)
          if (onChange) onChange(e)
        }}
        onBlur={radioInput.onBlur}
        ref={radioInput.ref}
        {...remaining}
      >
        {options.map((option, index) => (
          <StyledFormControlLabel
            key={index}
            value={option.value}
            control={
              <StyledRadio
                icon={<StyledRadioIcon />}
                checkedIcon={<StyledRadioCheckedIcon />}
              />
            }
            label={option.label}
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
      <StyledFormHelperText id={`${name}-helper-text`}>
        {errorMessage && (
          <>
            <ErrorIcon src="/patient/error-icon.svg" alert="Error icon" />
            {errorMessage}
          </>
        )}
      </StyledFormHelperText>
    </StyledFormControl>
  )
}

export default RadioOutlinedInput

// Overwriting with styled component

const StyledFormControl = styled(FormControl)`
    &.MuiFormControl-root {
        margin 5px 0;
    }    
`

const StyledFormLabel = styled(FormLabel)`
  &.MuiFormLabel-root {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0;
    color: #343a40;
    margin: 0 0 8px;

    &.Mui-focused {
      color: #343a40;
    }
  }
`

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 2px 40px 2px -5px;

    .MuiFormControlLabel-label {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      color: #343a40;
    }
  }
`

const StyledRadio = styled(Radio)`
  &.MuiIconButton-root {
    &.Mui-checked {
      color: #b16db3;
    }
  }
`

const StyledRadioIcon = styled.span`
  width: 16px;
  height: 16px;
  border: 1px solid #adb5bd;
  border-radius: 4px;
`

const StyledRadioCheckedIcon = styled.span`
  width: 16px;
  height: 16px;
  border: 1px solid #b16db3;
  border-radius: 4px;
  background: url('/patient/mini-tick.svg') center no-repeat #b16db3;
  background-size: 10px;
`

const StyledFormHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #d62424;

    &.MuiFormHelperText-contained {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
`

const ErrorIcon = styled.img`
  max-width: 20px;
  width: 100%;
  margin: 4px 5px 0 0;
  line-height: normal;
`
