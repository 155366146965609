import React from 'react'
import PageOk from '../../../../components/patientComponents/component/pageOk'

import styles from '../../../../styles/patientStyles/Register.module.css'
import {
  Text,
} from '../../../../components/patientComponents/styled-components/forgot-password/success/styled'

const EmailSent = () => {
  return (
    <PageOk>
      <div className={styles.container}>
        <img className={styles.img} src="/patient/letter.svg" alt="Sobre color rosa" />
        <Text logo="/patient/envelop-pink.svg" logoAlt="Sobre color rosa">
          Enviamos a su correo electrónico un enlace de verificación, siga las
          instrucciones para completar el registro
        </Text>
      </div>
    </PageOk>
  )
}

export default EmailSent
