import React from "react";
import { CssFormControlField, CssInputLabel, useStyles } from "../../../../styles/DoctorStyles/ui/filled-form";

import { OutlinedInput, FormHelperText } from "@material-ui/core";

const EmailFilledInput = ({
  name,
  label,
  required,
  register,
  registerOptions,
  errors,
  ...remaining
}) => {
  const classes = useStyles();

  const registerOption =
    registerOptions && registerOptions[name]
      ? registerOptions[name]
      : undefined;

  let isError = false;
  let errorMessage = "";
  if (errors && errors.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errors[name].message;
  }

  const handleInvalidDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <CssFormControlField
      size="small"
      className={classes.root}
      variant="filled"
    >
      <CssInputLabel
        className={classes.root}
        htmlFor={`outlined-adornment-${name}`}
      >
        {label}
        {required ? <span style={{ color: "red" }}> *</span> : ""}
      </CssInputLabel>
      <OutlinedInput
        {...register(name, registerOption)}
        id={`outlined-adornment-${name}`}
        className={classes.margin}
        type={"email"}
        defaultValue=""
        error={isError}
        {...remaining}
        aria-describedby="email-helper-text"
        onInvalid={handleInvalidDefault}
      />
      <FormHelperText style={{ margin: "-6px 0 0 0", display: "flex", alignItems: "center" }} id={`${name}-helper-text`}>
        {errorMessage && (
          <>
            <img src="/doctor/error-icon.svg" className="error-icon" alert="Error icon" alt="logo" />
            {errorMessage}
          </>
        )}
      </FormHelperText>
    </CssFormControlField>
  );
};

export default EmailFilledInput;
