import { Switch, Redirect, Route } from "react-router-dom";

//Routes
import PatientApp from "./patientRoutes";
import DoctorApp from "./doctorRoutes";

import MainPage from "../pages/mainPage";

//Theme
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { colorSatisfaction } from "../components/patientComponents/component/qualificationColors";
import Main404 from "./404";
import Main500 from "./500";
import { NewUserPassword } from "../pages/institutionPages/newUserPassword";
import SelectCountry from "../pages/selectCountry";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#b16db3",
    },
  },
  overrides: {
    MuiSlider: {
      root: {
        height: "4px",
        padding: "20px 0",
      },
      thumb: {
        width: "26px",
        height: "26px",
        marginTop: "-11px",
        marginLeft: "-12px",
        borderRadius: "4px",
        backgroundColor: (props) => colorSatisfaction[props.value].color,
      },
      valueLabel: {
        top: "7px",
        left: "calc(50% - 16px)",
      },
      mark: {
        width: "6px",
        height: "6px",
        borderRadius: "50%",
        bottom: "19px",
        backgroundColor: "#495057",
      },
      markActive: {
        opacity: "1",
        backgroundColor: "#495057",
      },
      rail: {
        width: "calc(100% + 2px)",
        height: "4px",
        opacity: "1",
        backgroundColor: "#495057",
      },
      track: {
        height: "4px",
        opacity: "0",
        backgroundColor: "#495057",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#B16DB3",
      },
    },
    MuiTypography: {
      body2: {
        fontFamily: '"Source Sans Pro", sans-serif',
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "15px",
      },
      subtitle1: {
        fontFamily: '"Source Sans Pro", sans-serif',
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
      },
    },
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        fontFamily: '"Source Sans Pro", sans-serif',
        fontWeight: "600",
        fontSize: "26px",
        lineHeight: "33px",
        textTransform: "capitalize",
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        textTransform: "capitalize",
      },
    },
    MuiPickersDay: {
      current: {
        color: "#B16DB3",
      },
      daySelected: {
        backgroundColor: "#B16DB3 !important",
        borderRadius: "6px",
      },
    },
    MuiButton: {
      textPrimary: {
        fontFamily: '"Source Sans Pro", sans-serif',
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#B16DB3",
      },
    },
  },
});

function IalurillApp() {
  return (
    <div>
      <div>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route exact path="/" render={() => <MainPage />} />
            <Route path="/patient" component={PatientApp} />
            <Route path="/doctor" component={DoctorApp} />
            <Route path="/selectCountry" component={SelectCountry} />
            <Route path="/apps">
              <Redirect to="/" />
            </Route>

            <Route
              path="/institution/newUserPassword"
              render={() => <NewUserPassword />}
            />

            <Route path="/404" render={() => <Main404 />} />
            <Route path="/500" render={() => <Main500 />} />
            <Route path="*">
              <Redirect to="/404" />
            </Route>
          </Switch>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default IalurillApp;
