import React, { useState, useEffect } from "react";
import Switch from "@material-ui/core/Switch";

const CountrySwitch = ({ currentCountry, onCountryChange }) => {
  const [selectedCountry, setSelectedCountry] = useState(currentCountry);

  useEffect(() => {
    setSelectedCountry(currentCountry);
  }, [currentCountry]);

  const handleCountryChange = () => {
    const newCountry = selectedCountry === "CO" ? "CR" : "CO";
    setSelectedCountry(newCountry);
    onCountryChange(newCountry);
  };

  return (
    <div className="country-switch">
      <div>
        <img
          src="/flags/colombia-flag.jpg"
          alt="colombia-flag"
          width="60"
          height="40"
          style={{
            cursor: "pointer",
            opacity: selectedCountry === "CO" ? 1 : 0.5,
            borderRadius: "5px",
          }}
          onClick={() => {
            setSelectedCountry("CO");
            onCountryChange("CO");
          }}
        />
      </div>

      {/* Switch */}
      <div style={{ display: "none" }}>
        <Switch
          checked={selectedCountry === "CO"}
          onChange={handleCountryChange}
        />
      </div>

      {/* Bandera del segundo país */}
      <div
        style={{
          marginLeft: "5px",
        }}
      >
        <img
          src="/flags/costa-rica-flag.jpg"
          alt="costa-rica-flag"
          width="60"
          height="40"
          style={{
            cursor: "pointer",
            opacity: selectedCountry === "CR" ? 1 : 0.5,
            borderRadius: "5px",
          }}
          onClick={() => {
            setSelectedCountry("CR");
            onCountryChange("CR");
          }}
        />
      </div>
    </div>
  );
};

export default CountrySwitch;
