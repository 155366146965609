import React from "react";

const RecommendationCard3 = () => {
  return (
    <>
      <div className="recommendationBox1">
        <div className="recommendationImgBox">
          <div className="numberBox">
            <p className="number">
              <b className="whiteBold">1</b>
            </p>
          </div>
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art3-01.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className="colorText">
            Con el fin de obtener mejores resultados con ialuril ®Prefill, es
            necesario que usted{" "}
            <b className="b">retenga la orina por el mayor tiempo posible</b>,
            se recomienda como <b className="b">minimo 30 minutos</b>
          </p>
        </div>
      </div>

      <div className="recommendationBox2">
        <div className="recommendationImgBox2">
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art3-02.png"
              alt="Info"
            />
          </div>
          <div className="numberBox2">
            <p className="number2">
            <b style={{color:"#b06db4"}}>2</b>
            </p>
          </div>
        </div>
        <div>
          <p className="whiteText">
            <b className="whiteBold">
              Realice cambios de posición luego del procedimiento
            </b>{" "}
            para que toda la vejiga se impregne con ialuril ®Prefill (sentado,
            acostado, boca arriba - abajo, de lado izquierdo y derecho)
          </p>
        </div>
      </div>

      <div className="recommendationBox5">
        <div className="recommendationImgBox">
          <div className="numberBox">
            <p className="number">
              <b className="whiteBold">3</b>
            </p>
          </div>
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art3-03.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className="colorText">
            Recomendamos que <b className="b">limite el consumo de líquidos</b>{" "}
            durante las <b className="b">2 horas siguientes</b> a la instilación
          </p>
        </div>
      </div>

      <div className="recommendationBox2">
        <div className="recommendationImgBox2">
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art3-04.png"
              alt="Info"
            />
          </div>
          <div className="numberBox2">
            <p className="number2">
            <b style={{color:"#b06db4"}}>4</b>
            </p>
          </div>
        </div>
        <div>
          <p className="whiteText">
            Recuerde <b className="whiteBold">lavar sus manos</b> antes y
            despues de ir al baño
          </p>
        </div>
      </div>

      <div className="recommendationBox3">
        <div className="recommendationImgBox">
          <div className="numberBox">
            <p className="number">
              <b className="whiteBold">5</b>
            </p>
          </div>
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art3-05.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className="colorText">
            Recuerde <b className="b">mantener una adecuada higiene</b> de sus
            genitales
          </p>
        </div>
      </div>
    </>
  );
};

export default RecommendationCard3;
