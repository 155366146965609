import React from 'react'
import styles from '../../../styles/patientStyles/EducationInfo.module.css'
import HeaderWithMenu from '../../../components/patientComponents/component/headerWithMenu'
import BackButton from '../../../components/patientComponents/component/backButton'
import CautionCard from '../../../components/patientComponents/component/cautionCard'
import RecommendationCard1 from '../../../components/patientComponents/component/Recommendations/recommendationCard1'
import Footer from '../../../components/patientComponents/component/footer'

const Recommendation1 = () => {
  return (
    <>
      <div className={styles.innerContainer}>
        <HeaderWithMenu />
        <BackButton />
        <div className={`${styles.title}`}>
          Antes de la instilación intravesical
        </div>
        <div className={styles.imgContainer}>
          <RecommendationCard1 />
        </div>
        <CautionCard text="Consulte a su médico tratante, si debe suspender la ingesta de algún medicamento" />
      </div>
      <Footer />
    </>
  )
}

export default Recommendation1
