import { getToken, onMessage } from "firebase/messaging";
import { messaging, config } from "./firebase";

export const getTokenMsg = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey: config.vapidKey,
    });
    return token;
  } catch (error) {
    console.log("Tuviste un error al generar el token");
  }
};