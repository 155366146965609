import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import Button2 from "../../doctorComponents/buttons/button-2";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextOutlinedInput from "../../doctorComponents/form/outlined-inputs/text";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "black,",
    "& .MuiDialog-paper": {
      margin: "16px",
    },
    "& .MuiDialogContent-root": {
      padding: "10px 10px 40px 10px",
    },
    "& .MuiTypography-h6": {
      fontFamily: " Source Sans Pro",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "22px",
      lineHeight: "28px",
      padding: "20px 0 0 10px",
      color: "#343A40",
    },
  },
  modalLabel: {
    width: "100%",
    maxWidth: "290px",
    margin: "0 auto 0 auto",
  }
  }
));

const AddSpecialty = ({ open, onClose, onAddSpecialty }) => {
  const classes = useStyles();

  const methods = useForm();
  const { register, handleSubmit, formState, reset } = methods;
  const { errors } = formState;

  const registerOptions = {
    name: { required: "Campo obligatorio" },
  };

  const onSubmit = handleSubmit((data) => {
    const obj = {
      specialty: data.specialty,
    }
    onAddSpecialty(obj, () => reset({ specialty: '' }))
  })

  return (
    <Dialog
      className={classes.root}
      open={open}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Agregar otra especialidad"}
      </DialogTitle>
      <DialogContent>
        <div>
          <TextOutlinedInput
            className={classes.modalLabel}
            name="specialty"
            label="Ingrese aquí su especialidad"
            placeholder="Ingrese aquí su especialidad"
            type={"text"}
            register={register}
            registerOptions={registerOptions}
            required
            errors={errors}
          />
          <div className={"div-horizontal"}>
            <Button2 label="Cerrar" onClick={onClose} />

            <Button2 label="Confirmar" onClick={onSubmit} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddSpecialty;
