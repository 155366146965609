import React from "react";
import { useHistory } from "react-router-dom";
import Button2 from "../../../components/doctorComponents/buttons/button-2";

const RecoverPasswordSuccess = () => {
  let history = useHistory();

  return (
    <>
      <div
        style={{
          height: "100vh",
        }}
        className="container"
      >
        <div className="inner-container">
          <div className="div-center">
            <img
              className="ialurilLogoWhite3"
              src="/doctor/logo-white-menu.svg"
              alt="IalurilLogoWhite"
            />
            <img className="letter" src="/doctor/confirmed.svg" alt="letter" />

            <p style={{ textAlign: "center" }} className="white-font-1">
              Su contraseña ha sido actualizada exitosamente, ya puede ingresar
              a su cuenta
            </p>

            <br />
            <br />

            <div className="div-center">
              <Button2 label="OK" onClick={()=>history.push('/doctor/login')}/>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoverPasswordSuccess;
