import React from "react";
import { getCountry } from "../../../../services/doctorServices/userService";

function showGreeting() {
  const fecha = new Date();
  const hour = fecha.getHours();

  let text = "Buenos Días,";

  if (hour >= 0 && hour < 12) {
    return text;
  }

  if (hour >= 12 && hour < 18) {
    text = "Buenas Tardes,";
    return text;
  }

  if (hour >= 18 && hour < 24) {
    text = "Buenas Noches,";
    return text;
  }
}

function showPicture() {
  const fecha = new Date();
  const hour = fecha.getHours();

  let img = "/doctor/Sun.svg";

  if (hour >= 0 && hour < 12) {
    return img;
  }

  if (hour >= 12 && hour < 18) {
    img = "/doctor/Sun2.svg";
    return img;
  }

  if (hour >= 18 && hour < 24) {
    img = "/doctor/Moon.svg";
    return img;
  }
}

const GreetingsHeader = (props) => {
  const country = getCountry();

  return (
    <div
      className="header-img"
      style={{
        background: `url("${props.img}") center no-repeat`,
        backgroundColor: "#9c9c9c",
      }}
    >
      <div
        style={{
          maxWidth: "576px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "flex-end",
        }}
        className="header-2"
      >
        <div className="greetings">
          <img src={showPicture()} alt="Sun" />
          <p className="header-2-greetings">{showGreeting()}</p>
        </div>
        <p className="header-2-name">{props.name}</p>
        <p className="header-register">
          {country === "CO" ? "Registro Invima 2014DM-0012306" : ""}
        </p>
      </div>
    </div>
  );
};

export default GreetingsHeader;
