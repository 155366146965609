import React from "react";
import { NotificationManager } from "react-notifications";

import {onMessage} from "firebase/messaging";
import {messaging} from "../../firebase/firebase";


const FirebaseListenNotification = () => {
  
  
  React.useEffect(() => {
    onMessage(messaging, (message) => {
      NotificationManager.success(message.notification.body, message.notification.title);
    })
  }, []);

  return (
    <div>
    </div>
  );
};

export default FirebaseListenNotification;
