import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Box, Icon } from "@material-ui/core";
import Button2 from "../buttons/button-2";
import { articlesGTM } from "../../../googleTagManager/doctor";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "80%",
  borderRadius: "6px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ArticleModalCard = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="card">
      <div className="card-title">
        <img src="/doctor/ArticleLogo.svg" alt="Art" />
        <p>{props.article}</p>
      </div>
      <div className="card-content">
        <p>{props.title}</p>
        <Link
          onClick={() => {
            articlesGTM(props.module, props.label);
            handleOpen();
          }}
        >
          Leer artículo
        </Link>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Icon className="fa fa-plus-circle" />
          <embed className="pdf-container" src={props.articlePath} />
          <div className="button-pdf-container">
            <a href={props.articlePathDownload} target="_blank" download>
              <Button2 label="Descargar" />
            </a>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ArticleModalCard;
