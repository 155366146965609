import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PatientFile from "../../../components/doctorComponents/patienFile/index";
import PatientFileCard from "../../../components/doctorComponents/patientFileCard";
import Modal from "../../../components/doctorComponents/patientFileCard/modal";
import { useHistory, Link } from "react-router-dom";

import { isRole } from "../../../services/doctorServices/userService";
import {
  RoleEnum,
  SurveyChartType,
} from "../../../constants/doctorConstants/enums";
import {
  callApi,
  getPatienInfo,
} from "../../../services/doctorServices/apiService";
import DoctorFile from "../../../components/doctorComponents/doctorFile/index";
import Progress from "../../../components/doctorComponents/Progress";
import InstitutionPatientFileCard from "../../../components/institutionComponents/patientFileCard";
import { useSelector } from "react-redux";


const PatientDetails = () => {
  const history = useHistory();
  const patientData = useSelector((state) => state.patientInfoState.patientInfo);
  const location = useLocation();
  const [patientInfo, setPatientInfo] = useState(null);

  const isRepresentative = isRole(RoleEnum.representative);
  const isInstitution = isRole(RoleEnum.institution);
  const [loader, setLoader] = useState(false);

  const [dlgDoctorInv, setDlgDoctorInv] = useState(false);
  const [currentDoctor, setCurrentDoctor] = useState(null);

  useEffect(() => {
    if (!location || !location.state){
      history.push("/doctor/patient-list");
      return;
    }
    if (isRepresentative) return;
    callApi(
      () => getPatienInfo(location.state.detail.patientId),
      setPatientInfo,
      setLoader
    );
  }, []);

  const getProp = (location, prop) => {
    if (!location || !location.state) return "";
    const patientData = location.state.detail;
    if (!patientData) return "";
    return patientData[prop];
  };

  if (isRepresentative) {
    return (
      <div className="container">
        {isRepresentative && (
          <Modal
            open={dlgDoctorInv}
            onClose={() => setDlgDoctorInv(false)}
            dataDoctor={currentDoctor}
          />
        )}
        <div className="inner-container">
          <PatientFileCard
            email={getProp(location, "email")}
            phone={getProp(location, "telephone")}
            name={getProp(location, "fullName")}
            cicle={getProp(location, "patientCount")}
            instillation={getProp(location, "surveyCount")}
            setCurrentDoctor={setCurrentDoctor}
            setDlgDoctorInv={setDlgDoctorInv}
            registered={getProp(location, "registered")}
            patientsSurveyed={getProp(location, "patientSurveyCount")}
            details={true}
          />
          <DoctorFile
            id={location.state.detail.doctorId}
            type={SurveyChartType.doctorDetail}
          />
        </div>
        <Progress loader={loader} />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="inner-container">
        {isInstitution && location.state ? (
          <InstitutionPatientFileCard
            name={getProp(location, "fullName")}
            cicle={getProp(location, "currentCycle")}
            instillation={getProp(location, "currentInstillation")}
            details={true}
            patientId={getProp(location, "patientId")}
          />
        ) : (
          <PatientFileCard
            name={getProp(location, "fullName")}
            cicle={getProp(location, "currentCycle")}
            instillation={getProp(location, "currentInstillation")}
            details={true}
          />
        )}

        <PatientFile patientInfo={patientInfo} />
      </div>

      <Progress loader={loader} />
    </div>
  );
};

export default PatientDetails;
