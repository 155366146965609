import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tab1 from "./tab-1";
import Tab3 from "./tab-3";
import AccordionBibliography from "../../../components/doctorComponents/accordion/accordion-2";
import CustomTable from "../../doctorComponents/customTable";
import {
  TableHeadStyle,
  TableBodyStyle,
} from "../../../constants/doctorConstants/enums";
import { isRole } from "../../../services/doctorServices/userService";
import { RoleEnum } from "../../../constants/doctorConstants/enums";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const AntTabs = withStyles({
  root: {
    boxShadow: "0 8px 6px -6px rgba(52, 58, 64, 0.4)",
  },
  indicator: {
    backgroundColor: "#AE5CA4",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 110,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&$selected": {
      color: "#FFFFFF",
      backgroundColor: "#AE5CA4",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#FFFFFF",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const TabsMenu = ({ patientInfo, questionNumber }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showTotalTab = (patientInfo) => {
    if (!patientInfo) return true;

    return patientInfo.totalCycles > 1;
  };

  let columns = [
    "Instilaciones",
    "Fecha de planeación",
    "Gestionado por",
    "Comentario",
  ];
  const getDataTable = (patientInfo) => {
    if (!patientInfo) return [[]];
    var data = patientInfo.surveysManagement.map((x) => {
      const obj = [x.instillationName, x.planningDate, x.nurse, x.comment];
      return obj;
    });
    return data;
  };
  const isInstitution = isRole(RoleEnum.institution);

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs value={value} onChange={handleChange} centered>
          {showTotalTab(patientInfo) && (
            <AntTab label="Total" {...a11yProps(0)} />
          )}
          <AntTab label="Ciclo" {...a11yProps(1)} />
          <AntTab label="Instilación" {...a11yProps(2)} />
        </AntTabs>
        <TabPanel value={0} index={0}>
          {showTotalTab(patientInfo) && value === 0 && 
            <Tab1 patientInfo={patientInfo} questionNumber={questionNumber} isTotalTab={true}/>
          }
          {((showTotalTab(patientInfo) && value === 1) || (!showTotalTab(patientInfo) && value === 0)) &&
            <Tab1 patientInfo={patientInfo} questionNumber={questionNumber} isTotalTab={false}/>
          }
          {((showTotalTab(patientInfo) && value === 2) || (!showTotalTab(patientInfo) && value === 1)) &&
            <>
                {isInstitution && (
                    <AccordionBibliography
                        title="Detalle Gestión"
                    >
                        <CustomTable
                            columns={columns}
                            rows={getDataTable(patientInfo)}
                            bodystyle={TableHeadStyle.white}
                            headstyle={TableBodyStyle.white}
                        />
                        <img
                            className="complete-width"
                            src="/doctor/Line-1.svg"
                            alt="Img"
                        />
                    </AccordionBibliography>
                )}
                <Tab3 patientInfo={patientInfo} questionNumber={questionNumber}/>
            </>
          }
        </TabPanel>
      </div>
    </div>
  );
};

export default TabsMenu;
