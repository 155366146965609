import React from "react";

import ArticleCard from "../../../components/doctorComponents/articleCard";

const Article3 = () => {
  return (
    <div>
      <ArticleCard
        article="ARTÍCULO 1"
        title="“Cistitis crónica bacteriana”"
        link="/doctor/module-3/chronic-bacterial-cystitis-3"
        module="module-3"
        label="chronic-bacterial-cystitis-3"
      />
      <ArticleCard
        article="ARTÍCULO 2"
        title="“Cistitis intersticial”"
        link="/doctor/module-3/interstitial-cystitis-3"
        module="module-3"
        label="interstitial-cystitis-3"
      />
      <ArticleCard
        article="ARTÍCULO 3"
        title="“Cistitis hemorrágica”"
        link="/doctor/module-3/hemorrhagic-cystitis-3"
        module="module-3"
        label="hemorrhagic-cystitis-3"
      />
    </div>
  );
};

export default Article3;
