import React from "react";

const EducationCard1 = () => {
  return (
    <>
      <div className="educationBox1">
        <p className="whiteText">
          Es una tecnica <b className="whiteBold">mínimamente invasiva</b> que
          consiste en la introducción de un catéter vesical (sonda) a través de
          la uretra, para la administración de una solución estéril (ialuril
          ®Prefill)
        </p>
      </div>
      <div className="imgContainer">
        <img
          className="imgEducation"
          src="/doctor/educacion-art1-01.png"
          alt="Info1"
        />
        <img
          className="imgEducation"
          src="/doctor/educacion-art1-02.png"
          alt="Info2"
        />
        <img
          className="imgEducation"
          src="/doctor/educacion-art1-03.png"
          alt="Info3"
        />
        <img className="imgEducation" src="/doctor/educacion-art1-04.png" alt="Info" />
      </div>
      <div className="educationBox3">
        <p className="whiteText">
          Se realiza{" "}
          <b className="whiteBold">
            con anestesia local y de forma ambulatoria
          </b>
          , es decir, sin requerir hospitalización
        </p>
      </div>
    </>
  );
};

export default EducationCard1;
