import React from "react";
import styles from "../../../../styles/patientStyles/components/RecommendationCard.module.css";
import { getCountry } from "../../../../services/doctorServices/userService";

const RecommendationCard1 = () => {
  const country = getCountry();
  return (
    <>
      <div className={styles.recommendationBox1}>
        <div className={styles.recommendationImgBox}>
          <div
            style={{
              height: "70px",
              display: "flex",
            }}
            className={styles.numberBox}
          >
            <p className={styles.number}>
              <b className={styles.whiteB}>1</b>
            </p>
          </div>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art1-01.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className={styles.colorText}>
            Antes de realizar su instilación intravesical{" "}
            <b className={`${styles.b}`}>comunique a su médico</b> tratante o
            profesional de la salud que va a realizar el procedimiento,{" "}
            <b className={`${styles.b}`}>
              cualquiera de los siguientes signos y/o síntomas:
            </b>
          </p>
          <div className={styles.list}>
            <p className={styles.colorText}>
              <b className={`${styles.b}`}>-ardor</b>
              <br />
              <b className={`${styles.b}`}>-mal olor de la orina</b>
              <br />
              <b className={`${styles.b}`}>-dolor</b>
            </p>
            <p className={styles.colorText}>
              <b className={`${styles.b}`}>-sangre en la orina</b>
              <br />
              <b className={`${styles.b}`}>-fiebre</b>
              <br />
              <b className={`${styles.b}`}>-dificultad para orinar</b>
            </p>
          </div>
        </div>
      </div>

      <div className={styles.recommendationBox2}>
        <div className={styles.recommendationImgBox2}>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art1-02.png"
              alt="Info"
            />
          </div>
          <div
            style={{
              height: "70px",
              display: "flex",
            }}
            className={styles.numberBox2}
          >
            <p className={styles.number2}>
              <b className={styles.purpleB}>2</b>
            </p>
          </div>
        </div>
        <div>
          <p className={styles.whiteText}>
            Para mayor facilidad <b className={`${styles.wb}`}>presentarse</b>{" "}
            al procedimiento <b className={`${styles.wb}`}>con ropa cómoda</b>
          </p>
        </div>
      </div>

      <div className={styles.recommendationBox5}>
        <div className={styles.recommendationImgBox}>
          <div
            style={{
              height: "70px",
              display: "flex",
            }}
            className={styles.numberBox}
          >
            <p className={styles.number}>
              <b className={styles.whiteB}>3</b>
            </p>
          </div>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art1-03.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className={styles.colorText}>
            En caso de que <b className={`${styles.b}`}>no pueda asistir</b> a
            su procedimiento, deberá{" "}
            <b className={`${styles.b}`}>
              comunicarse con su {country == 'CO' ? "I.P.S" : "C.C.S.S."}
            </b>{" "}
            a la mayor brevedad posible{" "}
            <b className={`${styles.b}`}>
              para la cancelación y/o reprogramacion
            </b>{" "}
            de la cita
          </p>
        </div>
      </div>

      <div className={styles.recommendationBox4}>
        <div className={styles.recommendationImgBox2}>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art1-04.png"
              alt="Info"
            />
          </div>
          <div
            style={{
              height: "70px",
              display: "flex",
            }}
            className={styles.numberBox2}
          >
            <p className={styles.number2}>
              <b className={styles.purpleB}>4</b>
            </p>
          </div>
        </div>
        <div>
          <p className={styles.whiteText}>
            Si su {country == 'CO' ? "I.P.S" : "C.C.S.S."} entregó su producto mantener las siguientes{" "}
            <b className={`${styles.wb}`}>recomendaciones de almacenamiento:</b>
          </p>
          <div className={styles.list}>
            <p className={styles.whiteText}>
              <b className={`${styles.wb}`}>
                -No conservar a temperatura superior a 25°
              </b>
              <br />
              <b className={`${styles.wb}`}>
                -Mantener en un sitio aireado, no expuesto a la luz directa
                solar ni a fuente de calor.
              </b>
              <br />
              <b className={`${styles.wb}`}>
                -No congelar ni mantener dentro de la nevera.
              </b>
              <br />
              <b className={`${styles.wb}`}>
                -Mantenerlo en un sitio limpio, no húmedo, y alejando de
                animales.
              </b>
              <br />
              <b className={`${styles.wb}`}>
                -No abrir la caja ni manipular su uso, solo para personal de
                salud.
              </b>
              <br />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecommendationCard1;
