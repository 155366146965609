import React, { useEffect } from 'react'
import styles from '../../../../styles/patientStyles/components/EducationCard.module.css'

const EducationCard3 = () => {
  return (
    <>
      <div className={styles.educationBoxTitle}>
        <p className={styles.whiteTextTitle}>
          <b style={{color: "#ffffff"}}>Verifique tener a mano:</b>
        </p>
      </div>
      <div className={`${styles.educationCard1}`}>
        <div className={`${styles.imgBox2}`}>
          <img
            className={`${styles.imgEducation2}`}
            src="/patient/edu-art3-01.png"
            alt="Info"
          />
        </div>
      </div>
      <div className={styles.educationBox2}>
        <p className={styles.number}>
          <b style={{color: "#ffffff"}}>1</b>
        </p>
        <p className={styles.whiteText}>
          La orden de aprobación vigente para el procedimiento de instilación
          intraversical
        </p>
      </div>
      <div className={`${styles.educationCard1}`}>
        <div className={`${styles.imgBox2}`}>
          <img
            className={`${styles.imgEducation2}`}
            src="/patient/edu-art3-02-06.png"
            alt="Info"
          />
        </div>
      </div>
      <div className={styles.educationBox3}>
        <p className={styles.number}>
          <b style={{color: "#ffffff"}}>2</b>
        </p>
        <p className={styles.whiteText}>
          La orden de aprobación del dispositivo médico ialuril ®Prefill
        </p>
      </div>
    </>
  )
}

export default EducationCard3
