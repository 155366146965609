import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/patientStyles/components/IalurilBox.module.css";

const IalurilBox = (props) => {

  return (
    <Link className={`${styles.box}`} to={props.href}>
      <div className={`${styles.box}`} >
        <img className={`${styles.boxImg}`} src={props.img} alt="Box image cap" />
        <div className={`${styles.boxTitle}`}>
          <p className={`${styles.title}`}>{props.title}</p>
          <img className={`${styles.icon}`} src="/patient/arrow-1.svg" alt="Info" />
        </div>
      </div>
    </Link>
  );
};

export default IalurilBox;
