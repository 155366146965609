import React from "react";
import VideoPlayer from "../../../components/doctorComponents/videos/VideoPlayer";

const DoctorVideosIaluadapter = () => {
  return (
    <div className="container">
      <VideoPlayer
        url="https://intelappnetauth.blob.core.windows.net/seranest/videos/UP-R_VID2D_IaluadapterMascu_03-05-2022VV.mp4"
        title="¿Cómo instilar ialuril® prefill con ialuadapter® en el paciente masculino?"
      />
      <VideoPlayer
        url="https://intelappnetauth.blob.core.windows.net/seranest/videos/UP-R_VID2D_IaluadapterFeme_03-05-22_VV.mp4"
        title="¿Cómo instilar ialuril® prefill con ialuadapter® en el paciente femenino?"
      />
      <VideoPlayer
        url="https://intelappnetauth.blob.core.windows.net/seranest/videos/entrevista_ingles_DrLovasz_V01.mp4"
        title="Video entrevista Dr. Lovasz eau2018"
      />
      <VideoPlayer
        url="https://intelappnetauth.blob.core.windows.net/seranest/videos/UP-R_Vi2d_Conferencia%20conclusiones_05-09_2022%20JP_final.mp4"
        title=" Conclusiones casos clínicos / Simposio “Eficacia clínica de los GAG en cistitis crónica multifactorial: Mitos y realidades”"
      />
    </div>
  );
};

export default DoctorVideosIaluadapter;
