import axios from 'axios'
/* import Router from 'next/router' */
import { saveUserInfo, signOut } from './authService'

function getTokenInfo() {
  var userInfo = localStorage.getItem('userInfo')
  if (userInfo) {
    userInfo = JSON.parse(userInfo)
    return { token: userInfo.token, refreshToken: userInfo.refreshToken }
  }
  return { token: '', refreshToken: '' }
}

let requestsToRefresh = []
let isRefreshRequesting = false

// Handling unexpected errors globally
axios.interceptors.response.use(undefined, (error) => {
  const originalRequest = error.config
  const API_URL = process.env.REACT_APP_URL_PATIENT

  if (originalRequest.url.includes(process.env.REACT_APP_SAS_GENERATOR)) return

  if (
    error.response &&
    (error.response.status === 403 || error.response.status === 401) &&
    !originalRequest._retry
  ) {
    if (!isRefreshRequesting) {
      isRefreshRequesting = true

      originalRequest._retry = true
      const tokenInfo = getTokenInfo()
      return axios
        .post(API_URL + '/token/refresh', tokenInfo)
        .then((res) => {
          if (res.status === 200) {
            saveUserInfo(res.data)
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + getTokenInfo().token

            requestsToRefresh.forEach((cb) => cb(true))
            return axios(originalRequest)
          }
        })
        .catch((error23) => {
          requestsToRefresh.forEach((cb) => cb(false))
          signOut()
          /* Router.push('/login') */
        })
        .finally(() => {
          requestsToRefresh = []
          isRefreshRequesting = false
        })
    }

    return new Promise((resolve, reject) => {
      requestsToRefresh.push((token) => {
        if (token) {
          resolve(axios(originalRequest))
        }
        reject(error)
      })
    })
  }

  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500
  if (!expectedError) console.log(error)

  return Promise.reject(error)
})

axios.interceptors.request.use(
  function (config) {
    if (config.url.includes(process.env.REACT_APP_SAS_GENERATOR)) return config

    const { token } = getTokenInfo()
    if (token) {
      config.headers.Authorization = `bearer ${token}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
}
