import React from "react";
import RegisterHeader from "../../components/doctorComponents/header/register-header";
import PreRegisterBanner from "../../components/doctorComponents/banners/pre-register-banner";

const RegisterLayout = (props) => {
  return (
    <>
      <RegisterHeader title="Registro de usuario" subtitle="Ingrese los datos requeridos a continuación para crear su perfil en el
          aplicativo" img="/doctor/bg-mobile-pattern.png" />
      <PreRegisterBanner />
      <div>
        <div className="main">{props.children}</div>
      </div>
     {/*  <Footer /> */}
    </>
  );
};

export default RegisterLayout;
