import React from "react";
import CustomTable from "../../doctorComponents/customTable";
import {
    TableHeadStyle,
    TableBodyStyle,
  } from "../../../constants/doctorConstants/enums";

const PatientToInstill = (patients) => {
  let columns = [
    "Fecha de Instilacion",
    "Paciente",
    "Correo Electronico",
  ];

  const getDataTable = (patientInfo) => {
    if (!patientInfo || patientInfo.length === 0) return [[]];
    var data = patientInfo.map((x) => {
      const obj = [x.date.slice(0, -9), x.name, x.email];
      return obj;
    });
    return data;
  };

  return (
    <div className="file" style={{height: "500px"}}>
      <h1 className="title-1">PRÓXIMOS PACIENTES A INSTILAR</h1>
      <CustomTable
        columns={columns}
        rows={getDataTable(patients.patients)}
        bodystyle={TableHeadStyle.white}
        headstyle={TableBodyStyle.white}
      />
    </div>
  );
};

export default PatientToInstill;
