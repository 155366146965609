import React from 'react'
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form'
import {
  Container,
  HeaderLogo,
  Logo,
  Title,
  Text,
  Form,
  ButtonSend,
} from '../../../components/patientComponents/styled-components/forgot-password/styled'
import EmailOutlinedInput from '../../../components/patientComponents/component/form/outlined-inputs/email'
import { callApi, postRestorePassword } from '../../../services/patientServices/apiList'

const ForgotPassword = () => {
  let history = useHistory();
  const defaultValues = {
    deliveryDate: null,
    document: null,
    chk: false,
  }

  const methods = useForm({ defaultValues })
  const { register, handleSubmit, formState } = methods
  const { errors } = formState

  const onSubmit = handleSubmit((data) => {
    const user = { email: data.email }
    callApi(
      () => postRestorePassword(user),
      () => {
        history.push("/patient/forgot-password/success")
      }
    )
  })

  const registerOptions = {
    email: {
      required: 'Ingrese su correo electrónico',
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: 'Este formato de correo electrónico no es válido',
      },
    },
  }

  return (
    <>
      <Container>
        <HeaderLogo>
          <Logo src="/patient/logo-color.svg" alt="ialuriApp Logo" />
        </HeaderLogo>

        <Title>Restablecer contrase&ntilde;a</Title>
        <Text>
          Ingrese su correo electr&oacute;nico, a continuaci&oacute;n
          recibir&aacute; un enlace para restablecer su contrase&ntilde;a
        </Text>

        <Form onSubmit={onSubmit}>
          <EmailOutlinedInput
            name="email"
            label="Correo electrónico"
            required={true}
            register={register}
            registerOptions={registerOptions}
            errors={errors}
          />

          <ButtonSend>Enviar</ButtonSend>
        </Form>
      </Container>
    </>
  )
}

export default ForgotPassword
