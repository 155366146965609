import React from 'react'
import { Link } from "react-router-dom";
import styles from '../../styles/patientStyles/Profile.module.css'

const DialogProfile = () => {
  return (
    <div className={styles.containerBox}>
      <img className={styles.logo} src="/patient/logo-color.svg" alt="ialuriApp Logo" />
      <img className={`${styles.img}`} src="/patient/bladder.svg" alt="bladder" />
      <p className={styles.dialogText}>
        Para brindarle un seguimiento más preciso sobre su tratamiento con{' '}
        <b>ialuril ® Prefill </b>
        es necesario diligenciar algunos datos
      </p>

      <Link to="/patient/post-register">
        <button className={styles.buttonSmall}>
          <p className={styles.buttonContent}>Continuar</p>
        </button>
      </Link>
    </div>
  )
}

export default DialogProfile
