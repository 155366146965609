import React from "react";

import AccordionComponent from "../../../../components/doctorComponents/accordion/accordion-1";
import AccordionBibliography from "../../../../components/doctorComponents/accordion/accordion-2";

const HemorrhagicCystitis = () => {
  return (
    <div className="container">
      <div className="inner-container">
        <AccordionComponent article="hemorrhagic-cystitis" title="INTRODUCCIÓN">
          <div>
            <p>
              Su definición comprende los episodios crónicos de hematuria de
              origen vesical con curso biológico variable, que incluso puede
              llevar a la pérdida de la función vesical y en casos extremos
              choque hipovolémico y la muerte del paciente. Desde el punto de
              vista fisiopatológico, la cistitis hemorrágica{" "}
              <b>
                se produce por activación de múltiples factores inflamatorios
                (citoquinas pro-inflamatorias)
              </b>{" "}
              y que llevan a la destrucción urotelial: piroptosis vesical, lo
              cual expone los vasos sanguíneos a la luz vesical.
            </p>
            <b>Las principales causas son:</b>
            <ul>
              <li> la radioterapia</li>
              <li> los metabolitos quimioterapéuticos</li>
              <li>
                y las infecciones víricas en el contexto del trasplante de
                células madre hematopoyéticas.
              </li>
            </ul>
            <p>
              El diagnóstico es relativamente simple y establecer un tratamiento
              oportuno es mandatorio con derivación e irrigación vesical,
              cauterización por agentes hemostáticos y/o fulguración vesical,
              oxigenoterapia en algunos casos, y los tratamientos locales, en su
              mayoría empíricos y sintomáticos que impidan la progresión del
              sangrado. Es importante considerar que algunas de las medidas
              preventivas (el 2-marcaptoetanosulfonato de sodio, MESNA) permiten
              disminuir este riesgo para evitar recidivas cada vez más intensas
              y que deterioran la función vesical o comprometan el pronóstico
              vital.{" "}
            </p>
            <p>
              La cistitis hemorrágica es una complicación rara de la cual no se
              dispone de estudios clínicos de rigor epidemiológico con
              suficiente evidencia científica para las recomendaciones
              terapéuticas, por lo tanto las medidas indicadas tienen resultados
              variables que pretenden evitar el daño progresivo de la función
              vesical, la pérdida del órgano o incluso desenlaces fatales.{" "}
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="hemorrhagic-cystitis" title="EPIDEMIOLOGÍA Y FACTORES DE RIESGO">
          <div>
            <p>
              Los datos epidemiológicos disponibles son limitados debido a la
              frecuencia baja de esta enfermedad; sin embargo, el
              <b>envejecimiento de la población</b> y la incidencia cada vez
              mayor de
              <b>
                tratamientos para el cáncer (radioterapia y quimioterapia)
              </b>{" "}
              hace que se incremente la frecuencia de casos graves de cistitis
              hemorrágica, en los cuales el sangrado se produce en pacientes con
              comorbilidad elevada, requieren soportes médicos más invasivos e
              incluso procedimientos quirúrgicos radicales: cistectomía.
            </p>
            <b>
              Los factores de riesgo están asociados al uso de agentes químicos,
              la radiación ionizante y la hemorragia inducida por agentes
              bacterianos.
            </b>
            <p>
              En el uso de quimioterapia con <b>ciclofosfamida e ifosfamida</b>{" "}
              están implicados dos metabolitos que ofrecen un efecto directo
              sobre la mucosa (acroleina y cloroacetaldehído) y la incidencia de
              cistitis hemorrágica es variable en las diferentes instituciones.
              En una cohorte de 834 pacientes sometidos a trasplante de médula
              ósea la <b>frecuencia en severidad varía de un 3-13%</b>, con{" "}
              <b>aparición promedio a los 35 días</b> del procedimiento y
              <b>desaparición a las 3 semanas</b>.<sup>1</sup> Se menciona también un
              metaanálisis con 18 estudios comparativos con una frecuencia de
              cistitis hemorrágica más grave de 7,4% y donde el 10% de los
              pacientes tiene una evolución clínica desfavorable.<sup>2</sup> El riesgo de
              cistectomía en un estudio realizado en 1300 pacientes con
              ciclofosfamida era del 0.2%<sup>3</sup> y el riesgo de fallecimiento por la
              cistitis hemorrágica con riesgo de infección por citomegalovirus
              en una cohorte de 250 pacientes evaluado por Xu fue de 6.7% con un
              porcentaje de cistitis hemorrágica elevado del 28.8%.<sup><b>3,4</b></sup>{" "}
            </p>
            <p>
              Otros agentes químicos de uso intravesical como la{" "}
              <b>
                mitomicina C, la epirrubicina y el bacilo de Calmette- Guérin
                (BCG) pueden también producir cistitis hemorrágica
              </b>
              , con menor frecuencia y el curso es más favorable al suspender su
              aplicación.
            </p>
            <p>
              Con relación a la <b>radioterapia</b>, puede aparecer
              secundariamente la cistitis hemorrágica entre{" "}
              <b>5-10% de los casos<sup>5</sup></b> y depende del grado de toxicidad
              utilizado en los diferentes estudios clínicos. La reacción
              inflamatoria aparece desde las{" "}
              <b>8 semanas hasta 15 años después de aplicado el tratamiento</b>{" "}
              y puede ser recurrente produciendo pérdida de la función vesical
              con repercusión sobre el tracto urinario superior y el riñón.
              Estos casos progresivos pueden requerir tratamientos más radicales
              con escisión de la vejiga y derivaciones permanentes de la vía
              urinaria.<sup><b>6</b></sup>
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="hemorrhagic-cystitis" title="FISIOPATOLOGÍA">
          <div>
            <p>
              La activación de los mecanismos moleculares inflamatorios lleva a
              una reacción difusa en la pared vesical de muerte celular,
              denominada <b>piroptosis</b>, la cual es atribuida a la radiación
              ionizante, la acroleína, metabolito urinario de agentes
              quimioterapéuticos <b>(ciclofosfamida, ifosfamida)</b> y algunos
              patógenos bacterianos y virales.
            </p>
            <p>
              Veamos en resumen cada uno de estos factores etiopatogénicos:{" "}
            </p>
            <p>
              <b className="bold-purple">
                1) ACROLEINA (<i>Figura 1</i>):{" "}
              </b>{" "}
              es un metabolito hepático de la ciclofosfamida e ifosfamida, que
              se elimina por la orina y produce ulceración de la mucosa vesical,
              exponiendo la muscularis mucosae y los vasos del estroma. El daño
              que produce directamente en la mucosa se produce por exfoliación
              de proteínas y ruptura del DNA.<sup><b>7,8</b></sup> La acroleina produce
              activación indirecta de la xantina oxidasa y aldehído-hidrogenasa
              que forma radicales libres (peroxinitrito) que rompen los puentes
              del DNA y activan secuencialmente los mecanismos de reparación del
              DNA , disminuyendo las fuentes de energía de la célula<sup><b>9</b></sup> y
              produciendo finalmente la muerte celular.
            </p>

            <div className="imgBox">
              <img className="img" src="/doctor/doctor-img009.png" alt="Img" />
            </div>
            <p className="italics-gray">
              Figura 1. Estructura química de la acroleína, principal metabolito
              de la ciclofosfamida e ifosfamida y responsable de la piroptosis
              vesical
            </p>

            <p>
              <b className="bold-purple">2) RADIOTERAPIA: </b> la terapia
              actínica produce inicialmente la ruptura del DNA, activación de
              los sistemas de reparación y finalmente apoptosis celular
              urotelial. El efecto de la radiación penetra a la pared muscular
              vesical, produce endarteritis local, isquemia y atrofia secundaria
              en la pared.
            </p>
            <p>
              {" "}
              <b className="bold-purple">3) AGENTES PATÓGENOS: </b> la cistitis
              puede ser ocasionada por bacterias, virus (citomegalovirus, virus
              BK y adenovirus) o incluso levaduras, estas últimas con menor
              frecuencia. En la infección bacteriana (E. Coli) los pili tipo 1 y
              la adhesina FimH se une a las proteínas de membrana de las células
              uroteliales superficiales ocasionando lisis de las mismas y
              proliferación celular neutrófila. Esta reacción inflamatoria puede
              estabilizarse con el uso de antibióticos, evolucionar de manera
              favorable y detener la hematuria; sin embargo, según el contexto
              del paciente (inmunosupresión) o las características del patógeno
              puede producirse una respuesta inflamatoria mayor que lleva a la
              activación de las vías metabólicas (inflamasoma) e inducir
              apoptosis urotelial (piroptosis).
            </p>
            <p>
              <b className="bold-purple">Activación del inflamasoma vesical:</b>{" "}
              en condiciones anormales o patológicas las células generan señales
              aberrantes y producen la activación de las vías de transcripción
              (NFkB y MAP-cinasa) y un grupo de citocinas pro-inflamatorias,
              quimiocinas y factores de regulación de la respuesta inmunitaria ;
              <sup><b>10</b></sup> el sistema inmunitario posee{" "}
              <b>Receptores de Reconocimiento de Patrones (PRR)</b> dirigidos
              contra estas señales. Los <b>NLR (Node Like Receptors)</b>{" "}
              representan un grupo de receptores de reconocimiento de patrones
              (PRR) citoplasmáticos que regulan la secreción de citosinas y
              desencadenan la activación del complejo pro-inflamatorio (Caspase
              1 activating complex + apoptosis associated speck like protein,
              ASC) y constituyen el Inflamasoma, y por via de la caspasa 1,
              inducen la apoptosis.
            </p>
            <p>
              De los receptores NLR, el mejor identificado es el <b>NLRP3</b>,
              que se activa por la exposición a las moléculas irritantes
              (sílice, asbesto, aluminio), a los patógenos o moléculas
              secretadas por los patógenos DAMP (
              <i>Damage Associated Molecular Patterns</i>): estas moléculas son
              transportadas por los agentes patógenos como toxinas
              bacterianas. En defensa a estas bacterias extracelulares y
              levaduras se produce la{" "}
              <b>
                activación de la caspasa 1 y la secreción de citocinas
                proinflamatorias (IL-1B, IL-18, IL-33) y proapoptósicas
              </b>{" "}
              secundaria a la formación del inflamasoma inducido por el NLRP3.
              Estas citosinas también están asociadas a los fenómenos de
              autoinmunidad. (<i>Figura 2</i>)
            </p>

            <div className="imgBox">
              <img className="img" src="/doctor/module1-img3.png" alt="Img" />
            </div>
            <p className="italics-gray">
              Figura 2. Vías de formación del complejo del inflamasoma y
              efectores responsables de la destrucción del urotelio. PAMP, DAMP:
              damage associated molecular patterns; NLR: node like receptor.
            </p>

            <p>
              El inflamasoma es un mecanismo de defensa natural que puede
              generar una respuesta tóxica en los órganos afectados por la
              activación de las citocinas proinflamatorias y la caspasa 1. Al
              desencadenar la apoptosis en la pared vesical (piroptosis) y
              posterior reparación inadecuada del músculo detrusor, se alteran
              las propiedades fundamentales de la vejiga de adaptabilidad y
              contractilidad, además del síndrome hemorrágico desencadenado por
              la toxicidad local inducida por la radio y quimioterapia: los
              mecanismos de retrocontrol son ineficaces.<sup>11,12</sup>
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="hemorrhagic-cystitis" title="MORFOLOGÍA">
          <div>
            <p>
              Macroscópicamente el aspecto de la cistitis hemorrágica es una
              extensa abrasión de la mucosa vesical con exposición de tejido
              estromal subyacente, con severa reacción inflamatoria, depósitos
              de fibrina, engrosamiento del detrusor que en casos progresivos
              produce esclerosis y deterioro irreversible de la función vesical.
              La exposición del estroma submucoso ocasiona la ruptura
              microvascular y sangrado consecuente. Los casos refractarios
              pueden comprometer el pronóstico vital. (<i>Figura 3</i>){" "}
            </p>
            <img className="img" src="/doctor/doctor-img011.png" alt="Img" />
            <p className="italics-gray">
              Figura 3. Imagen macroscópica de una cistitis hemorrágica donde se
              observa la severa reacción inflamatoria de la pared con
              exfacelación y pérdida de la mucosa, exposición del estroma
              submucoso con áreas de sangrado extenso.
            </p>

            <p>
              Desde el punto de vista microscópico, se aprecian en la{" "}
              (<i>figura 4.</i>) Los cambios histológicos principales de la
              reacción inflamatoria por el daño progresivo de los diferentes
              factores asociados con la apoptosis en la pared vesical.
            </p>

            <img className="img" src="/doctor/module1-img1.png" alt="Img" />
            <p className="italics-gray">
              Figura 4.Características microscópicas de la piroptosis vesical:
              A. Infiltración linfocitaria. B. Abrasión urotelial. C. Exposición
              de la membrana basal D. Severa reacción inflamatoria estromal
            </p>
          </div>
        </AccordionComponent>

        <AccordionBibliography title="BIBLIOGRAFIA">
          <div>
            <p className="bibliography-font">
              {" "}
              <b>1.</b> Hassan Z. Management of refractory hemorrhagic cystitis
              following hematopoietic stem cell transplantation in children.
              Pediatr Transplant 2011;15:348–61.
            </p>
            <p className="bibliography-font">
              <b>2.</b> Shi-Xia X. Total body irradiation plus cyclophosphamide
              versus busulphan with cyclophosphamide as conditioning regimen for
              patients with leukemia undergoing allogeneic stem cell
              transplantation: a meta-analysis. Leuk Lymphoma 2010;51:50–60.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>3.</b> Garderet L, Bittencourt H, Sebe P, Kaliski A, Claisse
              JP, Espérou H, et al. Cystectomy for severe hemorrhagic cystitis
              in allogeneic stem cell transplant recipients. Transplantation
              2000;70:1807–11.
            </p>
            <p className="bibliography-font">
              <b>4.</b>
              Xu LP, Zhang HY, Huang XJ, Liu KY, Liu DH, Han W, et al.
              Hemorrhagic cystitis following hematopoietic stem cell
              transplantation: incidence, risk factors and association with CMV
              reactivation and graft-versus-host disease. Chin Med J
              2007;120:1666–71.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>5.</b>
              Denton AS, Clarke NW, Maher EJ. Non-surgical interventions for
              late radiation cystitis in patients who have received radical
              radiotherapy to the pelvis. Cochrane Database Syst Rev
              2002;(3):CD001773.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>6.</b> Linder BJ, Tarrell RF, Boorjian SA. Cystectomy for
              refractory hemorrhagic cystitis: contemporary etiology,
              presentation and outcomes. J Urol 2014;192:1687–92.
            </p>
            <p className="bibliography-font">
              <b>7.</b>
              He X, Song W, Liu C, Chen S, Hua J. Rapamycin inhibits
              acrolein-induced apoptosis by alleviating ROS-driven mitochondrial
              dysfunction in male germ cells. Cell Prolif 2014;47: 161–71.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>8.</b> Korkmaz A, Topal T, Oter S. Pathophysiological aspects
              of cyclophosphamide and ifosfamide induced hemorrhagic cystitis;
              implication of reactive oxygen and nitrogen species as well as
              PARP activation. Cell Biol Toxicol 2007;23:303–12.
            </p>
            <p className="bibliography-font">
              <b>9.</b> Poeck H. Recognition of RNA virus by RIG-I results in
              activation of CARD9 and inflammasome signaling for interleukin
              1beta production. Nat Immunol 2010;11:63–9.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>10.</b>
              O’Neill LA, Bowie AG. The family of five: TIR-domain containing
              adaptors in Toll-like receptor signalling. Nat Rev Immunol
              2007;7:353–64.
            </p>
            <p className="bibliography-font">
              <b>11.</b>
              Padilla-Fernandez B, Bastida-Bermejo JM, Virseda-Rodriguez AJ,
              Labrador-Gomez J, Caballero-Barrigon D, Silva-Abuin JM.
              Hemorrhagic cystitis after bone marrow transplantation. Arch Esp
              Urol 2014;67:167–74.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>12.</b> deVries CR, Freiha FS. Hemorrhagic cystitis: a review.
              J Urol 1990;143:1–9.
            </p>
          </div>
        </AccordionBibliography>
      </div>
    </div>
  );
};

export default HemorrhagicCystitis;
