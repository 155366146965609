import React from "react";

import AccordionComponent from "../../../../components/doctorComponents/accordion/accordion-1";
import AccordionBibliography from "../../../../components/doctorComponents/accordion/accordion-2";

const HemorrhagicCystitis3 = () => {
  return (
    <div className="container">
      <div className="inner-container">
        <AccordionComponent article="hemorrhagic-cystitis" title="MEDIDAS PREVENTIVAS">
          <div>
            <p>
              La prescripción de MESNA 2-mercaptoetanosulfonato de sodio
              (MESNA), que neutraliza la acroleína, principal factor de las
              cistitis hemorrágicas inducidas por quimioterapia, y la
              hiperhidratación intravenosa con una irrigación vesical es la
              medida profiláctica más utilizada, con resultados variables.
            </p>
            <p>
              También parece que la irrigación continua es útil para reducir la
              duración promedio de la cistitis hemorrágica (10 vs 18 días) y,
              por lo tanto, de hospitalización (30 vs 39 días). La eficacia del
              MESNA en prevención de la cistitis hemorrágica ha sido objeto de
              varios ensayos aleatorizados. Los resultados son inconstantes y, a
              pesar de este tratamiento, alrededor del 33% de los pacientes
              presentan una cistitis hemorrágica. En el estudio más reciente de
              Jiang et al, la frecuencia de la cistitis hemorrágica disminuye
              significativamente en el grupo de pacientes tratados por vía
              intravenosa continua.<sup><b>1</b></sup>
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="hemorrhagic-cystitis" title="TRATAMIENTOS INTRAVESICALES">
          <div>
            <p>
              Se han evaluado varios tratamientos intravesicales, entre ellos el
              sulfato de condroitina, el ácido hialurónico, las prostaglandinas,
              el formaldehído y el alumbre, que es un derivado del aluminio.
            </p>
            <p>
              <b className="bold-purple">1) Condroitín sulfato:</b> esta
              sustancia puede tener un papel protector en la mayoría de los
              endotelios. En la vejiga, el sulfato de condroitina se ha probado
              como tratamiento sintomático de la cistitis y el dolor pélvico
              crónico.<sup><b>2</b></sup> Basándose en estudios en animales, la hipótesis era que
              la instilación de condroitin sulfato podía reducir el riesgo de
              cistitis postirradiación en los pacientes sometidos a radioterapia
              pélvica. Los resultados de un estudio prospectivo internacional
              sobre la protección con sulfato de condroitina de las cistitis
              asociadas a un posible déficit glucosaminoglicanos, que incluía
              también a los pacientes tratados con radioterapia.<sup><b>3</b></sup>
            </p>

            <p>
              <b className="bold-purple">2) Acido hialurónico:</b> el ácido
              hialurónico en instilación intravesical se ha mostrado eficaz en
              el tratamiento de la cistitis intersticial, con un efecto a largo
              plazo sobre el dolor y, más recientemente, en la prevención de la
              cistitis hemorrágica inducida por la quimioterapia y la
              radioterapia, también con un efecto protector prolongado y
              superior al de la oxigenoterapia hiperbárica.<sup><b>4</b></sup>
            </p>
            <p>
              {" "}
              En un estudio comparativo frente a placebo de la instilación
              intravesical de ácido hialurónico, Samper et al demuestran que el
              ácido hialurónico antes de la braquiterapia pélvica disminuyó
              significativamente la aparición de síntomas de cistitis (20,8%
              frente al 40,4%) y disminuye significativamente la toxicidad
              vesical de grado II (2,08% frente al 12,8%). Estos resultados se
              han confirmado en un estudio más reciente, que también demostraba
              un efecto protector del ácido hialurónico después de terapia con
              BCG.<sup><b>5</b></sup>
            </p>

            <p>
              <b className="bold-purple">3) Prostaglandinas:</b> varios estudios
              de cohortes y de casos clínicos sugieren que la instilación
              intravesical de prostaglandinas (PG) E1, E2 y F2 podría ser útil
              para prevenir o tratar la cistitis hemorrágica post-quimioterapia
              o radioterapia.
            </p>
            <p>
              El mecanismo probable de protección es la acción de las PG sobre
              la microvascularización submucosa, en particular la inducción de
              la agregación plaquetaria. Los datos disponibles proceden de
              estudios de cohortes, que demuestran que las instilaciones
              intravesicales de prostaglandina F2 son eficaces en más del 60%,
              pero con un efecto limitado a 7 días en un tercio de los
              pacientes. Se trata de estudios antiguos que no se han
              confirmado<sup><b>6,7</b></sup> y por lo tanto esta terapia no es recomendada.
            </p>
            <p>
              <b className="bold-purple">4) Formalina:</b> se ha evaluado la
              instilación intravesical de formalina (solución tamponada de
              formol al 10%) en la cistitis hemorrágica secundaria inducida por
              radio o quimioterapia con muy buenos resultados en términos de
              eficacia, con más del 80% de respuesta a este tratamiento, pero
              con una toxicidad demasiado importante.<sup><b>8,9</b></sup> En efecto, la
              toxicidad, que depende de la dosis, puede producir complicaciones
              importantes, como una fístula urinaria e intolerancia sistémica.
            </p>

            <p>
              <b className="bold-purple"> 5) Alumbre:</b> el alumbre de potasio
              es una sal (sulfato potásico de aluminio) administrada en forma de
              solución al 1%. Se utiliza en irrigación vesical continua a un pH
              de 4,5. Su acción se limita al urotelio. Provoca la contracción
              del espacio intersticial por precipitación de las proteínas. El
              resultado es un taponamiento vascular que disminuye el sangrado.
              La acción hemostática del alumbre de potasio en la cistitis
              hemorrágica ha sido objeto, inicialmente en 1982, de varios
              estudios con porcentajes de eficacia muy variables.<sup><b>10,11</b></sup> La
              toxicidad es neurológica, debido al paso de la sal a la
              circulación general.
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="hemorrhagic-cystitis" title="TRATAMIENTOS SISTÉMICOS">
          <div>
            <p>
              Se han utilizado varios tratamientos sistémicos, como la
              oxigenoterapia hiperbárica, los estrógenos a dosis elevadas, el
              pentosano polisulfato de sodio y los factores VII y VIII
              recombinantes, con porcentajes de respuesta muy variables.
            </p>
            <p>
              <b className="bold-purple"> 1) Oxígenoterapia hiperbárica: </b> la
              oxigenoterapia hiperbárica estimula la angiogénesis capilar y la
              cicatrización de los tejidos, y se ha explorado en el tratamiento
              de la cistitis postirradiación en fase hemorrágica.<sup><b>12</b></sup> Un análisis
              de la Cochrane demuestra que, de manera general, la oxigenoterapia
              mejora la cicatrización de las enfermedades de las vías aéreas
              superiores, del ano y del recto.<sup><b>13</b></sup>
            </p>
            <p>
              {" "}
              En el caso de la cistitis hemorrágica postirradiación, la eficacia
              inmediata de la oxigenoterapia hiperbárica es de más del 80%
              después de 20 sesiones a 3 bares y durante 90 minutos.<sup><b>14,15</b></sup> Hay
              estudios que demuestran que los resultados son muy heterogéneos e
              inconstantes, lo cual sugiere que el efecto terapéutico es
              aleatorio.<sup><b>16</b></sup>
            </p>
            <p>
              <b className="bold-purple"> 2) Estrógenos: </b> pueden utilizarse
              en varias situaciones de hemorragia visceral, teniendo en cuenta
              la acción moduladora de los derivados del 17 estradiol sobre el
              endotelio, que provoca una vasoconstricción de los capilares de
              las vísceras pélvicas.<sup><b>17</b></sup> Se han descrito en las publicaciones
              varios casos de cistitis hemorrágicas tratados con estrógenos por
              vía sistémica. Se trata de series limitadas que muestran una
              respuesta en más del 80% de los pacientes tratados. El riesgo de
              toxicidad hepática es bajo, inferior al 10%, y requiere la
              detención del tratamiento.<sup><b>18,19</b></sup>
            </p>
            <p>
              <b className="bold-purple">
                {" "}
                3) El pentosano polisulfato de sodio:{" "}
              </b>{" "}
              es un glucosaminoglucano semisintético, muy similar a la heparina,
              con propiedades anticoagulantes, antifibrinolíticas y
              anti-inflamatorias debido a la reducción de la respuesta
              inmunitaria a los estímulos inflamatorios.<sup><b>20</b></sup> Se trata de un
              tratamiento por vía oral que se elimina en un 5% por la orina, lo
              cual sin duda reduce su eficacia. El pentosano polisulfato de
              sodio puede utilizarse en instilación directa una vez que la
              molécula es vehiculada en microesferas y parece que tienen una
              mejor eficacia.<sup><b>21</b></sup>
            </p>
          </div>
        </AccordionComponent>

        <AccordionBibliography title="BIBLIOGRAFIA">
          <div>
            <p className="bibliography-font">
              {" "}
              <b>1.</b> Jiang Q,Huang H,Liu Q, Sun J,Zhou H, Fan Z, et al. Continuous
              IV infusion of MESNA can prevent hemorrhagic cystitis I HSCT and
              retain MESNA concentration in urine. Bone Marrow Transplant 2015;
              50:1490–2.
            </p>
            <p className="bibliography-font">
              <b>2.</b> Cicione A. Restoring the glycosaminoglycanslayer in
              recurrent cystitis: experimental and clinical foundations. Int J
              Urol 2014; 21:763–8.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>3.</b> Nordling J, van Ophoven A. Intravesical
              glycosaminoglycan replenishment with chondroitin sulphate in
              chronic forms of cystitis. A multinational, multicenter,
              prospective observational clinical trial. Arzneimittelforschung
              2008; 58:328–35.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>4.</b> Shao Y, Lu GL, Shen ZJ. Comparison of intravesical
              hyaluronic acid instillation and hyperbaric oxygen in the
              treatment of radiation-induced hemorrhagic cystitis. BJU Int 2012;
              109:691.
            </p>
            <p className="bibliography-font">
              <b>5.</b> Sommariva ML, Sandri SD, Ceriani V. Efficacy of sodium
              hyaluronate in the management of chemical and radiation cystitis.
              Minerva Urol Nefrol 2010; 62:145–50.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>6.</b> Ippoliti C. Intravesicular carboprost for the treatment
              of hemorrhagic cystitis after marrow transplantation. Urology
              1995; 46:811–5.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>7.</b> Levine LA, Jarrard DF. Treatment of cyclophosphamide
              induced hemorrhagic cystitis with intravesical carboprost
              tromethamine. J Urol 1993; 149:719–23.
            </p>
            <p className="bibliography-font">
              <b>8.</b> Lowe BA, Stamey TA. Endoscopic topical placement of
              formalin soaked pledgets to control localized hemorrhage due to
              radiation cystitis. J Urol 1997; 158:528–9.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>9.</b> Vicente J, Rios G, Caffaratti J. Intravesical formalin
              for the treatment of massive hemorrhagic cystitis: retrospective
              review of 25 cases. Eur Urol 1990; 18:204-6.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>10.</b> Ostroff EB, Chenault Jr OW. Alum irrigation for the
              control of massive bladder hemorrhage. J Urol 1982; 128:929–30.
            </p>
            <p className="bibliography-font">
              <b>11.</b> Arrizabalaga M. Treatment of massive haematuria with
              aluminous salts. Br J Urol 1987; 60:223–6.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>12.</b> Oliai C, Fisher B, Jani A, Wong M, Poli J, Brady LW, et
              al. Hyperbaric oxygen therapy for radiation-induced cystitis and
              proctitis. Int J Radiat Oncol Biol Phys 2012; 84:733–40.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>13.</b> Bennett MH.Hyperbaric oxygen therapy forlate radiation
              tissue injury. Cochrane Database Syst Rev 2016;(4):CD005005.
            </p>
            <p className="bibliography-font">
              <b>14.</b> Bevers RF, Bakker DJ, Kurth KH. Hyperbaric oxygen
              treatment for haemorrhagic radiation cystitis. Lancet 1995;
              346:803–5.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>15.</b> Corman JM. Treatment of radiation induced hemorrhagic
              cystitis with hyperbaric oxygen. J Urol 2003; 169:2200–2.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>16.</b> Mohamad Al-Ali B. Is treatment of hemorrhagic radiation
              cystitis with hyperbaric oxygen effective? Urol Int 2010;84:
              467–70.
            </p>
            <p className="bibliography-font">
              <b>17.</b> Sadhukhan PC, Tchetgen MB, Rackley RR, Vasavada SP,
              Liou.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>18.</b> Ba ZF, Lu A, Shimizu T, Szalay L, Schwacha MG, Rue 3<sup>rd</sup>
              LW, et al. 17beta-estradiol modulates vasoconstriction induced by
              endothelin-1 following trauma-hemorrhage. Am J Physiol Heart Circ
              Physiol 2007;292:H245–50.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>19.</b> L, Bandyopadhyay SK. Sodium pentosan polysulfate
              reduces urothelial responses to inflammatory stimuli via an
              indirect mechanism. J Urol 2002; 168:289–92.
            </p>
            <p className="bibliography-font">
              <b>20.</b> Sadhukhan PC, Tchetgen MB, Rackley RR, Vasavada SP,
              Liou L, Bandyopadhyay SK. Sodium pentosan polysulfate reduces
              urothelial responses to inflammatory stimuli via an indirect
              mechanism. J Urol 2002; 168:289–92.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>21.</b> Lander EB, See JR. Intravesical instillation of
              pentosan polysulfate encapsulated in a liposome nanocarrier for
              interstitial cystitis. Am J Clin Exp Urol 2014; 2:145–8.
            </p>
          </div>
        </AccordionBibliography>
      </div>
    </div>
  );
};

export default HemorrhagicCystitis3;
