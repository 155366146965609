import React from "react";

const PreRegisterBanner = () => {
  return (
    <div className="banner">
      <img className="banner-img" src="/doctor/ibanner.svg" alt="Img" />
      <p className="banner-1">
        Este contenido es de uso exclusivo para profesionales de la salud
      </p>
    </div>
  );
};

export default PreRegisterBanner;
