import React from "react";
import {StyleButtonXS, useStyles} from "../../../../styles/DoctorStyles/ui/buttons"

const ButtonXS = (props) => {
  const classes = useStyles();

  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <StyleButtonXS variant="contained" color="primary" className={classes.margin}{...props}>
        {props.label}
      </StyleButtonXS>
    </div>
  );
}

export default ButtonXS;
