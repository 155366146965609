import { configureStore } from "@reduxjs/toolkit";

import patientInfoReducer from "./patientInfoSlice";
import patientListSource from "./patientListSource";
import epsDetailReduce from "./epsDetailSlice";
import callReloadAlertsReducer from "./callReloadAlertsSlice";

import storage from "redux-persist/lib/storage";
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["patientInfoState", "patientListSourceState","epsDetailState","callReloadAlertsState"],
};


const rootReducer = combineReducers({
  patientInfoState: patientInfoReducer,
  patientListSourceState: patientListSource,
  epsDetailState:epsDetailReduce,
  callReloadAlertsState:callReloadAlertsReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
