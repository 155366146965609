import React from "react";

import ArticleCard from "../../../components/doctorComponents/articleCard";

const Article2 = () => {
  return (
    <div>
      <ArticleCard
        article="ARTÍCULO 1"
        title="“Cistitis crónica bacteriana”"
        link="/doctor/module-2/chronic-bacterial-cystitis"
        module="module-2"
        label="chronic-bacterial-cystitis"
      />
      <ArticleCard
        article="ARTÍCULO 2"
        title="“Cistitis intersticial”"
        link="/doctor/module-2/interstitial-cystitis"
        module="module-2"
        label="interstitial-cystitis"
      />
      <ArticleCard
        article="ARTÍCULO 3"
        title="“Cistitis hemorrágica”"
        link="/doctor/module-2/hemorrhagic-cystitis-2"
        module="module-2"
        label="hemorrhagic-cystitis-2"
      />
    </div>
  );
};

export default Article2;
