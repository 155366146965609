import React from 'react'
import styles from '../../../styles/patientStyles/Education.module.css'

import HeaderWithMenu from '../../../components/patientComponents/component/headerWithMenu/index'
import IalurilBox from '../../../components/patientComponents/component/ialurilBox'
import IalurilTextBox from '../../../components/patientComponents/component/ialurilTextBox'
import Footer from '../../../components/patientComponents/component/footer'

const PatientEducation = () => {
  return (
    <div>
      <HeaderWithMenu />
      <IalurilTextBox
        title="Información relevante"
        content="Aquí encontrará información educativa sobre su tratamiento con las instilaciones intravesicales de ialuril ® Prefill."
      />

      <div className={`${styles.boxContainer}`}>
        <div className={styles.innerContainer}>
          <IalurilBox
            img="/patient/educacion-1.png"
            title="¿Qué es una instilación intravesical?"
            href="/patient/education/what-is-an-intravesical-instillation"
          />
          <IalurilBox
            img="/patient/educacion-2.png"
            title="Este procedimiento ¿quién lo cubre?"
            href="/patient/education/who-covers-this-procedure"
          />
          <IalurilBox
            img="/patient/educacion-3.png"
            title="Documentos que debe tener en cuenta, cuando vaya a realizar su procedimiento"
            href="/patient/education/documentation-for-the-procedure"
          />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PatientEducation
