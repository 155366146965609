import { Switch, Route, Redirect } from "react-router-dom";

//pages
import Home from "../../pages/doctorPages/homePage/home";
import Dashboard from "../../pages/doctorPages/dashboard";
import PrivacyPolicy from "../../pages/doctorPages/privacyPolicy";
import TermsAndConditions from "../../pages/doctorPages/termsAndConditions";

import Message from "../../pages/doctorPages/login/message";
import Login from "../../pages/doctorPages/login/login";
import RecoverPassword from "../../pages/doctorPages/recoverPassword/recoverPassword";
import RecoverPasswordSend from "../../pages/doctorPages/recoverPassword/recoverPasswordSend";
import RecoverPasswordNew from "../../pages/doctorPages/recoverPassword/recoverPasswordNew";
import RecoverPasswordSuccess from "../../pages/doctorPages/recoverPassword/recoverPasswordSuccess";

import PatientList from "../../pages/doctorPages/patientList/patientList";
import PatientListIns from "../../pages/institutionPages/patientList/patientListIns";
import GeneralPatientList from "../../pages/doctorPages/patientList/generalPatientList";
import PatientDetails from "../../pages/doctorPages/patientList/patientDetails";

import ChangePassword from "../../pages/doctorPages/changePassword";
import Profile from "../../pages/doctorPages/profile/profile";

import Register from "../../pages/doctorPages/register/register";
import RegisterEmailSend from "../../pages/doctorPages/register/emailSend";
import RegisterSuccess from "../../pages/doctorPages/register/success";

import Article1 from "../../pages/doctorPages/articles/article1";
import Article2 from "../../pages/doctorPages/articles/article2";
import Article3 from "../../pages/doctorPages/articles/article3";
import Article4 from "../../pages/doctorPages/articles/article4";
import Article5 from "../../pages/doctorPages/articles/article5";
import Article6 from "../../pages/doctorPages/articles/article6";

import BasesOfChronicCystitisART from "../../pages/doctorPages/articles/module1/bases-of-chronic-cystitis";
import BacterialCystitisART from "../../pages/doctorPages/articles/module1/bacterial-cystitis";
import InterstitialCystitisPainfulBladderSyndrome from "../../pages/doctorPages/articles/module1/interstitial-cystitis-painful-bladder-syndrome";
import HemorrhagicCystitis from "../../pages/doctorPages/articles/module1/hemorrhagic-cystitis";

import ChronicbacterialCystitis from "../../pages/doctorPages/articles/module2/chronic-bacterial-cystitis";
import HemorrhagicCystitis2 from "../../pages/doctorPages/articles/module2/hemorrhagic-cystitis";
import InterstitialCystitis from "../../pages/doctorPages/articles/module2/interstitial-cystitis";

import HemorrhagicCystitis3 from "../../pages/doctorPages/articles/module3/hemorrhagic-cystitis";
import InterstitialCystitis3 from "../../pages/doctorPages/articles/module3/interstitial-cystitis";
import ChronicbacterialCystitis3 from "../../pages/doctorPages/articles/module3/chronic-bacterial-cystitis";

import IalurilIdealcomplement from "../../pages/doctorPages/articles/module4/Ialuril-the-ideal-complement";

import Education from "../../pages/doctorPages/education";
import Education1 from "../../pages/doctorPages/education/what-is-an-intravesical-instillation";
import Education2 from "../../pages/doctorPages/education/who-covers-this-procedure";
import Education3 from "../../pages/doctorPages/education/documentation-for-the-procedure";

import Recommendations from "../../pages/doctorPages/recommendation";
import Recommendation1 from "../../pages/doctorPages/recommendation/before-intravesical-instillation";
import Recommendation2 from "../../pages/doctorPages/recommendation/the-day-of-intravesical-instillation";
import Recommendation3 from "../../pages/doctorPages/recommendation/after-intravesical-instillation";

//layouts
import RegisterLayout from "../../layouts/doctorLayouts/RegisterLayout";
import BreadHeaderLayout from "../../layouts/doctorLayouts/BreadHeaderLayout";
import GreetingsHeaderLayout from "../../layouts/doctorLayouts/GreetingsHeaderLayout";
import LoginLayout from "../../layouts/doctorLayouts/LoginLayout";
import LoginLayout2 from "../../layouts/doctorLayouts/LoginLayout2";

import "../../assets/vendors/style";
import "../../App.css";
import Doctor404 from "../../pages/doctorPages/error/404";
import Doctor500 from "../../pages/doctorPages/error/500";
import AboutIaluril from "../../pages/doctorPages/articles/module4/about-ialuril";

//Protected Route
import DoctorProtectedRoute from "../../routes/protectedRoute/doctor";
import DoctorVideos from "../../pages/doctorPages/videos";
import Unauthorized from "../../pages/doctorPages/error/unauthorized";
import { RoleEnum } from "../../constants/doctorConstants/enums";
import DoctorVideosIaluadapter from "../../pages/doctorPages/videos/doctorVideosIaluadapter";
import EpsList from "../../pages/doctorPages/epsList/epsList";
import EpsDetails from "../../pages/doctorPages/epsList/epsDetails";
import Report from "../../pages/institutionPages/report";

//Google Tag Manager  GTM-TSVR5C7
import TagManager from "react-gtm-module";

import { isRole } from "../../services/doctorServices/userService";

const tagManagerArgs = {
  gtmId: "GTM-TSVR5C7",
};
TagManager.initialize(tagManagerArgs);

TagManager.dataLayer({
  dataLayer: {
    event: "pageview",
    path: "/doctor/dashboard",
  },
});

function DoctorApp() {
  const isInstitution = isRole(RoleEnum.institution);
  return (
    <div>
      <div>
        <Switch>
          <DoctorProtectedRoute
            exact
            path="/doctor/splash"
            render={() => <Home img="/doctor/bg-mobile-pattern.png" />}
          />
          <DoctorProtectedRoute
            exact
            path="/doctor"
            render={() => (
              <LoginLayout2 img="/doctor/bg-mobile-pattern.png">
                <Message />
              </LoginLayout2>
            )}
          />
          <DoctorProtectedRoute
            exact
            path="/doctor/login"
            render={() => (
              <LoginLayout img="/doctor/bg-mobile-pattern.png">
                <Login />
              </LoginLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            path="/doctor/recover-password"
            render={() => (
              <LoginLayout img="/doctor/bg-mobile-pattern.png">
                <RecoverPassword />
              </LoginLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            path="/doctor/recover-password-new"
            render={() => (
              <LoginLayout2 img="/doctor/bg-mobile-pattern.png">
                <RecoverPasswordNew />
              </LoginLayout2>
            )}
          />
          <DoctorProtectedRoute
            exact
            path="/doctor/recover-password-send"
            render={() => (
              <LoginLayout img="/doctor/bg-mobile-pattern.png">
                <RecoverPasswordSend />
              </LoginLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            path="/doctor/recover-password-success"
            render={() => (
              <LoginLayout img="/doctor/bg-mobile-pattern.png">
                <RecoverPasswordSuccess />
              </LoginLayout>
            )}
          />
          {/*{/*------------------------- Dashboard ------------------------- */}
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/dashboard"
            render={() => (
              <GreetingsHeaderLayout img="/doctor/bg-mobile-pattern.png">
                <Dashboard />
              </GreetingsHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/patient-list"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="LISTADO DE PACIENTES"
                subtitle="A continuación encontrará el listado de sus pacientes y la evolución de su tratamiento con ialuril ® Prefill"
              >
                {isInstitution ? (
                  <PatientListIns showBtnSurvey={true} />
                ) : (
                  <PatientList />
                )}
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/eps-list"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="LISTADO DE EPS"
                subtitle="A continuación encontrará el listado de las EPS asociadas a la institución"
              >
                <EpsList />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[RoleEnum.representative]}
            path="/doctor/doctor-list"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="LISTADO DE MÉDICOS"
                subtitle="A continuación encontrará el listado de los médicos asignados."
              >
                <PatientList />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[RoleEnum.doctor, RoleEnum.institution]}
            path="/doctor/patient-list/patient-details"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="FICHA DEL PACIENTE"
                subtitle="A continuación encontrará la sintomatología y evolución del paciente durante su tratamiento con ialuril ® Prefill"
              >
                <PatientDetails />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[RoleEnum.doctor, RoleEnum.institution]}
            path="/doctor/eps-list/eps-details/patient-list/patient-details"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="FICHA DEL PACIENTE"
                subtitle="A continuación encontrará la sintomatología y evolución del paciente durante su tratamiento con ialuril ® Prefill"
              >
                <PatientDetails />
              </BreadHeaderLayout>
            )}
          />

          <DoctorProtectedRoute
            exact
            role={[RoleEnum.institution]}
            path="/doctor/eps-list/eps-details"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="FICHA DE LA EPS"
                subtitle="A continuación encontrará la informacion de la EPS y de los pacientes durante sus tratamientos con ialuril ® Prefill"
              >
                <EpsDetails />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[RoleEnum.institution]}
            path="/doctor/eps-list/eps-details/patient-list"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="LISTADO DE PACIENTES POR EPS"
                subtitle="A continuación encontrará la lista de pacientes de la EPS durante sus tratamientos con ialuril ® Prefill"
              >
                <GeneralPatientList />
              </BreadHeaderLayout>
            )}
          />

          <DoctorProtectedRoute
            exact
            role={[RoleEnum.institution]}
            path="/doctor/report"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="REPORTE"
                subtitle="A continuación podrá descargar los reportes disponibles. Ialuril ® Prefill"
              >
                <Report/>
              </BreadHeaderLayout>
            )}
          />
          {/*{/*------------------------- 'Videos' ------------------------- */}
          <DoctorProtectedRoute
            exact
            role={[RoleEnum.representative]}
            path="/doctor/doctor-list/doctor-details"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="FICHA DEL MÉDICO"
                subtitle="A continuación encontrará la informacion y evolución del médico seleccionado"
              >
                <PatientDetails />
              </BreadHeaderLayout>
            )}
          />

          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/videos"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="VIDEOS"
              >
                <DoctorVideos />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            path="/doctor/videos-ialuadapter"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="VIDEOS IALUADAPTER"
              >
                <DoctorVideosIaluadapter />
              </BreadHeaderLayout>
            )}
          />
          {/*{/*------------------------- 'Profile' ------------------------- */}
          <DoctorProtectedRoute
            exact
            role={[RoleEnum.doctor, RoleEnum.representative]}
            path="/doctor/profile"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="Información de usuario"
                subtitle=""
              >
                <Profile />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[RoleEnum.doctor, RoleEnum.representative]}
            path="/doctor/change-password"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="Cambiar contraseña"
                subtitle="Registre y confirme la nueva contraseña para ingresar a su cuenta"
              >
                <ChangePassword />
              </BreadHeaderLayout>
            )}
          />

          {/*{/*------------------------- Register ------------------------- */}
          <DoctorProtectedRoute
            exact
            path="/doctor/register"
            render={() => (
              <RegisterLayout>
                <Register />
              </RegisterLayout>
            )}
          />
          <Route
            exact
            path="/doctor/register/email-send"
            render={() => (
              <LoginLayout img="/doctor/bg-mobile-pattern.png">
                <RegisterEmailSend />
              </LoginLayout>
            )}
          />
          <Route
            exact
            path="/doctor/register/success"
            render={() => (
              <LoginLayout img="/doctor/bg-mobile-pattern.png">
                <RegisterSuccess />
              </LoginLayout>
            )}
          />

          {/* {/*-------------------------Articulo 1 ------------------------- */}
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-1"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="MÓDULO 1: “BASES FISIOPATOLÓGICAS DE LA CISTITIS CRÓNICA”"
              >
                <Article1 />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-1/bases-of-chronic-cystitis"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="BASES FISIOPATOLÓGICAS DE LA CISTITIS CRÓNICA"
              >
                <BasesOfChronicCystitisART />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-1/bacterial-cystitis"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="CISTITIS BACTERIANA "
              >
                <BacterialCystitisART />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-1/interstitial-cystitis-painful-bladder-syndrome"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="CISTITIS INTERSTICIAL Y SÍNDROME DE VEJIGA DOLOROSA"
              >
                <InterstitialCystitisPainfulBladderSyndrome />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-1/hemorrhagic-cystitis"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="CISTITIS HEMORRÁGICA "
              >
                <HemorrhagicCystitis />
              </BreadHeaderLayout>
            )}
          />

          {/* {/*-------------------------Articulo 2/*------------------------- */}
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-2"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="MÓDULO 2: “EVALUACIÓN DIAGNÓSTICA”"
              >
                <Article2 />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-2/chronic-bacterial-cystitis"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="CISTITIS CRÓNICA BACTERIANA"
              >
                <ChronicbacterialCystitis />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-2/hemorrhagic-cystitis-2"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="CISTITIS HEMORRÁGICA"
              >
                <HemorrhagicCystitis2 />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-2/interstitial-cystitis"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="CISTITIS INTERSTICIAL "
              >
                <InterstitialCystitis />
              </BreadHeaderLayout>
            )}
          />

          {/*{/*------------------------- Articulo 3{/*------------------------- */}
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-3"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="MÓDULO 3: “ALTERNATIVAS DE TRATAMIENTO”"
              >
                <Article3 />
              </BreadHeaderLayout>
            )}
          />

          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-3/chronic-bacterial-cystitis-3"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="CISTITIS CRÓNICA BACTERIANA"
              >
                <ChronicbacterialCystitis3 />
              </BreadHeaderLayout>
            )}
          />

          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-3/interstitial-cystitis-3"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="CISTITIS INTERSTICIAL"
              >
                <InterstitialCystitis3 />
              </BreadHeaderLayout>
            )}
          />

          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-3/hemorrhagic-cystitis-3"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="CISTITIS HEMORRÁGICA"
              >
                <HemorrhagicCystitis3 />
              </BreadHeaderLayout>
            )}
          />

          {/*------------------------- Articulo 4 {/*-------------------------*/}
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-4"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="MÓDULO 4: “ialuril ® Prefill: LA NUEVA ERA EN CISTITIS CRÓNICA Y DOLOR PÉLVICO ASOCIADO”"
              >
                <Article4 />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-4/ialuril-the-ideal-complement"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="ialuril ® Prefill: LA NUEVA ERA EN CISTITIS CRÓNICA Y DOLOR PÉLVICO ASOCIADO"
              >
                <IalurilIdealcomplement />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-4/about-ialuril-Prefill"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="ACERCA DE ialuril ® Prefill"
              >
                <AboutIaluril />
              </BreadHeaderLayout>
            )}
          />
          {/*------------------------- Articulo 5 {/*-------------------------*/}
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/module-5"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="MÓDULO 5: NUEVOS ARTICULOS"
              >
                <Article5 />
              </BreadHeaderLayout>
            )}
          />
          {/*------------------------- Articulo 6 {/*-------------------------*/}
          <DoctorProtectedRoute
            exact
            path="/doctor/module-6"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="MÓDULO 6: MATERIAL IALUADAPTER"
              >
                <Article6 />
              </BreadHeaderLayout>
            )}
          />

          {/*-------------------------Patient Education ------------------------- */}

          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/patient-education"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="INFORMACIÓN RELEVANTE"
              >
                <Education />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/patient-education/what-is-an-intravesical-instillation"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="¿Qué es una instilación intravesical?"
              >
                <Education1 />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/patient-education/who-covers-this-procedure"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="Este procedimiento ¿quién lo cubre?"
              >
                <Education2 />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/patient-education/documentation-for-the-procedure"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="Documentos que debe tener en cuenta, cuando vaya a realizar su procedimiento"
              >
                <Education3 />
              </BreadHeaderLayout>
            )}
          />

          {/*-------------------------Patient Recommendations ------------------------- */}

          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/patient-recommendations"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="RECOMENDACIONES GENERALES"
              >
                <Recommendations />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/patient-recommendations/before-intravesical-instillation"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="Antes de la instilación intravesical"
              >
                <Recommendation1 />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/patient-recommendations/the-day-of-intravesical-instillation"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="El día de la instilación intravesical"
              >
                <Recommendation2 />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            role={[
              RoleEnum.doctor,
              RoleEnum.representative,
              RoleEnum.institution,
            ]}
            path="/doctor/patient-recommendations/after-intravesical-instillation"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="Después de la instilación intravesical"
              >
                <Recommendation3 />
              </BreadHeaderLayout>
            )}
          />
          {/*------------------------- terms and policy -------------------------*/}
          <DoctorProtectedRoute
            exact
            path="/doctor/privacy-policy"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="POLÍTICA DE PRIVACIDAD DE IALURILAPP"
              >
                <PrivacyPolicy />
              </BreadHeaderLayout>
            )}
          />
          <DoctorProtectedRoute
            exact
            path="/doctor/terms-and-conditions"
            render={() => (
              <BreadHeaderLayout
                img="/doctor/bg-mobile-pattern.png"
                title="TÉRMINOS Y CONDICIONES DE IALURILAPP"
              >
                <TermsAndConditions />
              </BreadHeaderLayout>
            )}
          />

          <DoctorProtectedRoute path="/doctor/apps">
            <Redirect to="/" />
          </DoctorProtectedRoute>

          {/*------------------------- Errors-------------------------*/}
          <DoctorProtectedRoute
            exact
            path="/doctor/500"
            render={() => <Doctor500 />}
          />
          <DoctorProtectedRoute
            exact
            path="/doctor/404"
            render={() => <Doctor404 />}
          />
          <DoctorProtectedRoute
            exact
            path="/doctor/unauthorized"
            render={() => <Unauthorized />}
          />
          <DoctorProtectedRoute path="*">
            <Redirect to="/doctor/404" />
          </DoctorProtectedRoute>
        </Switch>
      </div>
    </div>
  );
}

export default DoctorApp;
