import React, { useEffect, useState } from "react";
import styles from "../../../styles/patientStyles/Survey.module.css";
import { isRole } from "../../../services/doctorServices/userService";
import { useForm } from "react-hook-form";
import { RoleEnum } from "../../../constants/doctorConstants/enums";
import { Dialog } from "@material-ui/core";
import { Form } from "../../../components/patientComponents/styled-components/register/styled";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  callApi,
  postEndSurveyByInstitution,
} from "../../../services/patientServices/apiList";
import TextOutlinedInput from "../../patientComponents/component/form/outlined-inputs/text";
import { setCallReloadAlertsSource, selectCallReloadAlerts } from "../../../redux/callReloadAlertsSlice";
import { useDispatch } from "react-redux";

const EndSurvey = ({
  fnEndSurvey,
  finish,
  setFinishSurvey,
  instillationId,
  patientId,
  setIsNextClick,
  onNextClick
}) => {
  const isInstitution = isRole(RoleEnum.institution);
  const [openDlg, setOpenDlg] = useState(false);
  const dispatch = useDispatch();

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { register, handleSubmit, formState, watch, setValue } = methods;
  const { errors } = formState;

  const validationOptions = {
    firstnames: { required: "Debe ingresar al menos un nombre" },
    nurse: { required: "Debe ingresar el nombre de el enfermera(o)" },
  };

  useEffect(() => {
    if (finish && !isInstitution) {
      fnEndSurvey();
      return;
    }
    if (finish) {
      setOpenDlg(true);
    }
  }, [finish]);

  if (!isInstitution) {
    return "";
  }

  const handleCloseDlg = () => {
    onNextClick(false);
    setOpenDlg(false);
    setFinishSurvey(false);
  };

  const handleAdd = handleSubmit((data) => {
    var comment = {
      instillationId: instillationId,
      PatientId: patientId,
      nurse: data.nurse,
      comment: data.comment,
    };
    callApi(
      () => postEndSurveyByInstitution(comment),
      () => {
        setIsNextClick(true);
        dispatch(setCallReloadAlertsSource(true));
      }
    );
    setOpenDlg(false);
  });

  return (
    <div>
      <Dialog
        open={openDlg}
        keepMounted
        onClose={handleCloseDlg}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.dialogBox}>
          <DialogTitle>
            <p className={styles.title}>Detalle Gestión</p>
          </DialogTitle>
          <DialogContent style={{ display: "flex", flexDirection: "column" }}>
            <Form onSubmit={handleAdd}>
              <TextOutlinedInput
                name="nurse"
                label="Enfermera(o) quien atendio"
                type={"text"}
                register={register}
                registerOptions={validationOptions}
                errors={errors}
                required
              />
              <br />
              <TextOutlinedInput
                id="comment"
                name="comment"
                label="Comentarios"
                type={"text"}
                rows={5}
                multiline
                register={register}
                registerOptions={validationOptions}
                errors={errors}
                required
              />
            </Form>
          </DialogContent>
          <DialogActions>
            <button
              className={styles.confirmButtonA}
              onClick={handleCloseDlg}
              color="primary"
            >
              <p className={styles.buttonContentA}>Cancelar</p>
            </button>
            <button
              className={styles.confirmButtonB}
              onClick={handleAdd}
              color="primary"
            >
              <p className={styles.buttonContentB}>Aceptar</p>
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default EndSurvey;
