import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'

const SelectOutlinedInput = (props) => {
  const {
    name,
    label,
    options,
    required,
    register,
    registerOptions,
    errors,
    ...remaining
  } = props
  const [newData, setNewData] = useState([])

  useEffect(() => {
    const newOptions = options.map((data, index) => ({
      label: data.name,
      value: data.id,
    }))
    setNewData(newOptions)
  }, [options])

  const registerOption =
    registerOptions && registerOptions[name] ? registerOptions[name] : undefined

  let isError = false
  let errorMessage = ''
  if (errors && errors.hasOwnProperty(name)) {
    isError = true
    errorMessage = errors[name].message
  }

  const handleInvalidDefault = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <StyledFormControl variant="outlined" fullWidth={true}>
      <StyledInputLabel id={`select-outlined-label-${name}`}>
        {label}
        {required ? <span style={{ color: 'red' }}> *</span> : ''}
      </StyledInputLabel>
      <StyledSelect
        {...register(name, registerOption)} //
        labelId={`select-outlined-label-${name}`} //
        id={`select-outlined-${name}`} //
        name={name} //
        label={label} //
        defaultValue={props.multiple ? [] : ''} //
        error={isError} //
        onInvalid={handleInvalidDefault}
        aria-describedby="select-helper-text"
        labelWidth={0}
        IconComponent={(props) => (
          <ArrowIcon
            className={props.className}
            src="/patient/chevron-down.svg"
            alt="Arrow icon"
          />
        )}
        {...remaining}
      >
        {newData.map((option, index) => {
          return (
            <StyledMenuItem key={index} value={option.value}>
              {option.label}
            </StyledMenuItem>
          )
        })}
      </StyledSelect>
      <StyledFormHelperText id={`${name}-helper-text`}>
        {errorMessage && (
          <>
            <ErrorIcon src="/patient/error-icon.svg" alert="Error icon" />
            {errorMessage}
          </>
        )}
      </StyledFormHelperText>
    </StyledFormControl>
  )
}

export default SelectOutlinedInput

// Overwriting with styled component

const StyledFormControl = styled(FormControl)`
    &.MuiFormControl-root {
        margin 18px 0 5px;
    }    
`

const StyledInputLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    color: #6c757d;

    &.Mui-focused {
      color: #6c757d;
    }
  }

  &.MuiInputLabel-outlined {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    transform: translate(10px, 10px) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(10px, -18px) scale(0.75);
    }
  }
`

const StyledSelect = styled(Select)`
  &.MuiOutlinedInput-root {
    border-radius: 6px;

    fieldset legend {
      width: 0;
    }
  }

  &.Mui-focused {
    fieldset {
      border: 1px solid #b16db3 !important;
    }
  }

  .MuiSelect-root {
    padding: 11px 10px 10px;

    &:focus {
      background: transparent;
    }
  }
`

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    min-height: 40px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    border-bottom: 1px solid #ced4da;

    &:last-child {
      border-bottom: 0;
    }
  }
`

const StyledFormHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #d62424;

    &.MuiFormHelperText-contained {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
`

const ArrowIcon = styled.img`
  &.MuiSelect-icon {
    transition: 0.25s;
  }

  &.MuiSelect-iconOutlined {
    right: 10px;
  }
`

const ErrorIcon = styled.img`
  max-width: 20px;
  width: 100%;
  margin: 4px 5px 0 0;
  line-height: normal;
`
