import React from "react";

const Home = (props) => {
  return (
    <div
      style={{
        background: "linear-gradient(to bottom left, #CF63CF, #9DE5FF)",
      }}
    >
      <div
        style={{
          backgroundImage: `url("${props.img}")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className="homePage"
      >
        <img className="homeImg" src="/doctor/homeImg.svg" alt="homeImg" />
      </div>

    </div>
  );
};
export default Home;
