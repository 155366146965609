import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import SearchLabel from "../../../components/doctorComponents/form/search/searchLabel";
import PatientFileCard from "../../../components/doctorComponents/patientFileCard";
import {
  callApi,
  getPatientList,
  getPatientInstitutionList,
  getDoctorList,
} from "../../../services/doctorServices/apiService";
import { NotificationManager } from "react-notifications";
import Progress from "../../../components/doctorComponents/Progress";
import { isRole } from "../../../services/doctorServices/userService";
import { RoleEnum } from "../../../constants/doctorConstants/enums";
import Modal from "../../../components/doctorComponents/patientFileCard/modal";
import RepresentativeResume from "../../../components/doctorComponents/representativeResume/index";
import Autocomplete from "../../../components/doctorComponents/form/outlined-inputs/autocomplete";
import { Container } from "../../../components/patientComponents/styled-components/instillations/styled";

const filterList = (list, searchText, currentFilerStatus) => {
  let result = [...list];
  if (currentFilerStatus && currentFilerStatus > 1) {
    result = result.filter(
      (x) =>
        (currentFilerStatus === 2 && x.registered === true) ||
        (currentFilerStatus === 3 && x.registered === false)
    );
  }
  if (searchText) {
    const text = searchText.toUpperCase();
    result = result.filter(
      (x) => x.fullName && x.fullName.toUpperCase().includes(text)
    );
  }

  return result;
};

const PatientList = () => {
  const [loader, setLoader] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [currentDoctor, setCurrentDoctor] = useState(null);
  const [dlgDoctorInv, setDlgDoctorInv] = useState(false);

  const isRepresentative = isRole(RoleEnum.representative);
  const isInstitution = isRole(RoleEnum.institution);
  const [noRegisteredList, setNoRegisteredList] = useState([]);
  const [registeredList, setRegisteredList] = useState([]);
  const [currentFilerStatus, setCurrentFilerStatus] = useState(null);

  useEffect(() => {
    if (isRepresentative) {
      callApi(getDoctorList, (data) => {
        if (data && data.length === 0) {
          NotificationManager.warning("No tiene médicos asociados");
          return;
        }
        setNoRegisteredList(
          data.filter((noRegistered) => noRegistered.registered === false)
        );
        setRegisteredList(
          data.filter((docRegistered) => docRegistered.registered === true)
        );
        setPatientList(data);
      });
      return;
    }
    if (isInstitution) {
      callApi(
        getPatientInstitutionList,
        (data) => {
          if (data && data.length === 0) {
            NotificationManager.warning("No tiene pacientes asociados");
            return;
          }
          setPatientList(data);
        },
        setLoader
      );
      return;
    }
    callApi(
      getPatientList,
      (data) => {
        if (data && data.length === 0) {
          NotificationManager.warning("No tiene pacientes asociados");
          return;
        }
        setPatientList(data);
      },
      setLoader
    );
  }, []);

  const registrationStatusList = [
    { id: 1, name: "Todos" },
    { id: 2, name: "Médicos registrados" },
    { id: 3, name: "Médicos no registrados" },
  ];

  const defaultValues = { registrationStatus: 1 };
  const methods = useForm({ defaultValues });
  const { register, formState, watch, setValue } = methods;
  const { errors } = formState;

  const registrationStatus = watch("registrationStatus");
  useEffect(() => {
    setCurrentFilerStatus(registrationStatus);
  }, [registrationStatus]);

  return (
    <div className="container">
      <div className="inner-container">
        {isRepresentative && (
          <RepresentativeResume
            doctors={patientList.length}
            registered={registeredList.length}
            noRegistered={noRegisteredList.length}
          />
        )}
        {patientList && patientList.length > 0 && (
          <>
            {isRepresentative && (
              <Container>
                <div style={{ margin: "0 50px 0 50px" }}>
                  <Autocomplete
                    name="registrationStatus"
                    label="Estado"
                    options={registrationStatusList}
                    register={register}
                    errors={errors}
                    required
                    watch={watch}
                    setValue={setValue}
                  />
                </div>
              </Container>
            )}
            <SearchLabel
              placeholder={
                isRepresentative ? "Buscar médico" : "Buscar paciente"
              }
              setSearchText={setSearchText}
            />
          </>
        )}
        {isRepresentative && (
          <Modal
            open={dlgDoctorInv}
            onClose={() => setDlgDoctorInv(false)}
            dataDoctor={currentDoctor}
          />
        )}
        {filterList(patientList, searchText, currentFilerStatus).map(
          (item, index) => {
            if (isRepresentative) {
              return (
                <PatientFileCard
                  key={index}
                  item={item}
                  name={item.fullName}
                  cicle={item.patientCount}
                  instillation={item.surveyCount ?? ""}
                  patientsSurveyed={item.patientSurveyCount ?? ""}
                  registered={item.registered}
                  email={item.email ?? ""}
                  phone={item.telephone ?? ""}
                  link="/doctor/doctor-list/doctor-details"
                  setCurrentDoctor={setCurrentDoctor}
                  setDlgDoctorInv={setDlgDoctorInv}
                />
              );
            }

            return (
              <PatientFileCard
                key={index}
                item={item}
                name={item.fullName}
                cicle={item.currentCycle}
                instillation={item.currentInstillation ?? ""}
                link="/doctor/patient-list/patient-details"
              />
            );
          }
        )}
      </div>
      <Progress loader={loader} />
    </div>
  );
};

export default PatientList;
