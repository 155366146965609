import React, { useState } from 'react'

const ButtonAsync = ({ children, ...props }) => {
  const [executing, setExecuting] = useState(false)

  const { disabled, onClick, ...otherProps } = props

  const onRealClick = async (event) => {
    setExecuting(true)
    try {
      await onClick()
    } finally {
      setExecuting(false)
    }
  }

  return (
    <button
      onClick={onRealClick}
      disabled={executing || disabled}
      {...otherProps}
    >
      {children}
    </button>
  )
}

export default ButtonAsync
