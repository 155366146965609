import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, FormProvider } from "react-hook-form";
import {
  callApi,
  getMessageToDoc,
} from "../../../services/doctorServices/apiService";
import Button2 from "../../doctorComponents/buttons/button-2";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextOutlinedInput from "../../doctorComponents/form/outlined-inputs/text";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "black,",
    "& .MuiDialog-paper": {
      margin: "16px",
    },
    "& .MuiDialogContent-root": {
      padding: "10px 10px 40px 10px",
    },
    "& .MuiTypography-h6": {
      fontFamily: " Source Sans Pro",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "22px",
      lineHeight: "28px",
      padding: "20px 0 0 10px",
      color: "#343A40",
    },
  },
  modalLabel: {
    width: "100%",
    maxWidth: "400px",
    margin: "0 auto 0 auto",
  },
}));

const Modal = ({ open, onClose, dataDoctor }) => {
  const classes = useStyles();
  const [isRegistered, setIsRegistered] = useState(false);
  const defaultValues = {
    mobilePhone: "",
  };
  const defaultValues1 = {
    email: "",
  };
  const methods = useForm({ defaultValues });
  const { register, handleSubmit, setValue, watch, formState, reset } = methods;
  const { errors } = formState;
  const methods1 = useForm({ defaultValues: defaultValues1 });
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    watch: watch1,
    formState: formState1,
  } = methods1;
  const { errors: errors1 } = formState1;

  const onSubmit = handleSubmit((data) => {
    let mobilePhone= data.mobilePhone;
    if (isRegistered) {
      callApi(getMessageToDoc, (data) => {
        var msg = data.msgWhatsapp;
        var baseUrl = `https://web.whatsapp.com/send?phone=+57${mobilePhone}&text=${msg}&source&data&app_absent`;
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
        ) {
          baseUrl = `https://wa.me/+57${mobilePhone}?text=${msg}`;
        }
        window.open(baseUrl, "_blank");
      });
    } else {
      var msg =
        "Hola%20Dr%28a%29%2C%20lo%20invito%20a%20inscribirse%20a%20*https%3A//app.ialuril.co/doctor*%2C%20la%20herramienta%20tecnologica%20e%20innovadora%20que%20le%20permitira%20ver%20la%20evolucion%20de%20sus%20pacientes%20en%20tiempo%20real%20con%20el%20tratamiento%20de%20ialuril%20Prefill%20ahora%20con%20ialuadapter.";
      var baseUrl = `https://web.whatsapp.com/send?phone=+57${mobilePhone}&text=${msg}&source&data&app_absent`;
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        baseUrl = `https://wa.me/+57${mobilePhone}?text=${msg}`;
      }
      window.open(baseUrl, "_blank");
    }
  });
  const onSubmit1 = handleSubmit1((data) => {
    const obj = { email: data.email };
    if (isRegistered) {
      callApi(getMessageToDoc, (data) => {
        var subject = data.msgEmailTopic;
        var msg = data.msgEmailBody;
        window.location.href = `mailto:${data.email}?subject=${subject}&body=${msg}`;
      });
    } else {
      var msg =
        "Hola%20Dr%28a%29%2C%20lo%20invito%20a%20inscribirse%20a%20*https%3A//app.ialuril.co/doctor*%2C%20la%20herramienta%20tecnologica%20e%20innovadora%20que%20le%20permitira%20ver%20la%20evolucion%20de%20sus%20pacientes%20en%20tiempo%20real%20con%20el%20tratamiento%20de%20ialuril%20Prefill%20ahora%20con%20ialuadapter.";
      var subject = "IalurilApp - Invitación a Registrarse";
      window.location.href = `mailto:${data.email}?subject=${subject}&body=${msg}`;
    }
  });
  const registerOptions = {
    mobilePhone: {
      required: { value: true, message: "El número telefonico es requerido" },
      minLength: {
        value: 10,
        message: "El número es más corto de lo esperado",
      },
      maxLength: {
        value: 10,
        message: "El número es más largo de lo esperado",
      },
      pattern: {
        value: /^(?=.*[0-9])([0-9]+)$/,
        message: "Debe utilizar unicamente valores numericos",
      },
    },
  };
  const registerOptions1 = {
    email: {
      required: { value: true, message: "El correo electrónico es requerido" },
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: "Este correo electrónico no es válido",
      },
    },
  };
  const loadData = (obj) => {
    setValue1("email", !obj || !obj.email ? "" : obj.email);
    setValue("mobilePhone", !obj || !obj.phone ? "" : obj.phone);
  };
  const cleanData = () => {
    setValue1("email", "");
    setValue("mobilePhone", "" );
  };
  useEffect(() => {
    if (!dataDoctor) {
      return;
    }
    setTimeout(() => {
      setIsRegistered(dataDoctor.registered);
      loadData(dataDoctor);
    }, 500);
  }, [dataDoctor]);

  return (
    <Dialog
      className={classes.root}
      open={open}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Invitar Usuario"}
      </DialogTitle>
      <DialogContent>
        <div>
          <div className={"div-horizontal"}>
            <img
              src="/doctor/qr_code_ialuril_doctor.svg"
              alt="qr ialuril doctor"
              height={150}
            />
          </div>
          <FormProvider {...methods}>
            <div className={"div-horizontal"}>
              <TextOutlinedInput
                className={classes.modalLabel}
                name="mobilePhone"
                label="Telefono celular: +57"
                type={"text"}
                required
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                value={watch("mobilePhone")}
              />
              <Button2
                label="WhatsApp"
                onClick={() => {
                  onSubmit();
                }}
              />
            </div>
          </FormProvider>
          <FormProvider {...methods1}>
            <div className={"div-horizontal"}>
              <TextOutlinedInput
                className={classes.modalLabel}
                name="email"
                label="Correo electrónico"
                type={"text"}
                required
                register={register1}
                registerOptions={registerOptions1}
                errors={errors1}
                value={watch1("email")}
              />
              <Button2
                label="Correo"
                onClick={() => {
                  onSubmit1();
                }}
              />
            </div>
          </FormProvider>
          <div className={"div-horizontal"}>
            <Button2 label="Cerrar" onClick={onClose} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
