import React from "react";
import CautionCard from "../../../components/doctorComponents/articleCard/cautionCard";
import EducationCard3 from "../../../components/doctorComponents/articleCard/educationCard3";

const Education3 = () => {
  return (
    <>
      <div className="educationInfo-innerContainer1">
        <div className="educationInfo-imgContainer">
          <EducationCard3 />
        </div>
        <CautionCard
          text="En el caso que su dispensador le haya entregado el dispositivo médico
      ialuril® Prefill, debe llevarlo el día del procedimiento"
        />
      </div>
    </>
  );
};

export default Education3;
