import React, { useState } from 'react'
import DialogConfirm from '../dialogConfirm'
import styles from '../../../../styles/patientStyles/components/Instillation.module.css'
import SelectOutlinedInput from '../../component/form/outlined-inputs/select'

const getNroInstillations = () => {
  const options = [4, 5, 6, 7, 8, 9, 10, 11, 12]
  return options.map((x) => ({ id: x, name: x + '' }))
}

const AddCycle = (props) => {
  const { register, registerOptions, errors, watch, fnAddCycle, trigger } =
    props
  const [dlgConfirmFinish, setDlgConfirmFinish] = useState(false)

  const nroInstillationsValue = watch('nroInstillations')

  return (
    <>
      <SelectOutlinedInput
        name="nroInstillations"
        label="Número de instilaciones formuladas"
        placeholder="Seleccione número de instilaciones formuladas"
        register={register}
        registerOptions={registerOptions}
        options={getNroInstillations()}
        required
        errors={errors}
      />

      <button
        className={styles.button}
        onClick={async () => {
          const isOk = await trigger()
          if (!isOk) return
          setDlgConfirmFinish(true)
        }}
      >
        <p className={styles.buttonContent}>Confirmar</p>
      </button>

      <DialogConfirm
        openDialog={dlgConfirmFinish}
        handleRequestClose={() => setDlgConfirmFinish(false)}
        img="/patient/warning.svg"
        text={
          '¿Agregar ' +
          (nroInstillationsValue ? nroInstillationsValue : '') +
          ' instilaciones al ciclo?'
        }
        onAcceptClick={() => {
          setDlgConfirmFinish(false)
          fnAddCycle(nroInstillationsValue)
        }}
      />
    </>
  )
}

export default AddCycle
