import React from "react";
import { Link } from "react-router-dom";

const ArticleCardImg = (props) => {
  return (
    <div className="card-img">
      <Link to={props.link}>
        <div
          style={{
            backgroundImage: `url("${props.img}")`,
            backgroundRepeat: "round space",
            borderTopRightRadius: "6px",
            borderTopLeftRadius: "6px",
          }}
          className="card-title-img"
        >
          <p>{props.module}</p>
          <div className="card-title-2">
            <img src="/doctor/ArticleLogo.svg" alt="Art" />
            <p>{props.article}</p>
          </div>
        </div>
        <div className="card-content-2">
          <p>{props.title}</p>
        </div>
      </Link>
    </div>
  );
};

export default ArticleCardImg;
