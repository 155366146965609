import React from "react";
import VideoPlayer from "../../../components/doctorComponents/videos/VideoPlayer";

const DoctorVideos = () => {
  return (
    <div className="container">
       <VideoPlayer
        url="https://intelappnetauth.blob.core.windows.net/seranest/videos/Doc-Ceballos-Ialuril-Seranest.mp4"
        title="Uso de los GAG en cistitis hemorrágica tras radioterapia"
      />
       <VideoPlayer
        url="https://intelappnetauth.blob.core.windows.net/seranest/videos/UP-R_Vi2D_Estudio%20mistic_27-9-22.mp4"
        title="Evidencia de los GAG en el manejo de la cistitis Actínica"
      />
    </div>
  );
};

/* https://intelappnetauth.blob.core.windows.net/seranest/videos/Doc-Ceballos-Ialuril-Seranest.mp4 */
/* https://intelappnetauth.blob.core.windows.net/seranest/videos/Resultados-Prof-Ialuril-Seranest.mp4  */
/* https://intelappnetauth.blob.core.windows.net/seranest/videos/Prof-Ialuril-Seranest_06-08-2021.mp4 */

export default DoctorVideos;
