import React, { useEffect, useState } from "react";
import { 
    callApi,
    getNotifications
} from "../../../services/doctorServices/apiService";
import { useDispatch } from "react-redux";
import { setCallReloadAlertsSource, selectCallReloadAlerts } from "../../../redux/callReloadAlertsSlice";
import { useSelector } from "react-redux";

const AlertComponent = ({showMenu, setNotificationList }) => {
  const dispatch = useDispatch();
  const callReloadAlertsData = useSelector(selectCallReloadAlerts);
  const [notificationCount, setNotificationCount] = useState(0);
  const handleNotificationClick = () => {
    setShowNotification(!showNotification);
  };
  const [showNotification, setShowNotification] = useState(true);

  useEffect(() => {
    dispatch(setCallReloadAlertsSource(true));
  },[]);

  useEffect(() => {
    if(callReloadAlertsData == true){
      callApiFn();
    }
  },[callReloadAlertsData]);

  const callApiFn= () =>{
    callApi(getNotifications,
      (data) =>{
        setNotificationList(data);
        setNotificationCount(data.length);
        dispatch(setCallReloadAlertsSource(false));
      });
  }

  return (
    <div onClick={showMenu}>
      <div className="notification-bell" onClick={handleNotificationClick}>
        {notificationCount > 0 ? (
          <img
            className="file-icon"
            src="/doctor/bell-notification.svg"
            alt="icon"
          />
        ) : (
          <img className="file-icon" src="/doctor/bell.svg" alt="icon" />
        )}
        {notificationCount > 0 && (
          <span className="notification-count">{notificationCount}</span>
        )}
      </div>
    </div>
  );
};

export default AlertComponent;
