import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import PasswordOutlinedInput from "../../../components/doctorComponents/form/outlined-inputs/password";
import ConfirmPasswordOutlinedInput from "../../../components/doctorComponents/form/outlined-inputs/confirmPassword";
import { usePasswordValidation } from "../../../hook/doctorHook/usePasswordValidation";
import Button1 from "../../../components/doctorComponents/buttons/button-1";
import {
  callApi,
  postChangePassword,
} from "../../../services/doctorServices/apiService";

const ChangePassword = () => {
  let history = useHistory();

  const [passwordValidation, setPasswordValidation] = useState({
    firstPassword: "",
    secondPassword: "",
  });

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { register, handleSubmit, formState, watch } = methods;
  const { errors } = formState;

  const [validLength, hasNumber, match, hasLetter] = usePasswordValidation({
    firstPassword: passwordValidation.firstPassword,
    secondPassword: passwordValidation.secondPassword,
  });

  const password = useRef({});
  password.current = watch("password", "");

  const registerOptions = {
    password: {
      required: "Ingrese su contraseña",
      validate: {
        validLength: () => (validLength ? true : false),
        hasNumber: () => (hasNumber ? true : false),
        hasLetter: () => (hasLetter ? true : false),
      },
    },
  };

  const setFirst = (event) => {
    setPasswordValidation({
      ...passwordValidation,
      firstPassword: event.target.value,
    });
  };
  const setSecond = (event) => {
    setPasswordValidation({
      ...passwordValidation,
      secondPassword: event.target.value,
    });
  };

  const onSubmit = handleSubmit((data) => {
    const password = {
      currentPassword: data.currentPassword,
      newPassword: data.password,
    };

    callApi(
      () => postChangePassword(password),
      () => {
        NotificationManager.success("La operación se realizó correctamente");
        history.push("/doctor/dashboard");
      }
    );
  });

  return (
    <div className="container">
      <div className="inner-container">
        <PasswordOutlinedInput
          labelwith="210"
          name="currentPassword"
          label="Ingrese su contraseña actual"
          register={register}
          errors={errors}
          required={true}
        />

        <PasswordOutlinedInput
          name="password"
          labelwith="210"
          label="Ingrese su nueva contraseña"
          register={register}
          registerOptions={registerOptions}
          errors={errors}
          required={true}
          onChange={setFirst}
        />

        <ul className="validation-list">
          <li className="validation-item-list">
            <img
              src={
                !validLength
                  ? "/doctor/cross-red.svg"
                  : "/doctor/tick-green.svg"
              }
              alt="tic"
            />
            Que sea entre 6 y 16 caracteres
          </li>
          <li className="validation-item-list">
            <img
              src={
                !hasLetter ? "/doctor/cross-red.svg" : "/doctor/tick-green.svg"
              }
              alt="tic"
            />
            Incluya al menos una letra
          </li>
          <li className="validation-item-list">
            <img
              src={
                !hasNumber ? "/doctor/cross-red.svg" : "/doctor/tick-green.svg"
              }
              alt="tic"
            />
            Incluya al menos un número
          </li>
          <li className="validation-item-list">
            <img
              src={!match ? "/doctor/cross-red.svg" : "/doctor/tick-green.svg"}
              alt="tic"
            />
            Las contraseñas deben ser iguales
          </li>
        </ul>

        <ConfirmPasswordOutlinedInput
          name="password_repeat"
          label="Confirmar nueva contraseña"
          labelwith="215"
          register={register}
          registerOptions={registerOptions}
          errors={errors}
          required={true}
          onChange={setSecond}
        />
        <div className="div-center">
          <Button1 label="Actualizar contraseña" onClick={onSubmit} />
          <br />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
