import React, { useState, useEffect } from "react";
import CountrySwitch from "../../components/components/countrySwitch";
import { getCountry } from "../../services/doctorServices/userService";

const PrivacyPolicy = () => {
  const [currentCountry, setCurrentCountry] = useState(null);
  const handleCountryChange = (newCountry) => {
    setCurrentCountry(newCountry);
  };

  useEffect(() => {
    const getcountry = getCountry();

    if (getcountry && Object.keys(getcountry).length !== 0) {
      setCurrentCountry(getcountry);
    } else {
      setCurrentCountry("CO");
    }
  }, []);

  return (
    <div className="container" style={{ paddingTop: "15px" }}>
      <CountrySwitch
        currentCountry={currentCountry}
        onCountryChange={handleCountryChange}
      />

      <div className="inner-container">
        <p>
          A continuación, se expone la política de privacidad del sitio web
          ialuril App- página web Ialuril app médicos (en adelante, el “Sitio
          Web”), de titularidad de SerAnest Pharma Pharma. (en adelante,
          “SerAnest Pharma”).
        </p>

        <p className="subtitle-purple">1) Objeto</p>
        <p>
          El objeto de la presente política de privacidad es la protección de
          los datos personales y la privacidad de quienes interactúen con el
          Sitio Web, a fin de garantizar su derecho al honor y a la intimidad,
          como así también el acceso a la información que sobre ellas pudiera
          registrarse.
        </p>
        <p>
          Ha obtenido legalmente información y datos personales conforme a los
          lineamientos establecidos por la ley regulatoria del derecho al habeas
          data. asimismo, a través de la expedición de la presente política se
          da cumplimiento a lo previsto en el literal k del artículo 17 de la
          referida ley.
        </p>

        <p className="subtitle-purple">2) Definiciones</p>
        <p>
          A los fines de esta política de privacidad (en adelante, la “
          <b>Política de Privacidad</b>”), los términos “datos personales”,
          “datos sensibles”, “base de datos”, “tratamiento de datos”,
          “responsable de la base de datos”, “datos informatizados”, titular de
          los datos”, “usuario de datos” y “disociación de datos” tendrán el
          significado asignado por la Ley de Protección de Datos Personales y
          Habeas Data ley 1266 / 2008(en adelante, la “<b>LPDP</b>”).
        </p>

        <p className="subtitle-purple">
          3) Principios de protección de los datos personales
        </p>
        <p>
          SerAnest Pharma cumple con toda la normativa de protección de datos
          personales y en particular, con la LPDP y su ley estatutaria 1581
          /2012 (en adelante, la{" "}
          <b>“Normativa de Protección de Datos Personales”</b>).
        </p>
        <p>
          En particular, SerAnest Pharma cumple con todos los principios que
          surgen de la Normativa de Protección de Datos Personales, que incluyen
          pero no se limitan a: <b>(i)</b> el principio de legalidad, dado que
          las bases de datos de SerAnest Pharma que contienen datos personales
          se encuentran debidamente registradas por ante el Registro Nacional de
          Bases de Datos; <b>(ii)</b> el principio de calidad, dado que SerAnest
          Pharma únicamente recolecta aquellos datos personales que resultan
          necesarios y adecuados para la prestación de sus servicios y el uso de
          los Sitios Web, y lo hace por medios legales y respetando la intimidad
          de los titulares de dichos datos personales; <b>(iii)</b> el principio
          de finalidad, dado que SerAnest Pharma utiliza los datos personales
          recabados únicamente para el fin para el que fueron recolectados;{" "}
          <b>(iv)</b> el principio del consentimiento informado, dado que
          SerAnest Pharma recaba el consentimiento de los titulares de los datos
          personales en la forma prescripta por la LPDP siempre que ello resulta
          necesario por no encontrarse en alguna de las excepciones de dicha
          norma; y <b>(v)</b> los principios de seguridad y confidencialidad de
          la información, mediante la utilización de tecnología avanzada que
          permite resguardar adecuadamente –según el estado de la técnica- la
          confidencialidad y seguridad de la información.{" "}
        </p>
        <p>
          Asimismo, para garantizar la seguridad de los datos, SerAnest Pharma
          aplicará los mismos criterios y el mismo grado de diligencia que
          aplica para resguardar su propia información.
        </p>

        <p className="subtitle-purple">
          4) Recolección automatizada de datos personales
        </p>
        <p>
          SerAnest Pharma no recolectará en forma automatizada datos personales.
          El Sitio Web únicamente podrá recolectar en forma automatizada
          información disociada y estadística sobre la utilización del Sitio
          Web.
        </p>

        <p className="subtitle-purple">5) Recolección de datos personales</p>
        <p>
          Los formularios de recolección de datos personales del Sitio Web
          contienen las previsiones exigidas por la LPDP, en particular en lo
          referido al consentimiento informado.
        </p>
        <p>
          El titular de los datos personales es responsable por la veracidad y
          exactitud de la información que consigne en ellos, y asume la
          responsabilidad de mantener actualizada dicha información.
        </p>
        <p>
          SerAnest Pharma podrá utilizar medidas de validación específicas en
          los formularios para verificar que <b>(i)</b> no sean completados en
          forma automatizada y sin intervención directa e inmediata del usuario
          y <b>(ii)</b>
          el usuario cumpla los requisitos o posea las calidades necesarias para
          registrarse y/o proporcionar los datos personales requeridos.
        </p>

        <p className="subtitle-purple">6) Datos sensibles</p>
        <p>
          En ningún caso SerAnest Pharma recolectará automáticamente ni
          requerirá que el usuario proporcione datos sensibles.
        </p>

        <p className="subtitle-purple">
          7) Ejercicio de los derechos de acceso, actualización rectificación,
          supresión o confidencialidad
        </p>
        <p>
          A fin de ejercer los derechos de acceso, actualización, rectificación,
          supresión o confidencialidad respecto de sus datos personales, los
          usuarios del Sitio Web deberán informar en forma inmediata a SerAnest
          Pharma completando el formulario de contacto disponible en ialurilApp.
        </p>
        <p>
          Una vez verificada la identidad del titular de los datos personales,
          SerAnest Pharma permitirá el acceso y/o el ejercicio de los demás
          derechos, en forma gratuita a intervalos no menores de seis meses,
          salvo que acredite un interés legítimo al efecto, y en los términos
          previstos en la LPDP.
        </p>

        <p className="subtitle-purple">8) Plazo de conservación</p>
        <p>
          SerAnest Pharma conservará la información recolectada mientras la
          relación con el usuario esté vigente y por los plazos de prescripción
          correspondientes según la normativa vigente en la República de
          {currentCountry == "CO" ? "Colombia" : "Costa Rica"}.
        </p>

        <p className="subtitle-purple">
          9) Modificaciones a la Política de Privacidad
        </p>
        <p>
          Esta Política de Privacidad podrá ser modificada por SerAnest Pharma a
          fin de adecuarla a las disposiciones legales y reglamentarias
          vigentes. Las modificaciones se publicarán en el Sitio Web y entrarán
          en vigencia el mismo día de su publicación, salvo que dispongan lo
          contrario.
        </p>

        <p className="subtitle-purple">
          10) Forma de proceder respecto a las consultas y solicitudes hechas
          por los titulares de los datos
        </p>
        <p>
          todo titular de datos personales tiene derecho a realizar consultas y
          elevar solicitudes a la compañía respecto al manejo y tratamiento dado
          a su información.
        </p>
        <p style={{ color: "#894381" }}>
          a). Procedimiento para el trámite de reclamos o solicitudes:
        </p>
        <p>
          Toda solicitud, petición, queja o reclamo (pqr) que sea presentada a
          SerAnest pharma Ltda. por parte de cualquier titular o sus
          causahabientes respecto al manejo y tratamiento dado a su información
          será resuelta de conformidad con la ley regulatoria al derecho al
          habeas data y será tramitado bajo las siguientes reglas:
        </p>
        <p>
          a. La petición o reclamo se formulará mediante escrito o cualquier
          otro de los medios definidos en la presente política para tal fin,
          dirigido a SerAnest Pharma Ltda. con la identificación del titular, la
          descripción de los hechos que dan lugar al reclamo, la dirección o
          medio a través del cual desea obtener su respuesta, y si fuere el
          caso, acompañando los documentos de soporte que se quieran hacer
          valer. en caso de que el escrito resulte incompleto, la compañía
          solicitará al interesado para que subsane las fallas dentro de los
          cinco (5) días siguientes a la recepción del reclamo. transcurridos
          dos meses desde la fecha del requerimiento, sin que el solicitante
          presente la información requerida, se entenderá que ha desistido de la
          reclamación o petición.
        </p>
        <p>
          b. Una vez recibida la petición o reclamo completo, la compañía
          incluirá en el registro individual en un término no mayor a dos (2)
          días hábiles una leyenda que diga «reclamo en trámite» y la naturaleza
          del mismo. dicha información deberá mantenerse hasta que el reclamo
          sea decidido.
        </p>
        <p>
          c. El solicitante recibirá una respuesta por parte de SerAnest Pharma
          Ltda. dentro de los diez (10) días hábiles siguientes contados a
          partir de la fecha en la cual ha tenido conocimiento efectivo de la
          solicitud.
        </p>
        <p>
          d. Cuando no fuere posible atender la petición dentro de dicho
          término, se informará al interesado, expresando los motivos de la
          demora y señalando la fecha en que se atenderá su petición, la cual en
          ningún caso podrá superar los cinco (5) días hábiles siguientes al
          vencimiento del primer término.
        </p>

        <p style={{ color: "#894381" }}>b). Consultas:</p>
        <p>
          La política de manejo de la información personal por parte de SerAnest
          pharma Ltda. y los derechos básicos que los titulares de los datos
          tienen en relación con la misma podrán ser solicitados para su
          consulta mediante solicitud dirigida a la siguiente dirección de
          correo: calle 134 a No. 19-34 oficina 203 Bogotá, o llamando al (57) 1
          6331601 o a la siguiente dirección de correo electrónico: soporte
          técnico
        </p>
        <p>
          Cualquier consulta que tenga un titular sobre su información o datos
          personales o cuando considere necesario instaurar una solicitud de
          información o considere que sus derechos han sido vulnerados en
          relación con el uso y el manejo de su información; podrá hacerlo a
          través de cualquiera de los siguientes medios:
        </p>
        <ul>
          <li>
            <p>
              Por escrito, radicado en la siguiente dirección:{" "}
              {currentCountry == "CO"
                ? "calle 134 A no. 19-34 oficina 203 Bogotá, Colombia"
                : "CR-CONTENT"}{" "}
            </p>
          </li>
          <li>
            <p>
              Por escrito, enviando mensaje de correo electrónico a la siguiente
              dirección: servicioalcliente@seranestpharma.com
            </p>
          </li>
        </ul>
        <p>
          si dentro de los diez (10) días señalados, no fuere posible para la
          compañía atender la consulta, el área correspondiente deberá informar
          al interesado, los motivos de la demora e indicarle la fecha en que se
          atenderá la misma, la cual en ningún caso podrá superar los cinco (5)
          días hábiles siguientes al vencimiento del primer término.
        </p>

        <p style={{ color: "#894381" }}>c). responsable del tratamiento:</p>
        <p>
          SerAnest Pharma ltda. tiene la calidad de responsable y encargado del
          tratamiento, a través de la presente política se permite informar sus
          datos de identificación:
        </p>
        <p>
          <b>Razón social:</b> SerAnest pharma Ltda
        </p>
        <p>
          <b>nit: </b>830123305-0
        </p>
        <p>
          <b>Domicilio principal:</b>{" "}
          {currentCountry == "CO"
            ? "calle 134 A No. 19-34 oficina 203 Bogotá D.C."
            : "CR-CONTENT"}
        </p>
        <p>
          <b>
            Persona o dependencia responsable de la atención de peticiones,
            consultas y reclamos:
          </b>{" "}
          el área encargada de recibir y canalizar todas las solicitudes e
          inquietudes es la oficina de servicio al cliente en{" "}
          {currentCountry == "CO"
            ? "la carrera 7C No. 180-19 local 17 modulo 0 manzana 2, o llamando al (57) 1 6331601"
            : "CR-CONTENT"}
          .
        </p>
        <p className="subtitle-purple">10) Órgano de control</p>
        <p>
          La superintendencia de industria y comercio, en su carácter de Órgano
          de Control de{" "}
          {currentCountry == "CO" ? "la Ley 1581 de 2012" : "CR-CONTENT"} tiene
          la atribución de atender las denuncias y reclamos que interpongan
          quienes resulten afectados en sus derechos por incumplimiento de las
          normas vigentes en materia de protección de datos personales.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
