export const YesNoChart = (
  titleText,
  tooltipKey,
  locale,
  yesNoQuestionData,
  cycleIdValue,
  questionIdValue
) => {
  const data = yesNoQuestionData.filter(
    (x) => x.cycleName === cycleIdValue && x.questionNumber === questionIdValue
  );
  const categories = data.map((x) => x.instillationName);
  const serie1 = data.map((x) => {
    const yes = x.chartData.find((y) => y.key === "Sí");
    if (yes) return yes.value;
    return 0;
  });
  const serie2 = data.map((x) => {
    const no = x.chartData.find((y) => y.key === "No");
    if (no) return no.value;
    return 0;
  });

  return {
    chart: {
      type: "column",
    },
    title: {
      text: titleText,
    },
    xAxis: {
      categories: categories,
      crosshair: true,
      title: { text: "Instilación" },
    },
    yAxis: {
      min: 0,
      title: {
        text: "No. de pacientes",
      },
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:10px">Instilación: {point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Sí",
        data: serie1,
      },
      {
        name: "No",
        data: serie2,
      },
    ],
    credits: {
      enabled: false
    }
  };
};

export const QuestionChart = (
  titleText,
  tooltipKey,
  locale,
  questionData,
  cycleIdValue,
  questionIdValue
) => {
  const data = questionData.filter(
    (x) => x.cycleName === cycleIdValue && x.questionNumber === questionIdValue
  );
  const categories = !data || data.length === 0 ? [] : data[0].categories;
  const series = !data || data.length === 0 ? [] : data[0].series;

  return {
    credits: { enabled: false },
    chart: { type: "column" },
    title: { text: titleText },
    xAxis: {
      categories: categories,
      crosshair: true,
      title: { text: "Instilación" },
    },
    yAxis: {
      min: 0,
      title: {
        text: "No. de pacientes",
      },
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:10px">Instilación: {point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: series,
  };
};

export const LineChart = (
  titleText,
  tooltipKey,
  locale,
  questionData,
  cycleIdValue,
  questionIdValue
  ) => {

    const data = questionData.filter((x) => x.cycleName === cycleIdValue && x.questionNumber === 11);
    const categories = !data || data.length === 0 ? undefined : data[0].categories;
    const dataChart = !data || data.length === 0 ? [] : data[0].series[0].data;

  return {
    chart: { type: 'line' },
    title: { text: titleText },
    xAxis: { 
      categories: categories,
      title: { text: "Instilación" }, 
    },
    legend: {
      enabled: false,
      reversed: false,
    },
    yAxis: {
      labels: {
        formatter: function () {
          return this.value;
        },
      },
      title: {
          text: 'Nivel de dolor (Escala visual análoga EVA)'
      },  
    },
    plotOptions: {
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
    },
    series: [{
        name: '',
        data: dataChart
    }],
    credits: {
      enabled: false
    }
  };
};

export const OneLineChart = (
  titleText,
  tooltipKey,
  locale,
  yesNoQuestionData,
  cycleIdValue,
  questionIdValue
) => {
  const data = yesNoQuestionData.filter(
    (x) => x.cycleName === cycleIdValue && x.questionNumber === questionIdValue
  );
  const categories = data.map((x) => x.instillationName);
  const serie1 = data.map((x) => {
    const yes = x.chartData.find((y) => y.key === "Sí");
    if (yes) return yes.value;
    return 0;
  });
  const serie2 = data.map((x) => {
    const no = x.chartData.find((y) => y.key === "No");
    if (no) return no.value;
    return 0;
  });

  return {
    chart: {
      type: "column",
    },
    title: {
      text: titleText,
    },
    xAxis: {
      categories: categories,
      crosshair: true,
      title: { text: "Instilación" },
      
    },
    yAxis: {
      min: 0,
      title: {
        text: "No. de pacientes",
      },
      stackLabels: {
        enabled: true,
      }
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:10px">Instilación: {point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
          enabled: true
        }
      },
    },
    series: [
      {
        name: "Sí",
        data: serie1,
        color: '#88b3e7'
      },
      {
        name: "No",
        data: serie2,
        color: '#434348'
      },
    ],
    credits: {
      enabled: false
    }
  };
};