import React from "react";
import RecommendationCard2 from "../../../components/doctorComponents/articleCard/recommendationCard2";
import CautionCard from "../../../components/doctorComponents/articleCard/cautionCard";

const Recommendation2 = () => {
  return (
    <>
      <div className="educationInfo-innerContainer1">
        <div className="educationInfo-imgContainer">
          <RecommendationCard2 />
        </div>
        <CautionCard text="El procedimiento de instilación dura pocos minutos y es ambulatorio, no presenta efectos secundarios." />
      </div>
    </>
  );
};

export default Recommendation2;
