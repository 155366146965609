/* -----------------------------TABLA 1----------------------------------- */

export const HeaderTable1 = [
  "Suspect multidrug-resistant gram-negative urinary tract infection in patients with a history of any of the following in the prior three months:",
];
export const BodyTable1 = [
  ["A multidrug-resistant gram-negative urinary isolate"],
  [
    "Inpatient stay at a health care facility (eg, hospital, nursing home, long-term acute care facility) ",
  ],
  [
    "Use of a fluoroquinolone, trimethoprim-sulfamethoxazole, or broad-spectrum beta-lactam (eg, third or later generation cephalosporin)*",
  ],
  [
    "Travel to parts of the world with high rates of multidrug-resistant organisms¶",
  ],
  [
    "NOTE: The predictive value of these risk factors for multidrug-resistant gram-negative urinary tract infections has not been systematically evaluated. In particular, the time interval since these exposures is not well validated. The threshold for empirically covering a multidrug-resistant infection varies with the severity of infection, with a lower threshold warranted for more severe disease.",
  ],
];

/* -----------------------------TABLA 2----------------------------------- */

export const HeaderTable2 = ["SYMPTOM", "PATIENTS (%)"];

export const BodyTable2 = [
  ["Urinary urgency", "57 to 98"],
  ["Daytime frequency", "84 to 97"],
  ["Pain", "66 to 94"],
  ["Nocturia", "44 to 90"],
  ["Pain with voiding/dysuria", "71 to 98"],
  ["Suprapubic pain", "39 to 71"],
  ["Perineal pain", "25 to 56"],
  ["Patient sensation of bladder spasms", "50 to 74"],
  ["Pubic pressure", "60 to 71"],
  ["Dyspareunia", "46 to 80"],
  ["Depression", "55 to 67"],
];

/* -----------------------------TABLA 3----------------------------------- */

export const HeaderTable3 = [
  "GRADO",
  "SÍNTOMAS URINARIOS Y SISTÉMICOS ASOCIADOS",
  "HEMATURIA",
];

export const BodyTable3 = [
  [
    "I",
    "Síndrome irritativo moderado \n Incontinencia de grado I",
    "Microscópica o intermitente",
  ],
  [
    "II",
    "Empeoramiento de los síntomas miccionales \n Incontinencia de grado II \n Necesidad de sondeo con irrigación simple",
    "Macroscópica y persistente \n Necesidad de sondeo sin maniobra endoscópica",
  ],
  [
    "III",
    "Retención completa \n Dolor suprapúbico \n Síndrome hemorrágico controlado",
    "Macroscópica y resistente \n Necesidad de hemostasia endoscópica y de eliminación de coágulos sucesivos",
  ],
  [
    "IV",
    "Alteración del estado general \n Dolor y síndrome hemorrágico \n Trastornos de la hemostasia",
    "Macroscópica e incontrolable \n Recurso a los tratamientos por embolización o cistectomía",
  ],
];
