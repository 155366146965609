import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button2 from "../../doctorComponents/buttons/button-2";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { PublicLocation } from "../../../services/common/locationService";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "black,",
    "& .MuiDialog-paper": {
      margin: "16px",
      width: "100%",
    },
    "& .MuiDialogContent-root": {
      padding: "10px 10px 40px 10px",
    },
    "& .MuiTypography-h6": {
      fontFamily: " Source Sans Pro",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "22px",
      lineHeight: "28px",
      padding: "20px 0 0 10px",
      textAlign: "center",
      color: "#343A40",
    },
  },
  modalLabel: {
    width: "100%",
    maxWidth: "290px",
    margin: "0 auto 0 auto",
  },
}));

const Contact = ({ open, onClose, onAddSpecialty }) => {
  const [publicCountry, setPublicCountry] = useState("Colombia");
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const countryCode = searchParams.get("country");

  PublicLocation()
  .then((countryName) => {
    console.log("Country Name:", countryName);
    setPublicCountry(countryName);
  })
  .catch((error) => {
    console.error("Error:", error);
  });

  return (
    <Dialog
      className={classes.root}
      open={open}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Contáctenos"}</DialogTitle>
      <DialogContent>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              style={{ padding: "0 10px 0 0" }}
              alt="img"
              src="/doctor/letter2.svg"
            />
            <p>
            {countryCode === "CO"
              ? "equipovirtual@seranestpharma.com"
              : "j.rodriguez@seranestpharma.com"}
              </p>
          </div>
          <div style={{ margin: "50px 0 0 0" }} className={"div-horizontal"}>
            <Button2 label="OK" onClick={onClose} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Contact;
