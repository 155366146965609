import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { Form } from "../../../components/patientComponents/styled-components/register/styled";
import {
  ProcessStateList,
  ProcessStateEnum,
  CauseofSuspension,
} from "../../../constants/patienteConstants/enums";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SelectOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/select";
//import styles from '../../styles/Dashboard.module.css'
import styles from "../../../styles/patientStyles/Profile.module.css";
import {
  callApi,
  updateProcessState,
} from "../../../services/patientServices/apiList";
import { NotificationManager } from "react-notifications";

const addWhichCauseofSuspension = (cause) => {
  return cause == ProcessStateEnum.treatmentSuspended;
};

const DashboardState = ({ dashboardInfo, onRefreshDashboard }) => {
  const [open, setOpen] = useState(false);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { register, handleSubmit, formState, watch, setValue } = methods;
  const { errors } = formState;

  const processStateValue = watch("processState");
  const causeofSuspensionValue = watch("causeofSuspension");

  useEffect(() => {
    if (
      causeofSuspensionValue &&
      processStateValue != ProcessStateEnum.treatmentSuspended
    )
      setValue("causeofSuspension", "");
  }, [processStateValue]);

  useEffect(() => {
    if (dashboardInfo) {
      setValue(
        "processState",
        !dashboardInfo.cycleState ? "" : dashboardInfo.cycleState
      );
      setValue(
        "causeofSuspension",
        !dashboardInfo.causeForSuspension
          ? ""
          : dashboardInfo.causeForSuspension
      );
    }
  }, [dashboardInfo]);

  const onSubmit = handleSubmit((data) => {
    const state = {
      cycleId: dashboardInfo.cycleId,
      creationDate: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
      processState: data.processState,
      causeForSuspension: !data.causeofSuspension
        ? null
        : data.causeofSuspension,
    };
    callApi(
      () => updateProcessState(state),
      () => {
        NotificationManager.success("La operación se realizó correctamente");
        onRefreshDashboard();
        setOpen(false);
      }
    );
  });

  const getTextbtnState = () => {
    if (!dashboardInfo || !dashboardInfo.cycleState)
      return "Indique estado actual de su proceso";

    var state = ProcessStateList().find(
      (x) => x.id === dashboardInfo.cycleState
    );
    return state.name;
  };

  const getColorState = () => {
    if (!dashboardInfo) return styles.buttonState;

    switch (dashboardInfo.cycleState) {
      case ProcessStateEnum.authorizedTreatment:
        return styles.buttonStateGreen;
      case ProcessStateEnum.treatmentSuspended:
        return styles.buttonStateRed;
      case ProcessStateEnum.inAuthorizationProcess:
        return styles.buttonStateYellow;
      case ProcessStateEnum.radicadoOnDispenser:
        return styles.buttonStateYellow;
      case ProcessStateEnum.deliveredPerDispenser:
        return styles.buttonStateGreen;
      case ProcessStateEnum.treatmentStarted:
        return styles.buttonStateGreen;
      case ProcessStateEnum.treatmentFinished:
        return styles.buttonStateGreen;
      default:
        return styles.buttonState;
    }
  };

  const getArrow = () => {
    if (!dashboardInfo) return "/patient/purplearrow.svg";

    switch (dashboardInfo.cycleState) {
      case ProcessStateEnum.authorizedTreatment:
        return "/patient/whitearrow.svg";
      case ProcessStateEnum.treatmentSuspended:
        return "/patient/whitearrow.svg";
      case ProcessStateEnum.inAuthorizationProcess:
        return "/patient/whitearrow.svg";
      case ProcessStateEnum.radicadoOnDispenser:
        return "/patient/whitearrow.svg";
      case ProcessStateEnum.deliveredPerDispenser:
        return "/patient/whitearrow.svg";
      case ProcessStateEnum.treatmentStarted:
        return "/patient/whitearrow.svg";
      case ProcessStateEnum.treatmentFinished:
        return "/patient/whitearrow.svg";
      default:
        return "/patient/purplearrow.svg";
    }
  };

  return (
    <div>
      <button
        className={getColorState()}
        onClick={() => {
          if (dashboardInfo && !dashboardInfo.cycleId) {
            NotificationManager.warning(
              "Por favor seleccione el ciclo y la cantidad de instilaciones formuladas en la página de Instilaciones"
            );
            return;
          }

          setOpen(true);
        }}
      >
        <div className={styles.buttonStateContent}>
          <p className={styles.buttonStateText}>{getTextbtnState()}</p>
          <img src={getArrow()} alt="arrow" />
        </div>
      </button>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <p
            style={{ margin: "20px 0 -20px 0", fontSize: "20px" }}
            className={styles.modalTitle}
          >
            Estado actual de su proceso
          </p>
        </DialogTitle>
        <div className={styles.box}>
          <DialogContent>
            <p className={styles.subtitle}>
              A continuación seleccione la opción correspondiente al estado
              actual de su proceso
            </p>
            <Form onSubmit={onSubmit}>
              <SelectOutlinedInput
                name="processState"
                label="Seleccione estado"
                placeholder="Seleccione estado"
                register={register}
                options={ProcessStateList()}
                errors={errors}
                value={processStateValue ?? ""}
              />

              {!processStateValue ||
                (addWhichCauseofSuspension(processStateValue) && (
                  <SelectOutlinedInput
                    name="causeofSuspension"
                    label="Seleccione causa de suspensión"
                    placeholder="Seleccione causa de suspensión"
                    register={register}
                    options={CauseofSuspension()}
                    errors={errors}
                    value={causeofSuspensionValue ?? ""}
                  />
                ))}

              <div className={styles.buttonBox}>
                <button className={styles.buttonSmall}>
                  <p className={styles.buttonContent}>Confirmar</p>
                </button>
              </div>
            </Form>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default DashboardState;
