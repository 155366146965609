import { Grid } from "@material-ui/core";
import ButtonXS from "../../doctorComponents/buttons/button-xs";
import { Link } from "react-router-dom";
import { getCountry } from "../../../services/doctorServices/userService";

const InstitutionPatientResume = ({ patients, polls, epsQuatity }) => {
  const country = getCountry();

  return (
    <div className="file">
      <div className="icon-container">
        <div className="file-segment">
          <div className="icon-background-A">
            <img className="file-icon" src="/doctor/name-icon.svg" alt="icon" />
          </div>

          <div className="file-info">
            <h1 className="file-title">Mis pacientes:</h1>
            <p className="file-name">{patients}</p>
          </div>
        </div>
        <div className="file-segment">
          <div className="icon-background-D">
            <img
              className="file-icon"
              src="/doctor/polls-icon.svg"
              alt="icon"
            />
          </div>
          <div className="file-info">
            <h1 className="file-title">Encuestas:</h1>
            <p className="file-name">{polls}</p>
          </div>
        </div>
        {country == "CO" ? (
          <div className="file-segment">
            <div className="icon-background-B">
              <img
                className="file-icon"
                src="/doctor/eps-icon.svg"
                alt="icon"
              />
            </div>
            <div className="file-info">
              <h1 className="file-title">
                Mis {country == "CO" ? "EPS" : "CCSS"}:
              </h1>
              <p className="file-name">
                {epsQuatity == null || epsQuatity == "" ? 0 : epsQuatity}
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Grid container>
          <Grid item xs={country == "CO" ? 6 : 12} justifyContent="center">
            <Link className="a-center" to="/doctor/patient-list">
              <ButtonXS
                label="Listado de pacientes"
                style={{ maxWidth: "80%" }}
              />
            </Link>
          </Grid>
          <Grid item xs={6} justifyContent="center">
            {country == "CO" ? (
              <Link className="a-center" to="/doctor/eps-list">
                <ButtonXS
                  label={country == "CO" ? "Listado de EPS" : "Listado de CCSS"}
                  style={{ maxWidth: "80%" }}
                />
              </Link>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </div>
      <Link className="a-center" to="/doctor/report">
        <ButtonXS label="Reporte" />
      </Link>
    </div>
  );
};

export default InstitutionPatientResume;
