import React from "react";
import { Link } from "react-router-dom";
import { getCountry } from "../../../services/doctorServices/userService";

const Footer = () => {
  const country = getCountry();

  return (
    <div className="footer-box">
      <img src="/doctor/footer-SerAnest-xxhdpi.png" alt="SerAnestPharmaLogo" />
      <Link to="/doctor/privacy-policy">
        <p className="link">Política de privacidad</p>
      </Link>
      <Link to="/doctor/terms-and-conditions">
        <p className="link">Términos y condiciones</p>
      </Link>
      <p
        style={{ marginBottom: "0", fontWeight: "600" }}
        className="footer-text-2"
      >
        Contáctenos
      </p>
      
      {country == "CO" ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            alt="img"
            style={{ padding: "0 10px 0 0", margin: "0", width: "25px" }}
            src="/doctor/letter2.svg"
          />
          <p className="footer-text-2">equipovirtual@seranestpharma.com</p>
        </div>
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              alt="img"
              style={{ padding: "0 10px 0 0", margin: "0", width: "25px" }}
              src="/doctor/letter2.svg"
            />
            <p className="footer-text-2">equipovirtual@seranestpharma.com</p>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              alt="img"
              style={{ padding: "0 10px 0 0", margin: "0", width: "25px" }}
              src="/doctor/letter2.svg"
            />
            <p className="footer-text-2">j.rodriguez@seranestpharma.com</p>
          </div>
        </>
      )}
      <p className="footer-text">
        © 2021 SerAnestPharma. Todos los derechos reservados
      </p>
    </div>
  );
};

export default Footer;
