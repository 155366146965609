import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import styles from "../../../styles/patientStyles/Survey.module.css";
import HeaderC from "../../../components/patientComponents/component/headerC";
import Footer from "../../../components/patientComponents/component/footer";
import QuestionBox from "../../../components/patientComponents/component/survey/questionBox";
import { StyledMobileStepper } from "../../../components/patientComponents/styled-components/mobileStepper/styled";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";


import {
  callApi,
  getSurvey,
  patchSurveyUpdate,
  instillationEndSurvey,
} from "../../../services/patientServices/apiList";

const Survey = ({ instillationId, fnEndSurvey, instillationNumber }) => {

  const theme = useTheme();

  const [question, setQuestion] = useState(0);
  const [survey, setSurvey] = useState(null);
  const [isNextClick, setIsNextClick] = useState(false);
  const [showEndButton, setShowEndButton] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  
  const loadSurvey = (instillationId) => {
    if (!instillationId) return;
    callApi(() => getSurvey(instillationId, 0), setSurvey);
  };

  const endSurvey = () => {
    callApi(
      () => instillationEndSurvey(instillationId),
      () => setIsNextClick(true)
    );
  };

  const updateSurvey = (survey) => {
    callApi(
      () => patchSurveyUpdate(survey),
      (data) => {
        setSurvey(data);
        const next = question + 1;
        if (next === 1 && !data.question1) setQuestion(next + 1);
        else setQuestion(next);
      }
    );
  };

  useEffect(() => loadSurvey(instillationId), [instillationId]);

  const onNextClick = (updatedSurvey) => {
    setIsNextClick(false);
    if (updatedSurvey) {
      updateSurvey(updatedSurvey);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleClick = () => {
    if (showEndButton) {
      endSurvey()
      return;
    }
    setIsNextClick(true);
  };

  const handleClickBack = () => {
    if (question === 0) return;

    const previous = question - 1;

    if (previous === 1 && !survey.question1) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setQuestion(previous - 1);
    }  else {
      setQuestion(previous);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <div>
      <HeaderC
        title="Escala de seguimiento, signos
      y síntomas"
        content="La siguiente encuesta ha sido diseñada y
      evaluada por el departamento médico y
      validado con escalas internacionales."
      />
      <div className={styles.questionBox}>
      {instillationNumber == 6 && question !== 8 && (
          <StyledMobileStepper
            variant="dots"
            steps={8}
            position="static"
            activeStep={activeStep}
            backButton={
              <Button onClick={handleClickBack} disabled={activeStep === 0}>
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
              </Button>
            }
            nextButton={
              <Button onClick={handleClick} disabled={activeStep === 9}>
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
          />
        )}
        {instillationNumber != 6 && question !== 7 && (
          <StyledMobileStepper
            variant="dots"
            steps={7}
            position="static"
            activeStep={activeStep}
            backButton={
              <Button onClick={handleClickBack} disabled={activeStep === 0}>
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
              </Button>
            }
            nextButton={
              <Button onClick={handleClick} disabled={activeStep === 9}>
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
          />
        )}
        <QuestionBox
          question={question}
          survey={survey}
          isNextClick={isNextClick}
          onNextClick={onNextClick}
          setShowEndButton={setShowEndButton}
          fnEndSurvey={fnEndSurvey}
          instillationNumber={instillationNumber}
        />
        { instillationNumber != 6 && question !== 7 && (
          <>
            <button className={styles.button} onClick={handleClick}>
              <p className={styles.buttonContent}>
                {question < 6 || !showEndButton
                  ? "Siguiente"
                  : "Finalizar encuesta"}
              </p>
            </button>
          </>
        )}
        {instillationNumber == 6 && question !== 8 &&(
          <>
            <button className={styles.button} onClick={handleClick}>
              <p className={styles.buttonContent}>
                {question < 7 || !showEndButton
                  ? "Siguiente"
                  : "Finalizar encuesta"}
              </p>
            </button>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Survey;
