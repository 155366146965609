import React from 'react'
import styles from '../../../styles/patientStyles/EducationInfo.module.css'

import HeaderWithMenu from '../../../components/patientComponents/component/headerWithMenu/index'
import BackButton from '../../../components/patientComponents/component/backButton'
import Footer from '../../../components/patientComponents/component/footer'
import EducationCard1 from '../../../components/patientComponents/component/Education/educationCard1'

const Education1 = () => {
  return (
    <>
      <div className={styles.innerContainer}>
        <HeaderWithMenu />
        <BackButton />
        <div className={`${styles.title}`}>
          ¿Qué es una instilación intravesical?
        </div>
        <div className={styles.imgContainer}>
          <EducationCard1 />
          {/*  <img
            className={`${styles.img}`}
            src="/educacion-art-00-01.jpg"
            alt="Info"
          /> */}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Education1
