import React from "react";

import AccordionComponent from "../../../../components/doctorComponents/accordion/accordion-1";
import AccordionBibliography from "../../../../components/doctorComponents/accordion/accordion-2";

const InterstitialCystitis3 = () => {
  return (
    <div className="container">
      <div className="inner-container">
        <p></p>
        <p>
          Por su carácter multifactorial y etiología no dilucidada de manera
          conclusiva, no existen tratamientos curativos y el objetivo del manejo
          es proporcionar alivio de los síntomas para mejorar la calidad de vida
          del paciente. Existen muchos enfoques terapéuticos para la IC/BSP,
          ninguno de los cuales ha demostrado tener una efectividad absoluta
          para todos los pacientes referencia.
          <sup>
            <b>1,2</b>
          </sup>
        </p>

        <p>
          Aunque el tratamiento inicial de la mayoría de los pacientes puede
          realizarlo el médico general o de otra especialidad médica, existen
          pacientes con IC / BPS, como aquellos con disfunción vesical asociada
          (incontinencia urinaria, vaciado incompleto de la vejiga) o
          alteraciones estructurales de la vejiga que debe ser evaluado por un
          urólogo antes del tratamiento.
        </p>

        <p>
          Los pacientes a menudo requieren diversas modalidades de tratamiento
          antes de lograr una mejoría clínica notoria. Sin embargo, el
          diagnóstico de IC / BPS debe reconsiderarse si no se produce una
          mejoría después de varios ciclos de diferentes terapias y esos casos
          también es apropiada la remisión a urología.
        </p>

        <AccordionComponent article="interstitial-cystitis" title="EDUCACIÓN DEL PACIENTE">
          <div>
            <p>
              En la primera línea de tratamiento de pacientes con IC / BPS es
              fundamental la capacitación sobre los criterios de diagnóstico, la
              variabilidad de los síntomas y la naturaleza crónica de la
              afección.3 Se debe identificar el funcionamiento normal de la
              vejiga y aconsejar a los pacientes para reconocer las actividades,
              alimentos o conductas que puedan exacerbar los síntomas.
            </p>
            <p>
              Asimismo, se orienta a los pacientes sobre las expectativas
              razonables con respecto al curso del tratamiento y los resultados.
              Se advierte a los pacientes que se puede lograr un alivio adecuado
              de los síntomas, pero que esto puede requerir múltiples ensayos de
              diferentes tipos de terapias.
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="interstitial-cystitis" title="EVALUACIÓN DE COMORBILIDADES">
          <div>
            <p>
              Diagnóstico de condiciones agudas y crónicas que puedan exacerbar
              los síntomas de IC / BPS para seguir una conducta terapéutica
              adecuada. Esto incluyen los trastornos genitourinarios como
              infección del tracto urinario o vulvovaginitis, como patologías
              agudas.
            </p>
            <p>
              Los trastornos crónicos incluyen vulvodinia, endometriosis u otras
              etiologías de dolor pélvico crónico o dismenorrea, enfermedad
              inflamatoria intestinal, diverticulitis, síndrome del intestino
              irritable y fibromialgia, todos los cuales pueden provocar un
              aumento de la sensibilidad de la vejiga [4]. Por lo general, es
              necesario el enfoque multidisciplinario en el manejo de estas
              patologías.
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="interstitial-cystitis" title="EVALUACIÓN PSICOSOCIAL">
          <div>
            <p>
              El apoyo psicosocial es una parte integral del tratamiento de
              cualquier trastorno de dolor crónico, incluido el IC / BPS. Se
              debe establecer una valoración en este sentido para identificar
              condiciones asociadas de depresión, ansiedad y estrés y así
              establecer las estrategias de tratamiento adecuadas.
            </p>

            <p>
              Algunos pacientes con IC / BPS se benefician del entrenamiento en
              técnicas de relajación y reducción del estrés. Se ha demostrado
              que mayores niveles de estrés aumentan los síntomas de IC / BPS2 y
              el paciente con sospecha de depresión o ansiedad debe remitirse al
              psicoterapeuta con experiencia en el manejo de dolor crónico.
              Idealmente, el paciente debe estar en contacto con grupos de apoyo
              que estén trabajando en el manejo de seta patología crónica.
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="interstitial-cystitis" title="AUTOCUIDADO Y MODIFICACIÓN DEL ESTILO DE VIDA">
          <div>
            <p>
              En algunos pacientes, estas medidas pueden ser efectivas por sí
              solas, aunque en la mayoría de los pacientes requerirán terapia
              adicional. Estas estrategias incluyen3:
            </p>

            <ul>
              <li>
                {" "}
                Aplicación de calor o frío local sobre la vejiga o el periné.
              </li>
              <li>
                {" "}
                Consejos dietéticos: aunque la evidencia sobre el impacto de los
                alimentos y bebidas en IC/BPS, se recomienda evitar alimentos o
                bebidas que agravan los síntomas (p. Ej., Cafeína, alcohol,
                edulcorantes artificiales, alimentos picantes y con vitamina C).
              </li>
              <li>
                {" "}
                Evitar ejercicios, actividades recreativas, actividades sexuales
                o posiciones corporales que exacerban los síntomas.
              </li>
              <li>
                {" "}
                Manejo de fluidos: estas recomendaciones deben individualizarse
                para cada paciente. Los pacientes que experimentan un
                empeoramiento de los síntomas con la orina concentrada pueden
                encontrar útil aumentar la ingesta de líquidos. Otros que
                experimentan dolor con el llenado de la vejiga pueden encontrar
                que la restricción moderada de líquidos les brinda cierto
                alivio. Sin embargo, se debe instruir a los pacientes para que
                eviten la ingesta elevada de líquidos.{" "}
                <b>
                  En la mayoría de los pacientes, no es necesario beber más de
                  2L de líquido al día
                </b>
                .
              </li>
              <li> Re-entrenamiento vesical.</li>
            </ul>
          </div>
        </AccordionComponent>

        <AccordionComponent article="interstitial-cystitis" title="TERAPIA FÍSICA">
          <div>
            <p>
              Se utiliza la fisioterapia para los pacientes que presentan dolor
              a la palpación de los músculos del suelo pélvico y especialmente
              si la palpación de los músculos reproduce sus síntomas de
              dolor.6,7 Para estos pacientes, se sugiere un{" "}
              <b>esquema de fisioterapia de 12 semanas </b>
              sólo o en combinación con otros tratamientos.
            </p>

            <p>
              La fisioterapia pélvica incluye el tratamiento de los puntos
              sensibles de la musculatura pélvica, los puntos gatillo, las
              restricciones del tejido conectivo y las anomalías musculares de
              los tejidos blandos.{" "}
              <b>
                Deben evitarse los ejercicios de fortalecimiento del suelo
                pélvico (por ejemplo, Ejercicios de Kegel).8{" "}
              </b>
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="interstitial-cystitis" title="TERAPIA FARMACOLÓGICA">
          <div>
            <p>
              La decisión de cuándo iniciar la terapia oral debe
              individualizarse, teniendo en cuenta la gravedad de los síntomas,
              la frecuencia de las recurrencias, la preferencia del paciente y
              los posibles efectos adversos de la terapia establecida.
            </p>
            <p>
              En ciertos pacientes, puede ser apropiado combinar medicamento,
              teniendo precaución sobre el riesgo del uso combinado de los
              mismos y un factor primordial a tener en cuenta, es suspender los
              medicamentos con respuesta inadecuada.
            </p>

            <p>
              <b className="bold-purple">1) Amitriptilina:</b> los
              antidepresivos tricíclicos son una opción interesante por las
              propiedades analgésicas y alivio de los síntomas depresivos
              asociados con el dolor crónico. La amitriptilina es eficaz en
              dosis más altas, aunque estas dosis pueden estar limitadas por
              efectos adversos. Debe iniciarse con dosis bajas (10 mg en la
              noche) y aumentar progresivamente en intervalos semanales hasta la
              dosis máxima tolerada y/o aparición de efectos adversos
              anticolinérgicos, sedación, hipotensión ortostática o trastornos
              del ritmo cardiaco.
            </p>
            <p>
              La eficacia de la amitriptilina se demuestra mejor en dosis más
              altas (50 mg al día) y generalmente, la efectividad se observa
              luego de 4 semanas de tratamiento.{" "}
            </p>

            <p>
              <b className="bold-purple"> 2) Pentosan polisulfato (PPS): </b> el
              mecanismo de acción propuesto del PPS es el restablecimiento de la
              capa protectora de glicosaminoglicanos sobre el urotelio. Se puede
              utilizar por vía intravesical. En un metaanálisis de seis ensayos,
              el tratamiento con PPS resultó en una mejoría del 12 % tanto en
              los síntomas generales como en el dolor, y una mejoría del 9 % en
              la urgencia, en comparación con el placebo.
              <sup>
                <b>10</b>
              </sup>
            </p>
            <p>Dosis: 100 mg tres veces al día.</p>
            <p>
              Los efectos secundarios (10-12%) pueden incluir náuseas, diarrea,
              caída del cabello, elevación de las enzimas hepáticas (precaución
              en pacientes con disfunción hepática), enfermedad ocular macular
              (los síntomas visuales incluyen dificultad para leer, trastornos
              de adaptación y visión borrosa.
            </p>

            <p>
              <b className="bold-purple"> 3) Antihistamínicos: </b> pacientes
              con IC / BPS y trastornos alérgicos asociados pueden ser indicados
              con un antihistamínico, basado en la hipótesis que el incremento
              de mastocitos en la pared vesical es el factor asociado con los
              síntomas irritativos urinarios.
            </p>
            <p>
              La hidroxicina es el antihistamínico más utilizado para el
              tratamiento de IC / BPS con una dosis de 25 a 50 mg al acostarse;
              su tolerancia es adecuada, aunque puede producir sedación.
              Requiere mayor evidencia epidemiológica para confirmar el efecto
              terapéutico adecuado.
              <sup>
                <b>11</b>
              </sup>
            </p>

            <p>
              <b className="bold-purple">
                4) Analgesia adyuvante / de rescate:
              </b>{" "}
              se utiliza generalmente como terapia adyuvante con el objetivo de
              minimizar el dolor y maximizar la función.8 En algunos pacientes
              con IC / BPS, al igual que con otras afecciones de dolor crónico,
              puede ser necesario el uso prolongado de analgésicos orales y
              requerir la participación de Algesiólogos en las clínicas de dolor
              crónico.
            </p>
            <p>
              Pueden utilizarse medicamentos antiinflamatorios no esteroideos
              (AINES`s), analgésicos opiáceos y acetaminofen. Además, utilizamos
              analgésicos selectivos urinarios orales (fenazopiridina,
              metenamina), con sus debidas precauciones relacionadas con la
              función renal y hepática, para el alivio a corto plazo de los
              síntomas urinarios, ya sea solos o en combinación con AINES`s.
            </p>

            <p>
              <b className="bold-purple"> 5) Otros medicamentos orales:</b> con
              evidencia clínica limitada y efectividad variable
            </p>
            <ul>
              <li> Cimetidina.</li>
              <li> Sildenafil.</li>
            </ul>
          </div>
        </AccordionComponent>

        <AccordionComponent article="interstitial-cystitis" title="HIDRODISTENSIÓN DE LA VEJIGA">
          <div>
            <p>
              Se ha utilizado con fines de diagnóstico, pero también se ha
              considerado terapéutico porque{" "}
              <b>
                algunos pacientes tienen un alivio prolongado de los síntomas
                después del procedimiento
              </b>
              . La hipótesis sobre el efecto terapéutico beneficioso se debe
              posiblemente a la alteración de los nervios sensoriales dentro de
              la pared de la vejiga, descrito clasicamente por Dumm.
              <sup>
                <b>12</b>
              </sup>
            </p>
            <p>
              Por lo general, se indican la hidrodistensión vesical o las
              terapias intravesicales{" "}
              <b>
                solo si los tratamientos iniciales de medicamentos orales no
                logran reducir los síntomas o si el paciente muestra una
                progresión rápida de los síntomas
              </b>
              ; generalmente se realiza la hidrodistensión de la vejiga antes
              que las terapias intravesicales, ya que puede proporcionar
              información de diagnóstico adicional (úlceras de Hunner, capacidad
              vesical) y, si es eficaz, normalmente requiere menos tratamientos.
            </p>
            <p>
              La técnica no ha sido estandarizada, pero la hidrodistensión se
              suele realizar bajo anestesia general o con sedación profunda,
              utilizando una presión de distensión de 60 a 80 cm H<sub>2</sub>O
              por un tiempo menor a 10 minutos.
            </p>
            <p>
              Puede tener respuesta clínica paradójica por empeoramiento de los
              síntomas y el efecto favorable ser de corta duración, {"< 6"}{" "}
              meses.
              <sup>
                <b>13</b>
              </sup>{" "}
              En una revisión sistemática de estudios que evaluaron la
              hidrodistensión para el tratamiento de IC / BPS, la mejor
              respuesta sintomática fue una{" "}
              <b>
                mejoría subjetiva en el 57% de los pacientes.
                <sup>
                  <b>14</b>
                </sup>
              </b>
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="interstitial-cystitis" title="TRATAMIENTO DE LAS ÚLCERAS DE HUNNER">
          <div>
            <p>
              Las alternativas de tratamiento son: resección, cauterización
              eléctrica o inyección intralesional de un corticoide. La duración
              del efecto del tratamiento es variable, aunque en general, el
              efecto de la cauterización o la inyección de corticoides puede
              durar hasta seis meses y requerir un retratamiento periódico.
              <sup>
                <b>15</b>
              </sup>
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="interstitial-cystitis" title="TERAPIAS INTRAVESICALES">
          <div>
            {" "}
            <p>
              Existen múltiples esquemas terapéuticos, con enfoque agudo o
              terapia de mantenimiento y se utilizan diversos medicamentos, a
              menudo combinados
              <sup>
                <b>16</b>
              </sup>
              :
            </p>
            <ul>
              <li> Anestésicos locales (Lidocaína, Bupivacaína).</li>
              <li>
                {" "}
                Glucosaminoglicanos (Heparina, ácido hialurónico, condroitin
                sulfato).
              </li>
              <li> Bicarbonato de sodio.</li>
              <li> Esteroides (Solumedrol).</li>
              <li> Dimetilsulfóxido (DMSO).</li>
              <li> Antibióticos (Gentamicina).</li>
            </ul>
            <p>
              <b className="bold-purple">Efectos adversos:</b> infección
              urinaria, disuria, irritación uretral y aumento del dolor de la
              vejiga.
            </p>
            <p>
              Existen limitaciones de los estudios realizados y la evidencia
              clínica requiere de ensayos controlados, prospectivos y con
              criterios homogéneos en la inclusión de pacientes, el esquema
              terapéutico y el seguimiento. Sin embargo, la eficacia del DMSO
              tiene respaldo por ensayos aleatorizados y se propone un mecanismo
              de acción multifactorial, incluidos los efectos antiinflamatorios,
              analgésicos, relajantes del músculo liso y de inhibición de los
              mastocitos.
              <sup>
                <b>17</b>
              </sup>
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="interstitial-cystitis" title="OTRAS TERAPIAS">
          <div>
            <p>
              Indicadas en pacientes con refractariedad a los otros tratamientos
              mencionados, con importante afectación de su calidad de vida y
              quienes pueden autorizar estas terapias asumiendo los riesgos de
              los eventos colaterales. En este momento, no hay datos suficientes
              para determinar cuál de estos tratamientos es preferible. Por lo
              general, esta decisión se toma en función de las preferencias del
              paciente después de discutir los posibles efectos adversos.
            </p>

            <p>
              <b className="bold-purple">
                1) Inyección intradetrusor de toxina botulínica (NTBo):
              </b>{" "}
              sola o en combinación con la hidrodistensión,
              <sup>
                <b>18</b>
              </sup>{" "}
              puede aliviar los síntomas, pero existe el riesgo de producir
              retención urinaria y es importante tener en consideración la
              opción de realizar autocateterismo vesical. Su efecto está en
              relación con la modulación de la neurotransmisión sensorial y está
              disponible como onabotulinumtoxinA (Botox) y abobotulinumtoxinA
              (Dysport).
            </p>
            <p>
              En un ensayo de 67 pacientes aleatorizados para recibir una
              inyección suburotelial de BoNT-A (100 o 200 unidades) combinada
              con hidrodistensión o hidrodistensión sola, el tratamiento con
              BoNT-A dio como resultado una mayor proporción de pacientes con
              una mejoría moderada o marcada de los síntomas en tres meses de
              seguimiento (71% vs 48%) y esta diferencia se mantuvo durante 24
              meses.18 Inicialmente, se utilizaron 200 unidades de BoNT, pero se
              produjeron reacciones adversas en 9 de 15 pacientes (cuatro
              pacientes tenían retención urinaria aguda o crónica, siete tenían
              disuria grave) y la dosis se redujo a 100 unidades. Con la dosis
              de 100 unidades, el número de efectos adversos disminuyó, pero fue
              aún más frecuente que para la hidrodistensión sola.
            </p>

            <p>
              <b className="bold-purple">2) Neuromodulación sacra: </b> la FDA
              aprueba un dispositivo implantable para la neuromodulación sacra
              (InterStim) para el tratamiento de la urgencia y frecuencia
              urinaria, pero no para el tratamiento de IC / BPS.
            </p>
            <p>
              Los estudios observacionales sugieren un alivio sintomático
              (nicturia y dolor); sin embargo, la tasa de reintervención es alta
              debido al mal funcionamiento del dispositivo, el fracaso del
              tratamiento o la pérdida de beneficios.
              <sup>
                <b>19,20</b>
              </sup>
            </p>

            <p>
              3) Ciclosporina A: (CyA): puede disminuir los síntomas, pero la
              evidencia disponible no posee un respaldo epidemiológico
              suficiente.
              <sup>
                <b>21</b>
              </sup>{" "}
              Además, la posibilidad de efectos colaterales serios limita
              notoriamente su uso (nefrotoxicidad, hipertensión,
              inmunosupresión, crecimiento del cabello, hiperplasia gingival,
              parestesias, dolor abdominal, rubor y dolor muscular).
            </p>
            <p>
              El único ensayo (n = 64) que evaluó esta terapia comparó seis
              meses de tratamiento con CyA oral (1,5 mg / kg dos veces al día)
              con pentosano polisulfato sódico (PPS; 100 mg PPS tres veces al
              día).
              <sup>
                <b>22</b>
              </sup>{" "}
              Un mayor porcentaje de pacientes que recibieron CyA mostró una
              mejoría en una escala de síntomas (75% vs 19%) y una mayor
              reducción en la frecuencia urinaria (-6,7 vs -2,0 veces/ 24
              horas). Sin embargo, el grupo CyA tuvo tasas más altas de efectos
              adversos generales (94% vs 56%) que el grupo PPS.{" "}
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="interstitial-cystitis" title="DERIVACIÓN URINARIA">
          <div>
            <p>Procedimiento quirúrgico con las siguientes indicaciones:</p>
            <ul>
              <li>
                {" "}
                Los síntomas afectan significativamente la calidad de vida.
              </li>
              <li>
                {" "}
                Se han excluido otras etiologías asociadas con los síntomas.
              </li>
              <li> Todas las demás terapias IC / BPS han fallado.</li>
              <li>
                {" "}
                El paciente valora más el alivio de los síntomas que los riesgos
                y cambios en el estilo de vida asociados con el procedimiento.
              </li>
            </ul>
            <p>
              La derivación urinaria aliviará de forma confiable los síntomas de
              la frecuencia urinaria y urgencia miccional, pero el dolor pélvico
              puede persistir incluso si se realiza una cistectomía
              concomitante.
              <sup>
                <b>23</b>
              </sup>
            </p>
          </div>
        </AccordionComponent>

        <AccordionBibliography title="BIBLIOGRAFIA">
          <div>
            <p className="bibliography-font">
              {" "}
              <b>1.</b> Rovner E, Propert KJ, Brensinger C, et al. Treatments
              used in women with interstitial cystitis: the interstitial
              cystitis data base (ICDB) study experience. The Interstitial
              Cystitis Data Base Study Group. Urology 2000; 56:940.
            </p>
            <p className="bibliography-font">
              <b>2.</b> Pazin C, de Souza Mitidieri AM, Silva AP, et al.
              Treatment of bladder pain syndrome and interstitial cystitis: a
              systematic review. Int Urogynecol J 2016; 27:697.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>3.</b> Hanno PM, Burks DA, Clemens JQ, et al. AUA guideline for
              the diagnosis and treatment of interstitial cystitis/bladder pain
              syndrome. J Urol 2011; 185:2162.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>4.</b> Pezzone MA, Liang R, Fraser MO. A model of neural
              cross-talk and irritation in the pelvis: implications for the
              overlap of chronic pelvic pain disorders. Gastroenterology 2005;
              128:1953.
            </p>
            <p className="bibliography-font">
              <b>5.</b> Rothrock NE, Lutgendorf SK, Kreder KJ, et al. Stress and
              symptoms in patients with interstitial cystitis: a life stress
              model. Urology 2001; 57:422.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>6.</b> Peters KM, Carrico DJ, Kalinowski SE, et al. Prevalence
              of pelvic floor dysfunction in patients with interstitial
              cystitis. Urology 2007; 70:16.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>7.</b> Bassaly R, Tidwell N, Bertolino S, et al. Myofascial
              pain and pelvic floor dysfunction in patients with interstitial
              cystitis. Int Urogynecol J 2011; 22:413.
            </p>
            <p className="bibliography-font">
              <b>8.</b> Hanno PM, Erickson D, Moldwin R, et al. Diagnosis and
              treatment of interstitial cystitis/bladder pain syndrome: AUA
              guideline amendment. J Urol 2015; 193:1545.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>9.</b> van Ophoven A, Vonde K, Koch W, et al. Efficacy of
              pentosan polysulfate for the treatment of interstitial
              cystitis/bladder pain syndrome: results of a systematic review of
              randomized controlled trials. Curr Med Res Opin 2019; 35:1495.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>10.</b> Carrico DJ, Peters KM, Diokno AC. Guided imagery for
              women with interstitial cystitis: results of a prospective,
              randomized controlled pilot study. J Altern Complement Med 2008;
              14:53.
            </p>
            <p className="bibliography-font">
              <b>11.</b> Sant GR, Propert KJ, Hanno PM, et al. A pilot clinical
              trial of oral pentosan polysulfate and oral hydroxyzine in
              patients with interstitial cystitis. J Urol 2003; 170:810.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>12.</b> Dunn M, Ramsden PD, Roberts JB, et al. Interstitial
              cystitis, treated by prolonged bladder distension. Br J Urol 1977;
              49:641.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>13.</b> Erickson DR, Kunselman AR, Bentley CM, et al. Changes
              in urine markers and symptoms after bladder distention for
              interstitial cystitis. J Urol 2007; 177:556.
            </p>
            <p className="bibliography-font">
              <b>14.</b> Olson LE, Dyer JE, Haq A, et al. A systematic review of
              the literature on cystodistension in bladder pain syndrome. Int
              Urogynecol J 2018; 29:251.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>15.</b> Chennamsetty A, Khourdaji I, Goike J, et al.
              Electrosurgical management of Hunner ulcers in a referral center's
              interstitial cystitis population. Urology 2015; 85:74.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>16.</b> Barua JM, Arance I, Angulo JC, Riedl CR. A systematic
              review and meta-analysis on the efficacy of intravesical therapy
              for bladder pain syndrome/interstitial cystitis. Int Urogynecol J
              2016; 27:1137.
            </p>
            <p className="bibliography-font">
              <b>17.</b> Yamada T, Murayama T, Andoh M. Adjuvant hydrodistension
              under epidural anesthesia for interstitial cystitis. Int J Urol
              2003; 10:463.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>18.</b> Kuo HC, Chancellor MB. Comparison of intravesical
              botulinum toxin type A injections plus hydrodistention with
              hydrodistention alone for the treatment of refractory interstitial
              cystitis/painful bladder syndrome. BJU Int 2009; 104:657.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>19.</b> Peters KM, Feber KM, Bennett RC. A prospective,
              single-blind, randomized crossover trial of sacral vs pudendal
              nerve stimulation for interstitial cystitis. BJU Int 2007;
              100:835.
            </p>
            <p className="bibliography-font">
              <b>20.</b> Zabihi N, Mourtzinos A, Maher MG, et al. Short-term
              results of bilateral S2-S4 sacral neuromodulation for the
              treatment of refractory interstitial cystitis, painful bladder
              syndrome, and chronic pelvic pain. Int Urogynecol J Pelvic Floor
              Dysfunct 2008; 19:553.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>21.</b> Forrest JB, Payne CK, Erickson DR. Cyclosporine A for
              refractory interstitial cystitis/bladder pain syndrome: experience
              of 3 tertiary centers. J Urol 2012; 188:1186.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>22.</b> Sairanen J, Tammela TL, Leppilahti M, et al.
              Cyclosporine A and pentosan polysulfate sodium for the treatment
              of interstitial cystitis: a randomized comparative study. J Urol
              2005; 174:2235.
            </p>
            <p className="bibliography-font">
              <b>23.</b> Rössberger J, Fall M, Jonsson O, Peeker R. Long-term
              results of reconstructive surgery in patients with bladder pain
              syndrome/interstitial cystitis: subtyping is imperative. Urology
              2007; 70:638.
            </p>
          </div>
        </AccordionBibliography>
      </div>
    </div>
  );
};

export default InterstitialCystitis3;
