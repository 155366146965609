import React from "react";
import Footer from "../../components/doctorComponents/footer";
import BreadcrumbsHeader from "../../components/doctorComponents/header/breadcrumbs-header";
import HeaderWithMenu from "../../components/doctorComponents/headerWithMenu";
import PreRegisterBanner from "../../components/doctorComponents/banners/pre-register-banner";
import { getUserInfo } from "../../services/doctorServices/authService";
import RegisterHeader from "../../components/doctorComponents/header/register-header";

const BreadHeaderLayout = (props) => {
  const userlogin = getUserInfo();

  return (
    <div>
      {userlogin !== null && <HeaderWithMenu img="/doctor/menu-mobile-pattern.png" />}
      {userlogin === null && <PreRegisterBanner />}
      {userlogin === null && <RegisterHeader title={props.title} img="/doctor/bg-mobile-pattern.png" />}
      <div className="main">
        {userlogin !== null && <BreadcrumbsHeader
          title={props.title}
          subtitle={props.subtitle}
          img="/doctor/ialurilHeadImage.png"
        />}
        {props.children}
        <Footer />
      </div>
    </div>
  );
};

export default BreadHeaderLayout;
