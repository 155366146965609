import React from 'react'
import Question01Eva from './question01-eva'
import QuestionEnd from './questionEnd'
import QuestionCheck from '../questionCheck'
import QuestionLastInstillation from './questionLastInstillation'
import SymptomsPresented from './symptomsPresented'
import { SymptomTypeEnum } from '../../../../constants/patienteConstants/enums'

const QuestionBox = (props) => {
  const { question, setShowEndButton, instillationNumber } = props

  const optionsYesNo = [
    { value: '1', label: 'Si' },
    { value: '0', label: 'No' },
  ]
  const optionsQ2 = [
    { value: '0', label: '0 - 4 veces' },
    { value: '1', label: '5 - 9 veces' },
    { value: '2', label: '10 - 14 veces' },
    { value: '3', label: 'Más de 15 veces' },
  ]
  const optionsQ3 = [
    { value: '0', label: 'Ninguna' },
    { value: '1', label: 'Una vez' },
    { value: '2', label: '2 veces' },
    { value: '3', label: 'Más de 3 veces' },
  ]
  const optionsQ4 = [
    { value: '0', label: 'Siempre puedo aguantar' },
    { value: '1', label: 'A veces puedo aguantar' },
    { value: '2', label: 'Nunca puedo aguantar' },
  ]

  switch (question) {
    case 0:
      return (
        <QuestionCheck
          questionNumber="1"
          label="1. ¿Presenta dolor pélvico y/o ardor al orinar?"
          options={optionsYesNo}
          {...props}
        />
      )
    case 1:
      return <Question01Eva {...props} />
    case 2:
      return (
        <QuestionCheck
          questionNumber="2"
          label="2. ¿Cuántas veces va a orinar durante el día?"
          options={optionsQ2}
          {...props}
        />
      )
    case 3:
      return (
        <QuestionCheck
          questionNumber="3"
          label="3. ¿Cuántas veces va a orinar en la noche luego de haber conciliado el sueño?"
          options={optionsQ3}
          {...props}
        />
      )
    case 4:
      return (
        <QuestionCheck
          questionNumber="4"
          label="4. ¿Cuándo siente el deseo de orinar debe ir rápidamente o puede aguantar?"
          options={optionsQ4}
          {...props}
        />
      )
    case 5:
      return (
        <QuestionCheck
          questionNumber="5"
          label="5. ¿Ha presentado usted fiebre superior a 38 °C, asociado con alguno de los síntomas anteriores?"
          options={optionsYesNo}
          {...props}
        />
      )
    case 6:
      return (
        <QuestionCheck
          instillationNumber={instillationNumber}
          questionNumber="6"
          label="6. ¿Ha presentado usted sangrado en la orina asociado con alguno de los síntomas anteriores?"
          options={optionsYesNo}
          {...props}
        />
      )
    case 7:
      if(instillationNumber == 6){
        return <QuestionLastInstillation {...props}/>
      }
      return <QuestionEnd {...props} />
    case 8:
      return <QuestionEnd {...props} />
    default:
      return <div> </div>
  }
}

export default QuestionBox
