import Footer from "../../../components/doctorComponents/footer";
import HeaderWithMenu from "../../../components/doctorComponents/headerWithMenu";
import Button2 from "../../../components/doctorComponents/buttons/button-2";
import PreRegisterBanner from "../../../components/doctorComponents/banners/pre-register-banner";
import { getUserInfo } from "../../../services/doctorServices/authService";
import RegisterHeader from "../../../components/doctorComponents/header/register-header";
import { useHistory } from "react-router-dom";

const Unauthorized = () => {
  const userlogin = getUserInfo();
  let history = useHistory();

  return (
    <>
      {userlogin !== null && (
        <HeaderWithMenu img="/doctor/menu-mobile-pattern.png" />
      )}
      {userlogin === null && <PreRegisterBanner />}
      {userlogin === null && (
        <RegisterHeader img="/doctor/bg-mobile-pattern.png" />
      )}
      <div className="container">
        <div className="error-inner-container">
          <div className="div-center">
            <p className="error-title-1">NO AUTORIZADO</p>
            <p className="error-title-2">:(</p>
            <p>
              Lo sentimos, no esta autorizado para ingresar a la pagina que
              solicita.
            </p>
            <Button2 label="Ir al inicio" onClick={()=>history.push('/doctor/dashboard')}/>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Unauthorized;
