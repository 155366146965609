import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import {
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    FormHelperText,
} from '@material-ui/core'

const ConfirmPasswordOutlinedInput = ({
    name,
    label,
    required,
    register,
    registerOptions,
    errors,
    passwordToCheck,
    ...remaining
}) => {
    const [isMatch, setIsMatch] = useState(false)

    const registerOption =
        registerOptions && registerOptions[name]
            ? registerOptions[name]
            : undefined

    let isError = false
    let errorMessage = ''
    if (errors && errors.hasOwnProperty(name)) {
        isError = true
        errorMessage = errors[name].message
    }

    return (
        <StyledFormControl variant="outlined" fullWidth={true}>
            <StyledInputLabel htmlFor="outlined-adornment-password">
                {label}
                {required ? <span style={{ color: 'red' }}> *</span> : ''}
            </StyledInputLabel>
            <StyledOutlinedInput
                {...register(name, registerOption)}
                id="outlined-adornment-password_repeat"
                type={'password'}
                defaultValue=""
                error={isError}
                onChange={(e) =>
                    passwordToCheck === e.target.value
                        ? setIsMatch(true)
                        : setIsMatch(false)
                }
                {...remaining}
                endAdornment={
                    <InputAdornment position="end">
                        <CheckImg
                            src={
                                !isMatch
                                    ? '/patient/check-password-grey.svg'
                                    : '/patient/check-password-green.svg'
                            }
                            alt={
                                !isMatch
                                    ? 'Check icon grey'
                                    : 'Check icon green'
                            }
                        />
                    </InputAdornment>
                }
                labelWidth={0}
            />
            <StyledFormHelperText id={`${name}-helper-text`}>
                {errorMessage && (
                    <>
                        <ErrorIcon src="/patient/error-icon.svg" alert="Error icon" />
                        {errorMessage}
                    </>
                )}
            </StyledFormHelperText>
        </StyledFormControl>
    )
}

export default ConfirmPasswordOutlinedInput

// Overwriting with styled component

const StyledFormControl = styled(FormControl)`
    &.MuiFormControl-root {
        margin 18px 0 5px;
    }    
`

const StyledInputLabel = styled(InputLabel)`
    &.MuiFormLabel-root {
        color: #6c757d;

        &.Mui-focused {
            color: #6c757d;
        }
    }

    &.MuiInputLabel-outlined {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        transform: translate(10px, 10px) scale(1);

        &.MuiInputLabel-shrink {
            transform: translate(10px, -18px) scale(0.75);
        }
    }
`

const StyledOutlinedInput = styled(OutlinedInput)`
    &.MuiOutlinedInput-root {
        border-radius: 6px;

        &.Mui-focused fieldset {
            border: 1px solid #b16db3;
        }

        #outlined-adornment-password_repeat {
            padding: 11px 10px 10px;
        }
    }
`

const StyledFormHelperText = styled(FormHelperText)`
    &.MuiFormHelperText-root {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #D62424;

        &.MuiFormHelperText-contained {
            margin-left: 4px;
            margin-right: 4px;
        }
    }
`

const CheckImg = styled.img`
    display: flex;
`

const ErrorIcon = styled.img`
    max-width: 20px;
    width: 100%;
    margin: 4px 5px 0 0;
    line-height: normal;
`
