import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form'
import HeaderWithMenu from '../../../components/patientComponents/component/headerWithMenu'
import Autocomplete from '../../../components/patientComponents/component/form/outlined-inputs/autocomplete'
import styles from '../../../styles/patientStyles/Profile.module.css'
import { Form } from '../../../components/patientComponents/styled-components/profile/styled'
import { UserStateEnum } from '../../../constants/patienteConstants/enums'
import { saveUserInfo } from '../../../services/patientServices/authService'
import { callApi, getPatientList, postRelogin } from '../../../services/patientServices/apiList'
import { Container } from '../../../components/patientComponents/styled-components/login/styled'

const Relogin = () => {
  const [patientList, setPatientList] = useState([])
  let history = useHistory();

  useEffect(() => {
    callApi(getPatientList, setPatientList)
  }, [])

  const methods = useForm()
  const { register, handleSubmit, formState, watch, setValue } = methods
  const { errors } = formState
  const registerOptions = {
    user: { required: 'Debe seleccionar una opción' },
  }

  const onSubmit = handleSubmit((data) => {
    callApi(
      () => postRelogin({ username: data.user }),
      (userInfo) => {
        saveUserInfo(userInfo)
        const page =
          userInfo.state === UserStateEnum.active
            ? '/patient/dashboard'
            : '/patient/dialog-profile'
        history.push(page)
      }
    )
  })

  return (
    <>
      <HeaderWithMenu />
      <Container>
        <Form>
          <Autocomplete
            name="user"
            label="Seleccione un paciente"
            placeholder="Seleccione un paciente"
            registerOptions={registerOptions}
            register={register}
            options={patientList}
            required
            errors={errors}
            watch={watch}
            setValue={setValue}
          />
          <button className={styles.button} onClick={onSubmit}>
            <p className={styles.buttonContent}>Relogin</p>
          </button>
        </Form>
      </Container>
    </>
  )
}

export default Relogin
