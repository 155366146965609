import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import EmailFilledInput from "../../../components/doctorComponents/form/filled-inputs/email";
import PasswordFilledInput from "../../../components/doctorComponents/form/filled-inputs/password";
import Button1 from "../../../components/doctorComponents/buttons/button-1";
import { callApi, getToken } from "../../../services/doctorServices/apiService";
import { saveUserInfo } from "../../../services/doctorServices/authService";
import {
  getUserInfo,
  signOut,
} from "../../../services/doctorServices/authService";
import Progress from "../../../components/patientComponents/component/progress";
import Contact from "../../../components/doctorComponents/modals/contact";
import { getRoleUser } from "../../../services/doctorServices/userService";

const Login = () => {
  const [dlgContac, setDlgContact] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (getUserInfo() !== null) signOut();
  }, []);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const codeLogin = urlParams.get("logIns");

  const handleOpen = () => {
    setDlgContact(true);
  };

  const handleClose = () => {
    setDlgContact(false);
  };

  let history = useHistory();
  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { register, handleSubmit, formState } = methods;
  const { errors } = formState;

  /*   const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] =
    usePasswordValidation({}); */

  const registerOptions = {
    email: {
      required: "Debe ingresar un correo electrónico",
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: "Este correo electrónico no es válido",
      },
    },
    password: {
      required: "Ingrese su contraseña",
      // validate: {
      //   //validLength: () => (validLength ? true : false),
      //   hasNumber: () => (hasNumber ? true : false),
      //   upperCase: () => (upperCase ? true : false),
      //   lowerCase: () => (lowerCase ? true : false),
      //   specialChar: () => (specialChar ? true : false),
      // },
    },
  };

  const onSubmit = handleSubmit((data) => {
    callApi(
      () =>
        getToken({
          username: data.email,
          password: data.password,
        }),
      (userInfo) => {
        saveUserInfo(userInfo);
        getRoleUser();
        const urlback = localStorage.getItem("urlback");

        if (urlback) {
          localStorage.removeItem("urlback");
          history.push(urlback);
          return;
        }

        history.push("/doctor/dashboard");
      },
      setLoader
    );
  });

  return (
    <>
      <div className="container">
        <div className="inner-container">
          <div className="div-center">
            <img
              className="ialurilLogoWhite3"
              src="/doctor/logo-white-menu.svg"
              alt="IalurilLogoWhite"
            />
            <p className="white-font-1">
              Su aliado en el tratamiento con ialuril ® Prefill
            </p>
          </div>
          <FormProvider {...methods}>
            <form noValidate autoComplete="off">
              <EmailFilledInput
                name="email"
                label="Ingrese su correo electrónico"
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                required={true}
              />
              <PasswordFilledInput
                name="password"
                label="Contraseña"
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                required={true}
              />
            </form>
          </FormProvider>
          <div className="div-center">
            <Button1 label="Iniciar sesión" onClick={onSubmit} />
            <Link
              to="/doctor/recover-password"
              style={{ fontSize: "18px", marginTop: "20px" }}
              className="white-font-underline"
            >
              Olvidé mi contraseña
            </Link>
            {codeLogin != "true" && (
              <p style={{ fontSize: "18px" }} className="white-font-1">
                ¿No tiene una cuenta?{" "}
                <Link
                  // to="/doctor/register"
                  to="/selectCountry?app=doctor"
                  style={{ fontSize: "18px" }}
                  className="white-font-underline"
                >
                  Regístrese
                </Link>
              </p>
            )}

            <p style={{ fontSize: "18px" }} className="white-font-1">
              ¿Necesita ayuda?{" "}
              <u
                style={{ cursor: "pointer", fontSize: "18px" }}
                onClick={handleOpen}
                className="white-font-underline"
              >
                Contáctenos
              </u>
            </p>
          </div>
        </div>
      </div>
      <Progress loader={loader} />
      <Contact open={dlgContac} onClose={handleClose} />
    </>
  );
};

export default Login;
