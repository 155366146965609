import React from "react";
import { TableHeadStyle, TableBodyStyle } from "../../../../constants/doctorConstants/enums";
import { HeaderTable1, BodyTable1 } from "./tables/tables-module-2";
import AccordionComponent from "../../../../components/doctorComponents/accordion/accordion-1";
import AccordionBibliography from "../../../../components/doctorComponents/accordion/accordion-2";
import CustomTable from "../../../../components/doctorComponents/customTable";

const ChronicbacterialCystitis = () => {
  return (
    <div className="container">
      <div className="inner-container">
        <p>
          La cistitis crónica recurrente corresponde a una infección urinaria
          baja cuyo{" "}
          <b>
            {" "}
            periodo de latencia entre uno y otro episodio es inferior a 6 meses
          </b>
          , es decir, dos o más infecciones en seis meses o ≥3 infecciones en un
          año. Las recurrencias de ITU son típicamente síntomas irritativos
          urinarios bajos agudos y no las manifestaciones correspondientes a una
          IVU complicada.
        </p>

        <p>
          Se cree que la mayoría de las recurrencias representan una reinfección
          más que una recaída (incluso las recurrencias causadas por la misma
          cepa uropatógena), aunque en ocasiones un foco persistente puede
          producir una infección recurrente.
        </p>

        <AccordionComponent article="chronic-bacterial-cystitis" title="EPIDEMIOLOGÍA">
          <div>
            <p>
              Desde el punto de vista epidemiológico por su{" "}
              <b>mayor frecuencia en mujeres que en hombres (proporción 4:1)</b>
              , los datos aquí definidos corresponden a la cistitis simple
              recurrente en mujeres no embarazadas. Estas infecciones
              generalmente son causadas por los mismos organismos y comparten
              características clínicas, pruebas de diagnóstico y regímenes de
              tratamiento con cistitis simple aguda aislada.{" "}
            </p>
            <p>
              La incidencia de la cistitis recurrente es común entre las
              mujeres, incluso entre mujeres pre-menopáusicas que no tiene
              alteraciones anatomofuncionales de la vía urinaria. En un estudio
              de mujeres universitarias con su primer episodio de cistitis,{" "}
              <b>el 27% experimentó al menos una recurrencia</b> confirmada por
              urocultivo dentro de los seis meses posteriores a la infección
              inicial, y el
              <b> 2.7% tuvo una segunda recurrencia</b> durante este mismo
              período de tiempo.
              <sup>
                <b>1</b>
              </sup>{" "}
              Cuando la primera infección es causada por
              <i> Escherichia coli</i>, las mujeres parecen tener más
              probabilidades de desarrollar un segundo episodio de cistitis en
              seis meses que aquellas, con una primera infección, por otro
              organismo.
              <sup>
                <b>2</b>
              </sup>
            </p>
            <p>
              Existen factores de riesgo, conductuales, anatómicos, biológicos
              y/o genéticos del huésped que predisponen a las mujeres a la
              cistitis aguda y a su recurrencia.
            </p>

            <p className="subtitle-purple">
              1) Factores de riesgo comportamentales:{" "}
            </p>
            <ul>
              <li>
                {" "}
                Las relaciones sexuales y el uso de diafragma, espermicida y
                algunos condones son factores de riesgo que se asocian con un
                mayor riesgo de tener un episodio aislado y su consecuente
                recurrencia. Scholes y Roberts, en un estudio de casos y
                controles de mujeres con y sin antecedentes de cistitis
                recurrente, la frecuencia de las relaciones sexuales fue el
                factor de riesgo más importante en un análisis multivariado.
                <sup>
                  <b>3</b>
                </sup>
              </li>
              <li> Tener una nueva pareja sexual durante el año pasado.</li>
              <li>
                {" "}
                Primoinfección del tracto urinario a los 15 años de edad o
                antes.
              </li>
              <li> Antecedente familiar (madre) con IVU recurrente.</li>
            </ul>

            <p>
              Las dos últimas asociaciones son una prueba más que{" "}
              <b>
                los factores hereditarios pueden ser importantes en algunas
                mujeres con cistitis recurrente
              </b>
              . No se identificaron asociaciones con los patrones de evacuación
              antes y después del coito, la frecuencia de la micción, los
              hábitos de evacuación tardía, los patrones de limpieza, las duchas
              vaginales, el uso de jacuzzis, el uso frecuente de pantimedias o
              mallas o el índice de masa corporal.
              <sup>
                <b>3</b>
              </sup>
            </p>

            <p className="subtitle-purple">2) Factores urológicos: </p>

            <p>
              En las <b>mujeres post-menopáusicas</b> se encontraron condiciones
              urológicas asociadas más frecuentes que el factor comportamental.
              La revisión de Raz y Wasser
              <sup>
                <b>4</b>
              </sup>{" "}
              describe de los más importantes:
            </p>

            <ul>
              <li>
                {" "}
                Incontinencia urinaria (<b>41 versus 9%</b> para casos y
                controles, respectivamente).
              </li>
              <li>
                {" "}
                Presencia de un cistocele (<b>19 frente a 0%</b>).
              </li>
              <li>
                {" "}
                Orina residual posterior a la micción (<b>28 frente al 2%</b>).
              </li>
            </ul>

            <p>
              Sin embargo, en otro estudio de 213 mujeres (100 con antecedentes
              de cistitis recurrente y 113 controles sin ella), no hubo
              diferencias entre los casos y los controles en la longitud
              uretral, la orina residual posmiccional o las características de
              micción.
              <sup>
                <b>5</b>
              </sup>
            </p>

            <p className="subtitle-purple">
              3) Factores biológicos o genéticos:{" "}
            </p>

            <p>
              Existe evidencia en estudios clásicos de Stamey y Schaeffer
              <sup>
                <b>6</b>
              </sup>{" "}
              que sugiere que las{" "}
              <b>
                mujeres con cistitis recurrente tienen una mayor susceptibilidad
                a la colonización vaginal con uropatógenos
              </b>
              , incluso durante períodos asintomáticos, en comparación con las
              mujeres sin antecedentes de recurrencia, posiblemente atribuído a
              que los bacilos coliformes uropatógenos se adhieren al urotelio de
              estas mujeres.
            </p>

            <p>
              El fenotipo no secretor está sobrerrepresentado entre las niñas y
              las mujeres con cistitis recurrente. Las células uroepiteliales de
              mujeres que no son secretadoras de antígenos del grupo sanguíneo
              ABH muestran una mayor adherencia de <i>E. coli</i> uropatógena en
              comparación con las células secretoras.
              <sup>
                <b>7</b>
              </sup>{" "}
              La asociación entre el fenotipo no secretor y la cistitis
              recurrente puede ser menos importante en mujeres que tienen otros
              factores de riesgo importantes de cistitis recurrente, como el uso
              de espermicidas o las relaciones sexuales frecuentes.
              <sup>
                <b>8</b>
              </sup>
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="chronic-bacterial-cystitis" title="DIAGNÓSTICO CLÍNICO Y MICROBIOLÓGICO">
          <div>
            <p>
              Las pacientes con cistitis recurrente refieren antecedentes de
              episodios aislados de síntomas irritativos urinarios típicos, que
              incluyen disuria, frecuencia urinaria, urgencia miccional asociado
              con dolor pélvico. La probabilidad de cistitis es{" "}
              <b>
                superior al 50% en mujeres con cualquiera de estos síntomas y
                superior al 90% en mujeres que tienen disuria y frecuencia
                miccional/
              </b>
              , sin secreción o irritación vaginal.
              <sup>
                <b>9</b>
              </sup>
            </p>
            <p>
              Esta condición clínica hace que el diagnóstico de cistitis
              recurrente sea evidente y no requiere exámenes diagnósticos para
              confirmarlo. Algunas pacientes atribuyen síntomas urinarios
              crónicos o inespecíficos a la cistitis y es probable que existan
              causas no infecciosas de tales síntomas; en estos casos,{" "}
              <b>
                las pruebas de orina durante un episodio sintomático son útiles
                para confirmar o descartar el diagnóstico de cistitis
              </b>
              . El uroanálisis (ya sea por microscopía o por tira reactiva) es
              una prueba diagnóstica de laboratorio valiosa para confirmar la
              infección urinaria al evaluar sensiblemente la piuria y su
              ausencia sugiere un diagnóstico diferente.
              <sup>
                <b>10</b>
              </sup>
            </p>
            <ul>
              <li>
                El método más preciso para evaluar la piuria es examinar una
                muestra de orina del chorro medio evacuada no centrifugada bajo
                el microscopio con un hemocitómetro;{" "}
                <b>un resultado anormal es ≥10 leucocitos / microL</b>. La
                presencia de hematuria es útil ya que es común en el contexto de
                IVU, pero no en uretritis o vaginitis. Sin embargo, la hematuria
                no es un predictor de infección complicada y no altera el
                enfoque del tratamiento. Los cilindros de glóbulos blancos en la
                orina, aunque raros, son indicativos de infección del tracto
                superior más que de cistitis simple.
              </li>
              <li>
                Las tiras reactivas son insumos disponibles comercialmente que
                detectan la presencia de esterasa leucocitaria (una enzima
                liberada por los leucocitos, que refleja la piuria) y nitrito
                (que refleja la presencia de Enterobacterias, que convierten el
                nitrato urinario en nitrito). La prueba con tira reactiva es más
                precisa para predecir la IVU cuando es positiva para esterasa
                leucocitaria o nitrito, con una sensibilidad del 75% y una
                especificidad del 82%.
                <sup>
                  <b>9</b>
                </sup>{" "}
                Sin embargo,{" "}
                <b>
                  los resultados de la prueba con tira reactiva brindan poca
                  información útil cuando la historia clínica sugiere
                  fuertemente una infección urinaria
                </b>
                , ya que incluso los resultados negativos de ambas pruebas no
                descartan de manera confiable la infección en tales casos.
              </li>
            </ul>

            <p>
              En el enfoque diagnóstico de la cistitis aguda no es necesario
              realizar <b>urocultivo</b> en todos los casos, pero sí se solicita
              durante un episodio agudo de cistitis en el contexto de factores
              de riesgo de resistencia a los antimicrobianos (<i>tabla 2</i>) o
              infección grave.{" "}
              <b>
                En mujeres con cistitis recurrente, el uso reciente de
                antibióticos es un factor de riesgo primario para la resistencia
                a los antimicrobianos
              </b>
              .
            </p>

            <div className="imgBox">
              <p className="title-gray-2">
                RISK FACTORS FOR MULTIDRUG-RESISTANT GRAM-NEGATIVE URINARY TRACT
                INFECTIONS
              </p>

              <CustomTable
                bodystyle={TableBodyStyle.interspersed}
                headstyle={TableHeadStyle.purple}
                columns={HeaderTable1}
                rows={BodyTable1}
              />
              <br />
              <b>
                Multidrug resistance refers to nonsusceptibility to at least one
                agent in three or more antibiotic classes. This includes
                isolates that produce an extended-spectrum beta-lactamase
                (ESBL).
              </b>
              <p>
                {" "}
                * This includes a single antibiotic dose given for prophylaxis
                prior to prostate procedures. ¶ The prevalence of multidrug
                resistance is not well documented in all parts of the world.
                Some countries where the prevalence is particularly high include
                India, Israel, Spain, and Mexico.
              </p>
            </div>
            <p className="italics-gray">
              Tabla 2. Factores de riesgo para IVU por Gram negativos
              multirresistentes
            </p>
            <p>
              Si se solicitan urocultivos, el médico debe pedirle al laboratorio
              que cuantifique el germen causal y 103 unidades formadoras de
              colonias / ml mejora la especificidad.
            </p>

            <p>
              Anotaciones importantes sobre estos gérmenes: el <i>E. coli</i> no
              debe considerarse necesariamente un contaminante si crece en una
              flora mixta, ya que casi cualquier crecimiento de <i>E. coli</i>{" "}
              en la orina evacuada en un paciente sintomático refleja el
              crecimiento de la vejiga y el crecimiento de organismos que
              generalmente se cree que son contaminantes (como lactobacilos,
              enterococos, estreptococos del grupo B y estafilococos coagulasa
              negativos no saprofitos) puede considerarse causal cuando se
              encuentran en la muestra de orina del flujo en recuentos altos y
              con crecimiento aislado.{" "}
            </p>

            <p>
              Es importante distinguir clinicamente si los episodios recurrentes
              son producto de una <b>reinfección</b> (la infección previa fue
              erradicada) o es una <b>recidiva</b> (infección previa fue
              parcialmente erradicada y hay recaída de la misma); dicho en otros
              términos del curso biológico y el agente etiológico, la cistitis
              recurrente se define arbitrariamente como una <b>recidiva</b> si
              la recurrencia ocurre dentro de las{" "}
              <b>
                dos semanas posteriores a la finalización del tratamiento para
                la infección inicial y la cepa uropatógena infectante es la
                misma
              </b>
              . Por el contrario, la cistitis recurrente que ocurre{" "}
              <b>
                después de dos semanas del tratamiento se considera una
                reinfección, incluso si el uropatógeno infectante es el mismo
                que el original
              </b>
              . Cuando se documenta un cultivo de orina estéril entre los dos
              episodios de cistitis en un paciente sin antibióticos o cuando los
              episodios recurrentes son causados por una cepa de uropatógenos
              diferente de la original, la recurrencia también se clasifica como
              una <b>reinfección</b>.
            </p>

            <p>
              La gran mayoría de las cistitis recurrentes parecen ser
              reinfecciones, incluso siendo el mismo germen uropatógeno que
              puede persistir en la flora fecal después de la eliminación del
              tracto urinario, posteriormente recolonizar el introito y la
              vejiga y finalmente causar cistitis recurrente;
              <sup>
                <b>11</b>
              </sup>{" "}
              la mayoría de las recurrencias se producen dentro de los primeros
              tres meses después de la infección inicial.
              <sup>
                <b>12,13</b>
              </sup>
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="chronic-bacterial-cystitis" title="IMÁGENES DIAGNÓSTICAS">
          <div>
            <p>
              La mayoría de las mujeres con cistitis recurrente no requieren una
              evaluación radiológica por imágenes. Tal indicación estaría
              reservada para mujeres con un cuadro clínico que sugiera algunas
              anomalías estructurales o funcionales del tracto genitourinario
              que pueden justificar una intervención adicional.
            </p>

            <p>
              <b>
                Por lo general, se indica una ecografía renal y de vías
                urinarias, una tomografía computarizada (TC)
              </b>{" "}
              para descartar nefrolitiasis o uropatía obstructiva o una
              <b>cistografia miccional</b> con sospecha de reflujo
              vesicoureteral (<i>Foto 1, 2, 3</i>).
            </p>

            <ul>
              <li> Infección recurrente o antecedente de pielonefritis.</li>
              <li>
                {" "}
                Aislamiento repetido de Proteus spp, que a menudo se asocia con
                nefrolitiasis.
              </li>
              <li> Historial de lituria espontánea.</li>
            </ul>

            <img
              className="img"
              src="/doctor/paciente-con-hidronefrosis-derecha-secundaria-xhdpi.png"
              alt="Img"
            />
            <p className="italics-gray">
              Foto 1. Paciente con hidronefrosis derecha secundaria a
              ureterolitiasis ipsilateral asociada a IVU recurrente (archivo
              personal*)
            </p>
            <img
              className="img"
              src="/doctor/doctor-img021.png"
              alt="Img"
            />
            <p className="italics-gray">
              Foto 2. Rx abdomen simple de mujer de 24 años, con síntomas
              irritativos urinarios persistentes con cateter ureteral de
              autoretención calcificado (archivo personal*)
            </p>
            <img
              className="img"
              src="/doctor/doctor-img001.png"
              alt="Img"
            />
            <p className="italics-gray">
              Foto 3. Cistografía de paciente femenina con cistitis recurrente
              por divertículo y reflujo vesico-ureteral. (archivo personal*)
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="chronic-bacterial-cystitis" title="EVALUACIÓN UROLÓGICA">
          <div>
            <p>
              Los estudios urológicos diagnósticos{" "}
              <b>(Urodinamia y Cistoscopia)</b>, además de las imágenes del
              tracto superior no deben obtenerse de forma rutinaria en el
              paciente índice que presenta IVU recurrentes.
              <sup>
                <b>14</b>
              </sup>{" "}
              Sin embargo,{" "}
              <b>
                existen condiciones clínico-patológicas que pueden ser
                indicación para realizar estos exámenes
              </b>
              :
            </p>

            <ul>
              <li>
                {" "}
                Pacientes con disfunción miccional (micción incompleta,
                incontinencia, prolapso genital) deben ser derivadas para una
                evaluación urológica (Urodinamia) o uroginecológica (
                <i>Foto 4</i>
                ).
              </li>
              <li>
                {" "}
                Pacientes con IVU complicada para evaluar anomalías anatómicas o
                estructurales (Divertículos vesicales, orificios ureterales
                ectópicos, duplicación ureteral, presencia de cuerpos extraños,
                fístulas, estenosis o anomalías de cirugías previas) y la
                hematuria persistente después de la erradicación de la infección
                pueden ser candidatas a <b>uretrocistoscopia</b> (<i>Foto 5</i>
                ).
              </li>
            </ul>

            <img
              className="img"
              src="/doctor/module2-urodinamia.png"
              alt="Img"
            />
            <p className="italics-gray">
              Foto 4. Urodinamia de paciente con incontinencia urinaria de
              urgencia (Vejiga hiperactiva) y episodios recurrentes de cistitis
              (archivo personal*)
            </p>

            <img
              className="img"
              src="/doctor/doctor-img002.png"
              alt="Img"
            />
            <p className="italics-gray">
              Foto 5. Cistoscopia de paciente de 40 años con cistitis recurrente
              asociada a cálculo vesical (archivo personal*)
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="chronic-bacterial-cystitis" title="DIAGNÓSTICO DIFERENCIAL">
          <div>
            <p>
              Los síntomas irritativos urinarios, el dolor pélvico y la
              hematuria no son patognomónicos de la cistitis bacteriana y pueden
              existir procesos inflamatorios extraurinarios que ocasionan el
              mismo síndrome:{" "}
            </p>
            <p>
              <b className="bold-purple">1) Vaginitis</b>: en mujeres con
              disuria, la presencia de secreción u olor vaginal, prurito,
              dispareunia y ausencia de frecuencia o urgencia urinaria debe
              impulsar la consideración de la vaginitis. Las causas de la
              vaginitis incluyen candidiasis, tricomoniasis y vaginosis
              bacteriana.{" "}
            </p>
            <p>
              <b className="bold-purple">2) Uretritis</b>: la evaluación de la
              uretritis está justificada en mujeres sexualmente activas con
              disuria, en particular aquellas con piuria en el uroanálisis, sin
              bacteriuria. Las causas de la uretritis en las mujeres incluyen
              clamidia, gonorrea, tricomoniasis, especies de Candida, virus del
              herpes simple e irritantes no infecciosos, como un gel
              anticonceptivo.{" "}
            </p>
            <p>
              <b className="bold-purple">3) Síndrome de vejiga dolorosa</b>:
              este es un diagnóstico de exclusión en mujeres que tienen
              molestias continúas relacionadas con la vejiga con síntomas de
              disuria, frecuencia y / o urgencia, pero sin evidencia de
              infección u otra causa identificable.{" "}
            </p>
            <p>
              <b className="bold-purple">4) Enfermedad pélvica inflamatoria</b>:
              el dolor y la fiebre en la parte inferior del abdomen o la pelvis
              son los hallazgos clínicos más comunes en pacientes con enfermedad
              pélvica inflamatoria (EPI), aunque también pueden presentar
              disuria. Los hallazgos de secreción endocervical mucopurulenta o
              dolor a la palpación del movimiento cervical en el examen pélvico
              son muy sugestivos de EPI.{" "}
            </p>
          </div>
        </AccordionComponent>

        <AccordionBibliography title="BIBLIOGRAFIA">
          <div>
            <p className="bibliography-font">
              {" "}
              <b>1.</b>Foxman B. Recurring urinary tract infection: incidence
              and risk factors. Am J Public Health 1990; 80:331.
            </p>
            <p className="bibliography-font">
              <b>2.</b> Foxman B, Gillespie B, Koopman J, et al. Risk factors
              for second urinary tract infection among college women. Am J
              Epidemiol 2000; 151:1194.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>3.</b> Scholes D, Hooton TM, Roberts PL, et al. Risk factors
              for recurrent urinary tract infection in young women. J Infect Dis
              2000; 182:1177.
            </p>
            <p className="bibliography-font">
              <b>4.</b> Raz R, Gennesin Y, Wasser J, et al. Recurrent urinary
              tract infections in postmenopausal women. Clin Infect Dis 2000;
              30:152.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>5.</b> Hooton TM, Stapleton AE, Roberts PL, et al. Perineal
              anatomy and urine-voiding characteristics of young women with and
              without recurrent urinary tract infections. Clin Infect Dis 1999;
              29:1600.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>6.</b>Schaeffer AJ, Stamey TA. Studies of introital
              colonization in women with recurrent urinary infections. IX. The
              role of antimicrobial therapy. J Urol 1977; 118:221.
            </p>
            <p className="bibliography-font">
              <b>7.</b> Lomberg H, Cedergren B, Leffler H, et al. Influence of
              blood group on the availability of receptors for attachment of
              uropathogenic Escherichia coli. Infect Immun 1986; 51:919.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>8.</b> Gupta K, Stamm WE. Pathogenesis and management of
              recurrent urinary tract infections in women. World J Urol 1999;
              17:415.
            </p>
            <p className="bibliography-font">
              <b>9.</b> Bent S, Nallamothu BK, Simel DL, et al. Does this woman
              have an acute uncomplicated urinary tract infection? JAMA 2002;
              287:2701.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>10.</b> Walker E, Lyman A, Gupta K, et al. Clinical Management
              of an Increasing Threat: Outpatient Urinary Tract Infections Due
              to Multidrug-Resistant Uropathogens. Clin Infect Dis 2016; 63:960.
            </p>
            <p className="bibliography-font">
              <b>11.</b> Russo TA, Stapleton A, Wenderoth S, et al. Chromosomal
              restriction fragment length polymorphism analysis of Escherichia
              coli strains causing recurrent urinary tract infections in young
              women. J Infect Dis 1995; 172:440.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>12.</b> Kraft JK, Stamey TA. The natural history of symptomatic
              recurrent bacteriuria in women. Medicine (Baltimore) 1977; 56:55.
            </p>
            <p className="bibliography-font">
              <b>13.</b> Stamm WE, McKevitt M, Roberts PL, White NJ. Natural
              history of recurrent urinary tract infections in women. Rev Infect
              Dis 1991; 13:77.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>14.</b> American Urological Association (AUA)/Canadian
              Urological Association (CUA)/Society of Urodynamics, Female Pelvic
              Medicine & Urogenital Reconstruction (SUFU): Guideline on
              recurrent uncomplicated urinary tract infections in women (2019).
            </p>
          </div>
        </AccordionBibliography>
      </div>
    </div>
  );
};

export default ChronicbacterialCystitis;
