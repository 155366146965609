import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { RoleEnum } from '../../../../constants/patienteConstants/enums'
import { getUserInfo, signOut } from '../../../../services/patientServices/authService'
import { useHistory } from "react-router-dom";

import {
  Container,
  HeaderContainer,
  Logo,
  ButtonMenu,
  IconLine,
  MenuContainer,
  Menu,
  MenuItem,
  MenuSeparator,
} from '../../styled-components/headerWithMenu/styled'

const HeaderWithMenu = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false)
  const [userInfo, setUserInfo] = useState(null)

  const handleButtonMenu = () => {
    setIsOpen(!isOpen)
  }

  const close = () => {
    signOut();
    history.push("/patient");
  }

  useEffect(() => setUserInfo(getUserInfo()), [])

  return (
    <Container>
      <HeaderContainer>
        <Logo
          src="/doctor/logo-white-menu.svg"
          alt="Logo Ialuril texto blanco con icono a color"
        />
        <ButtonMenu
          style={{ cursor: 'pointer' }}
          onClick={handleButtonMenu}
          className={isOpen ? 'menu-open' : ''}
        >
          <IconLine className="first" />
          <IconLine className="last" />
        </ButtonMenu>
      </HeaderContainer>
      <MenuContainer className={isOpen ? 'menu-open' : ''}>
        <Menu style={{overflowY:"auto"}}>
          {userInfo && userInfo.role !== RoleEnum.admin && (
            <>
              <MenuItem>
                <Link to="/patient/dashboard">Home</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/patient/education">Informaci&oacute;n relevante</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/patient/recommendations">Recomendaciones</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/patient/instillations">Instilaciones</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/patient/profile">Perfil</Link>
              </MenuItem>
              {userInfo && userInfo.isRelogin === true && (
                <>
                  <MenuItem>
                    <Link to="/patient/relogin">Relogin</Link>
                  </MenuItem>
                </>
              )}
              <MenuSeparator />

              <MenuItem>
                <Link to="/patient/privacyPolicy">Pol&iacute;tica de privacidad</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/patient/termsAndConditions">
                  T&eacute;rminos y condiciones
                </Link>
              </MenuItem>
              <MenuItem>
              <Link onClick={close}>Cerrar sesi&oacute;n</Link>
              </MenuItem>
            </>
          )}
          {userInfo && userInfo.role === RoleEnum.admin && (
            <>
              <MenuItem>
              <Link onClick={close}>Cerrar sesi&oacute;n</Link>
              </MenuItem>
            </>
          )}
        </Menu>
      </MenuContainer>
    </Container>
  )
}

export default HeaderWithMenu
