import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import styles from '../../../../styles/patientStyles/Survey.module.css'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

const PurpleCheckbox = withStyles({
  root: {
    color: '#B16DB3',
    '&$checked': {
      color: '#B16DB3',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

const CustomSymptom = ({ symptom, updateSymptom, onDelete }) => {
  const handleClick = () => {
    updateSymptom(symptom.id)
  }

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={styles.newSymptomContainer}
    >
      <div className={styles.newSymptomName}>
        <PurpleCheckbox
          checked={symptom.selected}
          onChange={handleClick}
          name="checkedB"
          color="primary"
        />
        <p className={styles.newSymptom}>{symptom.name}</p>
      </div>
      <div onClick={() => onDelete()}>
        <img className={styles.deleteIcon} src="/patient/delete.svg" alt="Icon" />
      </div>
    </Grid>
  )
}

export default CustomSymptom
