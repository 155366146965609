import React, { useState, useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { useHistory } from "react-router-dom";
import PasswordOutlinedInput from '../../../components/patientComponents/component/form/outlined-inputs/password'
import ConfirmPasswordOutlinedInput from '../../../components/patientComponents/component/form/outlined-inputs/confirmPassword'
import { usePasswordValidation } from '../../../hook/patientHook/usePasswordValidation'
import {
  Container,
  HeaderLogo,
  Logo,
  Title,
  Text,
  Form,
  SendButton,
  ValidationList,
  ValidationItemList,
  IconStateValidation,
} from '../../../components/patientComponents/styled-components/reset-password/styled'
import { callApi, getCodeIsValid, postResetPassword } from '../../../services/patientServices/apiList'

const ResetPassword = () => {
  const history = useHistory();
  const [code, setCode] = useState(false)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('code')

    if (!code) {
      history.push('/login')
      return
    }

    callApi(
      () => getCodeIsValid(code),
      (data) => {
        if (!data.result) {
          if (data.error) NotificationManager.warning(data.error)
          history.push('/login')
        }
        setCode(code)
      }
    )
  }, [])

  const [passwordValidation, setPasswordValidation] = useState({
    firstPassword: '',
    secondPassword: '',
  })

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] =
    usePasswordValidation({
      firstPassword: passwordValidation.firstPassword,
      secondPassword: passwordValidation.secondPassword,
    })

  const defaultValues = {
    deliveryDate: null,
    document: null,
    chk: false,
  }

  const methods = useForm({ defaultValues })
  const { register, handleSubmit, formState, watch } = methods
  const { errors } = formState

  const password = useRef({})
  password.current = watch('password', '')

  const onSubmit = handleSubmit((data) => {
    callApi(
      () =>
        postResetPassword({
          code,
          password: data.password,
        }),
      (data) => {
        if (!data.result) {
          if (data.error) NotificationManager.warning(data.error)
          history.push('/patient/login')
        } else {
          history.push('/patient/reset-password/success')
        }
      }
    )
  })

  const registerOptions = {
    password: {
      required: 'Ingrese su contraseña',
      validate: {
        validLength: () => (validLength ? true : false),
        hasNumber: () => (hasNumber ? true : false),
        upperCase: () => (upperCase ? true : false),
        lowerCase: () => (lowerCase ? true : false),
        specialChar: () => (specialChar ? true : false),
      },
    },
    password_repeat: {
      validate: (value) =>
        value === password.current || 'Las contraseñas no coinciden',
    },
  }

  const setFirst = (event) => {
    setPasswordValidation({
      ...passwordValidation,
      firstPassword: event.target.value,
    })
  }
  const setSecond = (event) => {
    setPasswordValidation({
      ...passwordValidation,
      secondPassword: event.target.value,
    })
  }

  return (
    <>
      <Container>
        <HeaderLogo>
          <Logo src="/patient/logo-color.svg" alt="ialuriApp Logo" />
        </HeaderLogo>

        <Title>Restablecer contraseña</Title>
        <Text>
          Registre y confirme la nueva contraseña para ingresar a su cuenta
        </Text>

        <Form onSubmit={onSubmit}>
          <PasswordOutlinedInput
            name="password"
            label="Nueva contraseña"
            required={true}
            register={register}
            registerOptions={registerOptions}
            errors={errors}
            onChange={setFirst}
          />

          <ValidationList>
            <ValidationItemList>
              <IconStateValidation
                src={!validLength ? '/patient/cross-red.svg' : '/patient/tick-green.svg'}
              />
              Que sea entre 8 y 16 caracteres
            </ValidationItemList>
            <ValidationItemList>
              <IconStateValidation
                src={!upperCase ? '/patient/cross-red.svg' : '/patient/tick-green.svg'}
              />
              Incluya al menos una letra mayúscula
            </ValidationItemList>
            <ValidationItemList>
              <IconStateValidation
                src={!lowerCase ? '/patient/cross-red.svg' : '/patient/tick-green.svg'}
              />
              Incluya al menos una letra minúscula
            </ValidationItemList>
            <ValidationItemList>
              <IconStateValidation
                src={!hasNumber ? '/patient/cross-red.svg' : '/patient/tick-green.svg'}
              />
              Incluya al menos un número
            </ValidationItemList>
            <ValidationItemList>
              <IconStateValidation
                src={!specialChar ? '/patient/cross-red.svg' : '/patient/tick-green.svg'}
              />
              Incluya al menos un carácter especial, por ejemplo ! $ + – ( ) @
              &lt; &gt;
            </ValidationItemList>
          </ValidationList>

          <ConfirmPasswordOutlinedInput
            name="password_repeat"
            label="Confirmar nueva contraseña"
            required={true}
            register={register}
            registerOptions={registerOptions}
            errors={errors}
            passwordToCheck={password.current}
            onChange={setSecond}
          />

          <SendButton>Cambiar contraseña</SendButton>
        </Form>
      </Container>
    </>
  )
}

export default ResetPassword
