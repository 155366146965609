import React from "react";
import {StyleButton3, useStyles} from "../../../../styles/DoctorStyles/ui/buttons"

const Button3 = (props) => {
  const classes = useStyles();

  return (
    <div>
      <StyleButton3 variant="contained" color="primary" className={classes.margin}>
        {props.label}
      </StyleButton3>
    </div>
  );
}

export default Button3;
