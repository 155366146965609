import React from 'react'
import styles from '../../../styles/patientStyles/components/IalurilTextBox.module.css'

const IalurilTextBox = (props) => {
  if (props.color === 'ligth') {
    return (
      <div className={`${styles.textContainer}`}>
        <div className={`${styles.textBox}`}>
          <div className={`${styles.ligthTitle}`}>{props.title}</div>
          <p className={`${styles.ligthContent}`}>{props.content}</p>
        </div>
      </div>
    )
  }
  return (
    <div className={`${styles.textBox}`}>
      <div className={`${styles.title}`}>{props.title}</div>
      <p className={`${styles.content}`}>{props.content}</p>
    </div>
  )
}

export default IalurilTextBox
