import React, { useState, useEffect } from 'react'
import { FormControl, FormHelperText } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import styled from 'styled-components'

const getSelectValue = (value, props, options) => {
  if (!value) return props.multiple ? [] : null

  if (props.multiple) {
    return options.filter((x) => value.includes(x.value))
  } else {
    const item = options.find((x) => x.value === value)
    return !item ? null : item
  }
}

const getChangeValue = (values, props) => {
  if (!values) return props.multiple ? [] : null
  return props.multiple ? values.map((c) => c.value) : values.value
}

const AutocompleteSelect = (props) => {
  const {
    name,
    label,
    options,
    required,
    register,
    registerOptions,
    errors,
    //value = null,
    watch,
    setValue,
    ...remaining
  } = props
  const [newData, setNewData] = useState([])

  useEffect(() => {
    const newOptions = options.map((data, index) => ({
      label: data.name,
      value: data.id,
    }))
    setNewData(newOptions)
  }, [options])

  const registerOption =
    registerOptions && registerOptions[name] ? registerOptions[name] : undefined

  let isError = false
  let errorMessage = ''
  if (errors && errors.hasOwnProperty(name)) {
    isError = true
    errorMessage = errors[name].message
  }

  const handleInvalidDefault = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const selectReg = register(name, registerOption)
  return (
    <StyledFormControl
      style={{
        margin: '0',
      }}
      variant="outlined"
      fullWidth={true}
    >
      <Autocomplete
        id={name}
        name={name}
        size="small"
        defaultValue={props.multiple ? [] : null}
        getOptionLabel={(option) => (option.label ? option.label : '')}
        onInvalid={handleInvalidDefault}
        disableClearable
        options={newData}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: 'search' }}
          />
        )}
        onChange={(e, values) => {
          setValue(name, getChangeValue(values, props, newData))
        }}
        onBlur={selectReg.onBlur}
        ref={selectReg.ref}
        value={getSelectValue(watch(name), props, newData)}
        {...remaining}
      />
      <StyledFormHelperText id={`${name}-helper-text`}>
        {errorMessage && (
          <>
            <ErrorIcon src="/patient/error-icon.svg" alert="Error icon" />
            {errorMessage}
          </>
        )}
      </StyledFormHelperText>
    </StyledFormControl>
  )
}

export default AutocompleteSelect

const StyledFormControl = styled(FormControl)`
    &.MuiFormControl-root {
        margin 18px 0 5px;
    }    
`

const StyledFormHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #d62424;

    &.MuiFormHelperText-contained {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
`

const ErrorIcon = styled.img`
  &.MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }
`
