import styled from 'styled-components'

export const Form = styled.form`
  padding: 0;
`
export const MarginSeparator = styled.hr`
  margin: ${(props) => props.margin};
  border: 0;
  unicode-bidi: unset;
`
