import React from "react";
import { getCountry } from "../../../services/doctorServices/userService";

const CountryIndicator = () => {
  const country = getCountry();

  return (
    <div>
      <img
        className="country-indicator"
        src={
          country == "CO"
            ? "/flags/colombia-flag.jpg"
            : "/flags/costa-rica-flag.jpg"
        }
        alt="flag"
      />
    </div>
  );
};

export default CountryIndicator;
