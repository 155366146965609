import React from 'react'
import { Link } from "react-router-dom";
import {
  Container,
  HeaderLogo,
  Logo,
  ReturnButton,
} from '../styled-components/reset-password/success/styled'

const PageOk = ({ children, ...props }) => {
  return (
    <>
      <Container>
        <HeaderLogo>
          <Logo src="/patient/logo-color.svg" alt="ialuriApp Logo" />
        </HeaderLogo>
        {children}
        <ReturnButton>
          <Link to="/patient/login">OK</Link>
        </ReturnButton>
      </Container>
    </>
  )
}

export default PageOk
