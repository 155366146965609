import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export const StyleButton1 = withStyles((theme) => ({
  root: {
    textTransform: "none",
    backgroundColor: "#AE5CA4",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#FFFFFF",
    boxShadow: "0px 7px 11px rgba(52, 58, 64, 0.3)",
    width: "100%",
    height: "45px",
    "&:hover": {
      backgroundColor: "#894381",
    },
  },
}))(Button);

export const StyleButton2 = withStyles((theme) => ({
  root: {
    textTransform: "none",
    backgroundColor: "#AE5CA4",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#FFFFFF",
    boxShadow: "0px 7px 11px rgba(52, 58, 64, 0.3)",
    width: "135px",
    height: "45px",
    "&:hover": {
      backgroundColor: "#894381",
    },
  },
}))(Button);

export const StyleButton3 = withStyles((theme) => ({
    root: {
      textTransform: "none",
      backgroundColor: "#AE5CA4",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "20px",
      color: "#FFFFFF",
      boxShadow: "0px 7px 11px rgba(52, 58, 64, 0.3)",
      width: "288px",
      height: "45px",
      "&:hover": {
        backgroundColor: "#894381",
      },
    },
  }))(Button);

  export const StyleButton4 = withStyles((theme) => ({
    root: {
      textTransform: "none",
      backgroundColor: "#AE5CA4",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "20px",
      color: "#FFFFFF",
      margin: "0 10px 0 10px",
      boxShadow: "0px 7px 11px rgba(52, 58, 64, 0.3)",
      width: "160px",
      height: "45px",
      "&:hover": {
        backgroundColor: "#894381",
      },
      ['@media (max-width:560px)']: {
        width: "175px",
      }
    },
  }))(Button);

  export const StyleButtonXS = withStyles((theme) => ({
    root: {
      textTransform: "none",
      backgroundColor: "#AE5CA4",
      fontWeight: "500",
      fontSize: "13px",
      lineHeight: "20px",
      color: "#FFFFFF",
      boxShadow: "0px 7px 11px rgba(52, 58, 64, 0.3)",
      width: "175px",
      height: "45px",
      "&:hover": {
        backgroundColor: "#894381",
      },
      ['@media (max-width:560px)']: {
        width: "135px",
      }
    },
  }))(Button);


export const useStyles = makeStyles((theme) => ({
  margin: {
    margin: "15px",
  },
}));
