import { Switch, Redirect } from "react-router-dom";

//pages
import PatientMain from "../../pages/patientPages/main";
import PatientLogin from "../../pages/patientPages/login";
import ForgotPassword from "../../pages/patientPages/forgot-password";
import ResetPassword from "../../pages/patientPages/reset-password/index";
import Success from "../../pages/patientPages/forgot-password/success/index";
import EmailSent from "../../pages/patientPages/register/email-sent";
import DialogProfile from "../../pages/patientPages/dialog-profile";
import SuccessReset from "../../pages/patientPages/register/success";
import SuccessResetPassword from "../../pages/patientPages/reset-password/success";
import PatientRegister from "../../pages/patientPages/register";
import PatientPostRegister from "../../pages/patientPages/post-register";
import Recommendation from "../../pages/patientPages/recommendations/index";
import Recommendation1 from "../../pages/patientPages/recommendations/before-intravesical-instillation";
import Recommendation2 from "../../pages/patientPages/recommendations/the-day-of-intravesical-instillation";
import Recommendation3 from "../../pages/patientPages/recommendations/after-intravesical-instillation";
import PatientPrivacyPolicy from "../../pages/patientPages/privacyPolicy";
import PatientTermsAndConditions from "../../pages/patientPages/termsAndConditions";
import Instillations from "../../pages/patientPages/instillations";
import InstillationsInstitution from "../../pages/institutionPages/institutionInstilations";
import PatientDashboard from "../../pages/patientPages/dashboard";
import PatientProfile from "../../pages/patientPages/profile";
import PatientEducation from "../../pages/patientPages/education";
import Education1 from "../../pages/patientPages/education/what-is-an-intravesical-instillation";
import Education2 from "../../pages/patientPages/education/who-covers-this-procedure";
import Education3 from "../../pages/patientPages/education/documentation-for-the-procedure";
import Survey from "../../pages/patientPages/survey";
import Patient404 from "../../pages/patientPages/404";
import Patient500 from "../../pages/patientPages/500";
import Relogin from "../../pages/patientPages/relogin";
import PatientDetailsFP from "../../pages/patientPages/patientDetailsFP";
import { NotificationContainer } from "react-notifications";
import PatientProtectedRoute from "../../routes/protectedRoute/patient";
import { isRole } from "../../services/doctorServices/userService";
import { RoleEnum } from "../../constants/doctorConstants/enums";


function PatientApp() {
 const isInstitution = isRole(RoleEnum.institution);
  return (
    <Switch>
      <PatientProtectedRoute
        exact
        path="/patient"
        render={() => <PatientMain />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/login"
        render={() => <PatientLogin />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/forgot-password"
        render={() => <ForgotPassword />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/forgot-password/success"
        render={() => <Success />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/reset-password"
        render={() => <ResetPassword />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/reset-password/success"
        render={() => <SuccessResetPassword />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/instillations2"
        render={ () => <Instillations />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/instillations"
        render={ isInstitution ? () => <InstillationsInstitution /> : () => <Instillations />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/survey"
        render={() => <Survey />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/relogin"
        render={() => <Relogin />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/register"
        render={() => <PatientRegister />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/register/email-sent"
        render={() => <EmailSent />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/register/code"
        render={() => <SuccessReset />}
      />{" "}
      {/* ruta importante para el correo de verificacion */}
      <PatientProtectedRoute
        exact
        path="/patient/dialog-profile"
        render={() => <DialogProfile />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/post-register"
        render={() => <PatientPostRegister />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/dashboard"
        render={() => <PatientDashboard />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/profile"
        render={() => <PatientProfile />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/recommendations"
        render={() => <Recommendation />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/recommendations/before-intravesical-instillation"
        render={() => <Recommendation1 />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/recommendations/the-day-of-intravesical-instillation"
        render={() => <Recommendation2 />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/recommendations/after-intravesical-instillation"
        render={() => <Recommendation3 />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/education"
        render={() => <PatientEducation />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/education/what-is-an-intravesical-instillation"
        render={() => <Education1 />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/education/who-covers-this-procedure"
        render={() => <Education2 />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/education/documentation-for-the-procedure"
        render={() => <Education3 />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/privacyPolicy"
        render={() => <PatientPrivacyPolicy />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/termsAndConditions"
        render={() => <PatientTermsAndConditions />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/500"
        render={() => <Patient500 />}
      />
      <PatientProtectedRoute
        exact
        path="/patient/404"
        render={() => <Patient404 />}
      />
      <PatientProtectedRoute path="/patient/apps">
        <Redirect to="/" />
      </PatientProtectedRoute>

      <PatientProtectedRoute
        exact
        path="/patient/patient-details"
        render={() => <PatientDetailsFP/>}
      />
      
      <PatientProtectedRoute path="*">
        <Redirect to="/patient/404" />
      </PatientProtectedRoute>

      

      <NotificationContainer />
    </Switch>
  );
}

export default PatientApp;
