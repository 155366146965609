import React from 'react'
import { useForm } from 'react-hook-form'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Form } from '../../styled-components/register/styled'
import TextOutlinedInput from '../../../patientComponents/component/form/outlined-inputs/text'
import styles from '../../../../styles/patientStyles/components/ModalButtons.module.css'

const AddDispenser = (props) => {
  const { open, onClose, onAddDispenser } = props

  const methods = useForm()
  const { register, handleSubmit, formState, reset } = methods
  const { errors } = formState

  const registerOptions = {
    name: { required: 'Campo obligatorio' },
  }

  const onSubmit = handleSubmit((data) => {
    const dispenser = {
      name: data.name,
    }
    onAddDispenser(dispenser, () => reset({ name: '' }))
  })

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {'Adicionar otro dispensador de tratamiento'}
      </DialogTitle>
      <DialogContent>
        <Form>
          <TextOutlinedInput
            name="name"
            label="Nombre"
            placeholder="Ingrese aquí su dispensador"
            type={'text'}
            register={register}
            registerOptions={registerOptions}
            required
            errors={errors}
          />
        </Form>
        <div className={styles.buttonBox}>
          <button className={styles.confirmButtonA} onClick={onClose}>
            <p className={styles.buttonContentA}>Cerrar</p>
          </button>
          <button className={styles.confirmButtonB} onClick={onSubmit}>
            <p className={styles.buttonContentB}>Agregar</p>
          </button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default AddDispenser
