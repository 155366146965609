import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form'
import styles from '../../../styles/patientStyles/Post-register.module.css'
import {
  Form,
  HeaderLogo,
  Logo,
  Subtitle,
  Text,
  Title,
  MarginSeparator,
  InnerContainer,
} from '../../../components/patientComponents/styled-components/register/styled'
import CheckboxOutlinedInput from '../../../components/patientComponents/component/form/outlined-inputs/checkbox'
import TextOutlinedInput from '../../../components/patientComponents/component/form/outlined-inputs/text'
import RadioOutlinedInput from '../../../components/patientComponents/component/form/outlined-inputs/radio'
import Autocomplete from '../../../components/patientComponents/component/form/outlined-inputs/autocomplete'
import AddDoctor from '../../../components/patientComponents/component/post-register/addDoctor'
import {
  AffiliationTypeList,
  HealthProgramTypeList,
  PlaceAttentionList,
  RegimeList,
  HealthProgramTypeEnum,
  PlaceAttentionEnum,
} from '../../../constants/patienteConstants/enums'
import {
  callApi,
  getPreRegisterSelects,
  getCyties,
  getHealthProgramByType,
  getDoctors,
  postAddDoctor,
  postAddPatient,
} from '../../../services/patientServices/apiList'
import LaddaBtn from '../../../components/components/LaddaBtn';

const isHealthProgramSelected = (healthProgramSelectedItems, type) => {
  if (!healthProgramSelectedItems) return false
  return healthProgramSelectedItems.find((x) => x === type) != undefined
}

const addWhichPlaceAttention = (place) => {
  return (
    place == PlaceAttentionEnum.ips ||
    place == PlaceAttentionEnum.urologicalClinics
  )
}

const PatientPostRegister = () => {
  let history = useHistory();
  const [stateProvinces, setStateProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [doctors, setDoctors] = useState([])
  const [pathologies, setPathologies] = useState([])
  const [entities, setEntities] = useState([])

  const [epsList, setEpsList] = useState([])
  const [supplementalPlanList, setSupplementalPlanList] = useState([])
  const [prepaidMedicinePlanList, setPrepaidMedicinePlanList] = useState([])
  const [healthPolicyList, setHealthPolicyList] = useState([])

  const [dlgAddDoctor, setDlgAddDoctor] = useState(false)

  const defaultValues = {}
  const methods = useForm({ defaultValues })
  const { register, setValue, handleSubmit, formState, watch, getValues } = methods
  const { errors } = formState

  const registerOptions = {
    department: { required: 'Debes seleccionar un departamento' },
    city: { required: 'Debes seleccionar una ciudad' },
    mobileNumber: { required: 'Debe ingresar su número de celular' },
    regime: { required: 'Por favor seleccione un régimen' },
    membership: { required: 'Debe seleccionar un tipo de afiliación' },
    healthProgram: { required: 'Debe seleccionar por lo menos un programa' },
    entity: { required: 'Debe seleccionar una entidad' },
    doctor: { required: 'Debe seleccionar un médico' },
    siteOfAttention: { required: 'Debe seleccionar un lugar' },
    pathology: { required: 'Debe seleccionar una patología' },
    whichPlaceAttention: { required: 'Campo obligatorio' },
  }

  const departmentValue = watch('department')
  const healthProgramValue = watch('healthProgram')
  const doctorValue = watch('doctor')
  const siteOfAttentionValue = watch('siteOfAttention')
  const isPrivatePurchaseValue = watch('isPrivatePurchase')

  const loadHealthProgramType = (selectedTypes) => {
    const callLoad = (type, setData) => {
      callApi(() => getHealthProgramByType(type), setData)
    }

    if (isHealthProgramSelected(selectedTypes, HealthProgramTypeEnum.eps)) {
      if (epsList.length === 0) callLoad(HealthProgramTypeEnum.eps, setEpsList)
    } else {
      setValue('eps', '')
    }

    if (
      isHealthProgramSelected(
        selectedTypes,
        HealthProgramTypeEnum.supplementalPlan
      )
    ) {
      if (supplementalPlanList.length === 0)
        callLoad(
          HealthProgramTypeEnum.supplementalPlan,
          setSupplementalPlanList
        )
    } else {
      setValue('supplementalPlan', '')
    }

    if (
      isHealthProgramSelected(
        selectedTypes,
        HealthProgramTypeEnum.prepaidMedicine
      )
    ) {
      if (prepaidMedicinePlanList.length === 0)
        callLoad(
          HealthProgramTypeEnum.prepaidMedicine,
          setPrepaidMedicinePlanList
        )
    } else {
      setValue('prepaidMedicine', '')
    }

    if (
      isHealthProgramSelected(selectedTypes, HealthProgramTypeEnum.healthPolicy)
    ) {
      if (healthPolicyList.length === 0)
        callLoad(HealthProgramTypeEnum.healthPolicy, setHealthPolicyList)
    } else {
      setValue('healthPolicy', '')
    }
  }

  useEffect(() => {
    callApi(getPreRegisterSelects, (data) => {
      setStateProvinces(data.stateProvinces)
      setDoctors([...data.doctors, { id: -1, name: 'Adicionar otro' }])
      setPathologies(data.pathologies)
      setEntities(data.entities)
    })
  }, [])

  useEffect(() => {
    if (!departmentValue) {
      setCities([])
      return
    }
    callApi(() => getCyties(departmentValue), setCities)
  }, [departmentValue])
  useEffect(() => {
    if (healthProgramValue) {
      loadHealthProgramType(healthProgramValue)
      return
    }
  }, [healthProgramValue])
  useEffect(() => {
    if (doctorValue === -1) setDlgAddDoctor(true)
  }, [doctorValue])
  useEffect(() => {
    if (!addWhichPlaceAttention(siteOfAttentionValue))
      setValue('whichPlaceAttention', null)
  }, [siteOfAttentionValue])

  const onAdddDoctor = (doctor, reset) => {
    callApi(
      () => postAddDoctor(doctor),
      () => {
        callApi(getDoctors, (data) => {
          const maxId = Math.max(...data.map((x) => x.id))
          setValue('doctor', maxId)
          setDoctors([...data, { id: -1, name: 'Adicionar otro' }])
          setDlgAddDoctor(false)
          reset()
        })
      }
    )
  }

  const onSubmit = handleSubmit((data) => {
    const patient = {
      cityId: data.city,
      address: data.direction,
      phone: data.phoneNumber,
      cellPhone: data.mobileNumber,
      regime: Number(data.regime),
      affiliationType: data.membership,
      entityId: data.entity,
      doctorId: data.doctor,
      placeAttention: data.siteOfAttention,
      companionName: data.accompanistName,
      companionPhone: data.accompanistNumber,
      pathologyId: data.pathology,
      whichPlaceAttention: data.whichPlaceAttention,
      epsId: !data.eps ? null : data.eps,
      supplementalPlanId: !data.supplementalPlan ? null : data.supplementalPlan,
      prepaidMedicineId: !data.prepaidMedicine ? null : data.prepaidMedicine,
      healthPolicyId: !data.healthPolicy ? null : data.healthPolicy,
      isPrivatePurchase: data.isPrivatePurchase === "true" ? true : false,
    }
    
    callApi(
      () => postAddPatient(patient),
      
      () => {
        history.push('/patient/instillations')
      }
    )
  })

  return (
    <>
      <HeaderLogo>
        <InnerContainer>
          <Logo src="/patient/logo-white.svg" alt="ialuriApp Logo" />
          <Title>Perfil de usuario</Title>
          <Text>
            Ingrese los datos requeridos a continuación para completar su perfil
            en el aplicativo
          </Text>
        </InnerContainer>
      </HeaderLogo>

      <Form onSubmit={onSubmit}>
        <InnerContainer>
          <Subtitle>Información de contacto</Subtitle>
          <>
            <MarginSeparator margin="10px 0 0" />
            <Autocomplete
              name="department"
              label="Seleccione su departamento"
              register={register}
              registerOptions={registerOptions}
              placeholder="Seleccione su departamento"
              options={stateProvinces}
              required
              watch={watch}
              setValue={setValue}
              errors={errors}
            />
            <Autocomplete
              register={register}
              registerOptions={registerOptions}
              name="city"
              label="Seleccione su ciudad"
              placeholder="Seleccione su ciudad"
              options={cities}
              required
              watch={watch}
              setValue={setValue}
              errors={errors}
            />
            <TextOutlinedInput
              name="direction"
              label="Ingrese su dirección"
              type={'text'}
              register={register}
            />
            <TextOutlinedInput
              name="phoneNumber"
              label="Ingrese su teléfono fijo"
              type={'text'}
              register={register}
            />
            <TextOutlinedInput
              name="mobileNumber"
              label="Ingrese su teléfono celular"
              type={'text'}
              required
              register={register}
              registerOptions={registerOptions}
              errors={errors}
            />
          </>

          <MarginSeparator margin="25px 0 0" />
          <Subtitle>Datos en salud</Subtitle>
          <>
            <MarginSeparator margin="10px 0 0" />
            <RadioOutlinedInput
              name="regime"
              label="Por favor seleccione su régimen"
              row
              options={RegimeList()}
              required
              register={register}
              registerOptions={registerOptions}
              errors={errors}
            />
            <MarginSeparator margin="15px 0 0" />
            <Autocomplete
              name="membership"
              label="Seleccione su tipo de afiliación"
              placeholder="Seleccione su tipo de afiliación"
              register={register}
              registerOptions={registerOptions}
              options={AffiliationTypeList()}
              required
              watch={watch}
              setValue={setValue}
              errors={errors}
            />

            <Autocomplete
              register={register}
              registerOptions={registerOptions}
              name="healthProgram"
              label="Seleccione su programa en salud"
              placeholder="Seleccione su programa en salud"
              multiple
              options={HealthProgramTypeList()}
              required
              watch={watch}
              setValue={setValue}
              errors={errors}
            />

            {isHealthProgramSelected(
              healthProgramValue,
              HealthProgramTypeEnum.eps
            ) && (
              <Autocomplete
                register={register}
                registerOptions={registerOptions}
                name="eps"
                label="EPS"
                placeholder="Seleccione su EPS"
                options={epsList}
                required
                watch={watch}
                setValue={setValue}
                errors={errors}
              />
            )}
            {isHealthProgramSelected(
              healthProgramValue,
              HealthProgramTypeEnum.supplementalPlan
            ) && (
              <Autocomplete
                register={register}
                registerOptions={registerOptions}
                name="supplementalPlan"
                label="Plan complementario"
                placeholder="Seleccione su plan complementario"
                options={supplementalPlanList}
                required
                watch={watch}
                setValue={setValue}
                errors={errors}
              />
            )}
            {isHealthProgramSelected(
              healthProgramValue,
              HealthProgramTypeEnum.prepaidMedicine
            ) && (
              <Autocomplete
                register={register}
                registerOptions={registerOptions}
                name="prepaidMedicine"
                label="Medicina prepagada"
                placeholder="Seleccione una opción"
                options={prepaidMedicinePlanList}
                required
                watch={watch}
                setValue={setValue}
                errors={errors}
              />
            )}
            {isHealthProgramSelected(
              healthProgramValue,
              HealthProgramTypeEnum.healthPolicy
            ) && (
              <Autocomplete
                register={register}
                registerOptions={registerOptions}
                name="healthPolicy"
                label="Póliza en salud"
                placeholder="Seleccione una opción"
                options={healthPolicyList}
                required
                watch={watch}
                setValue={setValue}
                errors={errors}
              />
            )}

            <Autocomplete
              register={register}
              registerOptions={registerOptions}
              name="entity"
              label="Seleccione la entidad donde fue atendido"
              placeholder="Seleccione la entidad donde fue atendido"
              options={entities}
              required
              watch={watch}
              setValue={setValue}
              errors={errors}
            />

            <Autocomplete
              register={register}
              registerOptions={registerOptions}
              name="doctor"
              label="Seleccione el médico que le formuló"
              placeholder="Seleccione el médico que le formuló *"
              options={doctors}
              required
              watch={watch}
              setValue={setValue}
              errors={errors}
            />

            <Autocomplete
              register={register}
              registerOptions={registerOptions}
              name="siteOfAttention"
              label="Seleccione dónde le atendió"
              placeholder="Seleccione dónde le atendió *"
              options={PlaceAttentionList()}
              required
              watch={watch}
              setValue={setValue}
              errors={errors}
            />

            {addWhichPlaceAttention(siteOfAttentionValue) && (
              <TextOutlinedInput
                name="whichPlaceAttention"
                label="¿Cuál?"
                type={'text'}
                register={register}
                registerOptions={registerOptions}
                required
                errors={errors}
              />
            )}
            <MarginSeparator margin="10px 0 0" />
            <MarginSeparator margin="-15px 0 0" />
            <TextOutlinedInput
              name="accompanistName"
              label="Ingrese el nombre del acompañante"
              type={'text'}
              register={register}
            />
            <TextOutlinedInput
              name="accompanistNumber"
              label="Ingrese el teléfono del acompañante"
              type={'text'}
              register={register}
            />
            <MarginSeparator margin="10px 0 0" />
            <Autocomplete
              register={register}
              registerOptions={registerOptions}
              name="pathology"
              label="Seleccione su patología"
              placeholder="Seleccione su patología *"
              options={pathologies}
              required
              watch={watch}
              setValue={setValue}
              errors={errors}
            />
            <CheckboxOutlinedInput
              id="isPrivatePurchase"
              name="isPrivatePurchase"
              register={register}
              registerOptions={registerOptions}
              errors={errors}
              value={isPrivatePurchaseValue == true ? true : false}
              onChange={(e) => setValue('isPrivatePurchase', e.target.checked)}
            >
              ¿Es compra particular?
            </CheckboxOutlinedInput>
          </>

          <p className={styles.asterisk}>(*) Campos obligatorios</p>
          
          <LaddaBtn onClick={onSubmit}>Completar perfil</LaddaBtn>
        </InnerContainer>
      </Form>
      <AddDoctor
        open={dlgAddDoctor}
        onClose={() => setDlgAddDoctor(false)}
        onAddDoctor={onAdddDoctor}
      />
    </>
  )
}

export default PatientPostRegister
