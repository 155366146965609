import React, { useState } from "react";
import { CssFormControlField, useStyles } from "../../../../styles/DoctorStyles/ui/form";

import {
  OutlinedInput,
  InputLabel,
  InputAdornment,
  IconButton,
  FormHelperText
} from "@material-ui/core";

const PasswordOutlinedInput = ({
  name,
  labelwith,
  label,
  type = "text",
  required,
  register,
  registerOptions,
  errors,
  ...others
}) => {
  const classes = useStyles();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const registerOption =
    registerOptions && registerOptions[name]
      ? registerOptions[name]
      : undefined;

  let isError = false;
  let errorMessage = "";
  if (errors && errors.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errors[name].message;
  }

  const handleClickShowPassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <CssFormControlField
        className={classes.root}
        size="small"
        variant="outlined"
      >
        <InputLabel
          className={classes.margin}
          htmlFor="outlined-adornment-password"
        >
          {label}
        </InputLabel>
        <OutlinedInput
          {...register(name, registerOption)}
          id="outlined-adornment-password"
          className={classes.margin}
          type={passwordVisible ? "text" : "password"}
          defaultValue=""
          error={isError}
          {...others}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {passwordVisible ? (
                  <img src="/doctor/password-eye.svg" alt="Icon eye visible" />
                ) : (
                  <img
                    src="/doctor/password-eye-hide.svg"
                    alt="Icon eye visible off"
                  />
                )}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={/* 85 */ labelwith}
        />
   <FormHelperText id={`${name}-helper-text`}>
        {errorMessage && (
          <div className="div-horizontal-form">
            <img src="/doctor/error-icon.svg" className="error-icon" alert="Error icon" alt="icon" />
            {errorMessage}
          </div>
        )}
      </FormHelperText>
      </CssFormControlField>
    </div>
  );
};

export default PasswordOutlinedInput;
