export const pieOptions = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "",
  },
  // subtitle: {
  //   text: "Click para ver detalles",
  // },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f}%'
      },
      showInLegend: true
    }
  },
  series: [
    {
      name: "Brands",
      colorByPoint: true,
      data: [],
    },
  ],
  credits: {
    enabled: false
  }
};

export const areaOptions = {
  chart: {
    type: "area",
  },
  accessibility: {
    description:
      "",
  },
  title: { text: "" },
  subtitle: { text: "" },
  xAxis: {
    allowDecimals: false,
    labels: {
      formatter: function () {
        return this.value; // clean, unformatted number for year
      },
    },
    accessibility: {
      rangeDescription: "",
    },
  },
  yAxis: {
    title: {
      text: "Niveles Escalas Visual Analoga",
    },
    labels: {
      formatter: function () {
        return this.value;
      },
    },
  },
  tooltip: {
    pointFormat:
      "La Instilación número <b>{point.y:,.0f}</b><br/> tiene un nivel número {point.x}",
  },
  plotOptions: {
    area: {
      pointStart: 1,
      marker: {
        enabled: false,
        symbol: "circle",
        radius: 2,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
  },
  series: [
    {
      name: "INSTILACIONES",
      data: [7, 2, 5, 0, 5, 6, 7],
    },
  ],
  credits: {
    enabled: false
  }
};

export const pieResumeOptions = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "Top 3 patologías más frecuentes",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
          enabled: false
      },
      showInLegend: true
    }
  },
  series: [
    {
      name: "Brands",
      colorByPoint: true,
      data: [
        {
          name: "Patologia 1",
          y: 60.0,
          sliced: true,
          selected: true,
        },
        {
          name: "Patologia 2",
          y: 40.0,
        },
        {
          name: "Patologia 3",
          y: 10.0,
        },
      ],
    },
  ],
};
