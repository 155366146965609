import React from "react";
import {
  CssFormControlField,
  PurpleCheckbox,
  chekboxStyles,
} from "../../../../styles/DoctorStyles/ui/form";

import {
  FormLabel,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";

function StyledCheckBox(props) {
  return <PurpleCheckbox {...props} />;
}

const CheckboxOutlinedInput = ({ children, ...props }) => {
  const {
    id,
    name,
    label,
    value,
    required,
    register,
    registerOptions,
    errors,
    ...remaining
  } = props;
  const registerOption =
    registerOptions && registerOptions[name]
      ? registerOptions[name]
      : undefined;

  let isError = false;
  let errorMessage = "";
  if (errors && errors.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errors[name].message;
  }
  const classes = chekboxStyles();

  return (
    <CssFormControlField component="fieldset" fullWidth={true}>
      <FormGroup className={classes.root}>
        <FormControlLabel
          {...register(name, registerOption)}
          control={<StyledCheckBox />}
          key={id}
          id={id}
          name={name}
          value={value}
          error={isError ? 1 : 0}
          {...remaining}
        />
        <FormLabel>{children}</FormLabel>
      </FormGroup>
      <FormHelperText id={`${name}-helper-text`}>
        {errorMessage && (
          <div className="div-horizontal-form">
            <img
              src="/doctor/error-icon.svg"
              className="error-icon"
              alert="Error icon"
              alt="icon"
            />
            {errorMessage}
          </div>
        )}
      </FormHelperText>
    </CssFormControlField>
  );
};

export default CheckboxOutlinedInput;
