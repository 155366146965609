import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getUserInfo } from "../../services/doctorServices/authService";
import { RoleEnum } from "../../../src/constants/doctorConstants/enums";

const isBrowser = () => typeof window !== "undefined";

const isRoleUser = (role, userInfo) => {
  if (!userInfo) return false;
  return role.find((x) => userInfo.roles.find((y) => y === x));
};

const DoctorProtectedRoute = (rest) => {
  let unprotectedRoutes = [
    "/",
    "/doctor",
    "/doctor/login",
    "/doctor/recover-password",
    "/doctor/recover-password-new",
    "/doctor/recover-password-send",
    "/doctor/recover-password-success",
    "/doctor/register",
    "/doctor/register/success",
    "/doctor/splash",
    "/doctor/privacy-policy",
    "/doctor/terms-and-conditions",
    "/doctor/500",
    "/doctor/404",
    "/doctor/unauthorized",
    "*",
  ];

  let pathIsProtected = unprotectedRoutes.indexOf(rest.path) === -1;
  if (isBrowser() && getUserInfo() === null && pathIsProtected) {
    const roleStorage = localStorage.getItem("role");
    if(roleStorage == RoleEnum.institution){
      localStorage.setItem("urlback", "/doctor/dashboard");
      return <Redirect to="/doctor/login?logIns=true" />;
    }
    localStorage.setItem("urlback", rest.path);
    return <Redirect to="/doctor/login" />;
  }

  //return <Route {...rest} />;

  if (!rest.role || isRoleUser(rest.role, getUserInfo())) {
    return <Route {...rest} />;
  } else {
    return <Redirect to="/doctor/unauthorized" />;
  }
};

export default DoctorProtectedRoute;
