import React, { useEffect, useState } from "react";
import {
  HeaderLogo,
  Logo,
  Text,
  Title,
  InnerContainer,
} from "../components/patientComponents/styled-components/register/styled";
import { useLocation, useHistory } from "react-router-dom";

const SelectCountry = () => {
  const [appType, setAppType] = useState(null);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const app = searchParams.get("app");
    setAppType(app);
  }, [location.search]);

  const countryMap = [
    { name: "Colombia", url: "/flags/colombia-flag.jpg", code: "CO" },
    { name: "Costa Rica", url: "/flags/costa-rica-flag.jpg", code: "CR" },
  ];

  const handleCountryClick = (country) => {
    console.log("appType", appType);
    const pathname =
      appType === "patient" ? "/patient/register" : "/doctor/register";
    const redirectUrl = `${pathname}?country=${country.code}`;
    history.push(redirectUrl);
  };

  const CountrySelect = () => {
    return (
      <div className="SC-scroll-container">
        {countryMap.map((content, index) => (
          <div className="V-Center-Container">
            <h2 className="country-name">{content.name}</h2>
            <div
              key={index}
              className="SC-scroll-item"
              onClick={() => handleCountryClick(content)}
            >
              <img className="flag-item" src={content.url} alt={content.name} />
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <HeaderLogo>
        <InnerContainer>
          <Logo src="/patient/logo-white.svg" alt="ialuriApp Logo" />
          <Title>Registro de usuario</Title>
          <Text>Antes de iniciar el proceso de registro, elige tu país.</Text>
        </InnerContainer>
      </HeaderLogo>

      <InnerContainer className="H-Center-Container">
        <CountrySelect />
      </InnerContainer>
    </>
  );
};

export default SelectCountry;
