import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles/DoctorStyles/loader.css";
import IalurillApp from "./routes";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/es/integration/react";
import { persistStore } from "redux-persist";
import FirebaseListenNofitication from "../src/components/components/FirebaseListenNotification";

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <PersistGate persistor={persistor} loading={null}>
      <Provider store={store}>
        <BrowserRouter>
          <IalurillApp />
          <NotificationContainer />
          <FirebaseListenNofitication />
        </BrowserRouter>
      </Provider>
    </PersistGate>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
reportWebVitals();
