export const PublicLocation = () => {
  return fetch("https://ipapi.co/json/")
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      // return data.country_name;
      return 'Costa Rica'
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
};
