import React from "react";

import ArticleCard from "../../../components/doctorComponents/articleCard";

const Article4 = () => {
  return (
    <div>
      <ArticleCard
        article="ARTÍCULO 1"
        title="“ialuril ® Prefill: La nueva era en cistitis crónica y dolor pélvico asociado”"
        link="/doctor/module-4/ialuril-the-ideal-complement"
        module="module-4"
        label="ialuril-the-ideal-complement"
      />
       <ArticleCard
        article="ARTÍCULO 2"
        title="“Acerca de ialuril ® Prefill”"
        link="/doctor/module-4/about-ialuril-Prefill"
        module="module-4"
        label="about-ialuril-Prefill"
      />
    </div>
  );
};

export default Article4;
