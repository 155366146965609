import React from 'react'
import styles from '../../../styles/patientStyles/Education.module.css'
import HeaderWithMenu from '../../../components/patientComponents/component/headerWithMenu'
import IalurilBox from '../../../components/patientComponents/component/ialurilBox'
import IalurilTextBox from '../../../components/patientComponents/component/ialurilTextBox'
import Footer from '../../../components/patientComponents/component/footer'

const Recommendation = () => {
  return (
    <div>
      <HeaderWithMenu />
      <IalurilTextBox
        title="Recomendaciones generales"
        content="Las presentes recomendaciones son de carácter informativo, para el conocimiento de los pacientes. En caso de tener recomendaciones especiales por parte de su médico tratante debe seguirlas. "
      />

      <div className={`${styles.boxContainer}`}>
        <div className={styles.innerContainer}>
          <IalurilBox
            img="/patient/recommendations-1.png"
            title="Antes de la instilación intravesical"
            href="/patient/recommendations/before-intravesical-instillation"
          />
          <IalurilBox
            img="/patient/recommendations-2.png"
            title="El día de la instilación intravesical"
            href="/patient/recommendations/the-day-of-intravesical-instillation"
          />
          <IalurilBox
            img="/patient/recommendations-3.png"
            title="Después de la instilación intravesical"
            href="/patient/recommendations/after-intravesical-instillation"
          />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Recommendation
