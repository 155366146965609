import React from 'react'
import CustomTable from "../../doctorComponents/customTable"
import {TableHeadStyle, TableBodyStyle} from "../../../constants/doctorConstants/enums"
import { getQuestionTitle } from "../../../constants/doctorConstants/index"

const Tab3 = ({patientInfo, questionNumber}) => {
  const getColumns = (questionNumber) => {
    let columns = [
      "Instilaciones",
      "Pregunta " + questionNumber
    ];
  
    if(questionNumber === 1)
      columns.push("Pregunta 1.1")

    return columns;
  } 

  const getData = (questionNumber) => {
    if(!patientInfo) return [[]];
    
    var item = patientInfo.currentCycleQuestions.find(x=>x.questionNumber === questionNumber);
    var data = item.answers.map(x=>{
      const obj = Object.values(x)
      if(questionNumber === 1)
        return obj.slice(0,3)
      else if(questionNumber !== 7 && questionNumber !== 8)
        return obj.slice(0,2)
      return obj
    });
    return data;
  }

  return (
    <>
      <p className="title-2">{getQuestionTitle(questionNumber)}</p>
      { questionNumber === 1 &&
        <p className="title-2">{getQuestionTitle(11)}</p>
      }
      <CustomTable
        key={"tab-3" + questionNumber}
        bodystyle={TableHeadStyle.white} 
        headstyle={TableBodyStyle.white} 
        columns={getColumns(questionNumber)} 
        rows={getData(questionNumber)}/>    
    </>
  )
}

export default Tab3
