import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    patientInfo: {}
  }

const patientInfoSlice = createSlice({
  name: "institution",
  initialState,
  reducers: {
    setPatientInfo: (state, action) => {
      state.patientInfo = { ...action.payload}
    },
  },
});

export const {setPatientInfo} = patientInfoSlice.actions
export const selectPatientInfo = (state) => state.patientInfoState.patientInfo;
export default patientInfoSlice.reducer;
