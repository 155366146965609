import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { signOut } from "../../../services/doctorServices/authService";
import { RoleEnum } from "../../../constants/doctorConstants/enums";
import {
  getCountry,
  isRole
} from "../../../services/doctorServices/userService";
import { topMenuGTM } from "../../../googleTagManager/doctor";
import { useDispatch } from "react-redux";
import { setPatientInfo } from "../../../redux/patientInfoSlice.js";
import Menu from "@material-ui/core/Menu";
import AlertComponent from "./alertComponent";
import AlertItem from "./alertItem";
import CountryIndicator from "../../components/countryIndicator/index.js";

const HeaderWithMenu = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const isRepresentative = isRole(RoleEnum.representative);
  const isInstitution = isRole(RoleEnum.institution);
  const [notificationList, setNotificationList] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const country = getCountry();

  const handleClickAlert = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAlert = () => {
    setAnchorEl(null);
  };
  const handleButtonMenu = () => {
    setIsOpen(!isOpen);
  };

  const close = () => {
    dispatch(setPatientInfo(null));
    signOut();
    history.push("/doctor");
  };

  if (isInstitution) {
    return (
      <div className="header-wm-container">
        <div
          style={{
            background: `url("${props.img}") center no-repeat, linear-gradient(to bottom left, #CF63CF, #9DE5FF)`,
            backgroundSize: "cover",
            boxShadow: "0px 2px 4px 0px #343a4066",
            position: "relative",
            zIndex: "5"
          }}
        >
          <div
            className="header-wm-container2"
            style={{
              margin: "0 auto"
            }}
          >
            <img
              className="header-wm-logo"
              src="/doctor/logo-white-menu.svg"
              alt="Logo Ialuril texto blanco con icono a color"
            />
            <CountryIndicator />
            <div className="alert-component">
              <AlertComponent
                showMenu={handleClickAlert}
                setNotificationList={setNotificationList}
                className="alert-class"
              />
              <>
                <Menu
                  style={
                    notificationList.length < 1 || notificationList == null
                      ? { paddingRight: "0px !important", top: "10px" }
                      : { paddingRight: "0px !important" }
                  }
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseAlert}
                >
                  <>
                    {notificationList.length > 0 ? (
                      notificationList.map((item, index) => {
                        return (
                          <AlertItem
                            style={{ marginBottom: "6px", marginTop: "0px" }}
                            key={index}
                            item={item}
                            fullName={item.fullName}
                            currentCycle={item.currentCycle}
                            currentInstillation={item.currentInstillation}
                            daysLate={item.daysLate}
                          />
                        );
                      })
                    ) : (
                      <div
                        className="file"
                        style={{
                          marginBottom: "6px",
                          marginTop: "0px",
                          width: "190px"
                        }}
                      >
                        <div
                          style={{ marginBottom: "-9px", marginTop: "-17px" }}
                        >
                          <div className="file-segment">
                            <div className="file-info">
                              <h1
                                className="file-title"
                                style={{ marginBottom: "-10px" }}
                              >
                                No hay instilaciones pendientes
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                </Menu>
              </>
              <div
                onClick={handleButtonMenu}
                className={
                  isOpen ? "menu-open hwm-button-menu" : "hwm-button-menu"
                }
              >
                <span className="hwm-iconLine first" />
                <span className="hwm-iconLine last" />
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            isOpen ? "menu-open hwm-menu-container" : "hwm-menu-container"
          }
        >
          <ul className="hwm-menu">
            <li className="hwm-menu-item">
              <Link to="/doctor/dashboard" onClick={handleButtonMenu}>
                Home
              </Link>
            </li>

            <li className="hwm-menu-item">
              <Link to="/doctor/patient-list" onClick={handleButtonMenu}>
                Listado de pacientes
              </Link>
            </li>

            {country === "CO" ? (
              <li className="hwm-menu-item">
                <Link to="/doctor/eps-list" onClick={handleButtonMenu}>
                  {country === "CO" ? "Listado de EPS" : "Listado de CCSS"}
                </Link>
              </li>
            ) : (
              <></>
            )}

            <li className="hwm-menu-item">
              <Link
                to="/doctor/module-1"
                onClick={() => {
                  handleButtonMenu();
                  topMenuGTM("/doctor/module-1", "module-1");
                }}
              >
                Módulo 1
              </Link>
            </li>
            <li className="hwm-menu-item">
              <Link
                to="/doctor/module-2"
                onClick={() => {
                  handleButtonMenu();
                  topMenuGTM("/doctor/module-2", "module-2");
                }}
              >
                Módulo 2
              </Link>
            </li>
            <li className="hwm-menu-item">
              <Link
                to="/doctor/module-3"
                onClick={() => {
                  handleButtonMenu();
                  topMenuGTM("/doctor/module-3", "module-3");
                }}
              >
                Módulo 3
              </Link>
            </li>
            <li className="hwm-menu-item">
              <Link
                to="/doctor/module-4"
                onClick={() => {
                  handleButtonMenu();
                  topMenuGTM("/doctor/module-4", "module-4");
                }}
              >
                Módulo 4
              </Link>
            </li>
            <li className="hwm-menu-item">
              <Link
                to="/doctor/module-5"
                onClick={() => {
                  handleButtonMenu();
                  topMenuGTM("/doctor/module-5", "module-5");
                }}
              >
                Módulo 5
              </Link>
            </li>
            <li className="hwm-menu-item">
              <Link
                to="/doctor/module-6"
                onClick={() => {
                  handleButtonMenu();
                  topMenuGTM("/doctor/module-6", "module-6");
                }}
              >
                Módulo 6
              </Link>
            </li>
            <li className="hwm-menu-item">
              <Link
                to="/doctor/videos"
                onClick={() => {
                  handleButtonMenu();
                  topMenuGTM("/doctor/videos", "videos");
                }}
              >
                Videos
              </Link>
            </li>
            <li className="hwm-menu-item">
              <Link
                to="/doctor/videos-ialuadapter"
                onClick={() => {
                  handleButtonMenu();
                  topMenuGTM(
                    "/doctor/videos-ialuadapter",
                    "videos-ialuadapter"
                  );
                }}
              >
                Videos Ialuadapter
              </Link>
            </li>
            <li className="hwm-menu-item">
              <Link
                to="/doctor/patient-education"
                onClick={() => {
                  handleButtonMenu();
                  topMenuGTM("/doctor/patient-education", "patient-education");
                }}
              >
                Información relevante
              </Link>
            </li>
            <li className="hwm-menu-item">
              <Link
                to="/doctor/patient-recommendations"
                onClick={() => {
                  handleButtonMenu();
                  topMenuGTM(
                    "/doctor/patient-recommendations",
                    "patient-recommendations"
                  );
                }}
              >
                Recomendaciones generales
              </Link>
            </li>

            <hr className="menu-separator" />

            <li className="hwm-menu-item">
              <Link to="/doctor/privacy-policy" onClick={handleButtonMenu}>
                Pol&iacute;tica de privacidad
              </Link>
            </li>
            <li className="hwm-menu-item">
              <Link
                to="/doctor/terms-and-conditions"
                onClick={handleButtonMenu}
              >
                T&eacute;rminos y condiciones
              </Link>
            </li>
            {isInstitution && (
              <li className="hwm-menu-item">
                <Link to="/doctor/report" onClick={handleButtonMenu}>
                  Generar reporte
                </Link>
              </li>
            )}

            <li className="hwm-menu-item">
              <Link onClick={close}>Cerrar sesi&oacute;n</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div className="header-wm-container">
      <div
        style={{
          background: `url("${props.img}") center no-repeat, linear-gradient(to bottom left, #CF63CF, #9DE5FF)`,
          backgroundSize: "cover",
          boxShadow: "0px 2px 4px 0px #343a4066",
          position: "relative",
          zIndex: "5"
        }}
      >
        <div
          className="header-wm-container2"
          style={{
            margin: "0 auto"
          }}
        >
          <img
            className="header-wm-logo"
            src="/doctor/logo-white-menu.svg"
            alt="Logo Ialuril texto blanco con icono a color"
          />
          <div
            onClick={handleButtonMenu}
            className={isOpen ? "menu-open hwm-button-menu" : "hwm-button-menu"}
          >
            <span className="hwm-iconLine first" />
            <span className="hwm-iconLine last" />
          </div>
        </div>
      </div>
      <div
        className={
          isOpen ? "menu-open hwm-menu-container" : "hwm-menu-container"
        }
      >
        <ul className="hwm-menu" style={{ overflowY: "auto" }}>
          <li className="hwm-menu-item">
            <Link
              to="/doctor/dashboard"
              onClick={() => {
                handleButtonMenu();
                topMenuGTM("/doctor/dashboard", "doctor-dashboard");
              }}
            >
              Home
            </Link>
          </li>
          <li className="hwm-menu-item">
            <Link
              to="/doctor/module-1"
              onClick={() => {
                handleButtonMenu();
                topMenuGTM("/doctor/module-1", "module-1");
              }}
            >
              Módulo 1
            </Link>
          </li>
          <li className="hwm-menu-item">
            <Link
              to="/doctor/module-2"
              onClick={() => {
                handleButtonMenu();
                topMenuGTM("/doctor/module-2", "module-2");
              }}
            >
              Módulo 2
            </Link>
          </li>
          <li className="hwm-menu-item">
            <Link
              to="/doctor/module-3"
              onClick={() => {
                handleButtonMenu();
                topMenuGTM("/doctor/module-3", "module-3");
              }}
            >
              Módulo 3
            </Link>
          </li>
          <li className="hwm-menu-item">
            <Link
              to="/doctor/module-4"
              onClick={() => {
                handleButtonMenu();
                topMenuGTM("/doctor/module-4", "module-4");
              }}
            >
              Módulo 4
            </Link>
          </li>
          <li className="hwm-menu-item">
            <Link
              to="/doctor/module-5"
              onClick={() => {
                handleButtonMenu();
                topMenuGTM("/doctor/module-5", "module-5");
              }}
            >
              Módulo 5
            </Link>
          </li>
          <li className="hwm-menu-item">
            <Link
              to="/doctor/module-6"
              onClick={() => {
                handleButtonMenu();
                topMenuGTM("/doctor/module-6", "module-6");
              }}
            >
              Módulo 6
            </Link>
          </li>
          <li className="hwm-menu-item">
            <Link
              to="/doctor/videos"
              onClick={() => {
                handleButtonMenu();
                topMenuGTM("/doctor/videos", "videos");
              }}
            >
              Videos
            </Link>
          </li>
          {isRepresentative && (
            <li className="hwm-menu-item">
              <Link
                to="/doctor/doctor-list"
                onClick={() => {
                  handleButtonMenu();
                  topMenuGTM("/doctor/doctor-list", "doctor-list");
                }}
              >
                Listado de médicos
              </Link>
            </li>
          )}
          {!isRepresentative && (
            <li className="hwm-menu-item">
              <Link
                to="/doctor/patient-list"
                onClick={() => {
                  handleButtonMenu();
                  topMenuGTM("/doctor/patient-list", "patient-list");
                }}
              >
                Listado de pacientes
              </Link>
            </li>
          )}
          <li className="hwm-menu-item">
            <Link
              to="/doctor/videos-ialuadapter"
              onClick={() => {
                handleButtonMenu();
                topMenuGTM("/doctor/videos-ialuadapter", "videos-ialuadapter");
              }}
            >
              Videos Ialuadapter
            </Link>
          </li>
          <li className="hwm-menu-item">
            <Link
              to="/doctor/patient-education"
              onClick={() => {
                handleButtonMenu();
                topMenuGTM("/doctor/patient-education", "patient-education");
              }}
            >
              Información relevante
            </Link>
          </li>
          <li className="hwm-menu-item">
            <Link
              to="/doctor/patient-recommendations"
              onClick={() => {
                handleButtonMenu();
                topMenuGTM(
                  "/doctor/patient-recommendations",
                  "patient-recommendations"
                );
              }}
            >
              Recomendaciones generales
            </Link>
          </li>

          <hr className="menu-separator" />

          <li className="hwm-menu-item">
            <Link
              to="/doctor/profile"
              onClick={() => {
                handleButtonMenu();
                topMenuGTM("/doctor/profile", "profile");
              }}
            >
              Perfil
            </Link>
          </li>
          <li className="hwm-menu-item">
            <Link
              to="/doctor/privacy-policy"
              onClick={() => {
                handleButtonMenu();
                topMenuGTM("/doctor/privacy-policy", "privacy-policy");
              }}
            >
              Pol&iacute;tica de privacidad
            </Link>
          </li>
          <li className="hwm-menu-item">
            <Link
              to="/doctor/terms-and-conditions"
              onClick={() => {
                handleButtonMenu();
                topMenuGTM(
                  "/doctor/terms-and-conditions",
                  "terms-and-conditions"
                );
              }}
            >
              T&eacute;rminos y condiciones
            </Link>
          </li>
          <li className="hwm-menu-item">
            <Link onClick={close}>Cerrar sesi&oacute;n</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HeaderWithMenu;
