import React from 'react'
import ButtonAsync from '../../../patientComponents/component/ButtonAsync/index'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import styles from '../../../../styles/patientStyles/components/DialogConfirm.module.css'

const DialogConfirm = ({
  openDialog,
  handleRequestClose,
  title,
  text,
  img,
  onAcceptClick,
}) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={openDialog}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <p className={styles.title}>{title}</p>
      </DialogTitle>
      <DialogContent className="new-patient-dialog-content">
        <div className={styles.row}>
          <img src={img} className={styles.img} alt="warning" />
          <p className={styles.text}>{text}</p>
        </div>
      </DialogContent>
      <div className={styles.confirmButtonContainer}>
        <DialogActions>
          <button
            className={styles.confirmButtonA}
            onClick={handleRequestClose}
          >
            <p className={styles.buttonContentA}>Cancelar</p>
          </button>
          <ButtonAsync
            className={styles.confirmButtonB}
            onClick={onAcceptClick}
          >
            <p className={styles.buttonContent}>Aceptar</p>
          </ButtonAsync>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export const DialogConfirmAsync = ({
  openDialog,
  handleRequestClose,
  title,
  text,
  onAcceptClickAsync,
}) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={openDialog}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className="new-patient-dialog-content">
        <div className="row">{text}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          Cancelar
        </Button>
        <ButtonAsync onClick={onAcceptClickAsync} color="primary">
          Aceptar
        </ButtonAsync>
      </DialogActions>
    </Dialog>
  )
}

export default DialogConfirm
