import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { NotificationManager } from 'react-notifications'
import Button2 from "../../../components/doctorComponents/buttons/button-2";
import { callApi, postVerifyEmail } from '../../../services/doctorServices/apiService'

const RecoverPasswordSuccess = () => {
  let history = useHistory();

  const [showOk, setShowOk] = useState(false)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('code')
    if (!code) {
      history.push('/login')
      return
    }
    
    callApi(
      () => postVerifyEmail({ code }),
      (data) => {
        if (!data.result) {
          if (data.error) NotificationManager.warning(data.error)
          history.push('/doctor/login')
          return
        }
        setShowOk(true)
      }
    )
  }, [])

  return (
    <>
      { showOk && 
        <div
          style={{
            height: "100vh",
          }}
          className="container"
        >
          <div className="inner-container">
            <div className="div-center">
              <img
                className="ialurilLogoWhite3"
                src="/doctor/logo-white-menu.svg"
                alt="IalurilLogoWhite"
              />
              <img className="letter" src="/doctor/confirmed.svg" alt="letter" />

              <p style={{ textAlign: "center" }} className="white-font-1">
                Su correo electrónico ha sido verificado, ya puede acceder a su perfil en el aplicativo
              </p>

              <br />
              <br />

              <div className="div-center">
                <Button2 label="OK" onClick={()=>history.push('/doctor/login')}/>
                <br />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default RecoverPasswordSuccess;