import http from "./httpService";
import { NotificationManager } from "react-notifications";
const urlApi = process.env.REACT_APP_URL_PATIENT;

export const callActionApi = (apiFunc, setData, handleError, setLoader) => {
  const fetchData = async () => {
    try {
      await apiFunc()
        .then((response) => {
          if (response) {
            setData(response.data);
          }
        })
        .catch((err) => {
          var error = "";
          if (err.response) {
            if (err.response.data && err.response.status === 400) {
              handleError(err);
              return;
            } else if (err.response.data) {
              error = err.response.data.error;
              console.log("error-response: ", err.response.data.error);
            } else {
              error = err.response;
              console.log("error-response: ", err.response);
            }
            //console.log("err.response",err.response)
          } else if (err.request) {
            error = err.request;
            console.log("error-request: ", err.request);
          } else {
            error = err;
            console.log("error-error: ", err);
          }

          if (handleError) {
            console.log("handleError: -> ", error);
            handleError(error);
          }
        })
        .finally(() => {
          if (setLoader) setLoader(false);
        });
    } catch (e) {
      console.log("error: ", e.message);
    }
  };
  if (setLoader) setLoader(true);
  fetchData();
};

export const callActionApiAsync = async (
  apiFunc,
  setData,
  handleError,
  setLoader
) => {
  console.log("apiFunc", apiFunc);
  console.log("urlApi", urlApi);
  if (setLoader) setLoader(true);
  try {
    await apiFunc()
      .then(async (response) => {
        if (response) {
          if (setData.constructor.name === "AsyncFunction")
            await setData(response.data);
          else setData(response.data);
        }
      })
      .catch((err) => {
        var error = "";
        if (err.response) {
          if (err.response.data && err.response.status === 400) {
            handleError(err);
            return;
          }
          if (err.response.data) {
            error = err.response.data.error;
            console.log("error-response: ", err.response.data.error);
          } else {
            error = err.response;
            console.log("error-response: ", err.response);
          }
        } else if (err.request) {
          error = err.request;
          console.log("error-request: ", err.request);
        } else {
          error = err;
          console.log("error-error: ", err);
        }

        if (handleError) {
          console.log("handleError: -> ", error);
          handleError(error);
        }
      })
      .then(() => {
        if (setLoader) setLoader(false);
      });
  } catch (e) {
    console.log("error: ", e.message);
  }
};

export const callApi = (apiFunc, setData, setLoader) => {
  callActionApi(
    apiFunc,
    setData,
    (error) => {
      if (typeof error === "string" && error) {
        NotificationManager.error(error);
      } else if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        NotificationManager.warning(error.response.data);
      }
    }, //, (data) => dispatch(setLoader(data))
    (data) => {
      if (setLoader) setLoader(data);
    }
  );
};

export const callApiAsync = async (apiFunc, setData, setLoader) => {
  await callActionApiAsync(
    apiFunc,
    setData,
    (error) => {
      if (typeof error === "string") {
        NotificationManager.error(error);
      } else if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        NotificationManager.warning(error.response.data);
      }
    }, //, (data) => dispatch(setLoader(data))
    (data) => {
      if (setLoader) setLoader(data);
    }
  );
};

export const getToken = (data) => http.post(`${urlApi}/Token`, data);
export const postRelogin = (data) => http.post(`${urlApi}/Token/ReLogin`, data);

export const getStateProvinces = () =>
  http.get(`${urlApi}/StateProvince/`);
export const getCyties = (stateProvinceId) =>
  http.get(`${urlApi}/City/` + stateProvinceId);
export const getHealthProgramByType = (type) =>
  http.get(`${urlApi}/HealthProgram/` + type);
export const getDashboard = () => http.get(`${urlApi}/Dashboard`);

export const getPatient = () => http.get(`${urlApi}/Patient`);
export const getPreRegisterSelects = (countryId) =>
  http.get(`${urlApi}/Patient/GetPreRegisterSelects?countryId=` + countryId);
export const postAddPatient = (patient) =>
  http.post(`${urlApi}/Patient`, patient);
export const patientUpdateInformation = (infoUser) =>
  http.post(`${urlApi}/Patient/UpdateInformation`, infoUser);
export const patientUpdateContact = (contactInfo) =>
  http.post(`${urlApi}/Patient/UpdateContact`, contactInfo);
export const patientUpdateHealth = (healthInfo) =>
  http.post(`${urlApi}/Patient/UpdateHealth`, healthInfo);
export const getPatientList = () => http.get(`${urlApi}/Patient/GetAll`);

export const getDoctors = () => http.get(`${urlApi}/Doctor`);
export const postAddDoctor = (doctor) => http.post(`${urlApi}/Doctor`, doctor);
export const getDispensers = () => http.get(`${urlApi}/Dispenser`);
export const postAddDispenser = (dispenser) =>
  http.post(`${urlApi}/Dispenser`, dispenser);

export const getCycles = (patientId) =>
  http.get(`${urlApi}/Cycle/` + patientId);
export const postAddCycle = (cycle) => http.post(`${urlApi}/Cycle`, cycle);
export const patchCycleAddMaintenance = (cycle) =>
  http.patch(`${urlApi}/Cycle`, cycle);
export const updateProcessState = (state) =>
  http.patch(`${urlApi}/Cycle/UpdateProcessState`, state);
export const patchEndCycle = (cycleId) =>
  http.patch(`${urlApi}/Cycle/EndCycle?cycleId=${cycleId}`, {});
export const AdminEndCycle = (cycleId) =>
  http.patch(`${urlApi}/Cycle/AdminEndCycle?cycleId=${cycleId}`, {});

export const adminRestartCycle = (cycle) =>
  http.post(`${urlApi}/Cycle/AdminRestartCycle`, cycle);

export const adminEditCycle = (cycle) =>
  http.post(`${urlApi}/Cycle/AdminEditCycle`, cycle);

export const adminInitializeCycle = (cycle) =>
  http.post(`${urlApi}/Cycle/AdminInitializeCycle`, cycle);

export const getInstillationsByCycle = (cycleId) =>
  http.get(`${urlApi}/Instillation/` + cycleId);
export const instillationPlanning = (instillation) =>
  http.patch(`${urlApi}/Instillation`, instillation);

export const instillationEndSurvey = (instillationId, patientId) =>
  http.put(
    `${urlApi}/Instillation/EndSurvey?id=${instillationId}&&patientId=${patientId}`
  );

export const postEndSurveyByInstitution = (data) =>
  http.post(`${urlApi}/Instillation/EndSurveyByInstitution`, data);

export const getSurvey = (instillationId, patientId) =>
  http.get(
    `${urlApi}/Survey/GetSurvey?instillationId=${instillationId}&&patientId=${patientId}`
  );

export const patchSurveyUpdate = (survey) =>
  http.patch(`${urlApi}/Survey`, survey);

export const getSymptomsSurvey = (surveyId) =>
  http.get(`${urlApi}/Survey/GetSymptoms?surveyId=` + surveyId);
export const deleteSymptom = (symptomId) =>
  http.delete(`${urlApi}/Survey/` + symptomId);

export const postAddUser = (user) => http.post(`${urlApi}/User`, user);
export const postRestorePassword = (user) =>
  http.post(`${urlApi}/User/RestorePassword`, user);
export const postResetPassword = (user) =>
  http.post(`${urlApi}/User/ResetPassword`, user);
export const postVerifyEmail = (user) =>
  http.post(`${urlApi}/User/VerifyEmail`, user);
export const getCodeIsValid = (code) =>
  http.get(`${urlApi}/User/CodeIsValid?code=` + code);
export const postChangePassword = (user) =>
  http.post(`${urlApi}/User/ChangePassword`, user);

export const sendNotification = (data) =>
  http.post(`${urlApi}/User/SendNotification`, data);

export const saveTokenNotification = (data) =>
  http.post(`${urlApi}/NotificationToken/SaveTokenNotification`, data);

export const updateTokenNotification = (data) =>
  http.post(`${urlApi}/NotificationToken/UpdateTokenNotification`, data);

export const getCodeVerification = (code) =>
  http.get(`${urlApi}/User/CodeVerification?code=` + code);
export const setPassword = (user) =>
  http.post(`${urlApi}/User/SetPassword`, user);

export const getSurveyAnswers = (instillationId) =>
  http.get(`${urlApi}/Survey/GetSurveyDetail?instillationId=` + instillationId);
