import styled from 'styled-components'

export const Container = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #6c757d;
  padding: 20px 16px 16px;
`

export const InnerContainer = styled.div`
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
`

export const HeaderLogo = styled.div`
  padding: 43px 16px 13px;

  background-color: #b16db3;
`

export const HeaderLogoWhite = styled.div`
  padding: 90px 16px 13px;
`

export const Logo = styled.img`
  max-width: 150px;
  width: 100%;
  display: block;
  margin: 0 auto 39px;
`

export const Title = styled.h1`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 22px;
  color: #ffffff;
`

export const TitleColor = styled.h1`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #6c757d;
  text-align: center;
`

export const Text = styled.p`
  font-size: 16px;
  line-height: 20px;
  margin: 10px auto 46px;
`

export const LoginButton = styled.p`
  a {
    display: block;
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    margin: 0 auto 20px;
    padding: 10px 16px;
    border: 2px solid #b16db3;
    border-radius: 6px;
    background-color: #b16db3;
    box-sizing: border-box;
    box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
  }
`

export const RegisterButton = styled.p`
  a {
    display: block;
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #b16db3;
    text-align: center;
    text-decoration: none;
    margin: 0 auto;
    padding: 10px 16px;
    border: 2px solid #b16db3;
    border-radius: 6px;
    background-color: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
  }
`
