import React from "react";
import CautionCard from "../../../components/doctorComponents/articleCard/cautionCard";
import RecommendationCard1 from "../../../components/doctorComponents/articleCard/recommendationCard";

const Recommendation1 = () => {
  return (
    <>
      <div className="educationInfo-innerContainer1">
        <div className="educationInfo-imgContainer">
          <RecommendationCard1 />
        </div>
        <CautionCard text="Consulte a su médico tratante, si debe suspender la ingesta de algún medicamento" />
      </div>
    </>
  );
};

export default Recommendation1;
