import React from "react";
import ChartComponent from "../index";

const BasicLine = ({ options, questionTitle }) => {
  return (
    <>
      <p className="title-2">{questionTitle}</p>
      <br></br>
      <ChartComponent options={options} />
    </>
  );
};

export default BasicLine;
