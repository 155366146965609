import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import styles from "../../../../styles/patientStyles/components/DialogConfirm.module.css";
import { useForm } from "react-hook-form";
import { Form } from "../../../../components/patientComponents/styled-components/register/styled";
import TextOutlinedInput from "../../../patientComponents/component/form/outlined-inputs/text";

const QuestionLastInstillation = ({
  label,
  options,
  questionNumber,
  survey,
  isNextClick,
  onNextClick,
  setShowEndButton,
}) => {

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { register, getValues , handleSubmit, formState, trigger, watch, setValue } = methods;
  const { errors } = formState;
  
  const [selectedButton, setSelectedButton] = useState(null);

  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
      setShowEndButton(true)
  };

  const saveAsync = async () => {
    if(selectedButton == null){
      NotificationManager.warning("Para nosotros tu opinión es muy importante, por favor selecciona una opción");
      onNextClick(false);
      return;
    }
    const d = getValues()
    survey.questionLast=selectedButton==1?true:false;
    survey.questionLastComment=watch("questionLastComment");
    const obj = { ...survey }
    onNextClick(obj)
  }
  const loadData = () => {
  }

  useEffect(() => {
    if(selectedButton == null){
      return;
    }
    if (survey) setTimeout(loadData, 500)
  }, [survey])

  useEffect(async () => {
    if (isNextClick) await saveAsync()
  }, [isNextClick])


  return (
    <div className="container">
      <div className="inner-container">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <img
            style={{ width: "200px", height: "auto" }}
            className="img"
            src="/doctor/last-survey.png"
            alt="Img"
          />
        </div>
        <p className="text-justify">
          Valoramos enormemente la satisfacción de nuestros pacientes al
          finalizar su tratamiento con <b>Ialuril®Prefill</b>. Por ello, nos
          esforzamos en obtener su opinión sobre si han experimentado mejoras en
          su calidad de vida al finalizar el proceso de instilaciones.
        </p>

        <p className="text-center">
          <b>¿Consideras que tu calidad de vida ha mejorado?</b>
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          className={"div-horizontal"}
        >
          <div className={styles.buttonBox}>
            <button  onClick={() => handleButtonClick(1)} className={styles.buttonSmall}
             style={{ background: selectedButton === 1 ? "#a770af":"#724270"}}  >
              <p className={styles.buttonContent}>Si</p>
            </button>
            <button onClick={() => handleButtonClick(2)} className={styles.buttonSmall}
            style={{ background: selectedButton === 2 ? "#a770af":"#724270" }}  >
              <p className={styles.buttonContent}>No</p>
            </button>
          </div>
        </div>
        <p className="text-justify">
          Agradecemos sinceramente la participación de nuestros pacientes en
          esta importante evaluación. Su voz es fundamental para nosotros en
          Ialuril Institucional, ya que nos impulsa a brindar una atención de
          calidad y a asegurarnos de que cada paciente experimente una mejora
          significativa en su bienestar.
        </p>
        <p className="text-center">
          <b>Danos tus comentarios. ¡Los apreciamos!</b>
        </p>
          <TextOutlinedInput
            id="questionLastComment"
            name="questionLastComment"
            label="Comentarios"
            type={"text"}
            rows={5}
            multiline
            register={register}
            errors={errors}
          />
      </div>
    </div>
  );
};

export default QuestionLastInstillation;
