import React, { useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { usePasswordValidation } from "../../../hook/doctorHook/usePasswordValidation";
import TextOutlinedInput from "../../../components/doctorComponents/form/outlined-inputs/text";
import EmailOutlinedInput from "../../../components/doctorComponents/form/outlined-inputs/email";
import PasswordOutlinedInput from "../../../components/doctorComponents/form/outlined-inputs/password";
import ConfirmPasswordOutlinedInput from "../../../components/doctorComponents/form/outlined-inputs/confirmPassword";
import CheckboxOutlinedInput from "../../../components/doctorComponents/form/outlined-inputs/checkbox";
import AddSpecialty from "../../../components/doctorComponents/modals/add-specialty";
import Button1 from "../../../components/doctorComponents/buttons/button-1";
import Autocomplete from "../../../components/doctorComponents/form/outlined-inputs/autocomplete";
import {
  callApi,
  getStateProvinces,
  getCities,
  getSpecialties,
  getDocumentTypes,
  postRegister,
  postAddSpecialty
} from "../../../services/doctorServices/apiService";
import Contact from "../../../components/doctorComponents/modals/contact";
import LaddaBtn from "../../../components/components/LaddaBtn";
import Progress from "../../../components/patientComponents/component/progress";

const crDocumentType = [{ id: 5, name: "Cédula de Identidad" }];

const Register = () => {
  let history = useHistory();
  const location = useLocation();

  const [loader, setLoader] = useState(false);
  const [dlgAddSpecialty, setDlgSpecialty] = useState(false);
  const [dlgContac, setDlgContact] = useState(false);
  const [stateProvinces, setStateProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);

  const searchParams = new URLSearchParams(location.search);

  const countryCode = searchParams.get("country");
  const countryCodeId = searchParams.get("country") === "CO" ? 1 : 2;

  const handleClose = () => {
    setDlgContact(false);
  };

  const handleOpen = () => {
    setDlgContact(true);
  };

  useEffect(() => {
    callApi(
      () => getStateProvinces(countryCodeId),
      (data) => setStateProvinces(data)
    );
    callApi(
      () => getDocumentTypes(countryCode),
      (data) => setDocumentTypes(data)
    );
    callApi(getSpecialties, (data) =>
      setSpecialties([...data, { id: -1, name: "Agregar especialidad" }])
    );
  }, []);

  const [passwordValidation, setPasswordValidation] = useState({
    firstPassword: "",
    secondPassword: ""
  });

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { register, setValue, handleSubmit, formState, watch } = methods;
  const { errors } = formState;

  const [validLength, hasNumber, match, hasLetter] = usePasswordValidation({
    firstPassword: passwordValidation.firstPassword,
    secondPassword: passwordValidation.secondPassword
  });

  const password = useRef({});
  password.current = watch("password", "");
  const legalValue = watch("legal");

  const stateProvinceValue = watch("stateProvince");
  const specialtyValue = watch("specialty");

  useEffect(() => {
    if (specialtyValue === -1) setDlgSpecialty(true);
  }, [specialtyValue]);

  useEffect(() => {
    if (!stateProvinceValue) {
      setCities([]);
      return;
    }
    callApi(() => getCities(stateProvinceValue), setCities);
  }, [stateProvinceValue]);

  const registerOptions = {
    name: { required: "Debe ingresar al menos un nombre" },
    lastName: { required: "Debe ingresar al menos un apellido" },
    documentType: {
      required: "Por favor seleccione un tipo de identificación"
    },
    document: { required: "Debe ingresar su número de identificación" },

    medicalRecord: { required: "Debe ingresar su registro médico" },
    stateProvince: { required: "Debe seleccionar su departamento" },
    city: { required: "Debe seleccionar su ciudad" },
    specialty: { required: "Debe seleccionar una especialidad" },
    email: {
      required: "Debe ingresar un correo electrónico",
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: "Este correo electrónico no es válido"
      }
    },
    password: {
      required: "Ingrese su contraseña",
      validate: {
        validLength: () => (validLength ? true : false),
        hasNumber: () => (hasNumber ? true : false),
        isMatch: () => (match ? true : false),
        hasLetter: () => (hasLetter ? true : false)
      }
    },
    legal: {
      required:
        "Para registrarse debe aceptar la Política de Privacidad y los Términos y Condiciones"
    }
  };

  const setFirst = (event) => {
    setPasswordValidation({
      ...passwordValidation,
      firstPassword: event.target.value
    });
  };
  const setSecond = (event) => {
    setPasswordValidation({
      ...passwordValidation,
      secondPassword: event.target.value
    });
  };

  const onAddSpecialty = (obj, reset) => {
    callApi(
      () => postAddSpecialty(obj),
      () => {
        callApi(getSpecialties, (data) => {
          const maxId = Math.max(...data.map((x) => x.id));
          setValue("specialty", maxId);
          setSpecialties([...data, { id: -1, name: "Agregar especialidad" }]);
          setDlgSpecialty(false);
          reset();
        });
      }
    );
  };

  const onSubmit = handleSubmit((data) => {
    const obj = {
      cityId: data.city,
      document: data.document,
      documentTypeId: data.documentType,
      email: data.email,
      company: data.entity,
      lastName: data.lastName,
      medicalRecord: data.medicalRecord,
      mobilePhone: data.mobileNumber,
      name: data.name,
      password: data.password,
      phone: data.phoneNumber,
      specialtyId: data.specialty,
      country: countryCode
    };

    callApi(
      () => postRegister(obj),
      () => {
        history.push("/doctor/register/email-send");
      },
      setLoader
    );
  });

  console.log("documentTypes", documentTypes);

  return (
    <div>
      <div className="container">
        <div className="inner-container">
          <div className="register-wrap">
            <p className="form-title">Información de usuario</p>
            <FormProvider {...methods}>
              <form noValidate autoComplete="off">
                <TextOutlinedInput
                  name="name"
                  label="Ingrese sus nombres *"
                  type={"text"}
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  required
                />

                <TextOutlinedInput
                  name="lastName"
                  label="Ingrese sus apellidos *"
                  type={"text"}
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  required
                />

                <Autocomplete
                  name="documentType"
                  label="Seleccione su tipo de identificación"
                  options={documentTypes}
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  required
                  watch={watch}
                  setValue={setValue}
                />

                <TextOutlinedInput
                  name="document"
                  label="Ingrese su número de identificación *"
                  type={"text"}
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  required
                />

                <TextOutlinedInput
                  name="medicalRecord"
                  label="Ingrese el número de su registro médico *"
                  type={"text"}
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  required
                />

                <Autocomplete
                  name="stateProvince"
                  label="Seleccione su departamento"
                  options={stateProvinces}
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  required
                  watch={watch}
                  setValue={setValue}
                />

                <Autocomplete
                  name="city"
                  label="Seleccione su ciudad"
                  options={cities}
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  required
                  watch={watch}
                  setValue={setValue}
                />

                <TextOutlinedInput
                  name="mobileNumber"
                  label="Ingrese su teléfono celular"
                  type={"text"}
                  required
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                />

                <TextOutlinedInput
                  name="phoneNumber"
                  label="Ingrese su teléfono fijo"
                  type={"text"}
                  required
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                />

                <Autocomplete
                  name="specialty"
                  label="Seleccione su especialidad"
                  options={specialties}
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  required
                  watch={watch}
                  setValue={setValue}
                />

                <TextOutlinedInput
                  name="entity"
                  label="Ingrese el nombre de su entidad"
                  type={"text"}
                  required
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                />
                <p className="form-title">Seguridad</p>

                <EmailOutlinedInput
                  name="email"
                  label="Ingrese su correo electrónico"
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  required={true}
                />

                <PasswordOutlinedInput
                  name="password"
                  label="Contraseña"
                  labelwith="85"
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  required={true}
                  onChange={setFirst}
                />

                <ul className="validation-list">
                  <li className="validation-item-list">
                    <img
                      src={
                        !validLength
                          ? "/doctor/cross-red.svg"
                          : "/doctor/tick-green.svg"
                      }
                      alt="tic"
                    />
                    Que sea entre 6 y 16 caracteres
                  </li>
                  <li className="validation-item-list">
                    <img
                      src={
                        !hasLetter
                          ? "/doctor/cross-red.svg"
                          : "/doctor/tick-green.svg"
                      }
                      alt="tic"
                    />
                    Incluya al menos una letra
                  </li>
                  <li className="validation-item-list">
                    <img
                      src={
                        !hasNumber
                          ? "/doctor/cross-red.svg"
                          : "/doctor/tick-green.svg"
                      }
                      alt="tic"
                    />
                    Incluya al menos un número
                  </li>
                  <li className="validation-item-list">
                    <img
                      src={
                        !match
                          ? "/doctor/cross-red.svg"
                          : "/doctor/tick-green.svg"
                      }
                      alt="tic"
                    />
                    Las contraseñas deben ser iguales
                  </li>
                </ul>

                <ConfirmPasswordOutlinedInput
                  name="password_repeat"
                  label="Confirmar nueva contraseña"
                  labelwith="215"
                  register={register}
                  registerOptions={registerOptions}
                  errors={errors}
                  required={true}
                  passwordToCheck={password.current}
                  onChange={setSecond}
                />

                <CheckboxOutlinedInput
                  id="legal"
                  name="legal"
                  register={register}
                  registerOptions={registerOptions}
                  required
                  errors={errors}
                  value={legalValue ?? false}
                >
                  He leído y acepto la{" "}
                  <a href="/doctor/privacy-policy">Política de Privacidad</a> y
                  los{" "}
                  <a href="/doctor/terms-and-conditions">
                    Términos y Condiciones
                  </a>
                </CheckboxOutlinedInput>

                <p className="form-title-2">(*) Campos obligatorios</p>
              </form>
            </FormProvider>
            <div className="button-wrap">
              {/*  <Button1 label="Registrarse" onClick={onSubmit} /> */}
              <LaddaBtn onClick={onSubmit}>Registrarse</LaddaBtn>

              <p>
                ¿Ya tiene una cuenta?{" "}
                <u>
                  <a href="/doctor/login">Inicie sesión</a>
                </u>
              </p>
              <p>
                ¿Necesita ayuda?{" "}
                <u
                  style={{ cursor: "pointer", color: "#9c1a8b" }}
                  onClick={handleOpen}
                >
                  Contáctenos
                </u>
              </p>
            </div>
          </div>
          <AddSpecialty
            open={dlgAddSpecialty}
            onClose={() => setDlgSpecialty(false)}
            onAddSpecialty={onAddSpecialty}
          />
          <Contact open={dlgContac} onClose={handleClose} />
        </div>
      </div>
      <Progress loader={loader} />
    </div>
  );
};

export default Register;
