import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button2 from "../../../components/doctorComponents/buttons/button-2";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { QuestionChart, LineChart, OneLineChart } from "./optionsCharts";
import Box from "@material-ui/core/Box";
import CustomChart from "../../../components/doctorComponents/highcharts";
import { saveAs } from "file-saver";
import { PDFDocument, StandardFonts, PDFImage } from "pdf-lib";
import domtoimage from "dom-to-image";
import {
  SurveyChartType,
} from "../../../constants/doctorConstants/enums";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "black,",
    "& .MuiDialog-paper": {
      margin: "16px",
    },
    "& .MuiDialogContent-root": {
      padding: "10px 10px 40px 10px",
    },
    "& .MuiTypography-h6": {
      fontFamily: " Source Sans Pro",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "22px",
      lineHeight: "28px",
      padding: "20px 0 0 10px",
      color: "#343A40",
    },
  },
  modalLabel: {
    width: "100%",
    maxWidth: "290px",
    margin: "0 auto 0 auto",
  },
}));

const getQuestionTitle = (questionNumber) => {
  switch (questionNumber) {
    case 1:
      return "1. ¿Presenta dolor pélvico y/o ardor al orinar?";
    case 11:
      return "1.1 Nivel de dolor según la Escala Visual Análoga (EVA)";
    case 2:
      return "2. ¿Cuántas veces va a orinar durante el día?";
    case 3:
      return "3. ¿Cuántas veces va a orinar en la noche luego de haber conciliado el sueño?";
    case 4:
      return "4. ¿Cuándo siente el deseo de orinar debe ir rápidamente o puede aguantar?";
    case 5:
      return "5. ¿Ha presentado usted fiebre superior a 38 °C, asociado con alguno de los síntomas anteriores?";
    case 6:
      return "6. ¿Ha presentado usted sangrado en la orina asociado con alguno de los síntomas anteriores?";
    default:
      return "";
  }
};
const PreviewReport = ({
  open,
  onClose,
  currentCycle,
  totalGrahps,
  namePdf,
  type,
}) => {
  const classes = useStyles();
  const chartRef1 = useRef(null);
  const chartRef11 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const chartRef4 = useRef(null);
  const chartRef5 = useRef(null);
  const chartRef6 = useRef(null);

  const getPropArray = (obj, prop, question) => {
    if (!obj) return [];
    return obj[question - 1][prop];
  };

  const onCloseHandle = () => {
    onClose();
  };

  const downloadPDF = async () => {
    const image1 = await domtoimage.toPng(chartRef1.current);
    const image11 = await domtoimage.toPng(chartRef11.current);
    const image2 = await domtoimage.toPng(chartRef2.current);
    const image3 = await domtoimage.toPng(chartRef3.current);
    const image4 = await domtoimage.toPng(chartRef4.current);
    const image5 = await domtoimage.toPng(chartRef5.current);
    const image6 = await domtoimage.toPng(chartRef6.current);

    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const pdfImage1 = await pdfDoc.embedPng(image1);
    const pdfImage11 = await pdfDoc.embedPng(image11);
    const pdfImage2 = await pdfDoc.embedPng(image2);
    const pdfImage3 = await pdfDoc.embedPng(image3);
    const pdfImage4 = await pdfDoc.embedPng(image4);
    const pdfImage5 = await pdfDoc.embedPng(image5);
    const pdfImage6 = await pdfDoc.embedPng(image6);
    page.drawImage(pdfImage1, {
      x: 40,
      y: page.getSize().height - 180,
      width: 250,
      height: 160,
    });

    page.drawImage(pdfImage11, {
      x: 310,
      y: page.getSize().height - 180,
      width: 250,
      height: 160,
    });

    page.drawImage(pdfImage2, {
      x: 40,
      y: page.getSize().height - 360,
      width: 250,
      height: 160,
    });

    page.drawImage(pdfImage3, {
      x: 310,
      y: page.getSize().height - 360,
      width: 250,
      height: 160,
    });

    page.drawImage(pdfImage4, {
      x: 40,
      y: page.getSize().height - 540,
      width: 250,
      height: 160,
    });

    page.drawImage(pdfImage5, {
      x: 310,
      y: page.getSize().height - 540,
      width: 250,
      height: 160,
    });

    page.drawImage(pdfImage6, {
      x: 40,
      y: page.getSize().height - 720,
      width: 250,
      height: 160,
    });

    const pdfBytes = await pdfDoc.save();
    saveAs(new Blob([pdfBytes], { type: "application/pdf" }), getPdfName());
  };

  const getPdfName = () => {
    const currentDate = new Date();
    const dia = String(currentDate.getDate()).padStart(2, "0");
    const mes = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    const fechaConcatenada = `${year}${mes}${dia}`;
    const cycleName = currentCycle.replace(" ", "");
    var msj = "";
    if (type == SurveyChartType.epsDetail) {
      msj = `${namePdf}-${cycleName}-${fechaConcatenada}.pdf`;
      
    }else{
      msj = `${cycleName}-${fechaConcatenada}.pdf`;
    }
    return msj;
  };

  return (
    <Dialog
      className={classes.root}
      open={open}
      keepMounted
      onClose={onCloseHandle}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Previsualización descargable"}
      </DialogTitle>
      <DialogContent>
        <div>
          <div className="div-center">
            <Box p={3}>
              <div ref={chartRef1}>
                <CustomChart
                  options={OneLineChart(
                    getQuestionTitle(1),
                    "role.visitor",
                    null,
                    getPropArray(totalGrahps, "yesNoQuestion", 1),
                    currentCycle,
                    1
                  )}
                />
              </div>
              <div ref={chartRef11}>
                <CustomChart
                  options={LineChart(
                    getQuestionTitle(11),
                    "role.visitor",
                    null,
                    getPropArray(totalGrahps, "question", 1),
                    currentCycle,
                    11
                  )}
                />
              </div>
              <div className="mt-3" ref={chartRef2}>
                <CustomChart
                  options={QuestionChart(
                    getQuestionTitle(2),
                    "role.visitor",
                    null,
                    getPropArray(totalGrahps, "question", 2),
                    currentCycle,
                    2
                  )}
                />
              </div>
              <div className="mt-3" ref={chartRef3}>
                <CustomChart
                  options={QuestionChart(
                    getQuestionTitle(3),
                    "role.visitor",
                    null,
                    getPropArray(totalGrahps, "question", 3),
                    currentCycle,
                    3
                  )}
                />
              </div>
              <div className="mt-3" ref={chartRef4}>
                <CustomChart
                  options={QuestionChart(
                    getQuestionTitle(4),
                    "role.visitor",
                    null,
                    getPropArray(totalGrahps, "question", 4),
                    currentCycle,
                    4
                  )}
                />
              </div>
              <div ref={chartRef5}>
                <CustomChart
                  options={OneLineChart(
                    getQuestionTitle(5),
                    "role.visitor",
                    null,
                    getPropArray(totalGrahps, "yesNoQuestion", 5),
                    currentCycle,
                    5
                  )}
                />
              </div>
              <div ref={chartRef6}>
                <CustomChart
                  options={OneLineChart(
                    getQuestionTitle(6),
                    "role.visitor",
                    null,
                    getPropArray(totalGrahps, "yesNoQuestion", 6),
                    currentCycle,
                    6
                  )}
                />
              </div>
            </Box>
          </div>
          <div className={"div-horizontal"}>
            <Button2 label="Cerrar" onClick={onCloseHandle} />
            <Button2 label="Descargar" onClick={downloadPDF} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewReport;
