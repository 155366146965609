import React from "react";

import AccordionComponent from "../../../../components/doctorComponents/accordion/accordion-1";
import AccordionBibliography from "../../../../components/doctorComponents/accordion/accordion-2";

const ChronicBacterialCystitis3 = () => {
  return (
    <div className="container">
      <div className="inner-container">
        <AccordionComponent article="chronic-bacterial-cystitis" title="ANTIBIÓTICOS">
          <div>
            <p>
              Se estima que la mitad de las mujeres padecerá una IVU a lo largo
              de su vida y que hasta en el 50% de éllas la infección será
              recurrente en los siguientes 6 meses. Siguiendo las Guías de la
              AUA se consideran las siguientes directrices:
            </p>
            <p>
              Los médicos deben utilizar la terapia de primera línea (es decir,
              nitrofurantoína, Trimetropin Sulfametazol (TMP-SMX), fosfomicina)
              dependiendo del antibiograma local para el tratamiento de las
              infecciones urinarias sintomáticas en las mujeres. (Recomendación
              fuerte; Nivel de evidencia: Grado B). Los médicos deben tratar a
              los pacientes con IVU recurrente que experimentan episodios de
              cistitis aguda con una duración de antibióticos tan corta como sea
              razonable, generalmente no más de siete días. (Recomendación
              moderada; Nivel de evidencia: Grado B).
            </p>
            <p>
              En pacientes con IVU recurrente que experimentan episodios de
              cistitis aguda asociados con urocultivos resistentes a
              antibióticos orales, los médicos pueden tratar con antibióticos
              parenterales dirigidos a cultivos durante un ciclo tan corto como
              sea razonable, generalmente no más de siete días. (Opinión
              experta). En la profilaxis antibiótica, lugo de analizar los
              riesgos, los beneficios y las alternativas, los médicos pueden
              prescribir profilaxis con antibióticos para reducir el riesgo de
              infecciones urinarias en el futuro en mujeres de todas las edades
              diagnosticadas previamente con infecciones urinarias.
              (Recomendación moderada; Nivel de evidencia: Grado B).
            </p>
            <p>
              Dado que actualmente la tasa de resistencia a fármacos
              administrados en monoterapia está aumentando excesivamente en todo
              el mundo y el uso indiscriminado de los antibióticos es común, se
              puede afirmar que los tratamientos combinados serán de rutina en
              el manejo de las IVU en el futuro. Para controlar la resistencia
              bacteriana, es necesario encontrar alternativas diferentes para
              reducir los síntomas sin recurrir al uso de antibióticos.
              <sup>
                <b>1</b>
              </sup>
            </p>{" "}
          </div>
        </AccordionComponent>
        <AccordionComponent article="chronic-bacterial-cystitis" title="ALTERNATIVAS NO–ANTIBIÓTICAS">
          <div>
            <p>
              Las IVU causan comúnmente infecciones en pacientes hospitalizados,
              una tasa de morbilidad significativa y, en consecuencia, largas
              ausencias laborales.{" "}
            </p>
            <p>
              Los antibióticos eficaces para el tratamiento de las IVU están
              disminuyendo de forma considerable y se han registrado altos
              niveles de resistencia a muchos antibióticos que se prescriben
              comúnmente. También se ha documentado la resistencia emergente
              transmisible de las enterobacterias frente a la colistina,
              demostrando que el último recurso de tratamiento para el manejo de
              las infecciones causadas por bacterias gramnegativas
              mutirresistentes no es totalmente eficaz y que al menos este tipo
              de infecciones no podrían controlarse con los antibióticos
              disponibles.
            </p>
            <p>
              El aumento de la resistencia a los antibióticos ha surgido como
              resultado de la combinación de varios mecanismos de resistencia:
              mutaciones sucesivas o cromosómicas y elementos genéticos móviles.
              <sup>
                <b>1</b>
              </sup>
            </p>

            <p className="subtitle-purple">1) Medidas de comportamiento:</p>
            <p>
              Uno de los mecanismos de defensa naturales clave contra las
              infecciones urinarias es la presencia de numerosos organismos
              comensales diferentes en las diferentes áreas de la vaginal y
              periuretral, más comúnmente especies de Lactobacillus.
              <sup>
                <b>2</b>
              </sup>{" "}
              Estos organismos comensales pueden unirse a las células
              epiteliales vaginales (evitando así la colonización de la zona
              vaginal y periuretral por parte de los uropatógenos) alteran el
              entorno vaginal local a través de cambios en el pH y liberan
              sustancias químicas directamente tóxicas (como el peróxido de
              hidrógeno), reduciendo así el potencial de colonización por
              bacterias uropatógenas.
              <sup>
                <b>3,4</b>
              </sup>{" "}
              Los factores que afectan la microbiota vaginal normal pueden, por
              lo tanto, aumentar el riesgo de desarrollar infecciones urinarias.
            </p>
            <p>
              Modificar ciertos hábitos cotidianos en la clínica es importante,
              ya que evitar la actividad que se considera un factor de riesgo de
              infección urinaria podría prevenir la recurrencia.
              <sup>
                <b>5</b>
              </sup>{" "}
              Las relaciones sexuales, historia de IVU en el último año,
              incontinencia urinaria, uso de antibióticos en las 2 semanas
              anteriores, anticonceptivos a base de espermicidas (nonoxinol-9) y
              exposición al frío.
            </p>
            <p>
              Un estudio de casos y controles basado en una encuesta de
              cuestionario no encontró diferencias estadísticamente
              significativas en los hábitos de micción (como demora en la
              micción, no micción antes o después de las relaciones sexuales o
              reducción de la frecuencia diurna) o prácticas de higiene personal
              (que incluyen limpiarse de atrás hacia adelante, duchas vaginales,
              uso de tampones o uso de ropa interior de algodón) entre con y sin
              IVU recurrente ({"P>"} 0,05), pero se observó un riesgo
              significativo de sesgo de recuerdo y, por lo tanto, las medidas de
              comportamiento requieren más estudios en ensayos prospectivos.
              <sup>
                <b>6</b>
              </sup>{" "}
            </p>

            <p className="subtitle-purple">2) Medidas dietéticas:</p>
            <p>
              El papel de la simple modificación de la dieta para prevenir el
              desarrollo de IVU sigue sin estar comprobado, pero las medidas
              dietéticas mejor estudiadas son la hidratación adecuada y el uso
              de vitamina C (Cuadro 1). La hidratación y la osmolalidad urinaria
              también están poco estudiadas en relación con la profilaxis contra
              las infecciones urinarias recurrentes. Se justifica la
              investigación adicional sobre la modificación del pH urinario o la
              osmolalidad como profilaxis contra las infecciones urinarias.
            </p>
            <p>
              Los estudios de acidificación urinaria con ácido ascórbico y su
              función en la prevención de las infecciones urinarias están
              limitados por el pequeño tamaño de las muestras, la falta de
              control de los factores dietéticos de confusión que podrían
              alterar el pH urinario o la falta de un grupo placebo. Por lo
              tanto, no se pueden generar conclusiones sobre el papel de la
              acidificación urinaria en el tratamiento o la prevención de la IVU
              recurrente. Se desconoce el efecto preventivo del ácido ascórbico
              y faltan pruebas de su uso en este contexto, pero los ensayos
              aleatorios con un gran número de participantes que examinan el
              efecto de ácido ascórbico en el pH urinario ayudaría a determinar
              el papel de la acidificación urinaria en la prevención de
              infecciones urinarias recurrentes.
            </p>

            <p className="subtitle-purple">3) Fitoterapia:</p>
            <p>
              Las <b>plantas medicinales chinas (CHM)</b> se han estudiado en
              ensayos controlados aleatorios tanto para el tratamiento de la IVU
              aguda como profilaxis para prevenir la IVU recurrente. In vitro,
              las hierbas chinas, como Huang Lian (
              <i>Coptis chinensis Franch</i>), tienen actividad inhibidora
              contra varias bacterias uropatógenas (incluida la E. coli) además
              de efectos antiinflamatorios. Se ha demostrado que otras hierbas,
              como los gránulos compuestos de Salvia Plebeia (CSPG), reducen la
              adherencia de E. coli al tejido urotelial de la vejiga.
              <sup>
                <b>7,8,9</b>
              </sup>{" "}
            </p>
            <p>
              Una revisión Cochrane de siete ensayos controlados aleatorizados
              con un total de 542 mujeres evaluó el papel de la HMC en el
              tratamiento y la prevención de la IVU recurrente.
              <sup>
                <b>10</b>
              </sup>{" "}
              Tres estudios compararon CHM con tratamientos con antibióticos,
              dos compararon CHM en combinación con antibióticos solos y dos
              compararon dos regímenes de CHM diferentes: todos los estudiados
              fueron clasificados como de muy baja calidad sobre la base de los
              criterios evidencia. Por estas razones,{" "}
              <b>
                no se puede recomendar el uso de CHM hasta que se realicen
                ensayos aleatorios más grandes, de mayor calidad y bien
                realizados
              </b>
              .
            </p>
            <p>
              La justificación del{" "}
              <b>
                uso de arándanos para la profilaxis de las infecciones urinarias
              </b>{" "}
              sigue siendo muy variable y existe poca evidencia de su función en
              el tratamiento de una infección urinaria aguda. El arándano es una
              planta de la familia <i>Ericaceae</i> y se conoce como{" "}
              <i>Vaccinium macrocarpon</i>, <i>Vaccinium oxycoccos</i> y{" "}
              <i>Vaccinium erythrocarpum</i>.
            </p>
            <p>
              Las semillas se componen predominantemente de agua (88%), seguida
              de ácido, flavonoides, antocianidinas, proantocianidinas,
              catequinas y triterpenoides. Las antocianidinas y
              proantocianidinas son taninos (polifenoles) que funcionan como un
              sistema de defensa natural de las plantas contra las infecciones
              microbianas y son los componentes que se cree que tienen mayor
              relevancia clínica en la prevención de las infecciones urinarias
              en las mujeres.{" "}
              <b>
                No se ha determinado un mecanismo de acción definitivo para
                explicar las propiedades profilácticas de los arándanos para
                prevenir las infecciones urinarias recurrentes
              </b>
              . El jugo puro de arándano es muy ácido, con un pH {"<2.5"}.
              <sup>
                <b>11</b>
              </sup>
              Sobre la base de esta acidez, inicialmente se planteó la hipótesis
              que la acidificación de la orina contribuiría al efecto
              antibacteriano del arándano.
              <sup>
                <b>12</b>
              </sup>
            </p>
            <p>
              Los arándanos contienen fructosa, que se ha descubierto que tiene
              un papel en la inhibición de la adherencia de E. coli con fimbrias
              tipo 1 a los receptores de células uroteliales in vitro, y
              proantocianidinas, que afectan la adherencia de E. coli fimbriada.
              El mecanismo preciso a través del cual los componentes del
              arándano rojo ejercen un efecto antiadherente sigue sin estar
              claro.{" "}
              <b>
                Los compuestos de arándano pueden actuar como análogos del
                receptor, inhibiendo competitivamente la adhesión de E. coli al
                urotelio
              </b>{" "}
              al unirse a las puntas fimbriales
              <sup>
                <b>13</b>
              </sup>{" "}
              (ver Figura 1).
            </p>
            <p>
              Vasileiou y sus colegas
              <sup>
                <b>14</b>
              </sup>{" "}
              sugirieron que otros componentes del arándano (como el ácido
              ursólico, un triterpenoide pentacíclico) tienen un papel
              complementario o sinérgico de los polifenoles de proantocianidina
              en el proceso de antiadhesión al causar una expresión génica
              diferencial en E. coli, lo que resulta en la inhibición de la
              formación de biofilm.{" "}
            </p>

            <div className="imgBox">
              <p className="title-gray-2">FITOTERAPIA</p>

              <img
                className="img"
                src="/doctor/doctor-img015.png"
                alt="Img"
              />
            </div>
            <p className="italics-gray">
              Figura 1. Mecanismo de acción de los componentes del arándano
            </p>
            <p>
              Se ha sugerido que la cantidad de jugo de arándano necesaria para
              evitar la adhesión bacteriana al urotelio es de 240 ml. Esta
              sugerencia se basa en un estudio in vitro de 39 aislamientos de E.
              Coli uropatógena (de la orina de mujeres premenopáusicas con IVU
              confirmada por cultivo), que muestra que después del consumo de
              240 ml del jugo de arándano, la orina previno la adhesión del 80%
              de los aislamientos de E. Coli, y el efecto duró hasta 10 horas
              después de la ingestión.
              <sup>
                <b>15</b>
              </sup>{" "}
            </p>
            <p>
              Los productos de arándano son de fácil acceso, tienen un perfil de
              efectos adversos bajo y no conllevan el mismo riesgo de
              resistencia bacteriana que los antibióticos.{" "}
              <b>
                Las mujeres con IVU recurrente deben ser informadas de la
                evidencia variable acerca de la eficacia, pero con un perfil de
                efectos adversos bajo, si desean usar arándano para la
                prevención de IVU
              </b>
              . Sin embargo, para poder hacer recomendaciones sólidas se
              requieren ensayos más grandes y bien diseñados.
            </p>

            <p className="subtitle-purple">
              4) Anti-inflamatorios no-esteroideos (AINE's):
            </p>
            <p>
              La inflamación crónica de la vejiga que se produce como resultado
              de la exposición prolongada a bacterias da como resultado
              hiperplasia linfonodular e infiltración de células B en la
              submucosa de la vejiga y se ha observado en estudios de pacientes
              con vejiga neurógena e IVU recurrente.
              <sup>
                <b>16</b>
              </sup>{" "}
              La expresión de COX2 aumenta en las partículas de orina de
              pacientes con IVU.
              <sup>
                <b>17,18 </b>
              </sup>
              Estos resultados sugieren un posible{" "}
              <b>
                papel beneficioso de los AINE's o los inhibidores de la COX2
              </b>{" "}
              en el tratamiento de la IVU aguda y en la prevención del
              desarrollo de IVU recurrente.
            </p>
            <p>
              No se han realizado estudios que evalúen los AINEs como un agente
              profiláctico para prevenir la recurrencia de la IVU. Por lo tanto,
              los resultados con respecto a la efectividad de los AINE's para la
              I-VU deben interpretarse con cautela, pero justifican una mayor
              investigación en grandes ensayos aleatorizados. Curiosamente, se
              ha demostrado que el inhibidor de la COX2 celecoxib revierte la
              resistencia a múltiples fármacos y aumenta la susceptibilidad de
              las bacterias a los antibióticos habituales
              <sup>
                <b>19</b>
              </sup>{" "}
              (<b>in viltro</b>).
            </p>

            <p className="subtitle-purple">5) Probióticos:</p>
            <p>
              Los probióticos son microorganismos vivos que se cree que
              confieren beneficios para la salud cuando se consumen. Se cree que
              la flora bacteriana normal de los seres humanos es un mecanismo de
              defensa clave contra las infecciones; por lo tanto, aumentar esta
              flora normal con probióticos es una estrategia atractiva para
              prevenir infecciones. El mecanismo de acción propuesto es que las{" "}
              <b>
                especies de
                <i>Lactobacillus</i> son los organismos comensales predominantes
                presentes en el área vaginal y periuretral
              </b>
              , y son responsables de prevenir la adherencia y migración de
              patógenos al urotelio vesical y que causan las IVU.
              <sup>
                <b>20</b>
              </sup>{" "}
              Se ha demostrado que una reducción significativa en los niveles de
              lactobacilos vaginales está asociada con un aumento en la tasa de
              colonización vaginal por E. coli (OR 4.0; P = 0.01){" "}
              <sup>
                <b>13</b>
              </sup>
              , y también ocurre una disminución en los niveles después de las
              relaciones sexuales, el uso de espermicidas y en pacientes
              posmenopáusicas.
            </p>
            <p>
              El{" "}
              <b>
                uso repetido de antibióticos también puede afectar adversamente
              </b>
              la composición local de lactobacilos de la vagina, y en un estudio
              de 70 mujeres tratadas con antibióticos para la IVU, un frotis
              vaginal 4 a 6 horas después del tratamiento mostró colonización de
              lactobacilos en solo el 14% de las mujeres.
              <sup>
                <b>21</b>
              </sup>{" "}
            </p>
            <p>
              <b className="bold-purple">Mecanismos de acción:</b> los
              lactobacilos pueden prevenir la infección del tracto urinario a
              través de muchos mecanismos, incluida la inhibición competitiva de
              la unión de uropatógenos a las células epiteliales vaginales (paso
              1); congregación alrededor de uropatógenos (paso 2); producción de
              bacteriocinas, peróxido de hidrógeno y ácido láctico (paso 3);
              ambiente ácido causado por el ácido láctico (paso 4); inhibición
              de la formación de biofilm bacteriano (paso 5); y regulación a la
              baja de citocinas proinflamatorias (paso 6). Factor de necrosis
              tumoral (TNF) (Figura 2).
            </p>

            <div className="imgBox">
              <p className="title-gray-2">PROBIÓTICOS</p>

              <img
                className="img"
                src="/doctor/doctor-img016.png"
                alt="Img"
              />
            </div>
            <p className="italics-gray">
              Figura 2. Mecanismo de acción de los probióticos
            </p>

            <p className="subtitle-purple">6) Vacunas e inmunoestimulantes:</p>
            <p>
              El desarrollo eficaz de vacunas contra las infecciones urinarias
              está limitado por factores como la falta de inmunidad protectora
              natural en los seres humanos después de una infección urinaria, la
              falta de inducción de anticuerpos en la vejiga, la diversidad de
              patógenos y el deseo de evitar inducir una respuesta inmunitaria
              patológica a la colonización de bacterias en el intestino.
            </p>
            <p>
              Después de la ingestión, se ha demostrado que{" "}
              <b>
                los inmunoestimulantes activan las células dendríticas, los
                neutrófilos y las células T auxiliares
              </b>
              . Esta activación da como resultado la activación de linfocitos T
              y linfocitos B, y la posterior liberación de IgA da como resultado
              inmunoprotección
              <sup>
                <b>22,23</b>
              </sup>{" "}
              (<i>Figura 3</i>).
            </p>

            <div className="imgBox">
              <p className="title-gray-2">VACUNAS E INMUNOESTIMULANTES</p>

              <img
                className="img"
                src="/doctor/doctor-img014.png"
                alt="Img"
              />
            </div>
            <p className="italics-gray">
              Figura 3. Mecanismo de acción de las vacunas e inmunoestimulantes
            </p>
            <p>
              En la tabla siguiente se muestran las características de las
              vacunas más frecuentemente utilizadas para la prevención de la IVU
              recurrente:
            </p>
            <div className="imgBox">
              <p className="title-gray-2">VACUNAS E INMUNOESTIMULANTES</p>
              <div className="container-horizontal-img">
                <img
                  className="img"
                  src="/doctor/Group 34.svg"
                  style={{ width: "auto" }}
                  alt="Img"
                />
              </div>
            </div>
            <p>
              Tanto OM-89 como OM-89-S incluyen solo cepas de UPEC, en contraste
              con otros inmunoestimulantes, que tienden a incluir una gama más
              amplia de uropatógenos; sin embargo, si es eficaz contra la UPEC
              por sí sola, aún tendría un papel claro en el manejo de la IVU. Se
              requieren más investigaciones dados los resultados del ensayo
              OM-89-S.
              <sup>
                <b>24</b>
              </sup>
            </p>
            <p>
              <b className="bold-purple">
                Strovac (también conocido como Solco-Urovac):
              </b>{" "}
              un metanálisis de tres estudios de fase II aleatorizados y
              controlados con placebo que incluyeron a 220 mujeres (rango de
              edad de 18 a 74 años) demostró un efecto modesto que apoya la
              necesidad de realizar más investigaciones sobre este
              inmunoestimulante (RR 0,81; IC del 95%: 0,68-0,96).
              <sup>
                <b>25</b>
              </sup>
            </p>
            <p>
              <b className="bold-purple">Uromune:</b> esta vacuna ha demostrado
              eficacia en estudios de cohorte, con una reducción del riesgo
              absoluto de infección del tracto urinario del 90%
              <sup>
                <b>26</b>
              </sup>{" "}
              y fue bien tolerada en un pequeño estudio de cohorte en el Reino
              Unido.
              <sup>
                <b>27</b>
              </sup>
            </p>
            <p>
              En conclusión, muchos enfoques de inmunoestimulación y vacunación
              para prevenir las infecciones urinarias son prometedoras, pero aún
              falta una vacuna claramente eficaz. Las opciones profilácticas se
              reducen, es de esperar que un inmunoestimulante, una vacuna o una
              cepa de E. coli viva atenuada pronto se establezca como eficaz
              para prevenir la considerable morbilidad asociada con la
              recurrencia de la IVU.
            </p>

            <p className="subtitle-purple">7) D- Manosa:</p>
            <p>
              La d- manosa es un{" "}
              <b>
                isómero monosacárido de la glucosa y participa en la
                glicosilación de ciertas proteínas
              </b>{" "}
              por ejemplo, anticuerpos monoclonales. La absorción de D-manosa es
              rápida cuando se administra por vía oral, y es detectable en el
              plasma ~ 30 minutos después del consumo y finalmente se excreta en
              el tracto urinario. Los factores de virulencia de bacterias
              entéricas, como E. coli, incluyen fimbrias o pili de tipo 1, que
              son apéndices proteicos que se extienden desde la célula
              bacteriana y son parte integral de la unión. El pili tipo 1 están
              compuestas por proteínas Fim con una molécula de adhesión FimH en
              la punta del fibril. El dominio de lectina de adhesión FimH le
              permite unirse a las proteínas del huésped manosiladas (las
              unidades terminales de manosa de la uroplaquina 1ª, la
              glucoproteína Tamm-Horsfall y las integrinas β1 y α3) en la
              superficie del tracto urinario.
            </p>
            <p>
              Como la D-manosa es similar en estructura al sitio de unión de los
              receptores de glicoproteína urotelial como la uroplaquina, en una
              concentración suficiente en la orina,{" "}
              <b>
                la D-manosa puede causar la saturación de las adhesinas FimH y
                evitar que las bacterias se unan a los receptores de
                glicoproteína urotelial
                <sup>
                  <b>28</b>
                </sup>
              </b>{" "}
              (<i>Figura 4</i>).{" "}
            </p>

            <div className="imgBox">
              <p className="title-gray-2">VACUNAS E INMUNOESTIMULANTES</p>

              <img
                className="img"
                src="/doctor/doctor-img012.png"
                alt="Img"
              />
            </div>
            <p className="italics-gray">
              Figura 4. Mecanismo de acción de la D-manosa
            </p>

            <p>
              Se carece de evidencia clínica para la D-manosa y solo existe un
              ensayo aleatorizado de este producto. En este estudio
              aleatorizado, prospectivo y controlado de 308 mujeres con IVU
              recurrente: las participantes fueron aleatorizadas para tomar
              D-manosa diariamente (2g de polvo diluido en 200ml de solución
              salina normal), nitrofurantoína (50mg diarios) o sin profilaxis.
              <sup>
                <b>29</b>
              </sup>
            </p>
            <p>
              Durante los 6 meses posteriores a la IVU aguda, los participantes
              que recibieron D-manosa o antibióticos tuvieron un riesgo menor
              estadísticamente significativo de IVU recurrente que los que no
              recibieron ningún tratamiento (P {"<0,0001"} para ambos
              tratamientos en comparación con ningún tratamiento). No se
              observaron diferencias estadísticamente significativas al comparar
              los grupos de D-manosa y nitrofurantoína. Estos resultados
              sugieren que{" "}
              <b>
                la D-manosa podría ser una alternativa a los antibióticos en el
                tratamiento de la IVU
              </b>
              no complicada en mujeres y reduce el riesgo de recurrencia.
            </p>
            <p>
              La evidencia muestra que la D-manosa se tolera bien y una
              combinación de medidas no antibióticas podría dar lugar a
              resultados superiores a la monoterapia: d- Manosa junto con
              extracto de arándano y lactobacilos, pero se requieren estudios
              aleatorizados más grandes para validar aún más su uso. Una
              evaluación del papel de la D-manosa en el tratamiento agudo versus
              profilaxis necesita una mayor aclaración, al igual que su papel en
              los hombres con IVU e IVU complejas (como las asociadas con la
              vejiga neurogénica).
            </p>

            <p className="subtitle-purple">8) Metenamina:</p>
            <p>
              El hipurato de metenamina{" "}
              <b>
                se ha utilizado durante décadas como antiséptico del tracto
                urinario en el tratamiento de la IVU
              </b>
              , ya que ofrece las ventajas de ser bacteriostático sin el
              desarrollo de resistencia a los antimicrobianos. Se cree que el
              mecanismo de acción de las sales de metenamina en las infecciones
              urinarias es bacteriostático debido a la producción de
              formaldehído a partir de la hexamina, que ocurre en orina ácida .
              Se cree que el formaldehído es bacteriostático al inhibir la
              división celular y al conducir a la formación de ácido
              1,3-tiazano-4-carboxílico, que previene la síntesis de metionina,
              un metabolito esencial involucrado en la síntesis citoplásmica.
              Por lo tanto,{" "}
              <b>
                la acción del formaldehído es inhibir la síntesis nuclear y
                citoplasmática
              </b>
              , lo que resulta en bacteriostasis
              <sup>
                <b>30</b>
              </sup>{" "}
              (Figura 5).
            </p>

            <div className="imgBox">
              <p className="title-gray-2">HIPURATO DE METENAMINA</p>

              <img
                className="img"
                src="/doctor/doctor-img013.png"
                alt="Img"
              />
            </div>
            <p className="italics-gray">
              Figura 5. Mecanismo de acción de la Metenamina
            </p>

            <p>
              Con relación a la evidencia clínica, el uso del hipurato de
              metenamina para el tratamiento de la IVU fue objeto de una
              revisión Cochrane en 2012.
              <sup>
                <b>31</b>
              </sup>{" "}
              En total, se incluyeron 13 estudios con 2032 participantes, y la
              mayoría de los estudios se realizaron antes del año 2000. La dosis
              de hipurato de metenamina varió entre 1g y 4g al día, y dos
              estudios también utilizaron un agente de acidificación urinario
              (vitamina C o fosfato ácido de sodio). El conjunto de datos
              utilizado reflejó una población de pacientes muy heterogénea con
              cohortes de mujeres premenopáusicas y posmenopáusicas, aquellas
              con IVU recurrente, hombres sometidos a cirugía de próstata,
              mujeres después de cirugía ginecológica, pacientes con lesión de
              la médula espinal y aquellas con cálculos en el tracto urinario.
            </p>
            <p>
              Se ha demostrado que{" "}
              <b>
                el hipurato de metenamina es beneficioso en la prevención de la
                IVU recurrente, con una bajo perfil de efectos adversos y sin
                riesgo de resistencia a los antimicrobianos
              </b>
              . Se esperan los resultados del ensayo ALTAR,
              <sup>
                <b>32</b>
              </sup>{" "}
              pero el uso de hipurato de metenamina es una opción de tratamiento
              no antibiótico muy prometedora para los pacientes con IVU
              recurrente.
            </p>

            <p className="subtitle-purple">9) Estrógenos:</p>
            <p>
              Las hormonas estrogénicas son potentes reguladores del crecimiento
              y la diferenciación celular. El principal estrógeno secretado por
              el ovario es el 17β-estradiol (E2). También se secreta estrona,
              pero la principal fuente de esta hormona es extraglandular. La
              hidroxilación de estas dos hormonas conduce a la formación de
              estriol, el principal estrógeno que se encuentra en la orina. La
              diferenciación y el mantenimiento del epitelio están mediados
              predominantemente por dos receptores de estrógeno principales
              (RE): ERα y ERβ. La expresión de estos receptores se observa en la
              vagina distal, la vejiga trigonal y el revestimiento epitelial y
              el complejo vascular submucoso de la uretra. ERβ predomina en la
              vejiga urinaria y ERα en la vagina.
            </p>
            <p>
              También se ha encontrado sensibilidad al estrógeno en el
              pubococcígeo y la musculatura del suelo pélvico. La disminución de
              los niveles de estrógeno después de la menopausia contribuye a
              cambios químicos y estructurales (como la reducción del flujo
              urinario y el aumento del volumen residual, así como un aumento
              del pH vaginal con pérdida de lactobacilos comensales en la
              vagina) que aumentan el riesgo de IVU.
              <sup>
                <b>33</b>
              </sup>{" "}
              El estrógeno tiene un papel clave en la modulación de las defensas
              naturales del tracto urinario inferior contra las infecciones
              urinarias. Sin embargo, los mecanismos específicos de esta defensa
              aún no se conocen bien.
              <sup>
                <b>34</b>
              </sup>{" "}
            </p>
            <p>
              <b className="bold-purple">En la vagina:</b> los estrógenos
              circulantes en mujeres premenopáusicas estimulan la colonización
              vaginal con lactobacilos. La conversión por los lactobacilos de
              glucógeno en ácido láctico, peróxido de hidrógeno y péptidos
              antimicrobianos (como la bacteriocina) mantiene un pH vaginal bajo
              (ácido) (pH {"<4,5"}) e inhibe el crecimiento de uropatógenos. El
              aumento del pH vaginal (por encima de 4,5), lactobacilos y un
              aumento de la colonización vaginal por organismos intestinales
              (como E. coli).
            </p>
            <p>
              <b className="bold-purple">En la vejiga y la uretra:</b> los
              estudios han demostrado que el contacto bacteriano con las células
              uroteliales da como resultado la liberación de un péptido
              antimicrobiano catelicidina LL-37 y su precursor, nCAP-18, en la
              orina como defensa. Como LL-37 y nCAP-18 también son expresados
              por células inmunes, los niveles se pueden medir en suero. Luthje
              et al.
              <sup>
                <b>35</b>
              </sup>{" "}
              <sup>
                <b>35</b>
              </sup>{" "}
              encontraron que los niveles de catelicidina sérica se redujeron
              significativamente en mujeres posmenopáusicas (p = 0,007) y que
              estos se correlacionaron positivamente directamente con los
              niveles de estradiol (r de Spearman = 0,57, p = 0,01). La
              influencia hormonal del aumento de los niveles de estrógenos en el
              trígono y la mucosa uretral también contribuye al aumento de las
              presiones de cierre uretral y, por lo tanto, a la mejora de la
              continencia.
              <sup>
                <b>36</b>
              </sup>
            </p>
            <p>
              En el metaanálisis de dos estudios que informaron cambios en el pH
              vaginal
              <sup>
                <b>37,38</b>
              </sup>{" "}
              se observó una disminución considerable en el pH vaginal (de pH
              medio 6,5 a 5,5, diferencia media −1,00 (IC del 95%: −1,43 a
              −0,57)), con un aumento de los efectos adversos como sensibilidad
              en los senos y sangrado vaginal leve en quienes toman estrógenos
              orales. Por tanto, se recomiendan preparaciones para la
              administración local para la profilaxis de la IVU recurrente en
              lugar de la administración sistémica.
            </p>
            <p>
              El papel del estrógeno tópico para la prevención de la IVU
              recurrente en mujeres posmenopáusicas en comparación con el
              placebo es claramente evidente y está recomendado por las guías de
              la Asociación Europea de Urología (EAU) para esta población. El
              efecto de los estrógenos sobre el epitelio urogenital y el efecto
              estrogénico sobre los péptidos antimicrobianos no se conocen bien
              y son áreas que requieren más investigación. Las cremas tópicas
              vaginales parecen más eficaces que los anillos o los pesarios,
              pero aún no se ha llegado a un consenso sobre la dosis óptima o la
              duración de la profilaxis.
            </p>

            <p className="subtitle-purple">10) Glicosaminoglicanos (GAG):</p>
            <p>
              Los glicosaminoglicanos presentan la propiedad de retener grandes
              cantidades de agua y de adoptar una conformación extendida en
              disolución, por lo que son útiles a la hora de acojinar o
              lubricar. Estas propiedades se consiguen gracias al gran número de
              grupos OH y de cargas negativas de esta molécula, lo que permite,
              por el establecimiento de fuerzas de repulsión, que se conserven
              relativamente separadas entre sí las cadenas de carbohidratos. Las
              moléculas de hialuronato son de mayor tamaño, cosa que favorece su
              <b>función de barrera</b> y la localización en la membrana basal
              celular constituye una barrera de permeabilidad más consistente en
              la base celular urotelial.{" "}
            </p>
            <p>
              Entre los factores reconocidos,{" "}
              <b>
                es probable que la interacción entre las bacterias y las células
                epiteliales que recubren la pared de la vejiga desempeñe un
                papel importante
              </b>
              . La capa de glicosaminoglicanos (GAG) que recubre el epitelio de
              transición de la vejiga humana está implicada en este proceso. Una
              capa de GAG dañada puede provocar una exposición directa de las
              células epiteliales a los componentes de la orina, lo que aumenta
              la posibilidad de adherencia bacteriana e infección. Se han
              desarrollado varios enfoques para revertir el daño y reconstruir
              la capa de GAG, incluido el uso de heparina, polisulfato de
              pentosano oral, ácido hialurónico y condroitín sulfato.
            </p>
            <p>
              <b>Algunos estudios documentan esta respuesta favorable:</b> Los
              episodios de IVU disminuyeron de 4,13 ± 1,14 a 0,44 ± 0.50 (p =
              0,01) a los 12 meses, mientras que el tiempo de IVU recurrente
              prolongado de 94,8 ± 25,1 días a 178,4 ± 37,3 días (p = 0,01) a
              los 12 meses;
              <sup>
                <b>39</b>
              </sup>{" "}
              comparando con la profilaxis de fosfomicina, los resultados
              muestran 72,7% de efectividad en grupo GAG (ácido hialurónico y
              condroitín sulfato), 75% en grupo AH-CS+ fosfomicina y 30.4% con
              fosfomicina solamente.
              <sup>
                <b>40</b>
              </sup>{" "}
              Otro estudio informa una significativa disminución de la tasa de
              IVU por paciente y año (DM) −3.41, 95%{" "}
              {"(CI) −4.33 a −2.49, p < 0.00001)"} fue encontrado. Similarmente,
              el análisis agrupado demostró una recurrencia de infección
              urinaria media significativamente mayor en tiempo (días)
              utilizando la terapia de AH o AH-CS{" "}
              {"(MD 187.35, 95% CI 94.33 – 280.37, p< 0.0001)"}.
              <sup>
                <b>41</b>
              </sup>
            </p>
            <b>
              Como la mayoria de estos esquemas terapéuticos no antimicrobianos,
              requieren desarrollar ensayos clínicos prospectivos, aleatorizados
              y con criterios de selección y seguimiento más estricto.{" "}
            </b>
            <p>
              En conclusión, se necesitan estudios de terapias combinadas en
              poblaciones específicas de pacientes (como mujeres
              premenopáusicas, mujeres posmenopáusicas y hombres) para orientar
              estos tratamientos de manera óptima. La investigación sobre los
              mecanismos moleculares subyacentes de la adherencia e invasión
              bacteriana parece muy prometedora y podría conducir a la
              identificación de nuevos objetivos para el desarrollo de fármacos,
              y muchas terapias potenciales se encuentran en desarrollo
              preclínico. El objetivo de estas estrategias es reducir la crisis
              de resistencia a los antimicrobianos (<i>Figura 6</i>).
            </p>

            <img
              className="img"
              src="/doctor/doctor-img017.png"
              alt="Img"
            />

            <p className="italics-gray">
              Figura 6. Mecanismos de acción de los tratamientos no antibióticos
              en cistitis crónica bacteriana
            </p>
          </div>
        </AccordionComponent>

        <AccordionBibliography title="BIBLIOGRAFIA">
          <div>
            <p className="bibliography-font">
              {" "}
              <b>1.</b> Stamm, W. E. & Norrby, S. R. Urinary tract infections:
              disease panorama and challenges. J. Infect. Dis. 183, S1–S4
              (2001).
            </p>
            <p className="bibliography-font">
              <b>2.</b> Heinemann, C. & Reid, G. Vaginal microbial diversity
              among postmenopausal women with and without hormone replacement
              therapy. Can. J. Microbiol. 51, 777–781 (2005).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>3.</b> Falagas, M. E., Rafailidis, P. I. & Makris, G. C.
              Bacterial interference for the prevention and treatment of
              infections. Int. J. Antimicrob. Agents 31, 518–522 (2008).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>4.</b> Chan, R. C., Reid, G., Irvin, R. T., Bruce, A. W. &
              Costerton, J. W. Competitive exclusion of uropathogens from human
              uroepithelial cells by Lactobacillus whole cells and cell wall
              fragments. Infect. Immun. 47, 84–89 (1985).
            </p>
            <p className="bibliography-font">
              <b>5.</b> Coull, N., Mastoroudes, H., Popert, R. & O’Brien, T. S.
              Redefining urological history taking anal intercourse as the cause
              of unexplained symptoms in heterosexuals Ann. R. Coll. Surg. Engl.
              90, 403–405 (2008).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>6.</b> Scholes, D. et al. Risk factors for recurrent urinary
              tract infection in young women. J. Infect. Dis. 182, 1177–1182
              (2000).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>7.</b> Ma, X. Y., Zhi, Y., Zhang, X., Zhao, H. & Gao, G. J.
              Clinical study of Xianqing Houbu method in treating senile female
              recurrent urinary tract infection. J. Emergency Tradit. Chinese
              Med. 20, 1918–1919 (2011).
            </p>
            <p className="bibliography-font">
              <b>8.</b> Chen, M., Wang, Y. & Gu, X. C. Clinical observation of
              modified “erxian decoction” plus antibiotics in treating chronic
              urinary tract infection in middle- aged and old women. Shanghai J.
              Tradit. Chinese Med. 42, 48–49 (2008).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>9.</b> Gu, X. C., Xu, Z., Chen, M. & Wang, M. Study of erding
              erxian docoction compared with sanjin tablet in treating recurrent
              urinary tract infection. Chinese J. Integr. Tradit. Western
              Nephrol. 12, 623–624 (2011).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>10.</b> Flower, A., Wang, L. Q., Lewith, G., Liu, J. P. & Li,
              Q. Chinese herbal medicine for treating recurrent urinary tract
              infections in women. Cochrane Database Syst Rev. 6, CD01044
              (2015).
            </p>
            <p className="bibliography-font">
              <b>11.</b> Guay, D. R. Cranberry and urinary tract infections.
              Drugs 69, 775–807 (2009).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>12.</b> Lynch, D. M. Cranberry for prevention of urinary tract
              infections. Am. Fam. Physician 70, 2175–2177 (2004).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>13.</b> Hisano, M., Bruschini, H., Nicodemo, A. C. & Srougi, M.
              Cranberries and lower urinary tract infection prevention. Clinics
              67, 661–668 (2012).
            </p>
            <p className="bibliography-font">
              <b>14.</b> Vasileiou, I., Katsargyris, A., Theocharis, S. &
              Giaginis, C. Current clinical status on the preventive effects of
              cranberry consumption against urinary tract infections. Nutr. Res.
              33, 595–607 (2013).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>15.</b> Howell, A. B. & Foxman, B. Cranberry juice and adhesion
              of antibiotic- resistant uropathogens. JAMA 287, 3082–3083 (2002).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>16.</b> Schlager, T. A., LeGallo, R., Innes, D., Hendley, J. O.
              & Peters, C. A. B cell infiltration and lymphonodular hyperplasia
              in bladder submucosa of patients with persistent bacteriuria and
              recurrent urinary tract infections. J. Urol. 186, 2359–2364
              (2011).
            </p>
            <p className="bibliography-font">
              <b>17.</b> Hannan, T. J. et al. Inhibition of cyclooxygenase-2
              prevents chronic and recurrent cystitis. EBioMedicine 1, 46–57
              (2014).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>18.</b> Wheeler, M. A., Hausladen, D. A., Yoon, J. H. & Weiss,
              R. M. Prostaglandin E2 production and cyclooxygenase-2 induction
              in human urinary tract infections and bladder cancer. J. Urol.
              168, 1568–1573 (2002).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>19.</b> Kalle, A. M. & Rizvi, A. Inhibition of bacterial
              multidrug resistance by celecoxib, a cyclooxygenase-2 inhibitor.
              Antimicrob. Agents Chemother. 55,439–442 (2011).
            </p>
            <p className="bibliography-font">
              <b>20.</b> Velraeds, M. M., van der Mei, H. C., Reid, G. &
              Busscher, H. J. Inhibition of initial adhesion of uropathogenic
              Enterococcus faecalis by biosurfactants from Lactobacillus
              isolates. Appl. Environ. Microbiol. 62, 1958–1963 (1996).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>21.</b> Reid, G., Bruce, A. W., Cook, R. L. & Llano, M. Effect
              on urogenital flora of antibiotic therapy for urinary tract
              infection. Scand. J. Infect. Dis. 22, 43–47 (1990).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>22.</b> Schmidhammer, S. et al. An Escherichia coli- based oral
              vaccine against urinary tract infections potently activates human
              dendritic cells. Urology 60, 521–526 (2002).
            </p>
            <p className="bibliography-font">
              <b>23.</b> Van Pham, T., Kreis, B., Corradin- Betz, S., Bauer, J.&
              Mauel, J. Metabolic and functional stimulation of lymphocytes and
              macrophages by an Escherichia coli extract (OM-89): in vitro
              studies. J. Biol. Response Mod. 9, 231–240 (1990).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>24.</b> Wagenlehner, F. M. et al. A randomized, double- blind,
              parallel- group, multicenter clinical study of Escherichia coli-
              lyophilized lysate for the prophylaxis of recurrent uncomplicated
              urinary tract infections. Urol. Intern. 95, 167–176 (2015).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>25.</b> Beerepoot, M. A., Geerlings, S. E., van Haarst, E. P.,
              van Charante, N. M. & ter Riet, G. Nonantibiotic prophylaxis for
              recurrent urinary tract infections: a systematic review and meta-
              analysis of randomized controlled trials. J. Urol. 190, 1981–1989
              (2013).
            </p>
            <p className="bibliography-font">
              <b>26.</b> Lorenzo- Gomez, M. F. et al. Comparison of sublingual
              therapeutic vaccine with antibiotics for the prophylaxis of
              recurrent urinary tract infections. Frontiers Cell. Infect.
              Microbiol. 5, 50 (2015).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>27.</b> Yang, B. & Foley, S. First experience in the UK of
              treating women with recurrent urinary tract infections with the
              bacterial vaccine Uromune®. BJU Int. 121, 289–292 (2018).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>28.</b> Michaels, E. K., Chmiel, J. S., Plotkin, B. J. &
              Schaeffer, A. J. Effect of D- mannose and D- glucose on
              Escherichia coli bacteriuria in rats. Urol. Res. 11, 97–102
              (1983).
            </p>
            <p className="bibliography-font">
              <b>29.</b> Kranjcec, B., Papes, D. & Altarac, S. D- Mannose powder
              for prophylaxis of recurrent urinary tract infections in women: a
              randomized clinical trial. World J. Urol. 32, 79–84 (2014).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>30.</b> Neely, W. B. Action of formaldehyde on
              microorganisms.III. Bactericidal action of sublethal
              concentrations of formaldehyde on aerobacter aerogenes. J.
              Bacteriol. 86, 445–448 (1963).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>31.</b> Lee, B. S., Bhuta, T., Simpson, J. M. & Craig, J. C.
              Methenamine hippurate for preventing urinary tract infections.
              Cochrane Database Syst. Rev. 10, (2012).
            </p>
            <p className="bibliography-font">
              <b>32.</b> ISRCTN registry. Alternatives to prophylactic
              antibiotics for the treatment of recurrent urinary tract infection
              in women. BMC http://www.isrctn.com/ SRCTN70219762 (2016).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>33.</b> Luthje, P., Hirschberg, A. L. & Brauner, A. Estrogenic
              action on innate defense mechanisms in the urinary tract.
              Maturitas 77, 32–36 (2014).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>34.</b> Hannan, T. J., Hooton, T. M. & Hultgren, S. J. Estrogen
              and recurrent UTI: what are the facts? Sci. Transl Med. 5,
              190fs123 (2013).
            </p>
            <p className="bibliography-font">
              <b>35.</b> Luthje, P. et al. Estrogen supports urothelial defense
              mechanisms. Sci. Transl Med. 5, 190ra180 (2013).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>36.</b> Robinson, D. & Cardozo, L. Oestrogens and the lower
              urinary tract. BJOG 111 (Suppl. 1), 10–14 (2004).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>37.</b> Kirkengen, A. L. et al. Oestriol in the prophylactic
              treatment of recurrent urinary tract infections in postmenopausal
              women. Scand. J. Prim. Health Care 10, 139–142 (1992).
            </p>
            <p className="bibliography-font">
              <b>38.</b> Ouslander, J. G. et al. Effects of oral estrogen and
              progestin on the lower urinary tract among female nursing home
              residents. J. Am. Geriatr. Soc. 49, 803–807 (2001).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>39.</b> Antonio Cicione, MD; Francesco Cantiello, MD; Giuseppe
              Ucciero, MD; Andrea Salonia, MD; Marco Torella, MD; Marco De Sio,
              MD; Riccardo Autorino, MD; Antonio Carbone, MD; Martin Romancik,
              MD; Roman Tomaskin, MD; Rocco Damiano, MD. Intravesical treatment
              with highly-concentrated hyaluronic acid and chondroitin sulphate
              in patients with recurrent urinary tract infections: Results from
              a multicentre survey Can Urol Assoc J 2014;8(9-10): e721-7.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>40.</b> Davide De Vita & Henrik Antell & Salvatore Giordano
              Effectiveness of intravesical hyaluronic acid with or without
              chondroitin sulfate for recurrent bacterial cystitis in adult
              women: a meta-analysis Int Urogynecol J vol 23 N° 11 202-210, 2011
            </p>
            <p className="bibliography-font">
              <b>41.</b> Marco Torella; Maria Teresa Schettino; Stefano
              Salvatore; Maurizio Serati; Pasquale De Franciscis; Nicola
              Colacurci. Intravesical therapy in recurrent cystitis: a
              multi-center experience J Infect Chemother Oct2013, Vol 19 número
              5, p920.
            </p>
          </div>
        </AccordionBibliography>
      </div>
    </div>
  );
};

export default ChronicBacterialCystitis3;
