import React from "react";
import {StyleButton1, useStyles} from "../../../../styles/DoctorStyles/ui/buttons"

const Button1 = (props) => {
  const classes = useStyles();

  return (
      <StyleButton1 variant="contained" color="primary" className={classes.margin} {...props}>
        {props.label}
      </StyleButton1>
  );
}

export default Button1;
