import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TextField, FormControl, InputLabel, Checkbox } from "@material-ui/core";

export const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#CF63CE",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#894381",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ADB5BD",
      },
      "&:hover fieldset": {
        borderColor: "#CF63CE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#CF63CE",
      },
    },
  },
})(TextField);

export const CssFormControlField = withStyles({
  root: {
    "& .MuiFormLabel-root.Mui-focused": {
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0",
      marginRight: "0",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ADB5BD",
      },
      "&:hover fieldset": {
        borderColor: "#CF63CE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#CF63CE",
      },
    },
  },
})(FormControl);

export const CssCheckBoxField = withStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: "15px auto 15px auto",
  },
})(FormControl);

export const CssInputLabel = withStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: "15px auto 15px auto",
  },
})(InputLabel);

export const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-input": {
      background: "white",
      borderRadius: "4px",
    },
    display: "flex",
    flexWrap: "wrap",
    margin: "14.5px auto 7.5px auto",
  },
  margin: {
    margin: "15px auto 15px auto",
    width: "100%",
  },
  label: {
    margin: "15px auto 15px auto",
    width: "100%",
  },
}));

export const useRadioStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: "15px auto 15px auto",
    width: "100%",
  },
  checkGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    margin: "15px auto 15px auto",
    width: "100%",
  },
  check1: {
    border: "1px solid #adb5bd",
    borderRadius: "4px",
    margin: "0 10px 0 0",
  },
  check2: {
    width: "16px",
    height: "16px",
    border: "1px solid #b16db3",
    borderRadius: "4px",
    background: "url('/mini-tick.svg') center no-repeat #b16db3",
    backgroundSize: "10px",
  },
  label: {},
}));

export const chekboxStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "unset",
    margin: "15px auto 15px auto",
  },
  check1: {
    margin: "0 0 0 10px",
    border: "1px solid #adb5bd",
    borderRadius: "4px",
  },
  check2: {
    width: "16px",
    height: "16px",
    border: "1px solid #b16db3",
    borderRadius: "4px",
    background: "url('/mini-tick.svg') center no-repeat #b16db3",
    backgroundSize: "10px",
  },
}));

export const PurpleCheckbox = withStyles({
  root: {
    color: "#AE5CA4",
    '&$checked': {
      color: "#AE5CA4",
    },
  },
  checked: {},
})(Checkbox);