import React from 'react'
import styles from "../../../../styles/patientStyles/Survey.module.css";

import Qualification from "../qualification";
import QuestionLastInstillation from "./questionLastInstillation";

const Question01Eva = props => {
    return (
        <div>
            <p className={styles.question}>1.1. Califique de 0 a 10 su nivel de dolor segúnla Escala Visual Análoga</p>
            <div className={styles.containerQualification}>
                <Qualification {...props}/>

            </div>
        </div>
    )
}

export default Question01Eva;