import React, { useState, useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import { useHistory } from "react-router-dom";
import PageOk from '../../../../components/patientComponents/component/pageOk'
import {
  GreenCheck,
  Text,
} from '../../../../components/patientComponents/styled-components/reset-password/success/styled'
import { callApi, postVerifyEmail } from '../../../../services/patientServices/apiList'

const SuccessReset = () => {
  let history = useHistory();
  const [showOk, setShowOk] = useState(false)
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    let code = params.get('code')

    if (!code) {
      history.push('/patient/login')
      return
    }

    callApi(
      () => postVerifyEmail({ code }),
      (data) => {
        if (!data.result) {
          if (data.error) NotificationManager.warning(data.error)
          history.push('/patient/login')
          return
        }
        setShowOk(true)
      }
    )
  }, [])

  return (
    <PageOk>
      {showOk && <GreenCheck src="/patient/tick-in-box.svg" alt="Green icon check" />}
      <Text logo="/patient/tick-in-box.svg" logoAlt="Green icon check">
        Su correo electrónico ha sido verificado, ya puede acceder a su perfil
        en el aplicativo
      </Text>
    </PageOk>
  )
}

export default SuccessReset
