import React from "react";
import ArticleModalCard from "../../../components/doctorComponents/articleCard/modalCard";

export const Article6 = () => {
  return (
    <div>
      <ArticleModalCard
        article="ARTÍCULO 1"
        articlePath="https://drive.google.com/file/d/1JPU8pDfUnptD29Gr56_4nucUzSnxn44N/preview?usp=sharing"
        articlePathDownload="https://drive.google.com/file/d/1JPU8pDfUnptD29Gr56_4nucUzSnxn44N/view?usp=sharing"
        title="“Guía rápida instilación paciente femenino”"
        module="module-6"
        label="female-patient-instillation-quick-guide"
      />
      <ArticleModalCard
        article="ARTÍCULO 2"
        articlePath="https://drive.google.com/file/d/1xuN6sYNcv1SvFr2swnUuv7NhjQHmIMaa/preview?usp=sharing"
        articlePathDownload="https://drive.google.com/file/d/1xuN6sYNcv1SvFr2swnUuv7NhjQHmIMaa/view?usp=sharing"
        title="“Guía rápida instilación paciente masculino”"
        module="module-6"
        label="male-patient-instillation-quick-guide"
      />
      <ArticleModalCard
        article="ARTÍCULO 3"
        articlePath="https://drive.google.com/file/d/1dkrjNmIXPlta5SVLlJEflzt-Krzaz0vM/preview?usp=sharing"
        articlePathDownload="https://drive.google.com/file/d/1dkrjNmIXPlta5SVLlJEflzt-Krzaz0vM/view?usp=sharing"
        title="“Manual de entrenamiento médico”"
        module="module-6"
        label="medical-training-manual"
      />
      <ArticleModalCard
        article="ARTÍCULO 4"
        articlePath="https://drive.google.com/file/d/1P3d7EC1zBYk6vVVgXmbj5LlYFsQpILia/preview?usp=sharing "
        articlePathDownload="https://drive.google.com/file/d/1P3d7EC1zBYk6vVVgXmbj5LlYFsQpILia/view?usp=sharing "
        title="“Estudio Dr. Lovasz ialuadapter”"
        module="module-6"
        label="study-dr-lovasz-ialuadapter"
      />
    </div>
  );
};
export default Article6;
