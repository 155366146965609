import styled from 'styled-components'

export const HeaderLogo = styled.div`
  padding: 13px 16px;
  background-color: #ffffff;
`

export const Logo = styled.img`
  max-width: 150px;
  width: 100%;
  display: block;
  margin: 0 auto 15px;
`

export const Container = styled.div`
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  padding: 69px 16px 16px;
`

export const Title = styled.h1`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 22px;
  color: #343a40;
  margin: 0 0 15px;
`

export const Text = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #6c757d;
  margin: 0 0 16px;
`

export const Form = styled.form`
  width: 100%;
  display: inline-flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
`

export const ButtonSend = styled.button`
  display: block;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  padding: 10px 16px;
  margin: 45px auto 12px;
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid #b16db3;
  background-color: #b16db3;
  box-sizing: border-box;
  box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
`
