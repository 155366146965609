export const getQuestionTitle = (questionNumber) => {
  switch(questionNumber){
    case 1: return "1. ¿Presenta dolor pélvico y/o ardor al orinar?";
    case 11: return "1.1 Califique de 0 a 10 su nivel de dolor según la Escala Visual Análoga";
    case 2: return "2. ¿Cuántas veces va a orinar durante el día?";
    case 3: return "3. ¿Cuántas veces va a orinar en la noche luego de haber conciliado el sueño?";
    case 4: return "4. ¿Cuándo siente el deseo de orinar debe ir rápidamente o puede aguantar?";
    case 5: return "5. ¿Ha presentado usted fiebre superior a 38 °C, asociado con alguno de los síntomas anteriores?";
    case 6: return "6. ¿Ha presentado usted sangrado en la orina asociado con alguno de los síntomas anteriores?";
    default: return ""
  }
}