import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    info: {}
  }

const patientListSource = createSlice({
  name: "patientListSource",
  initialState,
  reducers: {
    setPatientListSource: (state, action) => {
      state.info = {...action.payload}
    },
  },
});

export const {setPatientListSource} = patientListSource.actions
export const selectPatientListSource = (state) => state.patientListSourceState.info;

export default patientListSource.reducer;
