import React, { useState, useEffect } from "react";
import CountrySwitch from "../../components/components/countrySwitch";
import { getCountry } from "../../services/doctorServices/userService";

const TermsAndConditions = () => {
  const [currentCountry, setCurrentCountry] = useState(null);
  const handleCountryChange = (newCountry) => {
    setCurrentCountry(newCountry);
  };

  useEffect(() => {
    const getcountry = getCountry();

    if (getcountry && Object.keys(getcountry).length !== 0) {
      setCurrentCountry(getcountry);
    } else {
      setCurrentCountry("CO");
    }
  }, []);

  return (
    <div className="container">
      <div className="inner-container">
      <CountrySwitch
          currentCountry={currentCountry}
          onCountryChange={handleCountryChange}
        />
        <p>
          Los presentes términos y condiciones generales (en adelante, los
          <b>“Términos y Condiciones”</b>) rigen la relación entre los
          profesionales de la medicina o carreras afines autorizados para
          prescribir o dispensar medicamentos en{" "}
          {currentCountry == "CO" ? "Colombia" : "Costa Rica"}. que utilicen el
          sitio web Ialuril app y página web www.Ialuril (en adelante, los{" "}
          <b>“Usuarios”</b>) y SerAnest Pharma Ltda. , con domicilio{" "}
          {currentCountry == "CO"
            ? "Bogota calle 134 a No. 19-34 of 203"
            : "CR-CONTENT"}
          , (en adelante, <b>“SerAnest Pharma”</b>).
        </p>
        <p>
          El registro del Usuario en el sitio web Ialuril app- página web (en
          adelante, el <b>“Sitio”</b>) implica la aceptación inmediata y sin
          reserva de los presentes Términos y Condiciones. En consecuencia, el
          Usuario manifiesta haber leído y aceptado en su totalidad los Términos
          y Condiciones; dicha aceptación es indispensable para la utilización
          del Sitio. Si no está de acuerdo con los presentes Términos y
          Condiciones rogamos no utilizar el Sitio.
        </p>

        <p className="subtitle-purple">1) Descripción del Sitio</p>
        <p>
          El Sitio tiene como finalidad que los Usuarios accedan a información
          de carácter médico-científica y técnica sobre el producto Ialuril®
          prefill y sus patologías asociadas como cistitis crónica de diferente
          etiología y síndrome de vejiga dolorosa y futuras indicaciones una vez
          aprobadas y disponibles comercialmente (en adelante, el{" "}
          <b>“Contenido”</b>). SerAnest podrá, a través del Sitio, promocionar
          seminarios, webinars, cursos y otras actividades y/o eventos en los
          cuales el Usuario que quiera participar. En tal caso, SerAnest Pharma
          se reserva la facultad de implementar medidas adicionales para validar
          la identidad de los usuarios.
        </p>

        <p className="subtitle-purple">
          2) Requisitos que deben cumplir los Usuarios
        </p>
        <p>
          <b>2.1.</b> Solo podrán registrarse y/o utilizar el Sitio los
          profesionales de la medicina o carreras afines que, conforme a la
          legislación vigente{" "}
          {currentCountry == "CO" ? "Colombia" : "Costa Rica"}., estén
          habilitados para prescribir o dispensar medicamentos.
        </p>
        <p>
          <b>2.2.</b> La utilización del Sitio implica que el Usuario declara y
          garantiza a SerAnest Pharma que cumple con el requisito previsto en la
          presente cláusula, por lo que el Usuario asume total responsabilidad
          por cualquier daño y/o perjuicio que pudiera ocasionarse a SerAnest
          Pharma y/o a terceros por la falsedad de tal declaración.
        </p>

        <p className="subtitle-purple">3) Requisitos de acceso al Sitio</p>
        <p>
          <b>3.1</b>. Podrán acceder al Sitio los Usuarios que cumplan los
          requisitos descriptos en el punto 2 de estos Términos y Condiciones y
          que hayan completado el proceso de registro.
        </p>
        <p>
          <b>3.2.</b> El Usuario declara, acepta y garantiza que todos los datos
          personales suministrados en el proceso de registro, incluyendo, pero
          sin limitarse a su especialidad y matrícula médica, son verdaderos,
          completos y se encuentran actualizados. En tal sentido, el Usuario se
          compromete a mantener indemne a SerAnest Phrama y libre y exento de
          toda responsabilidad por cualquier sanción y/o reclamo y/o daño y/o
          perjuicio que pudiera suscitarse como consecuencia del incumplimiento
          de la declaración y garantía manifestada precedentemente.{" "}
        </p>
        <p>
          <b>3.3.</b> Al registrarse, el Usuario recibirá un correo electrónico
          con el usuario y contraseña que le permitirán acceder al Sitio. Esta
          información es personal e intransferible, debiendo el Usuario evitar
          que terceras personas puedan acceder a ella, quedando entendido que
          cualquier acción realizada en el Sitio mediante el nombre de usuario y
          contraseña elegidos por el Usuario será imputada a dicho Usuario. Si
          el Usuario advirtiera que su nombre de usuario y contraseña perdieron
          su condición de confidenciales y/o están siendo utilizados por
          terceros, deberá informar en forma inmediata a SerAnest Pharma al
          siguiente correo electrónico: <b>equipovirtual@seranestpharma.com</b>.{" "}
        </p>
        <p>
          <b>3.4.</b> Será responsabilidad del Usuario mantener actualizada su
          información personal.{" "}
        </p>
        <p>
          <b>3.5.</b> SerAnest Pharma utilizará la información suministrada por
          el Usuario exclusivamente con el objeto de permitir al Usuario
          utilizar el Sitio.{" "}
        </p>
        <p>
          <b>3.6.</b> El registro del Usuario y la posterior utilización del
          Sitio es gratuito, lo que implica que no deberá pagar por ello ningún
          arancel o retribución a SerAnest Pharma.
        </p>
        <p>
          <b>3.7.</b> Para poder utilizar el Sitio se requiere conexión a
          Internet. La libre descarga y la gratuidad del acceso no comprenden
          las facilidades de conexión a Internet. En ningún caso SerAnest
          proveerá a los Usuarios la conectividad necesaria para que puedan
          utilizar el Sitio. La conectividad a Internet será por exclusiva
          cuenta y cargo de cada Usuario.{" "}
        </p>
        <p>
          <b>3.8.</b> SerAnest Pharma podrá rechazar cualquier solicitud de
          registro o impedir, en cualquier momento, que un Usuario ingrese al
          Sitio sin que tal decisión deba ser justificada, y sin que ello genere
          derecho alguno en beneficio del Usuario.
        </p>

        <p className="subtitle-purple">4) Información médica</p>
        <p>
          <b>4.1.</b> El Contenido y/o el uso del Sitio está orientado
          exclusivamente a informar a los profesionales de la medicina o
          carreras afines autorizados para prescribir o dispensar medicamentos
          {currentCountry == "CO" ? "Colombia" : "Costa Rica"}. sobre el
          producto ialuril® prefill y futuras indicaciones una vez aprobadas y
          disponibles comercialmente. En ningún caso el Contenido podrá
          interpretarse como la totalidad de la información disponible sobre el
          producto ialuril® prefill como así tampoco debe utilizarse con el fin
          de establecer un diagnóstico o elegir un tratamiento determinado.
        </p>
        <p>
          <b>4.2.</b>
          SerAnest Pharma no realizará ninguna recomendación, explícita o
          implícita, sobre fármacos, técnicas, productos, etc. En caso de ser
          citados, será únicamente con finalidad informativa.{" "}
        </p>
        <p>
          <b>4.3.</b> El Contenido puede referirse directa o indirectamente a
          productos, programas y/o servicios de SerAnest Pharma que no están
          disponibles en todo el territorio{" "}
          {currentCountry == "CO" ? "colombiano" : "costarricense "}. Dichas
          referencias no implican ninguna intención por parte de SerAnest Pharma
          de vender productos, programas y/o servicios. Para más información el
          usuario deberá contactarse con un represente comercial de SerAnest
          Pharma.{" "}
        </p>
        <p>
          <b>4.4.</b> El Contenido se refiere exclusivamente a personas humanas
          y su finalidad es meramente orientativa y no pretende sustituir datos
          con carácter especializado. Por lo tanto, debe utilizarse únicamente
          con fines informativos.
        </p>

        <p className="subtitle-purple">5) Utilización del Sitio</p>
        <p>
          <b>5.1.</b> Los Usuarios deberán utilizar el Sitio de conformidad con
          las disposiciones establecidas en estos Términos y Condiciones; con el
          ordenamiento jurídico al que se encuentren sometidos debido al lugar,
          de las personas, o de la materia de la cual se trate, considerado en
          su conjunto; y según las pautas de conducta impuestas por la moral,
          las buenas costumbres y el debido respeto a los derechos de terceros.
        </p>
        <p>
          <b>5.2.</b> El uso del Sitio está limitado a profesionales de la
          medicina o carreras afines autorizados para prescribir o dispensar
          medicamentos {currentCountry == "CO" ? "Colombia" : "Costa Rica"},
          quedando prohibido el uso para fines comerciales, no pudiendo ser
          revendido a terceros.{" "}
        </p>
        <p>
          <b>5.3.</b> Queda terminante prohibido:
          <b>(i)</b> copiar, modificar, adaptar, traducir, realizar ingeniería
          inversa, descompilar o desensamblar cualquier parte del Contenido y/o
          del Sitio; <b>(ii)</b> hacer uso del Contenido en otro sitio web o
          entorno informático para cualquier propósito sin la autorización
          previa y por escrito de SerAnest Pharma; <b>(iii)</b> reproducir y/o
          comunicar por cualquier medio el contenido, dado que el acceso se
          otorga en forma personal y para uso exclusivo del Usuario; <b>(iv)</b>{" "}
          interferir o interrumpir el funcionamiento del Sitio; <b>(v)</b>{" "}
          vender, licenciar o explotar el Contenido y/o cualquier tipo de acceso
          y/o uso del Sitio;
          <b></b>(vi) utilizar el Sitio con fines ilícitos o inmorales; e{" "}
          <b>(vii)</b>
          infringir de cualquier modo los presentes Términos y Condiciones.
        </p>

        <p className="subtitle-purple">6) Protección de Datos Personales</p>
        <p>
          <b>6.1.</b> SerAnest Pharma cumple con lo establecido en la Ley 1581
          de 2012 y normativa complementaria (en adelante, la “Normativa de
          Datos Personales”). En particular, SerAnest Pharma se compromete a no
          utilizar y/o divulgar a terceros los datos personales del Usuario
          –salvo mediando su consentimiento o dentro de alguna de las
          excepciones legales para la obtención de dicho consentimiento-, y a
          cumplir con los principios de calidad, finalidad, confidencialidad y
          seguridad de la Normativa de Protección de Datos Personales.{" "}
        </p>
        <p>
          {" "}
          <b>6.2.</b>
          SerAnest Pharma se compromete a adoptar todos los recaudos que sean
          necesarios para garantizar la seguridad de los datos personales del
          Usuario, inclusive la prevención de procesamientos no autorizados o
          ilegítimos, pérdida accidental, destrucción o daños a dichos datos
          personales. Asimismo, SerAnest Pharma se compromete a adoptar todas
          las medidas que sean necesarias para garantizar que toda persona que
          tenga acceso a los datos personales del Usuario les dé el tratamiento
          detallado en esta cláusula.{" "}
        </p>
        <p>
          <b>6.3.</b> Adicionalmente a lo previsto en esta cláusula, SerAnest
          Pharma asume las obligaciones descriptas en la Política de Privacidad
          del Sitio, la que resulta complementaria de estos Términos y
          Condiciones y ha sido puesta en conocimiento del Usuario antes de su
          primer ingreso.
        </p>

        <p className="subtitle-purple">7) Responsabilidad</p>
        <p>
          <b>7.1.</b> SerAnest Pharma en ningún caso será responsable por lucro
          cesante ni por cualquier consecuencia mediata y/o causal, pérdida de
          datos o información, pérdida de chance, daños punitivos ni de
          cualquier otra naturaleza.
        </p>
        <p>
          <b>7.2.</b> SerAnest Pharma no será responsable por ningún mal
          funcionamiento, imposibilidad de acceso o malas condiciones de uso del
          Sitio debido al uso de equipos inadecuados, interrupciones
          relacionadas con proveedores de servicio de internet, la saturación de
          la red de internet y/o por cualquier otra razón.{" "}
        </p>
        <p>
          <b>7.3.</b> SerAnest Pharma no será responsable por la utilización del
          Contenido. El Usuario será el único responsable por las decisiones que
          tome y/o las acciones que realice como resultado del uso del Contenido
          y/o del Sitio.{" "}
        </p>
        <p>
          <b>7.4.</b>
          SerAnest Pharma no garantiza la exactitud, precisión, actualización o
          exhaustividad del Contenido. En consecuencia, SerAnest Pharma no será
          responsable por cualquier imprecisión, inexactitud, falta de
          actualización u omisión relativa al Contenido.
        </p>

        <p className="subtitle-purple">8) Enlaces a otros sitios</p>
        <p>
          En el Sitio podrán encontrarse enlaces a sitios web de terceros. En
          tal caso, Seranest no es responsable del contenido de dichos sitios
          web. Además, la existencia de un vínculo entre el Sitio y cualquier
          sitio de terceros de ninguna manera implica que SerAnest Pharma
          apruebe el contenido de dicho sitio, o, asimismo, cualquier uso que
          dicho contenido pueda tener.
        </p>

        <p className="subtitle-purple">9) Modificaciones del Sitio</p>
        <p>
          SerAnest Pharma podrá introducir todos los cambios y modificaciones
          que estime convenientes al Sitio, lo que incluye, pero no se limita a
          agregar, alterar, sustituir o suprimir cualquier contenido del Sitio
          en todo momento.
        </p>

        <p className="subtitle-purple">10) Propiedad Intelectual</p>
        <p>
          <b>10.1.</b> El Sitio y el Contenido son de titularidad exclusiva de
          SerAnest Pharma. A título meramente enunciativo, se entenderán
          incluidos las imágenes, fotografías, diseños, gráficos, sonidos,
          compilaciones de datos, marcas, nombres, títulos, designaciones,
          signos distintivos, y todo otro material accesible a través del Sitio.
        </p>
        <p>
          <b>10.2.</b> SerAnest se reserva todos los derechos sobre el Sitio y
          el Contenido, no cede ni transfiere a favor del Usuario ningún derecho
          sobre su propiedad intelectual. En consecuencia, su reproducción,
          distribución, y/o modificación deberá ser expresamente autorizada por
          Seranest. Cualquier actividad de recolección automática de información
          del Sitio (incluyendo, pero sin limitarse a web scrapping) será
          considerada una violación a los presentes Términos y Condiciones{" "}
        </p>
        <p>
          <b>10.3.</b> Los derechos de propiedad intelectual respecto de los
          criterios de selección y/o disposición del Contenido en el Sitio
          corresponden exclusivamente a SerAnest, quedando estrictamente
          prohibido al Usuario utilizar los Contenidos, las categorías y/o
          cualquier información del Sitio con otra finalidad distinta a la
          indicada en los presentes Términos y Condiciones.{" "}
        </p>
        <p>
          <b>10.4.</b> En el caso de que el Sitio permita descargar Contenido
          será únicamente para su posterior lectura por el Usuario, Seranest
          otorga al Usuario una licencia de uso temporal, limitada, no
          transferible, no exclusiva y para uso estrictamente personal, que
          estará vigente mientras el Usuario se encuentre registrado en el
          Sitio.
        </p>

        <p className="subtitle-purple">11) Operatividad del Sitio</p>
        <p>
          <b>11.1.</b> SerAnest Pharma no garantiza el pleno acceso y
          operatividad del Sitio en forma ininterrumpida.
        </p>
        <p>
          <b>11.2.</b> SerAnest Pharma podrá suspender el acceso al Sitio y/o a
          determinado contenido por motivos de mantenimiento o de seguridad en
          cualquier momento. La superintendencia de industria y comercio, en su
          carácter de Órgano de Control de la Ley 1581 de 2012 tiene la
          atribución de atender las denuncias y reclamos que interpongan quienes
          resulten afectados en sus derechos por incumplimiento de las normas
          vigentes en materia de protección de datos personales.
        </p>

        <p className="subtitle-purple">12) Notificaciones y comunicaciones</p>
        <p>
          Las notificaciones y comunicaciones cursadas por SerAnest Pharma la
          casilla de correo electrónico que haya provisto el Usuario al momento
          del registro se considerarán eficaces y plenamente válidas. Asimismo,
          se considerarán eficaces las comunicaciones que consistan en avisos y
          mensajes insertos en el Sitio que tengan por finalidad informar al
          Usuario sobre determinada circunstancia.
        </p>

        <p className="subtitle-purple">13) Notificaciones y comunicaciones</p>
        <p>
          <b>13.1.</b> A todos los efectos legales en relación con el Sitio,
          será aplicable la legislación vigente en{" "}
          {currentCountry == "CO" ? "Colombia" : "Costa Rica"}.
        </p>
        <p>
          <b>13.2.</b> Cualquier controversia que se suscite entre las partes
          con relación a estos Términos y Condiciones, su existencia, validez,
          calificación, interpretación, alcance, cumplimiento o resolución, se
          resolverá en primera instancia de manera directa entre las partes. De
          no lograrse un acuerdo entre las mismas, se resolverá de manera
          definitiva y exclusivamente por los Tribunales Ordinarios en los
          Comercial con asiento en la Ciudad de{" "}
          {currentCountry == "CO" ? "Bogotá-Colombia" : "CR-CONTENT"}.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
