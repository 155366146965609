import Footer from "../../../components/doctorComponents/footer";
import HeaderWithMenu from "../../../components/doctorComponents/headerWithMenu";
import PreRegisterBanner from "../../../components/doctorComponents/banners/pre-register-banner";
import { getUserInfo } from "../../../services/doctorServices/authService";
import RegisterHeader from "../../../components/doctorComponents/header/register-header"

const Doctor404 = () => {
  const userlogin = getUserInfo();

  return (
    <>
      {userlogin !== null && <HeaderWithMenu img="/doctor/menu-mobile-pattern.png" />}
      {userlogin === null && <PreRegisterBanner />}
      {userlogin === null &&  <RegisterHeader img="/doctor/bg-mobile-pattern.png" />}

      <div className="container">
        <div className="error-inner-container">
          <div className="div-center">
            <p className="error-title-1">ERROR</p>
            <p className="error-title-2">404</p>
          </div>
          <p>
            Lo sentimos, la página que está buscando no existe en nuestro
            servidor.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Doctor404;
