import styled from 'styled-components'
import { MobileStepper } from '@material-ui/core'

export const StyledMobileStepper = styled(MobileStepper)`
  &.MuiMobileStepper-root {
    padding: 0;
    background: transparent;

    .MuiButton-root {
      color: #b16db3;

      &.Mui-disabled {
        color: #adb5bd;
      }
    }

    .MuiMobileStepper-dots {
      align-items: center;

      .MuiMobileStepper-dot {
        margin: 0 6px;

        ${(props) =>
          props.activeStep >= 0 &&
          `&:nth-child(1) {
            width: 14px;
            height: 14px;
            margin: 0 3px;
            background: url('/mini-tick.svg') center no-repeat #b16db3;
            background-size: 8px;
          }
        `}
        ${(props) =>
          props.activeStep >= 1 &&
          `&:nth-child(2) {
            width: 14px;
            height: 14px;
            margin: 0 3px;
            background: url('/mini-tick.svg') center no-repeat #b16db3;
            background-size: 8px;
          }`}
        ${(props) =>
          props.activeStep >= 2 &&
          `&:nth-child(3) {
            width: 14px;
            height: 14px;
            margin: 0 3px;
            background: url('/mini-tick.svg') center no-repeat #b16db3;
            background-size: 8px;
          }`}
        ${(props) =>
          props.activeStep >= 3 &&
          `&:nth-child(4) {
            width: 14px;
            height: 14px;
            margin: 0 3px;
            background: url('/mini-tick.svg') center no-repeat #b16db3;
            background-size: 8px;
          }`}
        ${(props) =>
          props.activeStep >= 4 &&
          `&:nth-child(5) {
            width: 14px;
            height: 14px;
            margin: 0 3px;
            background: url('/mini-tick.svg') center no-repeat #b16db3;
            background-size: 8px;
          }`}
        ${(props) =>
          props.activeStep >= 5 &&
          `&:nth-child(6) {
            width: 14px;
            height: 14px;
            margin: 0 3px;
            background: url('/mini-tick.svg') center no-repeat #b16db3;
            background-size: 8px;
          }`}
        ${(props) =>
          props.activeStep >= 6 &&
          `&:nth-child(7) {
            width: 14px;
            height: 14px;
            margin: 0 3px;
            background: url('/mini-tick.svg') center no-repeat #b16db3;
            background-size: 8px;
          }`}
        ${(props) =>
          props.activeStep >= 7 &&
          `&:nth-child(8) {
            width: 14px;
            height: 14px;
            margin: 0 3px;
            background: url('/mini-tick.svg') center no-repeat #b16db3;
            background-size: 8px;
          }`}
        ${(props) =>
          props.activeStep >= 8 &&
          `&:nth-child(9) {
            width: 14px;
            height: 14px;
            margin: 0 3px;
            background: url('/mini-tick.svg') center no-repeat #b16db3;
            background-size: 8px;
          }`}
      }
    }
  }
`
