import React, { useEffect } from "react";
import styles from "../../../../styles/patientStyles/components/EducationCard.module.css";
import { getCountry } from "../../../../services/doctorServices/userService";

const EducationCard2 = () => {
  const country = getCountry();

  return (
    <>
      <div className={`${styles.educationCard1}`}>
        <div className={`${styles.imgBox1}`}>
          <img
            className={`${styles.imgEducation2}`}
            src="/patient/edu-art2-01.png"
            alt="Info"
          />
        </div>
        <div className={styles.educationBox2}>
          <p className={styles.whiteText}>
            La instilación intraversical es un procedimiento que{" "}
            <b style={{ color: "#ffffff" }}>
              está incluido en el{" "}
              {country == "CO"
                ? "plan de beneficios en salud (PBS)"
                : "Caja Costarricense de Seguro Social (CCSS)"}{" "}
            </b>{" "}
            al cual usted pertenece
          </p>
        </div>
      </div>

      <div className={`${styles.educationCard1}`}>
        <div className={`${styles.imgBox2}`}>
          <img
            className={`${styles.imgEducation3}`}
            src="/patient/edu-art2-02.png"
            alt="Info"
          />
        </div>
        <div className={styles.educationBox2}>
          <p className={styles.whiteText}>
            <b style={{ color: "#ffffff" }}>
              Se realiza en la{" "}
              {country == 'CO'
                ? "Institución Prestadora de Salud (IPS)"
                : "Caja Costarricense de Seguro Social (CCSS)"}
              asignada para realizar el procedimiento
            </b>{" "}
            al cual usted pertenece
          </p>
        </div>
      </div>

      <div className={`${styles.educationCard1}`}>
        <div className={`${styles.imgBox2}`}>
          <img
            className={`${styles.imgEducation3}`}
            src="/patient/edu-art2-03.png"
            alt="Info"
          />
        </div>
        <div className={styles.educationBox3}>
          <p className={styles.whiteText}>
            <b style={{ color: "#ffffff" }}>
              O en el consultorio de su médico tratante
            </b>{" "}
            si este se realiza de manera particular
          </p>
        </div>
      </div>
    </>
  );
};

export default EducationCard2;
