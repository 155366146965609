import React from "react";

import ArticleCard from "../../../components/doctorComponents/articleCard";

const Article1 = () => {
  return (
    <div>
      <ArticleCard
        article="ARTÍCULO 1"
        title="“Bases fisiopatológicas de la cistitis crónica”"
        link="/doctor/module-1/bases-of-chronic-cystitis"
        module="module-1"
        label="bases-of-chronic-cystitis"
      />
      <ArticleCard
        article="ARTÍCULO 2"
        title="“Cistitis bacteriana”"
        link="/doctor/module-1/bacterial-cystitis"
        module="module-1"
        label="bacterial-cystitis"
      />
      <ArticleCard
        article="ARTÍCULO 3"
        title="“Cistitis intersticial y síndrome de vejiga dolorosa”"
        link="/doctor/module-1/interstitial-cystitis-painful-bladder-syndrome"
        module="module-1"
        label="interstitial-cystitis-painful-bladder-syndrome"
      />
      <ArticleCard
        article="ARTÍCULO 4"
        title="“Cistitis hemorrágica”"
        link="/doctor/module-1/hemorrhagic-cystitis"
        module="module-1"
        label="hemorrhagic-cystitis"
      />
    </div>
  );
};

export default Article1;
