import React from 'react'

import styled from 'styled-components'

import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@material-ui/core'

const CheckboxOutlinedInput = ({ children, ...props }) => {
  const {
    id,
    name,
    label,
    value,
    register,
    registerOptions,
    errors,
    onChange,
    ...remaining
  } = props
  const registerOption =
    registerOptions && registerOptions[name] ? registerOptions[name] : undefined

  let isError = false
  let errorMessage = ''
  if (errors && errors.hasOwnProperty(name)) {
    isError = true
    errorMessage = errors[name].message
  }

  const checkReg = register(name, registerOption)
  return (
    <StyledFormControl component="fieldset" fullWidth={true}>
      <StyledFormGroup row>
        <StyledFormControlLabel
          control={
            <StyledCheckbox
              icon={<StyledCheckboxIcon />}
              checkedIcon={<StyledCheckboxCheckedIcon />}
            />
          }
          key={id}
          id={id}
          name={name}
          value={value}
          onChange={(e) => {
            checkReg.onChange(e)
            if (onChange) onChange(e)
          }}
          onBlur={checkReg.onBlur}
          ref={checkReg.ref}
          error={isError ? 1 : 0}
          {...remaining}
        />
        <StyledFormLabel component="legend">{children}</StyledFormLabel>
      </StyledFormGroup>
      <StyledFormHelperText id={`${name}-helper-text`}>
        {errorMessage && (
          <>
            <ErrorIcon src="/patient/error-icon.svg" alert="Error icon" />
            {errorMessage}
          </>
        )}
      </StyledFormHelperText>
    </StyledFormControl>
  )
}

export default CheckboxOutlinedInput

const StyledFormControl = styled(FormControl)`
    &.MuiFormControl-root {
        margin 5px 0;
    }    
`

const StyledFormGroup = styled(FormGroup)`
  &.MuiFormGroup-root {
    flex-wrap: nowrap;
    align-items: center;
  }
`

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0 12px 0 -5px;
  }
`

const StyledFormLabel = styled(FormLabel)`
  &.MuiFormLabel-root {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #343a40;

    a {
      color: #b16db3;
    }

    &.Mui-focused {
      color: #343a40;
    }
  }
`

const StyledCheckbox = styled(Checkbox)`
  &.MuiIconButton-root {
    &.Mui-checked {
      color: #b16db3;
    }
  }
`

const StyledCheckboxIcon = styled.span`
    width: 16px;
    height: 16px;
    border: 1px solid #ADB5BD;
    border-radius: 4px;
`

const StyledCheckboxCheckedIcon = styled.span`
  width: 16px;
  height: 16px;
  border: 1px solid #b16db3;
  border-radius: 4px;
  background: url('/patient/mini-tick.svg') center no-repeat #b16db3;
  background-size: 10px;
`

const StyledFormHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #d62424;

    &.MuiFormHelperText-contained {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
`

const ErrorIcon = styled.img`
  max-width: 20px;
  width: 100%;
  margin: 4px 5px 0 0;
  line-height: normal;
`
