import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

import styles from "../../../../styles/patientStyles/components/DialogConfirm.module.css" 

const DialogSurveyDateValidation = ({ children, ...props }) => {
  const { dlgOpen, handleRequestClose } = props;
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={dlgOpen}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <div className={styles.container}>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <button className={styles.button} onClick={handleRequestClose}>
            <p className={styles.buttonContent} >OK</p>
          </button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DialogSurveyDateValidation;