import React, { useEffect, useState } from "react";
import PatientResume from "../../../components/doctorComponents/patientResume/index";
import ArticleCardImg from "../../../components/doctorComponents/articleCardImg/index";
import {
  callApi,
  getDashboardInfo,
  getDoctorbyRep,
  GetDashboardInstitutionInfo,
  getPatientToInstill,
} from "../../../services/doctorServices/apiService";
import Progress from "../../../components/doctorComponents/Progress";
import { getCountry, isRole } from "../../../services/doctorServices/userService";
import {
  RoleEnum,
  SurveyChartType,
} from "../../../constants/doctorConstants/enums";
import InstitutionPatientResume from "../../../components/institutionComponents/resumeCard";
import DoctorFile from "../../../components/doctorComponents/doctorFile";
import PatientToInstill from "../../../components/institutionComponents/patientToInstillCard";

const Dashboard = () => {
  const [loader, setLoader] = useState(false);
  const [dashboardInfo, setDashboardInfo] = useState(null);
  const [dashboardRepInfo, setDashboardRepInfo] = useState({});
  const [dashboardInstitutionInfo, setDashboardInstitutionInfo] = useState({});
  const [SummaryInstitutionInfo, setSummaryInstitutionInfo] = useState(null);
  const [patientToInstill, setPatientToInstill] = useState([]);

  const isRepresentative = isRole(RoleEnum.representative);
  const isInstitution = isRole(RoleEnum.institution);
  const isDoctor = isRole(RoleEnum.doctor);

  useEffect(() => {
    if (isDoctor || isRepresentative) {
      callApi(
        getDashboardInfo,
        (data) => {
          if (data.patientCount === 0) return;
          setDashboardInfo(data);
        },
        setLoader
      );
    }
    if (isRepresentative) {
      callApi(getDoctorbyRep, (data) => {
        setDashboardRepInfo(data);
      });
    }
    if (isInstitution) {
      callApi(GetDashboardInstitutionInfo, (data) => {
        setDashboardInstitutionInfo(data);
      });
      callApi(getPatientToInstill, (data) => {
        setPatientToInstill(data);
      });
    }
  }, []);

  const country = getCountry()

  return (
    <div className="container">
      <div className="inner-container">
        {(dashboardInfo || isRepresentative) && (
          <PatientResume
            dashboardInfo={dashboardInfo}
            repDashboardInfo={dashboardRepInfo}
            patients={dashboardInfo ? dashboardInfo.patientCount : 0}
            polls={dashboardInfo ? dashboardInfo.surveyCount : 0}
          />
        )}
        {isInstitution && (
          <>
            <InstitutionPatientResume
              patients={
                dashboardInstitutionInfo ? dashboardInstitutionInfo.quantity : 0
              }
              polls={
                dashboardInstitutionInfo ? dashboardInstitutionInfo.surveys : 0
              }
              epsQuatity={
                dashboardInstitutionInfo
                  ? dashboardInstitutionInfo.epsQuantity
                  : 0
              }
            />
            <PatientToInstill patients={patientToInstill} />
            <DoctorFile type={SurveyChartType.institutionInfo} />
          </>
        )}

        <div>
          <p className="title">Información científica</p>
          <ArticleCardImg
            img="/doctor/mod1-preview.png"
            module="MODULO 1"
            article="Artículos: 4"
            title="“Bases fisiopatológicas de la cistitis crónica”"
            link="/doctor/module-1"
          />
          <ArticleCardImg
            img="/doctor/mod2-preview.png"
            module="MODULO 2"
            article="Artículos: 3"
            title="“Evaluación diagnóstica”"
            link="/doctor/module-2"
          />
          <ArticleCardImg
            img="/doctor/mod3-preview.png"
            module="MODULO 3"
            article="Artículos: 3"
            title="“Alternativas de tratamiento”"
            link="/doctor/module-3"
          />
          <ArticleCardImg
            img="/doctor/mod4-preview.png"
            module="MODULO 4"
            article="Artículos: 2"
            title="“ialuril ® Prefill: La nueva era en cistitis crónica y dolor pélvico asociado”"
            link="/doctor/module-4"
          />
          <ArticleCardImg
            img="/doctor/nuevos-art-preview.png"
            module="MODULO 5"
            article="Artículos: 3"
            title="Nuevos Articulos"
            link="/doctor/module-5"
          />
          <ArticleCardImg
            img="/doctor/articulos-ialuadapter-preview.png"
            module="MODULO 6"
            article="Artículos: 4"
            title="Material Ialuadapter"
            link="/doctor/module-6"
          />
        </div>
        <div>
          <p className="title">Videos</p>
          <ArticleCardImg
            img="/doctor/videos-ialuril-preview.png"
            module="VIDEOS"
            article="Contenido: 2"
            title="Videos ialuril ® Prefill"
            link="/doctor/videos"
          />
          <ArticleCardImg
            img="/doctor/videos-ialuadapter-preview.png"
            module="VIDEOS"
            article="Contenido: 4"
            title="Videos Ialuadapter"
            link="/doctor/videos-Ialuadapter"
          />
        </div>
        <div>
          <p className="title">Pacientes</p>
          <ArticleCardImg
            img="/doctor/info-relevante-preview.png"
            module="PACIENTES"
            article="Contenido: 3"
            title="Información relevante"
            link="/doctor/patient-education"
          />
          <ArticleCardImg
            img="/doctor/recomendaciones-preview.png"
            module="PACIENTES"
            article="Contenido: 3"
            title="Recomendaciones generales"
            link="/doctor/patient-recommendations"
          />
        </div>
      </div>
      <Progress loader={loader} />
    </div>
  );
};

export default Dashboard;
