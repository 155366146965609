import React from 'react'
import { useHistory } from "react-router-dom";
import styles from '../../styles/patientStyles/Errors.module.css'
import HeaderWithMenu from '../../components/patientComponents/component/headerWithMenu/index'

const Patient404 = () => {
  const history = useHistory();

  const onClick = () => {
    history.push('/patient')
  }

  return (
    <div className={styles.errorContainer}>
      <HeaderWithMenu />
      <img className={`${styles.img}`} src="/patient/404.png" alt="404" />
      <p className={styles.text}>
        Lo sentimos, la página que está buscando no existe en nuestro servidor
      </p>
      <button onClick={onClick} className={styles.button}>
        <p className={styles.buttonContent}> Ir al inicio</p>
      </button>
    </div>
  )
}

export default Patient404
