import React, { useState } from 'react'
import styled from 'styled-components'
import 'dayjs/locale/es'
import DateFnsUtils from '@date-io/dayjs'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { FormHelperText } from '@material-ui/core'

const DateOutlinedInput = ({
  name,
  label,
  register,
  registerOptions,
  errors,
  onChange,
  ...remaining
}) => {
  const [selectedDate, setSelectedDate] = useState(null)

  const registerOption =
    registerOptions && registerOptions[name] ? registerOptions[name] : undefined

  let isError = false
  let errorMessage = ''
  if (errors && errors.hasOwnProperty(name)) {
    isError = true
    errorMessage = errors[name].message
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const handleInvalidDefault = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <MuiPickersUtilsProvider locale="es" utils={DateFnsUtils}>
      <StyledKeyboardDatePicker
        autoOk
        {...register(name, registerOption)}
        name={name}
        inputVariant="outlined"
        label={label}
        error={isError}
        format="DD/MM/YYYY"
        value={selectedDate}
        InputAdornmentProps={{ position: 'end' }}
        onChange={(date) => {
          handleDateChange(date)
          if (onChange) onChange(date)
        }}
        onInvalid={handleInvalidDefault}
        keyboardIcon={<CalendarIcon src="/patient/calendar.svg" alt="Calendar icon" />}
        {...remaining}
      />
      <StyledFormHelperText id={`${name}-helper-text`}>
        {errorMessage && (
          <>
            <ErrorIcon src="/patient/error-icon.svg" alert="Error icon" />
            {errorMessage}
          </>
        )}
      </StyledFormHelperText>
    </MuiPickersUtilsProvider>
  )
}

export default DateOutlinedInput

// Overwriting with styled component

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  &.MuiFormControl-root {
    width: 100%;

    .MuiFormLabel-root {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      color: #6c757d;
      transform: translate(10px, 11px) scale(1);

      &.MuiInputLabel-shrink {
        transform: translate(10px, -17px) scale(0.75);
      }

      .MuiFormLabel-asterisk {
        color: red;
      }
    }

    .MuiOutlinedInput-root {
      padding-right: 0;

      .MuiOutlinedInput-input {
        padding: 11px 10px 10px;
      }

      .MuiInputAdornment-root {
        .MuiIconButton-root {
          right: 10px;
          padding: 8px 12px;
        }
      }

      &.Mui-focused fieldset {
        border: 1px solid #b16db3;
      }

      fieldset legend {
        width: 0;
      }
    }

    .MuiFormHelperText-root {
      display: none;
    }
  }
`

const StyledFormHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #d62424;

    &.MuiFormHelperText-contained {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
`

const CalendarIcon = styled.img`
  max-width: 24px;
  width: 100%;
`

const ErrorIcon = styled.img`
  max-width: 20px;  
  width: 100%;
  margin: 4px 5px 0 0;
  line-height: normal;
`
