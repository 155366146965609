import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import styles from "../../../../styles/patientStyles/components/DialogConfirm.module.css";
import SelectOutlinedInput from "../../component/form/outlined-inputs/select";
import {
  callApi,
  adminInitializeCycle,
} from "../../../../services/patientServices/apiList";

const InitializeCycle = ({ cycleId, instillationsList , callBack, callBack2 }) => {
  const [open, setOpen] = useState(false);

  const getNroInstillations = () => {
    const options = [4, 5, 6, 7, 8, 9, 10, 11, 12];
    return options.map((x) => ({ id: x, name: x + "" }));
  };
  const getNroCycles = () => {
    const options = ["Ciclo 1", "Ciclo 2", "Ciclo 3", "Ciclo 4", "Ciclo 5", "Ciclo 6", "Ciclo 7", "Ciclo 8", "Ciclo 9"];
    return options.map((x) => ({ id: x, name: x + "" }));
  };

  const defaultValues = {
    nroInstillations: null,
  };
  const methods = useForm({ defaultValues });
  const { register, handleSubmit, formState, watch, setValue, trigger } =
    methods;
  const { errors } = formState;

  const registerOptions = {
    nroInstillations: { required: "seleccione una opción" },
    nroInstillationsMaintenance: { required: "seleccione una opción" },
  };

  const onClose = () => {
    setValue("nroInstillations", null);
    setOpen(false);
  };

  const anySurveyFill = (list) => {
    var a = list.find((x) => x.state >= 2);
    const result = a == undefined;
    const result2 = cycleId > 0;
    return result && result2;
  };

  const onSubmit = handleSubmit((data) => {
    var cycle = {
      Id: cycleId,
      Name: data.nroCycle,
      Number: data.nroInstillations,
    };
    callApi(() => adminInitializeCycle(cycle),
    () => {
      onClose(); 
      callBack();
      callBack2();
    });
  });

  return (
    <>
      {anySurveyFill(instillationsList) && (
        <>
              <div>
                <h3>
                  Seleccione el número de ciclo en que se encuentra y número de instilaciones para el ciclo
                </h3>
                <FormProvider {...methods}>

                <SelectOutlinedInput
                    name="nroCycle"
                    label="En que ciclo se encuentra"
                    placeholder="Seleccione en que ciclo se encuentra"
                    register={register}
                    registerOptions={registerOptions}
                    options={getNroCycles()}
                    required
                    errors={errors}
                    value={watch("nroCycle") ?? ""}
                  />

                  <SelectOutlinedInput
                    name="nroInstillations"
                    label="Número de instilaciones formuladas"
                    placeholder="Seleccione número de instilaciones formuladas"
                    register={register}
                    registerOptions={registerOptions}
                    options={getNroInstillations()}
                    required
                    errors={errors}
                    value={watch("nroInstillations") ?? ""}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className={"div-horizontal"}
                  >
                    <div className={styles.buttonBox}>
                      <button onClick={onClose} className={styles.buttonSmallW}>
                        <p className={styles.buttonContentW}>Cancelar</p>
                      </button>
                      <button onClick={onSubmit} className={styles.buttonSmall}>
                        <p className={styles.buttonContent}>Confirmar</p>
                      </button>
                    </div>
                  </div>
                </FormProvider>
              </div>
        </>
      )}
    </>
  );
};

export default InitializeCycle;
