import React from "react";

import AccordionComponent from "../../../../components/doctorComponents/accordion/accordion-1";
import AccordionBibliography from "../../../../components/doctorComponents/accordion/accordion-2";
import CustomTable from "../../../../components/doctorComponents/customTable";
import { TableHeadStyle, TableBodyStyle } from "../../../../constants/doctorConstants/enums";
import { HeaderTable2, BodyTable2 } from "./tables/tables-module-2";

const InterstitialCystitis = () => {
  return (
    <div className="container">
      <div className="inner-container">
        <p>
          La cistitis intersticial y el síndrome de vejiga dolorosa (IC/BPS) es
          una condición debilitante que afecta notoriamente la calidad de vida.
          <sup>
            <b>1</b>
          </sup>
          El diagnóstico comprende el dolor pélvico crónico mayor a 6 semanas en
          ausencia de un factor etiológico claramente establecido y es válidonpm
          aclarar que aunque historicamente se denomina cistitis intersticial,
          se considera que éste término es inapropiado ya que no hay evidencia
          clara de que la inflamación de la vejiga (cistitis) esté involucrada
          en la etiología o fisiopatología de la afección, ni hay evidencia de
          que la afección esté asociada de manera confiable con anomalías del
          intersticio de la vejiga. Esta afección a menudo coexiste con otros
          síndromes de dolor crónico (fibromialgia, síndrome del intestino
          irritable, endometriosis).
        </p>

        <AccordionComponent article="interstitial-cystitis" title="EPIDEMIOLOGÍA">
          <div>
            <p>
              Este síndrome clínico es poco frecuente, aunque es probable que
              los estudios subestimen la verdadera prevalencia.{" "}
              <b>
                Es más común en mujeres que en hombres y se diagnostica con
                mayor frecuencia en la cuarta década o más tarde
              </b>
              .
              <sup>
                <b>2,3</b>
              </sup>{" "}
              Los criterios diagnósticos de IC / BPS han variado, lo que ha
              dificultado el establecimiento de la prevalencia.
            </p>
            <ul>
              <li>
                {" "}
                Las encuestas de autoinforme estiman una prevalencia de
                aproximadamente 850 por 100.000 mujeres y 60 por 100.000
                hombres.
                <sup>
                  <b>2</b>
                </sup>
              </li>
              <li>
                {" "}
                Las estimaciones de prevalencia basadas en datos de facturación
                médica son 197 por 100.000 mujeres y 41 por 100.000 hombres.
                <sup>
                  <b>4</b>
                </sup>
              </li>
              <li>
                {" "}
                El estudio RAND Interstitial Cystitis Epidemiology (RICE)
                utilizó un cuestionario de síntomas de CI / BPS y encuestó a
                mujeres en aproximadamente 150.000 hogares de Estados Unidos.
                <sup>
                  <b>6</b>
                </sup>
                Llegaron a la conclusión que entre el 2,7 y el 6,5 % de las
                mujeres estadounidenses tienen síntomas compatibles con un
                diagnóstico de CI / BPS.
              </li>
            </ul>
          </div>
        </AccordionComponent>

        <AccordionComponent article="interstitial-cystitis" title="DIAGNÓSTICO CLÍNICO">
          <div>
            <p>
              Por definición, el paciente con IC/BPS tiene dolor pélvico que se
              exacerba con el llenamiento vesical y alivia al evacuar la misma.
              La tabla 1 muestra la distribución de los síntomas cuando se hace
              el diagnóstico.
            </p>

            <br />
            <p className="title-gray-2">
              CLINICAL SYMPTOMS AT THE DIAGNOSIS OF INTERSTITIAL
              CYSTITIS/BLADDER PAIN SYNDROME
            </p>

            <CustomTable
              bodystyle={TableBodyStyle.interspersed}
              headstyle={TableHeadStyle.purple}
              columns={HeaderTable2}
              rows={BodyTable2}
            />

            <p className="italics-gray">
              Tabla 1. Distribución de síntomas clinicos en el diagnóstico de
              IC/BPS
            </p>

            <p>
              El dolor tiene diferentes matices que pueden incluir la sensación
              de presión, malestar o espasmos.
              <sup>
                <b>5</b>
              </sup>{" "}
              Estos síntomas suelen ser constantes, pero pueden variar de un día
              a otro y su gravedad varía de leve a grave. La localización de los
              síntomas suele describirse como suprapúbica o uretral, aunque se
              ha observado dolor abdominal bajo unilateral o lumbalgia con
              llenado de la vejiga.
              <sup>
                <b>6,7</b>
              </sup>
            </p>

            <p>
              Los síntomas suelen ser de aparición gradual y empeoran durante un
              período de meses. Sin embargo, algunos pacientes describen
              síntomas que son abruptos o severos desde su inicio. Aunque la
              mayoría de los pacientes no pueden identificar un evento
              desencadenante, algunos pacientes han desarrollado síntomas
              después de una infección del tracto urinario o un procedimiento
              quirúrgico sin complicaciones, o después de un traumatismo, como
              una caída sobre el cóccix. Pueden existir factores desencadenantes
              como la ingesta de ciertos alimentos y bebidas, el estrés o
              algunas actividades como el ejercicio, las relaciones sexuales o
              estar sentado durante mucho tiempo y también durante la fase lútea
              del ciclo menstrual.
              <sup>
                <b>8,9</b>
              </sup>
            </p>

            <p>
              El incremento en la frecuencia urinaria, a diferencia de los
              pacientes con vejiga hiperactiva que orinan con frecuencia para
              evitar la incontinencia urinaria, los pacientes con IC / BPS
              orinan con frecuencia para mantener bajos los volúmenes de la
              vejiga y evitar molestias.
              <sup>
                <b>10</b>
              </sup>{" "}
              Pueden aparecer síntomas urinarios adicionales, como urgencia
              miccional y no la incontinencia urinaria.
              <sup>
                <b>11</b>
              </sup>
            </p>

            <p>
              Un tema no menos importante es la afectación en la calidad de
              vida, en el entorno familiar y laboral como consecuencia de este
              síndrome doloroso
              <sup>
                <b>12</b>
              </sup>{" "}
              <b>
                pueden co-existir otras condiciones clínico-patológicas
                asociadas
              </b>
              , especialmente relacionadas con el dolor crónico (síndrome del
              intestino irritable, vulvodinia, fibromialgia),
              <sup>
                <b>13</b>
              </sup>{" "}
              la disfunción sexual y las comorbilidades psicosociales.
            </p>

            <p>
              Cuando se sospecha de IC / BPS, el objetivo principal de la
              evaluación diagnóstica es <b>excluir otras afecciones</b> y la
              exclusión de la malignidad es una prioridad principal en la
              evaluación de pacientes con cistitis intersticial y el dolor de
              vejiga.
            </p>

            <p>
              Un enfoque de diagnóstico se presenta en el siguiente algoritmo:
            </p>

            <div className="imgBox" style={{ alignItems: "center" }}>
              <div
                className="container-horizontal-img"
                style={{ width: "auto" }}
              >
                <img className="img" src="/doctor/Group 7.svg" alt="Img" />
              </div>
            </div>

            <p>
              Los antecedentes de IVU recurrente, traumatismo pélvico previo,
              cirugía pélvica, radiación o trastornos neurológicos que pueda
              afectar la función de la vejiga deben ser indicación para realizar
              pruebas especializadas como <b>cistoscopia o urodinamia</b>. La
              hematuria, incontinencia urinaria severa y dolor pélvico
              generalizado que no empeora con el llenado de la vejiga ni se
              alivia después de orinar son condiciones que también deben ser
              tenidas en cuenta para realizar estudios urológicos
              complementarios.
            </p>

            <p>
              En los pacientes con incremento de la frecuencia urinaria se debe
              evaluar si están tratando de evitar la incontinencia urinaria como
              síntoma de vejiga hiperactiva o efectivamente tratan de evitar el
              dolor al vaciar la vejiga, como sucede típicamente en la IC/BPS.
              Se recomienda un <b>registro del diario miccional</b> para
              confirmar el patrón miccional de bajo volumen ({"<"}300 ml) que es
              característico de IC / BPS, al mismo tiempo que se evalúan otros
              posibles síntomas, como una ingesta anormalmente alta de líquidos
              o una producción excesiva de orina durante la noche (figura 1).
            </p>

            <div className="imgBox">
              <img className="img" src="/doctor/Table-3.svg" alt="Img" />
            </div>
            <p className="italics-gray">
              Figura 1. Formulario de registro del diario miccional
            </p>

            <p>
              Existen escalas de validación de síntomas para evaluar la gravedad
              de los síntomas de IC / BPS y para monitorear el progreso clínico
              después del diagnóstico y terapia establecida; sin embargo, estas
              escalas no son útiles en el diagnóstico diferencial de IC / BPS.
              Ejemplos de estas escalas son:
            </p>

            <ul>
              <li>
                {" "}
                Índice de síntomas y problemas de CI <b>(ICSI, ICPI)</b>.
                <sup>
                  <b>14</b>
                </sup>
              </li>
              <li>
                {" "}
                Índice de dolor genitourinario.
                <sup>
                  <b>15</b>
                </sup>
              </li>
              <li>
                {" "}
                Escala de síntomas de dolor pélvico, urgencia y frecuencia
                <b>(PUF)</b>.
                <sup>
                  <b>16</b>
                </sup>
              </li>
              <li>
                {" "}
                Escala visual análoga <b>(EVA)</b>.
              </li>
            </ul>

            <p>
              Al examen clínico existe una sensibilidad variable de la región
              pélvica, incluyendo la pared abdominal, la cintura y el piso
              pélvico, como también la base de la vejiga y la uretra. Es
              probable que esto se deba a la sensibilización de las fibras
              nerviosas aferentes en los dermatomas asociados con la vejiga
              (toracolumbar y sacra). En los hombres, puede haber sensibilidad
              penoescrotal y todos los pacientes pueden experimentar alodinia
              (percepción de estímulos no nocivos, como el tacto ligero, como
              nocivos o dolorosos) como ocurre con otros pacientes con dolor
              crónico.
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="interstitial-cystitis" title="PARACLÍNICOS">
          <div>
            <p>
              El paciente típico de IC / BPS tendrá un <b>uroanálisis</b> sin
              alteraciones y un urocultivo estéril, aunque algunos pueden tener
              un urocultivo positivo ocasional debido a la frecuencia de
              bacteriuria en la población general.
            </p>
            <p>
              <b>
                Se debe realizar un <b>uroanálisis</b>
              </b>{" "}
              con microscopía en todos los pacientes con sospecha de IC / BPS
              para descartar una infección y evaluar la hematuria. También se
              justifica el análisis de orina repetido si se desarrollan nuevos
              signos o síntomas que sugieran una infección del tracto urinario
              superpuesta. <b>Se realiza urocultivo</b> si los resultados del
              análisis de orina sugieren una infección del tracto urinario.
            </p>
            <p>
              Si hay hematuria, se justifica un estudio adicional que incluya
              una cistoscopia para excluir una neoplasia maligna del tracto
              urinario u otra patología. Debe valorar el{" "}
              <b>residuo post-miccional</b>, mediante ecografía o con el uso de
              un catéter. La retención urinaria podría sugerir una obstrucción
              de la salida de la vejiga o una disfunción neurológica como causas
              alternativas de dolor crónico de la vejiga y que amerite un
              estudio urodinámico.
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="interstitial-cystitis" title="CISTOSCOPIA">
          <div>
            <p>
              No se requiere cistoscopia para hacer el diagnóstico de IC / BPS.
              Sin embargo, se puede realizar para excluir otras patologías, ya
              sea en la presentación inicial o en pacientes que no responden al
              tratamiento con medicamentos orales. Se debe considerar la
              <b> cistoscopia y / o la urodinamia</b> cuando el diagnóstico sea
              dudoso; estas pruebas no son necesarias para realizar el
              diagnóstico en presentaciones no complicadas. Opinión de expertos,
              AUA.
              <sup>
                <b>17</b>
              </sup>
            </p>
            <p>
              La cistoscopia es apropiada para muchos pacientes que tienen
              anomalías urológicas en la historia o el examen, como
              incontinencia o residuo posmiccional elevado. Además, los
              pacientes con sospecha de IC / BPS que tienen hematuria en la
              presentación inicial o antecedentes de cáncer de vejiga, incluso
              en ausencia de otras anomalías urológicas, deben someterse a una
              cistoscopia y otras pruebas según sea necesario para excluir una
              neoplasia maligna urotelial. Se debe realizar una biopsia de
              vejiga si hay hallazgos que sugieran malignidad. Hay pocos datos
              disponibles con respecto a la tasa de malignidad del tracto
              urinario en pacientes con CI / BPS y hematuria, y es probable que
              el riesgo sea similar al de la población general (12,7 % de los
              pacientes con hematuria en un estudio de una clínica de
              hematuria).
              <sup>
                <b>18,19</b>
              </sup>{" "}
              Además, en pacientes que no han respondido al tratamiento inicial
              con IC / BPS, el examen cistoscópico se puede utilizar para
              identificar causas alternativas de síntomas, como un cuerpo
              extraño intravesical o una lesión estructural (Cálculos en la
              vejiga, erosión de la malla quirúrgica).
            </p>
            <p>
              En caso de realizar la cistoscopia por la duda diagnóstica,
              existen algunas lesiones que pueden apoyar el diagnóstico y se
              evidencian endoscópicamente o en patología, al tomar una biopsia
              de estas lesiones:{" "}
            </p>

            <ul>
              <li>
                {" "}
                <b className="bold-purple">Lesiones de Hunner:</b> (lesiones
                enrojecidas en la mucosa de la vejiga con depósitos de fibrina
                adheridos; típicamente sangran después de la hidrodistensión).
              </li>
              <li>
                {" "}
                <b className="bold-purple">Mayor número de mastocitos:</b> en el
                examen histológico de muestras de biopsia de vejiga. Para
                identificar las lesiones de Hunner, se realiza una cistoscopia
                con visualización directa antes y después de la hidrodistensión
                y se toman biopsias de las lesiones sospechosas. Las lesiones de
                Hunner se identifican sólo en el 5 al 10 % de los pacientes; sin
                embargo, son muy específicos y, para los pacientes que los
                padecen, la fulguración puede ayudar a aliviar los síntomas.
              </li>
              <li>
                {" "}
                <b className="bold-purple">Las glomerulaciones:</b> (áreas rojas
                petequiales observadas después de la hidrodistensión) también se
                describen en pacientes con CI / BPS, pero tienen un valor
                diagnóstico limitado, ya que son un hallazgo inespecífico (por
                ejemplo, un estudio encontró glomerulaciones en el 45% de los
                pacientes sanos
                <sup>
                  <b>20</b>
                </sup>{" "}
                y su presencia no se correlaciona bien con los síntomas.
                <sup>
                  <b>21</b>
                </sup>
              </li>
            </ul>

            <p>
              Algunos médicos utilizan los hallazgos cistoscópicos para
              clasificar a los pacientes en subgrupos:
            </p>

            <p>
              <b className="bold-purple"> 1) Forma clásica</b>: se aprecian en
              la cistoscopia áreas vasculares mucosas que se rompen con la
              sobredistensión.
            </p>
            <p>
              <b className="bold-purple">2) Forma no ulcerativa</b>:
              típicamente, muestra petequias glomeruloides a la sobredistensión.
              Este subgrupo corresponde a la CI precoz.
            </p>
            <p>
              Sin embargo, no hay evidencia de que la subclasificación de
              pacientes con IC / BPS proporcione información significativa sobre
              el pronóstico o la respuesta al tratamiento.
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="interstitial-cystitis" title="PRUEBAS NO RECOMENDADAS RUTINARIAMENTE">
          <div>
            <p>
              <b className="bold-purple">
                1) Prueba de sensibilidad al potasio
              </b>
              : algunos investigadores han propuesto la prueba de sensibilidad
              al potasio como útil para diagnosticar IC / BPS,
              <sup>
                <b>22</b>
              </sup>{" "}
              pero no se recomienda para uso rutinario ya que sus resultados no
              son específicos para IC / BPS.
              <sup>
                <b>23</b>
              </sup>{" "}
              Además, la prueba puede ser extremadamente dolorosa para los
              pacientes y no hay evidencia consistente de que los resultados
              proporcionen información adicional para guiar el tratamiento.
            </p>
            <p>
              <b className="bold-purple">2) Instilación de lidocaína</b>: la
              instilación de lidocaína en la vejiga se ha descrito como un
              "desafío vesical anestésico". Con esta prueba, la mejoría de los
              síntomas después de la instilación de lidocaína se considera
              sugestiva de la presencia de IC / BPS. Sin embargo, al igual que
              con la prueba de sensibilidad al potasio, faltan datos para
              correlacionar estos resultados con la respuesta al tratamiento.
            </p>
            <p>
              <b className="bold-purple">3) Urodinamia</b>: las pruebas
              urodinámicas tienen un papel limitado en el diagnóstico de IC /
              BPS. No existen criterios urodinámicos estándar para el
              diagnóstico de la afección y las pruebas pueden causar una gran
              incomodidad al paciente. Sin embargo, en ciertos pacientes en los
              que existe incertidumbre clínica sobre el diagnóstico, puede ser
              importante en el diagnóstico diferencial de vejiga hiperactiva y
              pueden ayudar a guiar la terapia en pacientes con síntomas de
              dolor de vejiga, que también tienen incontinencia urinaria o
              evidencia de un mal vaciado de la vejiga.
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="interstitial-cystitis" title="DIAGNÓSTICO DIFERENCIAL">
          <div>
            <p>Incluye otras afecciones que provocan dolor pélvico crónico: </p>
            <ul>
              <li> Cáncer de vejiga o uretra.</li>
              <li> Cáncer del tracto genital.</li>
              <li> Infección urinaria o por transmisión sexual.</li>
              <li>
                {" "}
                Anomalías pélvicas benignas: las masas pélvicas benignas
                (leiomiomas uterinos y el prolapso de órganos pélvicos).
              </li>
              <li>
                {" "}
                Patología intravesical: cálculo en la vejiga u otro objeto
                extraño.
              </li>
              <li> Divertículo uretral.</li>
              <li> Disfunción vesical neurogénica.</li>
              <li>
                {" "}
                Síndromes de dolor pélvico crónico de diversa etiología y órgano
                específico.
              </li>
            </ul>
          </div>
        </AccordionComponent>

        <AccordionBibliography title="BIBLIOGRAFIA">
          <div>
            <p className="bibliography-font">
              {" "}
              <b>1.</b> Konkle KS, Berry SH, Elliott MN, et al. Comparison of an
              interstitial cystitis/bladder pain syndrome clinical cohort with
              symptomatic community women from the RAND Interstitial Cystitis
              Epidemiology study. J Urol 2012; 187:508.
            </p>
            <p className="bibliography-font">
              <b>2.</b> Clemens JQ, Joyce GF, Wise M, Payne C. Interstitial
              Cystitis and Painful Bladder Syndrome. In: Urologic Diseases in
              America. US Department of Health and Human Services, Public Health
              Service, National Institutes of Health, National Institute of
              Diabetes and Digestive and Kidney Diseases, Litwin MS, Saigal CS
              (Eds), Washington, DC 2007. p.123.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>3.</b> Hanno PM, Burks DA, Clemens JQ, et al. AUA guideline for
              the diagnosis and treatment of interstitial cystitis/bladder pain
              syndrome. J Urol 2011; 185:2162.
            </p>
            <p className="bibliography-font">
              <b>4.</b> Clemens JQ, Meenan RT, Rosetti MC, et al. Prevalence and
              incidence of interstitial cystitis in a managed care population. J
              Urol 2005; 173:98.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>5.</b> Teichman JM, Parsons CL. Contemporary clinical
              presentation of interstitial cystitis. Urology 2007; 69:41.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>6.</b> FitzGerald MP, Brensinger C, Brubaker L, et al. What is
              the pain of interstitial cystitis like? Int Urogynecol J Pelvic
              Floor Dysfunct 2006; 17:69.
            </p>
            <p className="bibliography-font">
              <b>7.</b> Koziol JA. Epidemiology of interstitial cystitis. Urol
              Clin North Am 1994; 21:7.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>8.</b> Friedlander JI, Shorter B, Moldwin RM. Diet and its role
              in interstitial cystitis/bladder pain syndrome (IC/BPS) and
              comorbid conditions. BJU Int 2012; 109:1584.
            </p>
            <p className="bibliography-font">
              <b>9.</b> Powell-Boone T, Ness TJ, Cannon R, et al. Menstrual
              cycle affects bladder pain sensation in subjects with interstitial
              cystitis. J Urol 2005; 174:1832.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>10.</b> Tsai CF, Ouyang WC, Tsai SJ, et al. Risk factors for
              poor sleep quality among patients with interstitial cystitis in
              Taiwan. Neurourol Urodyn 2010; 29:568.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>11.</b> Lai HH, Vetter J, Jain S, et al. The overlap and
              distinction of self-reported symptoms between interstitial
              cystitis/bladder pain syndrome and overactive bladder: a
              questionnaire based analysis. J Urol 2014; 192:1679.
            </p>
            <p className="bibliography-font">
              <b>12.</b> Koziol JA. Epidemiology of interstitial cystitis. Urol
              Clin North Am 1994; 21:7.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>13.</b> Clemens JQ, Meenan RT, O'Keeffe Rosetti MC, et al.
              Case-control study of medical comorbidities in women with
              interstitial cystitis. J Urol 2008; 179:2222.
            </p>
            <p className="bibliography-font">
              <b>14.</b> O'Leary MP, Sant GR, Fowler FJ Jr, et al. The
              interstitial cystitis symptom index and problem index. Urology
              1997; 49:58.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>15.</b> Clemens JQ, Calhoun EA, Litwin MS, et al. Validation of
              a modified National Institutes of Health chronic prostatitis
              symptom index to assess genitourinary pain in both men and women.
              Urology 2009; 74:983.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>16.</b> Parsons CL, Dell J, Stanford EJ..Increased prevalence
              of intersticial cystitis: previously unrecognized urologic and
              gynecology cases identified using a new symptom questionnaire and
              intravesical potassium sensitivity. Urology, 60 (2002), pp. 573-8.
            </p>
            <p className="bibliography-font">
              <b>17.</b> American Urological Association , AUA, : Guidelines
              Diagnosis and Treatment Interstitial Cystitis/Bladder Pain
              Syndrome (2014).
            </p>
            <p className="bibliography-font">
              {" "}
              <b>18.</b> Gomes CM, Sánchez-Ortiz RF, Harris C, et al.
              Significance of hematuria in patients with interstitial cystitis:
              review of radiographic and endoscopic findings. Urology 2001;
              57:262.
            </p>
            <p className="bibliography-font">
              <b>19.</b> Khadra MH, Pickard RS, Charlton M, et al. A prospective
              analysis of 1,930 patients with hematuria to evaluate current
              diagnostic practice. J Urol 2000; 163:524.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>20.</b> Parsons CL, Greenberger M, Gabal L, et al. The role of
              urinary potassium in the pathogenesis and diagnosis of
              interstitial cystitis. J Urol 1998; 159:1862.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>21.</b> Tomaszewski JE, Landis JR, Russack V, et al. Biopsy
              features are associated with primary symptoms in interstitial
              cystitis: results from the interstitial cystitis database study.
              Urology 2001; 57:67.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>22.</b> Parsons CL. Argument for the use of the potassium
              sensitivity test in the diagnosis of interstitial cystitis. For.
              Int Urogynecol J Pelvic Floor Dysfunct 2005; 16:430.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>20.</b> Melzack, R., et al. Central neuroplasticity and
              pathological pain. Ann N Y Acad Sci, 2001. 933: 157.
            </p>
          </div>
        </AccordionBibliography>
      </div>
    </div>
  );
};

export default InterstitialCystitis;
