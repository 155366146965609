import React, { useEffect, useState } from 'react'
import * as dayjs from 'dayjs'
import { NotificationManager } from 'react-notifications'
import { Grid, Switch } from '@material-ui/core'
import {
  InstillationTypeEnum,
  InstillationStateEnum,
} from '../../../../constants/patienteConstants/enums'
import styles from '../../../../styles/patientStyles/components/Instillation.module.css'
import DateOutlinedInput from '../form/outlined-inputs/dateWithoutRegister'
import DialogSurveyDateValidation from "../instillations/dialogSurveyDateValidation";
import {
  callApi,
  getSurveyAnswers,
} from "../../../../services/patientServices/apiList";

const getDate = (date) => dayjs(dayjs(date).format('YYYY-MM-DD'))

const isMaintenance = (x) => x.type === InstillationTypeEnum.maintenance

const isPreInstillation = (x) => x.type === InstillationTypeEnum.preInstillation

const InstillationItem = (props) => {
  const {
    instillation,
    onDateClick,
    onSurvey,
    switchOnChange,
    anyPlanned,
    idSwitched,
    currentId,
    resetId,
    daysMax
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [dlgResumeOpen, setDlgResumeOpen] = useState(false);
  const [switchValue, setSwitchValue] = useState(false)
  const [selectedDate, handleDateChange] = useState(dayjs())
  const [surveyDetails, setSurveyDetails] = useState({});

  const optionsQ2 = [
    "0 - 4 veces",
    "5 - 9 veces",
    "10 - 14 veces",
    "Más de 15 veces",
  ];

  const optionsQ3 = ["Ninguna", "Una vez", "2 veces", "Más de 3 veces"];

  const optionsQ4 = [
    "Siempre puedo aguantar",
    "A veces puedo aguantar",
    "Nunca puedo aguantar",
  ];

  const loadAnswers = (i) => {
    callApi(
      () => getSurveyAnswers(i),
      (data) => {
        setSurveyDetails(data);
      }
    );
  };

  const isActive = (x) => {
    if (
      x.type === InstillationTypeEnum.regular &&
      (x.id === idSwitched || x.id === currentId)
    ) {
      return true
    }

    if (
      x.type === InstillationTypeEnum.regular &&
      x.state === InstillationStateEnum.Executed
    ) {
      return true
    }
    
    if (
      x.type === InstillationTypeEnum.preInstillation &&
      x.state != InstillationStateEnum.Indeterminate
    ) {
      return true
    }

    if (
      x.type === InstillationTypeEnum.regular &&
      x.state === InstillationStateEnum.Planned &&
      x.planningDate &&
      getDate(x.planningDate) < getDate().add(daysMax, 'day')
    ) {
      return true
    }

    return false
  }

  const isDiseable = (x) => {
    if (
      x.type === InstillationTypeEnum.regular &&
      (x.id < idSwitched || x.state === InstillationStateEnum.Indeterminate)
    ) {
      return true
    }
    if (
      x.type === InstillationTypeEnum.preInstillation && x.state === InstillationStateEnum.Indeterminate
    ) {
      return true
    }
    return false
  }

  const getStyleItem = (x) => {
    if (isMaintenance(x)) return 'maintenance'

    if (isDiseable(x)) return 'disabled'

    if (isActive(x)) return 'active'
    return ''
  }

  const getSurveyColor = (instillation) => {
    if (instillation.state === InstillationStateEnum.Executed)
      return '/patient/instillationTableGreen.svg'

    return '/patient/instillationTable.svg'
  }

  const getDetailsBtn = (instillation) => {
    if (instillation.state === InstillationStateEnum.Executed) return "";

    return "none";
  };

  const setDate = () => {
    if (instillation && instillation.planningDate) {
      handleDateChange(dayjs(instillation.planningDate))
      return
    }
    handleDateChange(dayjs())
  }

  useEffect(() => setDate(), [instillation])
  useEffect(() => {
    if (isOpen) setDate()
  }, [isOpen])

  useEffect(() => {
    if (!resetId) return
    if (instillation.id === resetId) setSwitchValue(false)
  }, [resetId])

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={'list-group-item' + ' ' + getStyleItem(instillation)}
    >
      <div className={styles.nameContainer}>
        {!isMaintenance(instillation) && (
          <div className="icon">
            <img src="/patient/instillationIcon.svg" alt="Icon" />
          </div>
        )}

        <p className={'instillationName'}>{instillation.name}</p>
      </div>

      <div className={styles.buttonContainer}>
        {((anyPlanned && isActive(instillation) && !isDiseable(instillation) && !isPreInstillation(instillation)) ||
          isMaintenance(instillation)) && (
          <div
            className={'instillationCalendar'}
            onClick={() => {
              setDate()
              if (instillation.state === InstillationStateEnum.Executed) {
                NotificationManager.warning('La encuesta ya fue diligenciada')
                return
              }
              setIsOpen(true)
            }}
          >
            <img src="/patient/instillationCalendar.svg" alt="Icon" />
          </div>
        )}
        {anyPlanned &&
          !isDiseable(instillation) &&
          !isActive(instillation) &&
          !isPreInstillation(instillation) &&
          !isMaintenance(instillation) && (
            <div
              className={'instillationCalendar2'}
              onClick={() => {
                setDate()
                if (instillation.state === InstillationStateEnum.Executed) {
                  NotificationManager.warning('La encuesta ya fue diligenciada')
                  return
                }
                setIsOpen(true)
              }}
            >
              <img src="/patient/instillationCalendar.svg" alt="Icon" />
            </div>
          )}
        {((anyPlanned && !isDiseable(instillation) && isActive(instillation) && !isPreInstillation(instillation)) ||
          isMaintenance(instillation)) && (
          <div
            className={'instillationTable'}
            onClick={() => onSurvey(instillation)}
          >
            <img src={getSurveyColor(instillation)} alt="Icon" />
          </div>
        )}
        {(isPreInstillation(instillation) && 
          !isDiseable(instillation) && 
          isActive(instillation)
          ) && (
          <div
            className={'instillationTable'}
            onClick={() => onSurvey(instillation)}
          >
            <img src={getSurveyColor(instillation)} alt="Icon" />
          </div>
        )}
          {((anyPlanned && !isDiseable(instillation) && isActive(instillation)) ||
          isMaintenance(instillation)) && (
          <div
            className={"instillationTable"}
            onClick={() => {
              setDlgResumeOpen(true);
              loadAnswers(instillation.id);
            }}
            style={{ display: getDetailsBtn(instillation) }}
          >
            <img src={"/patient/password-eye.svg"} alt="Icon" />
          </div>
        )}
      </div>
      {!anyPlanned && (
        <Switch
          onChange={switchOnChange(instillation, setSwitchValue)}
          checked={switchValue}
        />
      )}
      <div>
        <DateOutlinedInput
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          value={selectedDate}
          onChange={(e) => {
            handleDateChange(e)
            onDateClick(instillation, e)
          }}
          TextFieldComponent={() => null}
        />
      </div>

      <DialogSurveyDateValidation
        dlgOpen={dlgResumeOpen}
        handleRequestClose={() => setDlgResumeOpen(false)}
      >
        <p className="surveyDetails">
          1. ¿Presenta dolor pélvico y/o ardor al orinar?
        </p>
        <p>{surveyDetails.question1 ? "SI" : "NO"}</p>

        <p className="surveyDetails">
          2. ¿Cuántas veces va a orinar durante el día?
        </p>
        <p>{optionsQ2[surveyDetails.question2]}</p>

        <p className="surveyDetails">
          3. ¿Cuántas veces va a orinar en la noche luego de haber conciliado el
          sueño?
        </p>
        <p>{optionsQ3[surveyDetails.question3]}</p>

        <p className="surveyDetails">
          4. ¿Cuándo siente el deseo de orinar debe ir rápidamente o puede
          aguantar?
        </p>
        <p>{optionsQ4[surveyDetails.question4]}</p>

        <p className="surveyDetails">
          5. ¿Ha presentado usted fiebre superior a 38 °C, asociado con alguno
          de los síntomas anteriores?
        </p>
        <p>{surveyDetails.question5 ? "SI" : "NO"}</p>

        <p className="surveyDetails">
          6. ¿Ha presentado usted sangrado en la orina asociado con alguno de
          los síntomas anteriores?
        </p>
        <p>{surveyDetails.question6 ? "SI" : "NO"}</p>
        <p>{surveyDetails.eigthSymptoms}</p>
      </DialogSurveyDateValidation>
    </Grid>
  )
}

export default InstillationItem
