import React from "react";
import styles from "../../../../styles/patientStyles/Survey.module.css";
import { InstillationRegularAdvance } from "../../../../constants/patienteConstants/enums";
import { isRole } from "../../../../services/doctorServices/userService";
import { RoleEnum } from "../../../../constants/doctorConstants/enums";
import { InstillationTypeEnum } from "../../../../constants/patienteConstants/enums";

const QuestionEnd = (props) => {
  const isInstitution = isRole(RoleEnum.institution);

  const { fnEndSurvey, survey } = props;
  const handleClick = () => {
    fnEndSurvey();
  };

  if(survey.instillationType === InstillationTypeEnum.preInstillation){
    return (
      <div className={`${styles.container}`}>
        <img
          className={`${styles.img}`}
          src="/patient/questionEnd.svg"
          alt="500"
        />
        <p className={styles.text}>
          La encuesta de la Pre Instilación ha sido registrada exitosamente. Recuerde diligenciar las
          encuentas de las instilaciones de todo el ciclo con <b>ialuril ® Prefill</b>
        </p>
        <button className={styles.buttonS} onClick={handleClick}>
          <p className={styles.buttonContent}>OK</p>
        </button>
      </div>
    );
  }

  return (
    <div className={`${styles.container}`}>
      <img
        className={`${styles.img}`}
        src="/patient/questionEnd.svg"
        alt="500"
      />
      {!survey.cycleAdvance && (isInstitution ? (
        <p>
          Muy bien! Ha completado la mitad de su tratamiento y cada vez falta
          menos para una completa mejoría. Recuerde diligenciar la encuesta de 
          esta instilación con <b>ialuril ® Prefill</b> la próxima vez que vea al paciente
        </p>
      ) : (
        <p className={styles.text}>
          Su encuesta ha sido registrada exitosamente. Recuerde diligenciar la
          evolución de sus síntomas en la próxima instilación (3 - 5 días)
          después del procedimiento de instilación con <b>ialuril ® Prefill</b>
        </p>
      ))}
      {survey.cycleAdvance === InstillationRegularAdvance.first &&
      (isInstitution ? (
        <>
          <p className={styles.text}>
            Hemos registrado su primera encuesta! Nos complace acompañarle
            durante su tratamiento y esperamos su pronta recuperación.
          </p>
          <br></br>
          <p className={styles.text}>
            Recuerde diligenciar la encuesta de esta instilación con <b>ialuril ® Prefill</b>
            la próxima vez que vea al paciente
          </p>
        </>
      ) : (
        <>
          <p className={styles.text}>
            Hemos registrado su primera encuesta! Nos complace acompañarle
            durante su tratamiento y esperamos su pronta recuperación.
          </p>
          <br></br>
          <p className={styles.text}>
            Recuerde diligenciar las próximas encuestas 3 - 5 días después del
            procedimiento de instilación con <b>ialuril ® Prefill</b>
          </p>
        </>
      ))}
      {survey.cycleAdvance === InstillationRegularAdvance.half &&
      (isInstitution ? (
        <>
          <p className={styles.text}>
            Muy bien! Ha completado la mitad de su tratamiento y cada vez falta
            menos para una completa mejoría.
          </p>
          <br></br>
          <p className={styles.text}>
            Recuerde diligenciar la encuesta de esta instilación con <b>ialuril ® Prefill</b>
            la próxima vez que vea al paciente
          </p>
        </>
      ) : (
        <>
          <p className={styles.text}>
            Muy bien! Ha completado la mitad de su tratamiento y cada vez falta
            menos para una completa mejoría.
          </p>
          <br></br>
          <p className={styles.text}>
            Recuerde diligenciar las próximas encuestas 3 - 5 días después del
            procedimiento de instilación con <b>ialuril ® Prefill</b>
          </p>
        </>
      ))}
      {survey.cycleAdvance === InstillationRegularAdvance.last && (
        <>
          <p className={styles.text}>
            Felicidades! Ha completado la totalidad de su tratamiento de
            instilaciones con <b>ialuril ® Prefill</b>.
          </p>
          <br></br>
          <p className={styles.text}>
            Deseamos que disfrute plenamente de su salud, muchas gracias por
            confiar en nosotros.
          </p>
        </>
      )}
      <button className={styles.buttonS} onClick={handleClick}>
        <p className={styles.buttonContent}>OK</p>
      </button>
    </div>
  );
};

export default QuestionEnd;
