// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDjvw2SpYoUwDwurwklNI81_gOVyA5tH1I",
  authDomain: "ialurilapp-6a867.firebaseapp.com",
  projectId: "ialurilapp-6a867",
  storageBucket: "ialurilapp-6a867.appspot.com",
  messagingSenderId: "259776784084",
  appId: "1:259776784084:web:c92a0cf07708b03a08bd41",
  measurementId: "G-XK2PKJT86D"
};

export const config = {
  vapidKey:
    "BA-PIdAJW780jJWpbhNJ3DxOWkAC2THN07UIjQ2r2AV2YjRKxfKGpNdn0P9VcIVxp5yaXDxiOd6xlc9u8RNcVfo",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const messaging = getMessaging(app);