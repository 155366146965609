import React, { useState, useEffect } from "react";
import SearchLabel from "../../../components/doctorComponents/form/search/searchLabel";
import PatientFileCard from "../../../components/doctorComponents/patientFileCard";
import {
  callApi,
  getPatientInstitutionFromEpsList,
} from "../../../services/doctorServices/apiService";
import { NotificationManager } from "react-notifications";
import Progress from "../../../components/doctorComponents/Progress";
import { isRole } from "../../../services/doctorServices/userService";
import {
  RoleEnum,
} from "../../../constants/doctorConstants/enums";
import { useSelector } from "react-redux";
import {selectPatientListSource} from "../../../redux/patientListSource";


const filterList = (list, searchText, currentFilerStatus) => {
  let result = [...list];
  if (currentFilerStatus && currentFilerStatus > 1) {
    result = result.filter(
      (x) =>
        (currentFilerStatus === 2 && x.registered === true) ||
        (currentFilerStatus === 3 && x.registered === false)
    );
  }
  if (searchText) {
    const text = searchText.toUpperCase();
    result = result.filter(
      (x) => x.fullName && x.fullName.toUpperCase().includes(text)
    );
  }

  return result;
};

const GeneralPatientList = () => {
  const patientListSource = useSelector(selectPatientListSource);
  const [loader, setLoader] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [currentDoctor, setCurrentDoctor] = useState(null);
  const isInstitution = isRole(RoleEnum.institution);
  const [currentFilerStatus, setCurrentFilerStatus] = useState(null);
  useEffect(() => {
    if (isInstitution) {
      var filter = {
        ...patientListSource,
      };
      callApi(
        () => getPatientInstitutionFromEpsList(filter),
        (data) => {
          if (data && data.length === 0) {
            NotificationManager.warning("No tiene pacientes asociados");
            return;
          }
          setPatientList(data);
        },
        setLoader
      );
      return;
    }
  }, []);

  return (
    <div className="container">
      <div className="inner-container">
        {patientList && patientList.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="file-info" style={{ width: "345px" }}>
                <SearchLabel
                  placeholder="Buscar paciente"
                  setSearchText={setSearchText}
                />
              </div>
            </div>
          </>
        )}
        {filterList(patientList, searchText, currentFilerStatus).map(
          (item, index) => {
            return (
              <PatientFileCard
                key={index}
                item={item}
                name={item.fullName}
                cicle={item.currentCycle}
                instillation={item.currentInstillation ?? ""}
                link="/doctor/eps-list/eps-details/patient-list/patient-details"
              />
            );
          }
        )}
      </div>
      <Progress loader={loader} />
    </div>
  );
};

export default GeneralPatientList;
