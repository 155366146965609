import React, { useState } from "react";
import { useStyles } from "../../../../styles/DoctorStyles/ui/search";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

const SearchLabel = ({ placeholder, setSearchText }) => {
  const classes = useStyles();
  const [inputText, setInputText] = useState("");

  const enter_key = (e) => {
    if (e.keyCode == 13) {
      setSearchText(inputText);
    }
  };
  window.onkeydown = enter_key;

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        value={inputText}
        onChange={(p) => {
          setInputText(p.target.value);
        }}
        inputProps={{ "aria-label": "search google maps" }}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        onClick={() => {
          setSearchText(inputText);
        }}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchLabel;
