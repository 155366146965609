import React from "react";
import PatientBox from "../../../components/doctorComponents/patientBox";

const Education = () => {
  return (
    <div>
      <div className="box-education-container">
        <div className="box-education-inner-container">
          <PatientBox
            img="/doctor/educacion-1.png"
            title="¿Qué es una instilación intravesical?"
            href="/doctor/patient-education/what-is-an-intravesical-instillation"
          />
          <PatientBox
            img="/doctor/educacion-2.png"
            title="Este procedimiento ¿quién lo cubre?"
            href="/doctor/patient-education/who-covers-this-procedure"
          />
          <PatientBox
            img="/doctor/educacion-3.png"
            title="Documentos que debe tener en cuenta, cuando vaya a realizar su procedimiento"
            href="/doctor/patient-education/documentation-for-the-procedure"
          />
        </div>
      </div>
    </div>
  );
};

export default Education;
