import React from "react";
import { getCountry } from "../../../services/doctorServices/userService";

const RecommendationCard1 = () => {
  const country = getCountry();

  return (
    <>
      <div className="recommendationBox1">
        <div className="recommendationImgBox">
          <div className="numberBox">
            <p className="number">
              <b className="whiteBold">1</b>
            </p>
          </div>
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art1-01.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className="colorText">
            Antes de realizar su instilación intravesical{" "}
            <b className="b">comunique a su médico</b> tratante o profesional de
            la salud que va a realizar el procedimiento,{" "}
            <b className="b">
              cualquiera de los siguientes signos y/o síntomas:
            </b>
          </p>
          <div className="list">
            <p className="colorText">
              <b className="b">-ardor</b>
              <br />
              <b className="b">-mal olor de la orina</b>
              <br />
              <b className="b">-dolor</b>
            </p>
            <p className="colorText">
              <b className="b">-sangre en la orina</b>
              <br />
              <b className="b">-fiebre</b>
              <br />
              <b className="b">-dificultad para orinar</b>
            </p>
          </div>
        </div>
      </div>

      <div className="recommendationBox2">
        <div className="recommendationImgBox2">
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art1-02.png"
              alt="Info"
            />
          </div>
          <div className="numberBox2">
            <p className="number2">
              <b style={{ color: "#b06db4" }}>2</b>
            </p>
          </div>
        </div>
        <div>
          <p className="whiteText">
            Para mayor facilidad <b className="whiteBold">presentarse</b> al
            procedimiento <b className="whiteBold">con ropa cómoda</b>
          </p>
        </div>
      </div>

      <div className="recommendationBox5">
        <div className="recommendationImgBox">
          <div className="numberBox">
            <p className="number">
              <b className="whiteBold">3</b>
            </p>
          </div>
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art1-03.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className="colorText">
            En caso de que <b className="b">no pueda asistir</b> a su
            procedimiento, deberá{" "}
            <b className="b">
              comunicarse con su {country == "CO" ? "I.P.S" : "C.C.S.S"}{" "}
            </b>{" "}
            a la mayor brevedad posible{" "}
            <b className="b">para la cancelación y/o reprogramacion</b> de la
            cita
          </p>
        </div>
      </div>

      <div className="recommendationBox4">
        <div className="recommendationImgBox2">
          <div className="imgBox2">
            <img
              className="imgRecomendation1"
              src="/doctor/recomendaciones-art1-04.png"
              alt="Info"
            />
          </div>
          <div className="numberBox2">
            <p className="number2">
              <b style={{ color: "#b06db4" }}>4</b>
            </p>
          </div>
        </div>
        <div>
          <p className="whiteText">
            Si su{" "}
            {country == 'CO'
              ? "Institución Prestadora de Salud (IPS)"
              : "Caja Costarricense de Seguro Social (CCSS)"}{" "}
            entregó su producto mantener las siguientes{" "}
            <b className="whiteBold">recomendaciones de almacenamiento:</b>
          </p>
          <div className="list">
            <p className="whiteText">
              <b className="whiteBold">
                -No conservar a temperatura superior a 25°
              </b>
              <br />
              <b className="whiteBold">
                -Mantener en un sitio aireado, no expuesto a la luz directa
                solar ni a fuente de calor.
              </b>
              <br />
              <b className="whiteBold">
                -No congelar ni mantener dentro de la nevera.
              </b>
              <br />
              <b className="whiteBold">
                -Mantenerlo en un sitio limpio, no húmedo, y alejando de
                animales.
              </b>
              <br />
              <b className="whiteBold">
                -No abrir la caja ni manipular su uso, solo para personal de
                salud.
              </b>
              <br />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecommendationCard1;
