import React, { useState, useEffect } from 'react'
import styles from '../../../../styles/patientStyles/components/CircularProgress.module.css'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

const CircleProgress = ({ progress }) => {
  //const [progress, setProgress] = useState(0)

  // const hadleClick = () => {
  //     setProgress(progress + 10)
  // }

  const useStyle = makeStyles({
    progressCircle: {
      color: '#B16DB3',
      width: '100px !important',
      height: '100px !important',
      value: { progress },
      position: 'relative',
    },
    circle: {
      width: '100px !important',
      height: '100px !important',
    },
  })

  const classes = useStyle()

  return (
    <div className={styles.circularProgress}>
      <div className={styles.progressContainer}>
        <div className={styles.off}>
          <CircularProgress
            className={classes.progressCircle}
            variant="determinate"
            value={progress}
          />
        </div>
        <div className={styles.progressContainerOFF}></div>
        <div className={styles.insideCircle}>
          <p className={styles.porcentage}>{`${progress}%`}</p>
        </div>
      </div>
      <p className={styles.completed}>Completado</p>
      {/* <button onClick={hadleClick}>
                click
            </button> */}
    </div>
  )
}

export default CircleProgress
