import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useForm, FormProvider } from "react-hook-form";
import { usePasswordValidation } from "../../../hook/doctorHook/usePasswordValidation";

import PasswordFilledInput from "../../../components/doctorComponents/form/filled-inputs/password";
import ConfirmPasswordFilledInput from "../../../components/doctorComponents/form/filled-inputs/confirmPassword";
import Button1 from "../../../components/doctorComponents/buttons/button-1";
import {
  callApi,
  getCodeIsValid,
  postResetPassword,
} from "../../../services/doctorServices/apiService";

const RecoverPasswordNew = () => {
  let history = useHistory();

  const [code, setCode] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    firstPassword: "",
    secondPassword: "",
  });

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");

    if (!code) {
      history.push("/doctor/login");
      return;
    }

    callApi(
      () => getCodeIsValid(code),
      (data) => {
        if (!data.result) {
          if (data.error) NotificationManager.warning(data.error);
          history.push("/doctor/login");
        }
        setCode(code);
      }
    );
  }, []);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { register, handleSubmit, formState, watch } = methods;
  const { errors } = formState;

  const [validLength, hasNumber, match, hasLetter] = usePasswordValidation({
    firstPassword: passwordValidation.firstPassword,
    secondPassword: passwordValidation.secondPassword,
  });

  const password = useRef({});
  password.current = watch("password", "");

  const registerOptions = {
    password: {
      required: "Ingrese su contraseña",
      validate: {
        validLength: () => (validLength ? true : false),
        hasNumber: () => (hasNumber ? true : false),
        hasLetter: () => (hasLetter ? true : false),
      },
    },
  };

  const setFirst = (event) => {
    setPasswordValidation({
      ...passwordValidation,
      firstPassword: event.target.value,
    });
  };
  const setSecond = (event) => {
    setPasswordValidation({
      ...passwordValidation,
      secondPassword: event.target.value,
    });
  };

  const onSubmit = handleSubmit((data) => {
    callApi(
      () =>
        postResetPassword({
          code,
          password: data.password,
        }),
      (data) => {
        if (!data.result) {
          if (data.error) NotificationManager.warning(data.error);
          history.push("/doctor/login");
        } else {
          history.push("/doctor/recover-password-success");
        }
      }
    );
  });

  return (
    <>
      <div className="container">
        <div className="inner-container">
          <div className="div-center">
            <img
              className="ialurilLogoWhite3"
              src="/doctor/logo-white-menu.svg"
              alt="IalurilLogoWhite"
            />
          </div>

          <p className="white-title">Restablecer contraseña</p>

          <p className="white-font-1">
            Registre y confirme la nueva contraseña para ingresar a su cuenta
          </p>
          <FormProvider {...methods}>
            <form noValidate autoComplete="off">
              <PasswordFilledInput
                name="password"
                label="Nueva Contraseña"
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                required={true}
                onChange={setFirst}
              />

              <ul className="validation-list">
                <li className="validation-item-list">
                  <img
                    src={
                      !validLength
                        ? "/doctor/cross-red.svg"
                        : "/doctor/tick-green.svg"
                    }
                    alt="tic"
                  />
                  Que sea entre 6 y 16 caracteres
                </li>
                <li className="validation-item-list">
                  <img
                    src={
                      !hasLetter
                        ? "/doctor/cross-red.svg"
                        : "/doctor/tick-green.svg"
                    }
                    alt="tic"
                  />
                  Incluya al menos una letra
                </li>
                <li className="validation-item-list">
                  <img
                    src={
                      !hasNumber
                        ? "/doctor/cross-red.svg"
                        : "/doctor/tick-green.svg"
                    }
                    alt="tic"
                  />
                  Incluya al menos un número
                </li>
                <li className="validation-item-list">
                  <img
                    src={
                      !match
                        ? "/doctor/cross-red.svg"
                        : "/doctor/tick-green.svg"
                    }
                    alt="tic"
                  />
                  Las contraseñas deben ser iguales
                </li>
              </ul>

              <ConfirmPasswordFilledInput
                name="password_repeat"
                label="Confirmar nueva contraseña"
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                required={true}
                onChange={setSecond}
              />
            </form>
          </FormProvider>
          <div className="div-center">
            <Button1 label="Cambiar contraseña" onClick={onSubmit} />
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoverPasswordNew;
