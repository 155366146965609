import React, { useState, useRef } from 'react'
import {
  Form,
  ValidationList,
  ValidationItemList,
  IconStateValidation,
  MarginSeparator,
} from '../../../components/patientComponents/styled-components/register/styled'
import { usePasswordValidation } from '../../../hook/patientHook/usePasswordValidation'
import { useForm } from 'react-hook-form'
import { callApi, postChangePassword } from '../../../services/patientServices/apiList'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import PasswordOutlinedInput from '../../../components/patientComponents/component/form/outlined-inputs/password'
import ConfirmPasswordOutlinedInput from '../../../components/patientComponents/component/form/outlined-inputs/confirmPassword'
import styles from '../../../styles/patientStyles/Profile.module.css'
import { NotificationManager } from 'react-notifications'

const NewPassword = () => {
  const [open, setOpen] = useState(false)
  const [passwordValidation, setPasswordValidation] = useState({
    firstPassword: '',
    secondPassword: '',
  })

  const defaultValues = {}
  const methods = useForm({ defaultValues })
  const { register, handleSubmit, formState, watch, reset, clearErrors } =
    methods
  const { errors } = formState

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] =
    usePasswordValidation({
      firstPassword: passwordValidation.firstPassword,
      secondPassword: passwordValidation.secondPassword,
    })

  const password = useRef({})
  password.current = watch('newPassword')

  const registerOptions = {
    currentPassword: { required: 'Campo requerido' },
    newPassword: {
      required: 'Campo requerido',
      validate: {
        validLength: () => (validLength ? true : false),
        hasNumber: () => (hasNumber ? true : false),
        upperCase: () => (upperCase ? true : false),
        lowerCase: () => (lowerCase ? true : false),
        specialChar: () => (specialChar ? true : false),
      },
    },
    password_repeat: {
      required: 'Campo requerido',
      validate: (value) =>
        value === password.current || 'Las contraseñas no coinciden',
    },
  }

  const setFirst = (event) => {
    setPasswordValidation({
      ...passwordValidation,
      firstPassword: event.target.value,
    })
  }
  const setSecond = (event) => {
    setPasswordValidation({
      ...passwordValidation,
      secondPassword: event.target.value,
    })
  }

  const onCloseHandle = () => {
    setOpen(false)
    reset({ currentPassword: '', newPassword: '', password_repeat: '' })
    setTimeout(() => clearErrors(), 300)
  }

  const onSubmit = handleSubmit((data) => {
    const password = {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    }

    callApi(
      () => postChangePassword(password),
      () => {
        NotificationManager.success('La operación se realizó correctamente')
        onCloseHandle()
      }
    )
  })

  return (
    <div>
      <p className={styles.text} onClick={() => setOpen(true)}>
        Cambiar contraseña
      </p>

      <Dialog
        open={open}
        keepMounted
        onClose={onCloseHandle}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <p className={styles.title}>Cambiar contraseña</p>
        </DialogTitle>
        <div className={styles.box}>
          <DialogContent>
            <p className={styles.subtitle}>
              Registre y confirme la nueva contraseña para ingresar a su cuenta
            </p>
            <Form onSubmit={onSubmit}>
              <PasswordOutlinedInput
                name="currentPassword"
                label="Contraseña Actual"
                required={true}
                register={register}
                registerOptions={registerOptions}
                errors={errors}
              />

              <PasswordOutlinedInput
                name="newPassword"
                label="Nueva contraseña"
                required={true}
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                onChange={setFirst}
              />

              <ValidationList>
                <ValidationItemList>
                  <IconStateValidation
                    src={!validLength ? '/patient/cross-red.svg' : '/patient/tick-green.svg'}
                  />
                  Que sea entre 8 y 16 caracteres
                </ValidationItemList>
                <ValidationItemList>
                  <IconStateValidation
                    src={!upperCase ? '/patient/cross-red.svg' : '/patient/tick-green.svg'}
                  />
                  Incluya al menos una letra mayúscula
                </ValidationItemList>
                <ValidationItemList>
                  <IconStateValidation
                    src={!lowerCase ? '/patient/cross-red.svg' : '/patient/tick-green.svg'}
                  />
                  Incluya al menos una letra minúscula
                </ValidationItemList>
                <ValidationItemList>
                  <IconStateValidation
                    src={!hasNumber ? '/patient/cross-red.svg' : '/patient/tick-green.svg'}
                  />
                  Incluya al menos un número
                </ValidationItemList>
                <ValidationItemList>
                  <IconStateValidation
                    src={!specialChar ? '/patient/cross-red.svg' : '/patient/tick-green.svg'}
                  />
                  Incluya al menos un carácter especial, por ejemplo ! $ + – ( )
                  @ &lt; &gt;
                </ValidationItemList>
              </ValidationList>

              <MarginSeparator />

              <ConfirmPasswordOutlinedInput
                name="password_repeat"
                label="Confirmar nueva contraseña"
                required={true}
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                passwordToCheck={password.current}
                onChange={setSecond}
              />

              <div className={styles.buttonBox}>
                <button onClick={onCloseHandle} className={styles.buttonSmallW}>
                  <p className={styles.buttonContentW}>Cancelar</p>
                </button>
                <button className={styles.buttonSmall}>
                  <p className={styles.buttonContent}>Confirmar</p>
                </button>
              </div>
            </Form>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  )
}

export default NewPassword
