import React from 'react'
import PageOk from '../../../../components/patientComponents/component/pageOk' 
import {
    EnvelopPink,
    Text,
} from '../../../../components/patientComponents/styled-components/forgot-password/success/styled'

const Success = () => {
    return (
        <PageOk>
            <EnvelopPink src="/patient/envelop-pink.svg" alt="Sobre color rosa" />
            <Text logo="/patient/envelop-pink.svg" logoAlt="Sobre color rosa">
                A su correo electrónico fue enviado el enlace para restablecer
                la contraseña
            </Text>
        </PageOk>
    )
}

export default Success
