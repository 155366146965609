import React from "react";

const CautionCard = (props) => {
  return (
    <div className="caution-card">
      <img className="caution-img" src="/doctor/caution.svg" alt="caution" />
      <div className="caution-text">{props.text}</div>
    </div>
  );
};

export default CautionCard;
