import React from "react";
import styles from "../styles/patientStyles/components/Footer.module.css";
import {
  Container,
  InnerContainer,
  HeaderLogoWhite,
  Logo,
  TitleColor,
  LoginButton,
  RegisterButton,
} from "../components/patientComponents/styled-components/styledWelcome";
import {
  callApi,
  sendNotification,
} from "../../src/services/patientServices/apiList";
import { RoleEnum } from "../../src/constants/doctorConstants/enums";
const MainPage = () => {
  const sendNotificationP = () => {
    console.log("sendNotification");
    callApi(sendNotification, () => {
    });
  };

  return (
    <div>
      <HeaderLogoWhite>
        <InnerContainer>
          <Logo src="/patient/logo-default-menu.svg" alt="ialuriApp Logo" />
          <TitleColor>
            Su aliado en el tratamiento con ialuril ® Prefill
          </TitleColor>
        </InnerContainer>
      </HeaderLogoWhite>

      <Container>
        <InnerContainer>
          <LoginButton>
            <a
              target="_blank"
              href="/patient"
              onClick={() => {
                localStorage.removeItem("role");
                localStorage.setItem("role", RoleEnum.patient);
              }}
            >
              Ingresar a App Pacientes
            </a>
          </LoginButton>

          <RegisterButton>
            <a
              target="_blank"
              href="/doctor"
              onClick={() => {
                localStorage.removeItem("role");
                localStorage.setItem("role", RoleEnum.doctor);
              }}
            >
              Ingresar a App Médicos
            </a>
          </RegisterButton>
          <LoginButton>
            <a
              target="_blank"
              href="/doctor/login?logIns=true"
              onClick={() => {
                localStorage.removeItem("role");
                localStorage.setItem("role", RoleEnum.institution);
              }}
            >
              Ingresar a App Instituciones
            </a>
          </LoginButton>
          {/* <a
              onClick={() => {
                sendNotificationP();
              }}
            >
              Send
            </a> */}
        </InnerContainer>
      </Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "35px 0 0 0",
        }}
      >
        <img
          src="/patient/footer-SerAnest-xxxhdpi.png"
          className={styles.img}
          alt="SerAnestPharmaLogo"
        />
        <img
          style={{ width: "100px", margin: "15px 0 0 0" }}
          src="/patient/logo-ibsa.png"
          alt="ibsa Logo"
        />
      </div>
    </div>
    
  );
};

export default MainPage;
