import React from 'react'
import styles from '../../../styles/patientStyles/components/InfoCard.module.css'

const InfoCard = (props) => {
    return (
        <div className={styles.card}>
            <div>
                <img src={props.img} alt="Sun" />
            </div>
            <div className={styles.infoCard} >
                <p className={styles.title} > {props.title} </p>
                <p className={styles.info} > {props.info} </p>
            </div>
        </div>
    )
}

export default InfoCard
