import React, { useEffect, useState } from "react";

const AlertItem = ({
  fullName,
  currentCycle,
  daysLate,
  currentInstillation,
}) => {
  return (               
    <div className="file"  style={{marginBottom: "6px", marginTop: "0px", width: "190px" }}>
      <div style={{marginBottom: "-9px", marginTop: "-17px" }}  >
        <div className="file-segment">
          <div className="file-info">
            <p className="file-name">{fullName} </p>
            <h1 className="file-title" style={{ marginBottom: "-10px" }}>
              Ciclo actual <b>{currentCycle}</b>
            </h1>
            <h1 className="file-title" style={{ marginBottom: "-10px" }}>
              Instilación actual <b>Nº {currentInstillation}</b>
            </h1>
            <h1 className="file-title" style={{ marginBottom: "-10px" }}>
              Ultima instilación <b style={{ color: "red" }} >hace {daysLate} días</b> 
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertItem;
