import styled from 'styled-components'

export const Container = styled.div`
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
`

export const MarginSeparator = styled.hr`
  margin: 8px 0 0;
  border: 0;
  unicode-bidi: unset;
`
