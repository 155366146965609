import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import styles from "../../../../styles/patientStyles/components/DialogConfirm.module.css";
import { StyleButton4  } from "../../../../styles/DoctorStyles/ui/buttons";

const ModalEndCycle = (props) => {
  const { onEndCycle } = props;
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <StyleButton4
        onClick={() => {
          setOpen(true);
        }}
      >
        Finalizar ciclo
      </StyleButton4>
      <br />

      <Dialog
        open={open}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                width: "50px",
              }}
              src="/patient/error-icon.svg"
              alt=""
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            <h3>¿Estas seguro de finalizar el ciclo actual?</h3>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              className={"div-horizontal"}
            >
              <div className={styles.buttonBox}>
                <button onClick={onClose} className={styles.buttonSmallW}>
                  <p className={styles.buttonContentW}>Cancelar</p>
                </button>
                <button
                  onClick={() => {
                    onEndCycle(true);
                    onClose();
                  }}
                  className={styles.buttonSmall}
                >
                  <p className={styles.buttonContent}>Confirmar</p>
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalEndCycle;
