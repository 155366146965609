import React from "react";
import styles from "../../../styles/patientStyles/components/BackButton.module.css";

const BackButton = () => {

  return (

    <a href="javascript: history.go(-1)">
      <img className={`${styles.icon}`} src="/patient/arrow-2.svg" alt="Back Button" />
    </a>
  );
};
export default BackButton;
