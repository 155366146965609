import React from "react";
import CautionCard from "../../../components/doctorComponents/articleCard/cautionCard";
import RecommendationCard3 from "../../../components/doctorComponents/articleCard/recommendationCard3";

const Recommendation3 = () => {
  return (
    <>
      <div className="educationInfo-innerContainer1">
        <div className="educationInfo-imgContainer">
          <RecommendationCard3 />
        </div>
        <CautionCard
          text="Recuerde que el éxito del tratamiento es seguir
        las directrices dadas por su médico tratante."
        />
      </div>
    </>
  );
};

export default Recommendation3;
