import React, { useState, useEffect } from "react";
import { CssFormControlField, useStyles } from "../../../../styles/DoctorStyles/ui/form";

import {
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

const SelectOutlinedInput = (props) => {
  const {
    name,
    label,
    options,
    required,
    register,
    registerOptions,
    errors,
    ...remaining
  } = props;
  const classes = useStyles();
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    const newOptions = options.map((data, index) => ({
      label: data.name,
      value: data.id,
    }));
    setNewData(newOptions);
  }, [options]);

  const registerOption =
    registerOptions && registerOptions[name]
      ? registerOptions[name]
      : undefined;

  let isError = false;
  let errorMessage = "";
  if (errors && errors.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errors[name].message;
  }

  const handleInvalidDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <CssFormControlField
      size="small"
      className={classes.root}
      variant="outlined"
    >
      <InputLabel
        className={classes.margin}
        id={`select-outlined-label-${name}`}
      >
        {label}
        {required ? <span style={{ color: "red" }}> *</span> : ""}
      </InputLabel>
      <Select
        {...register(name, registerOption)}
        labelId={`select-outlined-label-${name}`}
        id={`select-outlined-${name}`}
        className={classes.margin}
        name={name}
        label={label}
        defaultValue={props.multiple ? [] : ""}
        error={isError}
        onInvalid={handleInvalidDefault}
        aria-describedby="select-helper-text"
        labelWidth={0}
        IconComponent={(props) => (
          <img
            className={props.className}
            src="/doctor/chevron-down.svg"
            alt="Arrow icon"
          />
        )}
        {...remaining}
      >
        {newData.map((option, index) => {
          return (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText id={`${name}-helper-text`}>
        {errorMessage && (
          <div className="div-horizontal-form">
            <img src="/doctor/error-icon.svg" className="error-icon" alert="Error icon" alt="icon" />
            {errorMessage}
          </div>
        )}
      </FormHelperText>
    </CssFormControlField>
  );
};

export default SelectOutlinedInput;
