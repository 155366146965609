import React from "react";
import ArticleModalCard from "../../../components/doctorComponents/articleCard/modalCard";

const Article4 = () => {
  return (
    <div>
      <ArticleModalCard
        article="ARTÍCULO 1"
        articlePath="https://drive.google.com/file/d/1Tmdovqp_yOSVUIQWAqWj8dwKVj_R7hv8/preview?usp=sharing"
        articlePathDownload="https://drive.google.com/file/d/1Tmdovqp_yOSVUIQWAqWj8dwKVj_R7hv8/view?usp=sharing"
        title="“Bladder Instillation Therapy With Hyaluronic
        Acid and Chondroitin Sulfate Improves Symptoms
        of Postradiation Cystitis: Prospective Pilot Study”"
        link="/doctor/module-4/ialuril-the-ideal-complement"
        module="module-4"
        label="ialuril-the-ideal-complement"
      />

      <ArticleModalCard
        article="ARTÍCULO 2"
        articlePath="https://drive.google.com/file/d/1qCstg7kGX9TVnLImeEjN-L6M4yus-OjR/preview?usp=sharing"
        articlePathDownload="https://drive.google.com/file/d/1qCstg7kGX9TVnLImeEjN-L6M4yus-OjR/view?usp=sharing"
        title="EAU Guidelines of urological infections 2022"
        link="/doctor/module-4/ialuril-the-ideal-complement"
        module="module-4"
        label="ialuril-the-ideal-complement"
      />

      <ArticleModalCard
        article="ARTÍCULO 3"
        articlePath="https://drive.google.com/file/d/15Y9zSov7PzDc51SPgxXP5jJQgMsf86IB/preview?usp=sharing"
        articlePathDownload="https://drive.google.com/file/d/15Y9zSov7PzDc51SPgxXP5jJQgMsf86IB/view?usp=sharing"
        title="Comunicado internacional de IBSA"
        link="/doctor/module-4/ialuril-the-ideal-complement"
      />
    </div>
  );
};

export default Article4;
