import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getUserInfo } from "../../services/doctorServices/authService";
import { RoleEnum } from "../../../src/constants/doctorConstants/enums";


const isBrowser = () => typeof window !== "undefined";

const PatientProtectedRoute = (rest) => {
  let unprotectedRoutes = [
    "/",
    "/patient",
    "/patient/reset-password",
    "/patient/reset-password/success",
    "/patient/login",
    "/patient/forgot-password",
    "/patient/forgot-password/success",
    "/patient/register/code",
    "/patient/register/email-sent",
    "/patient/dialog-profile",
    "/patient/register", 
    "/patient/privacyPolicy",
    "/patient/termsAndConditions",
    "/patient/500",
    "/patient/404",
    "*",
  ];

  
  let pathIsProtected = unprotectedRoutes.indexOf(rest.path) === -1;
  if (isBrowser() && getUserInfo() == null && pathIsProtected) {
    localStorage.setItem("urlback", rest.path);

    const roleStorage = localStorage.getItem("role");
    if(roleStorage == RoleEnum.institution){
      localStorage.setItem("urlback", "/doctor/dashboard");
      return <Redirect to="/doctor/login?logIns=true" />;
    }
    return <Redirect to="/patient/login" />;
  }

  return <Route {...rest} />;
};

export default PatientProtectedRoute;

/* const userlogin = getUserInfo()

let pathIsProtected = unprotectedRoutes.indexOf(rest.path) === -1
return (
    <>
        {
        !(userlogin === null && pathIsProtected)
            ? <Route {...rest}  />
            : <Redirect to="/unauthorized" />
        }
    </>
) */
