import React, { useState, useEffect } from "react";
import HeaderWithMenu from "../../components/patientComponents/component/headerWithMenu";
import {
  HeaderLogo,
  InnerContainer,
  Title,
} from "../../components/patientComponents/styled-components/register/styled";
/* import PolicyText from '../components/policyText' */
import styles from "../../styles/patientStyles/PrivacyPolicy.module.css";
import Footer from "../../components/patientComponents/component/footer";
import { getUserInfo } from "../../services/patientServices/authService";
import { getCountry } from "../../services/doctorServices/userService";
import CountrySwitch from "../../components/components/countrySwitch";

const PatientPrivacyPolicy = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [currentCountry, setCurrentCountry] = useState(null);

  const handleCountryChange = (newCountry) => {
    setCurrentCountry(newCountry);
  };

  useEffect(() => {
    const getcountry = getCountry();

    if (getcountry && Object.keys(getcountry).length !== 0) {
      setCurrentCountry(getcountry);
    } else {
      setCurrentCountry("CO");
    }

    if (getUserInfo() !== null) {
      setIsLogged(true);
    }
  }, []);

  return (
    <div>
      {isLogged && <HeaderWithMenu />}
      <HeaderLogo>
        <InnerContainer>
          <br></br>
          <br></br>
          <Title>Política de privacidad</Title>
          <p className={styles.subTitle}>
            A continuación, se expone la política de privacidad del sitio web
            app.ialuril.co (en adelante, el “
            <b className={styles.wb}>Sitio Web</b>”), de titularidad de{" "}
            <b className={styles.wb}>SerAnest Pharma Pharma.</b> (en adelante, “
            <b className={styles.wb}>SerAnest Pharma</b>”).
          </p>
        </InnerContainer>
      </HeaderLogo>
      <div className={styles.container}>
        <CountrySwitch
          currentCountry={currentCountry}
          onCountryChange={handleCountryChange}
        />

        <div className={styles.containerText}>
          <p className={styles.titleII}>1) Objeto</p>
          <p className={styles.text}>
            El objeto de la presente política de privacidad es la protección de
            los datos personales y la privacidad de quienes interactúen con el
            Sitio Web, a fin de garantizar su derecho al honor y a la intimidad,
            como así también el acceso a la información que sobre ellas pudiera
            registrarse.
          </p>
          <p className={styles.text}>
            Ha obtenido legalmente información y datos personales conforme a los
            lineamientos establecidos por la ley regulatoria del derecho al
            habeas data. asimismo, a través de la expedición de la presente
            política se da cumplimiento a lo previsto en el{" "}
            {currentCountry == "CO"
              ? "literal k del artículo 17 de la referida ley"
              : "CR-CONTENT"}
            .
          </p>

          <p className={styles.titleII}>2) Definiciones</p>
          <p className={styles.text}>
            A los fines de esta política de privacidad (en adelante, la “
            <b className={styles.gb}>Política de Privacidad</b>”), los términos
            “datos personales”, “datos sensibles”, “base de datos”, “tratamiento
            de datos”, “responsable de la base de datos”, “datos
            informatizados”, titular de los datos”, “usuario de datos” y
            “disociación de datos” tendrán el significado asignado por{" "}
            {currentCountry == "CO"
              ? "la Ley de Protección de Datos Personales y Habeas Data ley 1266 / 2008(en adelante, la “LPDP”"
              : "la Ley de Protección de la Persona Frente al Tratamiento de sus Datos Personales (Ley No. 8968)"}
            .
          </p>

          {currentCountry == "CO" ? (
            <>
              <p className={styles.titleII}>
                3) Principios de protección de los datos personales
              </p>
              <p className={styles.text}>
                SerAnest Pharma cumple con toda la normativa de protección de
                datos personales y en particular, con la LPDP y su ley
                estatutaria 1581 /2012(en adelante, la “
                <b className={styles.gb}>
                  Normativa de Protección de Datos Personales
                </b>
                ”).
              </p>
              <p className={styles.text}>
                En particular, SerAnest Pharma cumple con todos los principios
                que surgen de la Normativa de Protección de Datos Personales,
                que incluyen pero no se limitan a:{" "}
                <b className={styles.gb}>(i)</b> el{" "}
                <u>principio de legalidad</u>, dado que las bases de datos de
                SerAnest Pharma que contienen datos personales se encuentran
                debidamente registradas por ante el Registro Nacional de Bases
                de Datos; <b className={styles.gb}>(ii)</b> el{" "}
                <u>principio de calidad</u>, dado que SerAnest Pharma únicamente
                recolecta aquellos datos personales que resultan necesarios y
                adecuados para la prestación de sus servicios y el uso de los
                Sitios Web, y lo hace por medios legales y respetando la
                intimidad de los titulares de dichos datos personales;{" "}
                <b className={styles.gb}>(iii)</b>{" "}
                <u>el principio de finalidad</u>, dado que SerAnest Pharma
                utiliza los datos personales recabados únicamente para el fin
                para el que fueron recolectados;{" "}
                <b className={styles.gb}>(iv)</b> el{" "}
                <u>principio del consentimiento</u> informado, dado que SerAnest
                Pharma recaba el consentimiento de los titulares de los datos
                personales en la forma prescripta por la LPDP siempre que ello
                resulta necesario por no encontrarse en alguna de las
                excepciones de dicha norma; y <b className={styles.gb}>(v)</b>{" "}
                los <u>principios de seguridad</u> y confidencialidad de la
                información, mediante la utilización de tecnología avanzada que
                permite resguardar adecuadamente –según el estado de la técnica-
                la confidencialidad y seguridad de la información.{" "}
              </p>
              <p className={styles.text}>
                Asimismo, para garantizar la seguridad de los datos, SerAnest
                Pharma aplicará los mismos criterios y el mismo grado de
                diligencia que aplica para resguardar su propia información
              </p>{" "}
            </>
          ) : (
            <>
              <p className={styles.titleII}>
                3) Ley de Protección de la Persona Frente al Tratamiento de sus
                Datos Personales (Ley No. 8968):
              </p>
              <p className={styles.text}>
                Esta ley regula la protección de los datos personales en Costa
                Rica y establece los principios y requisitos para el tratamiento
                de datos personales por parte de entidades públicas y privadas.
                Esta ley se basa en el derecho fundamental de las personas a la
                protección de sus datos personales.
              </p>
            </>
          )}

          <p className={styles.titleII}>
            4) Recolección automatizada de datos personales{" "}
          </p>
          <p className={styles.text}>
            SerAnest Pharma no recolectará en forma automatizada datos
            personales. El Sitio Web únicamente podrá recolectar en forma
            automatizada información disociada y estadística sobre la
            utilización del Sitio Web.
          </p>

          <p className={styles.titleII}>5) Recolección de datos personales </p>
          <p className={styles.text}>
            Los formularios de recolección de datos personales del Sitio Web
            contienen las previsiones exigidas por la LPDP, en particular en lo
            referido al consentimiento informado.
          </p>
          <p className={styles.text}>
            El titular de los datos personales es responsable por la veracidad y
            exactitud de la información que consigne en ellos, y asume la
            responsabilidad de mantener actualizada dichat información.
          </p>
          <p className={styles.text}>
            SerAnest Pharma podrá utilizar medidas de validación específicas en
            los formularios para verificar que <b className={styles.gb}>(i)</b>{" "}
            no sean completados en forma automatizada y sin intervención directa
            e inmediata del usuario y <b className={styles.gb}>(ii)</b> el
            usuario cumpla los requisitos o posea las calidades necesarias para
            registrarse y/o proporcionar los datos personales requeridos.
          </p>

          <p className={styles.titleII}>6) Datos sensibles </p>
          <p className={styles.text}>
            En ningún caso SerAnest Pharma recolectará automáticamente ni
            requerirá que el usuario proporcione datos sensibles.
          </p>

          <p className={styles.titleII}>
            7) Ejercicio de los derechos de acceso, actualización rectificación,
            supresión o confidencialidad{" "}
          </p>
          <p className={styles.text}>
            A fin de ejercer los derechos de acceso, actualización,
            rectificación, supresión o confidencialidad respecto de sus datos
            personales, los usuarios del Sitio Web deberán informar en forma
            inmediata a SerAnest Pharma completando el formulario de contacto
            disponible en, <b className={styles.gb}> Ialuril app</b>.
          </p>
          <p className={styles.text}>
            Una vez verificada la identidad del titular de los datos personales,
            SerAnest Pharma permitirá el acceso y/o el ejercicio de los demás
            derechos, en forma gratuita a intervalos no menores de seis meses,
            salvo que acredite un interés legítimo al efecto, y en los términos
            previstos en la LPDP.
          </p>

          <p className={styles.titleII}>8) Plazo de conservación </p>
          <p className={styles.text}>
            SerAnest Pharma conservará la información recolectada mientras la
            relación con el usuario esté vigente y por los plazos de
            prescripción correspondientes según la normativa vigente en la
            República de {currentCountry == "CO" ? "Colombia" : "Costa Rica"}.
          </p>

          <p className={styles.titleII}>
            9) Modificaciones a la Política de Privacidad{" "}
          </p>
          <p className={styles.text}>
            Esta Política de Privacidad podrá ser modificada por SerAnest Pharma
            a fin de adecuarla a las disposiciones legales y reglamentarias
            vigentes. Las modificaciones se publicarán en el Sitio Web y
            entrarán en vigencia el mismo día de su publicación, salvo que
            dispongan lo contrario.
          </p>

          <p className={styles.titleII}>
            10) Forma de proceder respecto a las consultas y solicitudes hechas
            por los titulares de los datos{" "}
          </p>
          <p className={styles.text}>
            Todo titular de datos personales tiene derecho a realizar consultas
            y elevar solicitudes a la compañía respecto al manejo y tratamiento
            dado a su información.{" "}
          </p>
          <p className={styles.textpurple}>
            a). Procedimiento para el trámite de reclamos o solicitudes:
          </p>
          <p className={styles.text}>
            Toda solicitud, petición, queja o reclamo (pqr) que sea presentada a
            SerAnest pharma Ltda. por parte de cualquier titular o sus
            causahabientes respecto al manejo y tratamiento dado a su
            información será resuelta de conformidad con la ley regulatoria al
            derecho al habeas data y será tramitado bajo las siguientes reglas:
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>a.</b> La petición o reclamo se formulará
            mediante escrito o cualquier otro de los medios definidos en la
            presente política para tal fin, dirigido a SerAnest Pharma Ltda. con
            la identificación del titular, la descripción de los hechos que dan
            lugar al reclamo, la dirección o medio a través del cual desea
            obtener su respuesta, y si fuere el caso, acompañando los documentos
            de soporte que se quieran hacer valer. en caso de que el escrito
            resulte incompleto, la compañía solicitará al interesado para que
            subsane las fallas dentro de los cinco (5) días siguientes a la
            recepción del reclamo. transcurridos dos meses desde la fecha del
            requerimiento, sin que el solicitante presente la información
            requerida, se entenderá que ha desistido de la reclamación o
            petición.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>b.</b> Una vez recibida la petición o
            reclamo completo, la compañía incluirá en el registro individual en
            un término no mayor a dos (2) días hábiles una leyenda que diga
            «reclamo en trámite» y la naturaleza del mismo. dicha información
            deberá mantenerse hasta que el reclamo sea decidido.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>c.</b> El solicitante recibirá una
            respuesta por parte de SerAnest Pharma Ltda. dentro de los diez (10)
            días hábiles siguientes contados a partir de la fecha en la cual ha
            tenido conocimiento efectivo de la solicitud.
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>d.</b> Cuando no fuere posible atender la
            petición dentro de dicho término, se informará al interesado,
            expresando los motivos de la demora y señalando la fecha en que se
            atenderá su petición, la cual en ningún caso podrá superar los cinco
            (5) días hábiles siguientes al vencimiento del primer término.
          </p>
          <p className={styles.textpurple}>b). Consultas:</p>
          <p className={styles.text}>
            La política de manejo de la información personal por parte de
            SerAnest pharma Ltda. y los derechos básicos que los titulares de
            los datos tienen en relación con la misma podrán ser solicitados
            para su consulta mediante solicitud dirigida a la siguiente
            dirección de correo:{" "}
            {currentCountry == "CO"
              ? "calle 134 A No. 19-34 oficina 203 Bogotá D.C."
              : "CR-CONTENT"}
            , o llamando al{" "}
            {currentCountry == "CO" ? "(57) 1 6331601" : "CR-CONTENT"} o a la
            siguiente dirección de correo electrónico: soporte técnico
          </p>
          <p className={styles.text}>
            Cualquier consulta que tenga un titular sobre su información o datos
            personales o cuando considere necesario instaurar una solicitud de
            información o considere que sus derechos han sido vulnerados en
            relación con el uso y el manejo de su información; podrá hacerlo a
            través de cualquiera de los siguientes medios:
          </p>
          <ul>
            <li>
              <p className={styles.text}>
                Por escrito, radicado en la siguiente dirección:
                {currentCountry == "CO"
                  ? "calle 134 A no. 19-34 oficina 203 Bogotá, Colombia"
                  : "CR-CONTENT"}
              </p>
            </li>
            <li>
              <p className={styles.text}>
                Por escrito, enviando mensaje de correo electrónico a la
                siguiente dirección: servicioalcliente@seranestpharma.com
              </p>
            </li>
          </ul>
          <p className={styles.text}>
            Si dentro de los diez (10) días señalados, no fuere posible para la
            compañía atender la consulta, el área correspondiente deberá
            informar al interesado, los motivos de la demora e indicarle la
            fecha en que se atenderá la misma, la cual en ningún caso podrá
            superar los cinco (5) días hábiles siguientes al vencimiento del
            primer término.
          </p>
          <p className={styles.textpurple}>c). Responsable del tratamiento:</p>
          <p className={styles.text}>
            SerAnest Pharma ltda. tiene la calidad de responsable y encargado
            del tratamiento, a través de la presente política se permite
            informar sus datos de identificación:
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>Razón social:</b> SerAnest pharma Ltda
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>nit:</b> 830123305-0
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>Domicilio principal:</b>{" "}
            {currentCountry == "CO"
              ? "calle 134 A No. 19-34 oficina 203 Bogotá D.C."
              : "CR-CONTENT"}
          </p>
          <p className={styles.text}>
            <b className={styles.gb}>
              Persona o dependencia responsable de la atención de peticiones,
              consultas y reclamos:
            </b>{" "}
            el área encargada de recibir y canalizar todas las solicitudes e
            inquietudes es{" "}
            {currentCountry == "CO"
              ? "la oficina de servicio al cliente en la carrera 7C No. 180-19 local 17 modulo 0 manzana 2, o llamando al (57) 1 6331601"
              : "CR-CONTENT"}{" "}
            .
          </p>
          <p className={styles.titleII}>11) Órgano de control</p>
          <p className={styles.text}>
            La superintendencia de industria y comercio, en su carácter de
            Órgano de Control de la Ley 1581 de 2012 tiene la atribución de
            atender las denuncias y reclamos que interpongan quienes resulten
            afectados en sus derechos por incumplimiento de las normas vigentes
            en materia de protección de datos personales.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PatientPrivacyPolicy;
