import React from "react";

import AccordionComponent from "../../../../components/doctorComponents/accordion/accordion-1";
import AccordionBibliography from "../../../../components/doctorComponents/accordion/accordion-2";
import { getCountry } from "../../../../services/doctorServices/userService";

const IalurilIdealcomplement = () => {
  const country = getCountry();

  return (
    <div className="container">
      <div className="inner-container">
        <AccordionComponent
          article="Ialuril-the-ideal-complement"
          title="INTRODUCCIÓN"
        >
          <div>
            <p>
              La etiología de la cistitis crónica no está completamente
              definida, y aunque la evidencia es limitada, se cree que el daño
              urotelial es el factor común y principal para todas estas
              patologías vesicales; el daño puede originarse por infección,
              inflamación neurogénica primaria, fármacos oncológicos o
              radiación, traumatismo de la vejiga o sobredistensión, disfunción
              del suelo pélvico o una enfermedad autoinmune.
              <sup>
                <b>1</b>
              </sup>
            </p>
            <p>
              El proceso inflamatorio comienza y evoluciona de manera similar en
              cada caso. El urotelio pierde su barrera y las sustancias propias
              de los metabolitos de la orina y otras tóxicas penetran las capas
              subepiteliales y desencadenan una inflamación más profunda (Figura
              1).
              <sup>
                <b>2</b>
              </sup>
            </p>

            <img className="img" src="/doctor/doctor-img007.png" alt="Img" />
            <p className="italics-gray">
              Figura 1. Efectos de la alteración de la permeabilidad urotelial{" "}
            </p>

            <p>
              El restablecimiento de esta capa de GAG es un objetivo terapéutico
              para restablecer la barrera de protección y el modulador de la
              respuesta inflamatoria en la pared vesical: una combinación de
              ácido hialurónico (AH) al 1.6% y condroitin sulfato (CS) al 2% con
              cloruro de calcio, está disponible comercialmente como una jeringa
              prellenada de 50 ml para instilación intravesical (ialuril ®
              Prefill;IBSA Institut Biochimique SA, Lugano, Suiza), (Figura 2).
            </p>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img className="img" src="/doctor/doctor-img018.png" alt="Img" />
              <p style={{ alignSelf: "flex-end", margin: "0 25px 0 0" }}>
                {country === "CO" ? "Registro Invima 2014DM-0012306" : ""}
              </p>
            </div>
            <p className="italics-gray">
              Figura 2. Moléculas del ialuril ® Prefill
            </p>

            <p>
              El AH y el CS reducen la producción de citoquinas
              proinflamatorias, reducen la permeabilidad urotelial y facilitan
              la reparación de la capa protectora de GAG. Está indicado en una
              variedad de condiciones clínicas que se originan por daño de la
              capa GAG (Figura 3).
            </p>

            <img
              className="img"
              src="/doctor/cascada-inflamatoria-en-la-pared-vesical-xhdpi.png"
              alt="Img"
            />
            <p className="italics-gray">
              Figura 3. Cómo afecta el urotelio la función vesical
              <sup>
                <b>3</b>
              </sup>
            </p>

            <p>
              Para la función de barrera, los GAG tienen la propiedad de retener
              grandes cantidades de agua y de adoptar una conformación extendida
              en disolución, por lo que son útiles a la hora de acojinar o
              lubricar. Estas propiedades se consiguen gracias al gran número de
              grupos OH y de cargas negativas de esta molécula, lo que permite,
              por el establecimiento de fuerzas de repulsión, que se conserven
              relativamente separadas entre sí las cadenas de carbohidratos.
            </p>
            <p>
              Las moléculas de hialuronano son de mayor tamaño, cosa que
              favorece su <b>función de barrera</b> y la localización en la
              membrana basal celular constituye una barrera de permeabilidad más
              consistente en la base celular urotelial (Figura 4).
            </p>

            <img
              className="img"
              src="/doctor/propiedades-de-los-GAG-xhdpi.png"
              alt="Img"
            />
            <p className="italics-gray">Figura 4. Propiedades de los GAG</p>
          </div>
        </AccordionComponent>

        <AccordionComponent
          article="Ialuril-the-ideal-complement"
          title="CISTITIS CRÓNICA BACTERIANA"
        >
          <div>
            <p>
              El urotelio sano está recubierto por una película de
              mucopolisacáridos compuesta de una variedad de elementos conocidos
              como glicosaminoglicanos (GAG), moléculas altamente hidrofílicas
              que atraen el agua creando una barrera química que protege el
              urotelio.
            </p>
            <p>
              La instilación intravesical de diversos GAG demostraron ser
              efectivos para restaurar la función urotelial en la cistitis
              intersticial y se han realizado varios estudios para evaluar si
              dicho tratamiento es una alternativa terapéutica práctica no
              antibiótica, para reducir los episodios de cistitis recurrente.3
              En consecuencia, la terapia intravesical con ácido hialurónico
              sólo o en asociación con condroitín sulfato se documentó que
              favorece la integridad urotelial, proporcionando estabilidad y
              elasticidad a la matrix extracelular, impide la diseminación de
              macromoléculas y la reducción en la adherencia bacteriana en la
              pared vesical (<i>Figura 5</i>).
            </p>

            <img
              className="img"
              src="/doctor/efecto-antiinflamatorio-y-cicatrizal-de-los-GAG-xhdpi.png"
              alt="Img"
            />
            <p className="italics-gray">
              Figura 5. Efecto anti-inflamatorio y cicatrizal de los GAG
            </p>

            <p>
              Se ha demostrado que las instilaciones vesicales con ácido
              hialurónico (AH) y condroitín sulfato (CS) reducen notablemente
              los episodios recurrentes de cistitis y mejoran los síntomas
              asociados.
              <sup>
                <b>4</b>
              </sup>{" "}
              Muchos estudios han demostrado que la terapia intravesical es más
              eficaz a largo plazo que la terapia con antibióticos para reducir
              los episodios recurrentes.
              <sup>
                <b>5,6</b>
              </sup>{" "}
              Damiano y col. han demostrado la eficacia de las instilaciones
              intravesicales de ácido hialurónico en asociación con condroitín
              sulfato para prevenir la recurrencia de las infecciones urinarias
              bajas. Finalmente, De Vita et al.
              <sup>
                <b>7,8</b>
              </sup>{" "}
              demostró que la terapia intravesical con ácido hialurónico y
              condroitín sulfato es más eficaz para reducir los episodios
              recurrentes que la terapia con antibióticos con sulfametoxazol y
              trimetoprim.
            </p>

            <div className="container-horizontal-img">
              <img
                className="img"
                src="/doctor/cistitis-crónica-recurrente-ficha-1-xxhdpi.png"
                alt="Img"
              />
              <img
                className="img"
                src="/doctor/cistitis-cronica-recurrente-ficha-2-xxhdpi.png"
                alt="Img"
              />
              <img
                className="img"
                src="/doctor/cistitis-cronica-recurrente-ficha-3-xxhdpi.png"
                alt="Img"
              />
            </div>
          </div>
        </AccordionComponent>

        <AccordionComponent
          article="Ialuril-the-ideal-complement"
          title="CISTITIS INTERSTICIAL Y SÍNDROME DE VEJIGA DOLOROSA"
        >
          <div>
            <p>
              La cascada inflamatoria consecutiva a la penetración de elementos
              de la orina en la pared vesical, especialmente el potasio, incluye
              el estímulo de las fibras C (sustancia P liberada),
              <sup>
                <b>9</b>
              </sup>{" "}
              la activación de mastocitos y la liberación de histamina.
              <sup>
                <b>10</b>
              </sup>{" "}
              Existen estudios que han demostrado una disminución de la
              eliminación urinaria de GAG en pacientes con cistitis
              intersticial,9 llevando a un incremento en la permeabilidad
              urotelial y consecuentemente agravando la lesión.
            </p>
            <p>
              Cervigni y col. (2008) evaluaron la seguridad y tolerabilidad de
              ialuril ® Prefill en 23 pacientes refractarios a varias
              instilaciones orales y / o intravesicales de ácido hialurónico
              (AH) 0,08% y fueron tratados durante 5 meses. No hay casos de
              intolerancia, efectos secundarios o complicaciones. Se observó una
              reducción estadísticamente significativa del dolor (32%) y
              disminución de la frecuencia urinaria (26%) y urgencia miccional
              (41%). Además, aumento del volumen miccional (33%) y reducción del
              dolor pélvico y puntuación de urgencia / frecuencia (29%).10
            </p>
            <p>
              En comparación con el DMSO, no se encontraron diferencias
              significativas gracias a su excelente tolerabilidad, efectos
              secundarios poco probables en comparación con DMSO, ialuril ®
              Prefill representa un atractivo arsenal clínico de la terapia
              intravesical.
            </p>
            <div className="container-horizontal-img">
              <img
                className="img"
                src="/doctor/cistitis-intersticial-svd-ficha-1-xxhdpi.png"
                alt="Img"
              />
              <img
                className="img"
                src="/doctor/cistitis-intersticial-svd-ficha-2-xxhdpi.png"
                alt="Img"
              />
              <img
                className="img"
                src="/doctor/cistitis-intersticial-svd-ficha-3-xxhdpi.png"
                alt="Img"
              />
            </div>
          </div>
        </AccordionComponent>

        <AccordionComponent
          article="Ialuril-the-ideal-complement"
          title="CISTITIS HEMORRÁGICA"
        >
          <div>
            <p>
              La radioterapia puede afectar la función del urotelio debido a la
              fibrosis y cambios en las fibras nerviosas de la pared vesical, lo
              que lleva a una respuesta inflamatoria local aguda y crónica con
              síntomas del tracto urinario inferior (STUI), asociados o no con
              hematuria macroscópica y dolor.
              <sup>
                <b>11,12</b>
              </sup>{" "}
              Varios estudios clínicos han demostrado la eficacia de la
              instilación intravesical de altas concentraciones de ácido
              hialurónico y condroitin sulfato en diferentes esquemas.
              <sup>
                <b>7,13</b>
              </sup>
            </p>
            <p>
              La protección del urotelio por AH y CS durante la radioterapia
              (RT) podría representar una estrategia preventiva que disminuya
              las consecuencias del deterioro anatomo-funcional de la vejiga,
              evitando o mejorando los síntomas de la cistitis inducida por
              radiación y a la vez mejorando la calidad de vida.
            </p>
            <p>
              Existe poca evidencia para determinar el momento de realizar la
              intervención. Algunos han demostrado que un tiempo más corto desde
              la radioterapia (RT) hasta el tratamiento, puede mejorar los
              resultados de los pacientes, respaldando la necesidad de
              tratamiento para aliviar o prevenir los síntomas urinarios.
              <sup>
                <b>14</b>
              </sup>{" "}
              En el estudio MISTIC (Multicentre International Study for the
              Prevention with ialuril ® Prefill of Radio-induced Cystitis) se
              realiza la instilación intravesical de ialuril ® Prefill y el
              tratamiento oral (curcumin, quercetin, AH y CS) al mismo tiempo
              que el inicio de la RT y continua el tratamiento oral durante 12
              semanas. Aunque la mejoría de los síntomas se mantuvo después de 1
              año, los hallazgos no permiten concluir de manera absoluta sobre
              los efectos a mediano y largo plazo de las preparaciones orales e
              intravesicales, y los riesgos de la cistitis tardía inducida por
              radiación.
              <sup>
                <b>15</b>
              </sup>
            </p>

            <img
              className="img"
              src="/doctor/module4-cistitis-ficha-1.png"
              alt="Img"
            />
          </div>
        </AccordionComponent>

        <AccordionBibliography title="BIBLIOGRAFIA">
          <div>
            <p className="bibliography-font">
              {" "}
              <b>1.</b> Parsons CL. The role of a leaky epithelium and potassium
              in the generation of bladder symptoms in interstitial
              cystitis/overactive bladder, urethral syndrome, prostatitis and
              gynaecological chronic pelvic pain. BJU Int 2011; 107:370–5.
            </p>
            <p className="bibliography-font">
              <b>2.</b> Costantini E. The role of the urinary epithelium.
              Presentation at: 26th Annual European Association of Urology
              Congress; March 18–21. Vienna, Austria, 2011.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>3.</b> Soler R, Bruschini H, Martins JR, et al. Urinary
              glycosaminoglycans as biomarker for urothelial injury: is it
              possible to discriminate damage from recovery? Urology. 2008;
              72:937–42.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>4.</b> Southgate J, Varley CL, Garthwaite MA, et al.
              Differentiation potential of urothelium from patients with benign
              bladder dysfunction. BJU Int.2007; 99:1506–16.
            </p>
            <p className="bibliography-font">
              <b>5.</b> Constantinides C, Manousakas T, Nikolopoulos P, et al.
              Prevention of recurrent bacterial cystitis by intravesical
              administration of hyaluronic acid: a pilot study. BJU Int. 2004;
              93:1262–6.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>6.</b> Lipovac M, Kurz C, Reithmayr F, et al. Prevention of
              recurrent bacterial urinary tract infections by intravesical
              instillation of hyaluronic acid. Int J Gynaecol Obstet. 2007;
              96:192–5.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>7.</b> Damiano R, Quarto G, Bava I, et al. Prevention of
              recurrent urinary tract infections by intravesical administration
              of hyaluronic acid and chondroitin sulphate: a placebo-controlled
              randomized trial. Eur Urol. 2011; 59:645–51.
            </p>
            <p className="bibliography-font">
              <b>8.</b> De Vita D, Giordano S. Effectiveness of intravesical
              hyaluronic acid/chondroitin sulfate in recurrent bacterial
              cystitis: a randomized study. Int Urogynecol J. 2012; 23:1707–13
            </p>
            <p className="bibliography-font">
              {" "}
              <b>9.</b> Marchand JE, Sant GR, Kream RM. Increased expression of
              substance P receptor-encoding mRNA in bladder biopsies from
              patients with interstitial cystitis. Br J Urol 1998; 81:224–8.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>10.</b> Sant GR, Kempuraj D, Marchand JE, Theoharides TC. The
              mast cell in interstitial cystitis: role in pathophysiology and
              pathogenesis. Urology 2007; 69:34–40.
            </p>
            <p className="bibliography-font">
              <b>11.</b> Giglio D, Podmolikova L, Tobin G. Changes in the
              neuronal control of the urinary bladder in a model of radiation
              cystitis. J Pharmacol Exp Ther 2018; 365:327–35.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>12.</b> McDonnell BM, Buchanan PJ, Prise KM, et al. Acute
              radiation impacts contractility of guinea-pig bladder strips
              affecting muco-sal-detrusor interactions. PLoS One 2018;13:
              e0193923.
            </p>
            <p className="bibliography-font">
              <b>13.</b> Stellavato A, Pirozzi AVA, Diana P, et al. Hyaluronic
              acid and chondroitin sulfate, alone or in combination, efficiently
              counteract induced bladder cell damage and inflammation. PLoS One
              2019;14: e0218475.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>14.</b> Oscarsson N, Muller B, Rosen A, et al.
              Radiation-induced cystitis treated with hyperbaric oxygen therapy
              (RICH-ART): a randomised, controlled, phase 2–3 trial. Lancet
              Oncol 2019; 20:1602–14.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>15.</b> Redorta J, Sanguedolce F, Pardo G, Lazzeri M.
              Multicentre International Study for the Prevention with ialuril ®
              Prefill of Radio-induced Cystitis (MISTIC): A Randomised
              Controlled Study Eur Urol Open Sc, 2021; 26: 45-54.
            </p>
          </div>
        </AccordionBibliography>
      </div>
    </div>
  );
};

export default IalurilIdealcomplement;
