import styled from 'styled-components'

export const Container = styled.div`
    max-width: 576px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 16px 16px;
`

export const HeaderLogo = styled.div`
    padding: 13px 16px;
    background-color: #fff;
`

export const Logo = styled.img`
    max-width: 150px;
    width: 100%;
    display: block;
    margin: 0 auto 15px;
`

export const GreenCheck = styled.img`
    max-width: 48px;
    width: 100%;
    display: block;
    margin: 79px auto 20px;
    filter: drop-shadow(0px 7px 11px #343a40);
`

export const Text = styled.p`
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #6c757d;
    margin: 0 0 53px;
    text-align: center;
`

export const ReturnButton = styled.p`
    a {
        max-width: 135px;
        width: 100%;
        display: block;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        text-decoration: none;
        padding: 12px 10px;
        margin: 0 auto;
        border-radius: 6px;
        background-color: #b16db3;
        box-sizing: border-box;
    }
`
