import React from "react";
import styles from "../../../styles/patientStyles/components/CautionCard.module.css";

const CautionCard = (props) => {
  return (
    <div className={`${styles.card}`}>
      <img className={`${styles.img}`} src="/patient/caution.svg" alt="caution" />
      <div className={`${styles.text}`}>
        {props.text}
      </div>
    </div>
  );
};

export default CautionCard;
