import React, { useState } from 'react'
import DialogConfirm from '../dialogConfirm'
import styles from '../../../../styles/patientStyles/components/Instillation.module.css'
import SelectOutlinedInput from '../../../patientComponents/component/form/outlined-inputs/select'

const getNroInstillationsMaintenance = () => {
  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  return options.map((x) => ({ id: x, name: x + '' }))
}

const AddMaintenance = (props) => {
  const {
    register,
    registerOptions,
    errors,
    watch,
    fnAddMaintenance,
    trigger,
  } = props
  const [dlgConfirmFinish, setDlgConfirmFinish] = useState(false)

  const nroInstillationsMaintenanceValue = watch('nroInstillationsMaintenance')

  return (
    <>
      <SelectOutlinedInput
        name="nroInstillationsMaintenance"
        label="N° de instilaciones de mantenimiento"
        placeholder="N° de instilaciones de mantenimiento"
        register={register}
        registerOptions={registerOptions}
        options={getNroInstillationsMaintenance()}
        required
        errors={errors}
      />

      <button
        className={styles.button}
        onClick={async () => {
          const isOk = await trigger()
          if (!isOk) return
          setDlgConfirmFinish(true)
        }}
      >
        <p className={styles.buttonContent}>Agregar</p>
      </button>

      <DialogConfirm
        openDialog={dlgConfirmFinish}
        handleRequestClose={() => setDlgConfirmFinish(false)}
        img="/patient/warning.svg"
        text={
          '¿Agregar ' +
          (nroInstillationsMaintenanceValue
            ? nroInstillationsMaintenanceValue
            : '') +
          ' instilaciones de mantenimiento?'
        }
        onAcceptClick={() => {
          setDlgConfirmFinish(false)
          fnAddMaintenance(nroInstillationsMaintenanceValue)
        }}
      />
    </>
  )
}

export default AddMaintenance
