import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import SearchLabel from "../../../components/doctorComponents/form/search/searchLabel";
import PatientFileCard from "../../../components/institutionComponents/patientFileCard/patientCardList";
import {
  callApi,
  getPatientInstitutionList,
} from "../../../services/doctorServices/apiService";
import { NotificationManager } from "react-notifications";
import Progress from "../../../components/doctorComponents/Progress";
import IconButton from "@material-ui/core/IconButton";
import PersonAddAltIcon from "@material-ui/icons/PersonAdd";
import AddPatient from "./addPatient";

const filterList = (list, searchText, currentFilerStatus) => {
  let result = [...list];
  if (currentFilerStatus && currentFilerStatus > 1) {
    result = result.filter(
      (x) =>
        (currentFilerStatus === 2 && x.registered === true) ||
        (currentFilerStatus === 3 && x.registered === false)
    );
  }
  if (searchText) {
    const text = searchText.toUpperCase();
    result = result.filter(
      (x) => x.fullName && x.fullName.toUpperCase().includes(text)
    );
  }

  return result;
};

const PatientListIns = () => {
  const [loader, setLoader] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [currentFilerStatus, setCurrentFilerStatus] = useState(null);
  const [dlgAddPatient, setDlgAddPatient] = useState(false);
  const [currentPatientId, setCurrentPatientId] = useState(null);

  useEffect(() => {
    callApi(
      getPatientInstitutionList,
      (data) => {
        if (data && data.length === 0) {
          NotificationManager.warning("No tiene pacientes asociados");
          return;
        }
        setPatientList(data);
      },
      setLoader
    );
    return;
  }, []);

  const defaultValues = { registrationStatus: 1 };
  const methods = useForm({ defaultValues });
  const { register, formState, watch, setValue } = methods;
  const { errors } = formState;

  const registrationStatus = watch("registrationStatus");
  useEffect(() => {
    setCurrentFilerStatus(registrationStatus);
  }, [registrationStatus]);

  return (
    <div className="container">
      <div className="inner-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {patientList && patientList.length > 0 ? (
            <>
              <div className="file-info" style={{ width: "345px" }}>
                <SearchLabel
                  placeholder="Buscar paciente"
                  setSearchText={setSearchText}
                />
              </div>
            </>
            ):(<>
            <p className="file-name">Agregar paciente</p>
            </>
          )}
          <div className="icon-background-C">
            <IconButton
              aria-label="search"
              onClick={() => {
                setDlgAddPatient(true);
              }}
            >
              <PersonAddAltIcon />
            </IconButton>
          </div>
        </div>

        {filterList(patientList, searchText, currentFilerStatus).map(
          (item, index) => {
            return (
              <PatientFileCard
                key={index}
                item={item}
                name={item.fullName}
                cicle={item.currentCycle}
                instillation={item.currentInstillation ?? ""}
                instillationType={item.currentInstillationType ?? -1}
                link="/doctor/patient-list/patient-details"
                setDlgAddPatient={setDlgAddPatient}
                setCurrentPatientId={setCurrentPatientId}
              />
            );
          }
        )}
      </div>
      <AddPatient
        open={dlgAddPatient}
        onClose={() => setDlgAddPatient(false)}
        currentPatientId={currentPatientId}
        setCurrentPatientId={setCurrentPatientId}
      />
      <Progress loader={loader} />
    </div>
  );
};

export default PatientListIns;
