export const TableHeadStyle = {
    white:"white",
    purple:"purple"
}
export const TableBodyStyle = {
    interspersed:"interspersed",
    white:"white"
}

export const RoleList = () =>[
    { id: "DOCTOR", name: "role.doctor" },
    { id: "REPRESENTATIVE", name:"role.representative"},
    { id: "INSTITUTION", name:"role.institution"}
  ];

export const RoleEnum = {
    doctor: "DOCTOR",
    representative: "REPRESENTATIVE",
    institution: "INSTITUTION",
    patient: "PATIENT",
  };

  
export const SurveyChartType = { 
  doctorDetail : 1, 
  institutionInfo: 2, 
  epsDetail: 3
};
  
export const InstitutionListTypeEnum = { 
  patientList : 1, 
  epsList: 2
};
  
export const PatientQueryTypeEnum = { 
  byEps : 1, 
  byInstitution: 2,
  byDoctor:3
};