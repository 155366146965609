import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Button3 from "../../doctorComponents/buttons/button-3";
import { RoleEnum , PatientQueryTypeEnum } from "../../../constants/doctorConstants/enums";
import { useDispatch } from "react-redux";
import { setPatientListSource } from "../../../redux/patientListSource";

const EpstFileCard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onClick = (link, item) => {
    if (item.completedSurveys === 0) {
      NotificationManager.warning("El paciente no tiene encuestas finalizadas");
      return;
    }
    history.push({
      pathname: link,
      state: { detail: item },
    });
  };

  return (
    <div className="file" style={{marginTop:0}}>
      <div>
        <div className="file-segment">
          <div className="icon-background-A">
            <img
              className="file-icon"
              src="/doctor/eps-orange.svg"
              alt="icon"
            />
          </div>
          <div className="file-info">
            <h1 className="file-title">Nombre de la EPS:</h1>
            <p className="file-name">{props.name} </p>
          </div>
        </div>
        <div className="file-segment">
          <div className="icon-background-B">
            <img
              className="file-icon"
              src="/doctor/instillation-icon.svg"
              alt="icon"
            />
          </div>
          <div className="file-info">
            <h1 className="file-title">Cantidad de pacientes:</h1>
            <p className="file-name">{props.cicle}</p>
          </div>
        </div>

        <div className="file-segment">
          <div className="icon-background-C">
            <img
              className="file-icon"
              src="/doctor/cicle-icon.svg"
              alt="icon"
            />
          </div>
          <div className="file-info">
            <h1 className="file-title">Cantidad de encuestas:</h1>
            <p className="file-name">{props.instillation}</p>
          </div>
        </div>
      </div>
      <Link
        className="a-center"
        to="/doctor/eps-list/eps-details/patient-list"
        onClick={() =>
          dispatch(setPatientListSource({ type: PatientQueryTypeEnum.byEps, id: props.epsId }))
        }
      >
        <Button3 label="Ver listado de pacientes" />
      </Link>
      {props.link && (
        <a className="a-end" onClick={() => onClick(props.link, props.item)}>
          Ver detalles
        </a>
      )}
    </div>
  );
};

export default EpstFileCard;
