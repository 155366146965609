import React from "react";

import AccordionComponent from "../../../../components/doctorComponents/accordion/accordion-1";
import AccordionBibliography from "../../../../components/doctorComponents/accordion/accordion-2";
import CustomTable from "../../../../components/doctorComponents/customTable";
import { TableHeadStyle, TableBodyStyle } from "../../../../constants/doctorConstants/enums";
import { HeaderTable3, BodyTable3 } from "./tables/tables-module-2";

const HemorrhagicCystitis2 = () => {
  return (
    <div className="container">
      <div className="inner-container">
        <p>
          El diagnóstico de cistitis hemorrágica debe sospecharse ante una
          hematuria refractaria (subaguda o crónica) en un contexto de
          quimioterapia, radioterapia, inmunosupresión o exposición a agentes
          químicos como los colorantes o ciertos tóxicos como los insecticidas.
          En el marco del trasplante, la cistitis hemorrágica es más frecuente
          en caso de transplante de células madre hematopoyéticas (TCMH), pero
          también puede producirse en caso de transplante de órganos sólidos.
        </p>

        <p>
          Aparte de la cistitis hemorrágica de origen bacteriano, que suele
          ceder con el tratamiento antibiótico estándar, las dos causas
          principales de cistitis hemorrágica son la quimioterapia y la
          radioterapia. La cistitis hemorrágica puede ser microscópica o
          macroscópica hasta la aparición de coágulos que causan síndromes
          obstructivos. Se clasifica la cistitis hemorrágica en cuatro grados,
          según Droller
          <sup>
            <b>1</b>
          </sup>{" "}
          (Cuadro 1).
        </p>

        <br />
        <p className="title-gray-2">
          GRADOS DE LA CISTITIS HEMORRÁGICA SEGÚN DROLLER
        </p>

        <CustomTable
          bodystyle={TableBodyStyle.interspersed}
          headstyle={TableHeadStyle.purple}
          columns={HeaderTable3}
          rows={BodyTable3}
        />

        <p className="italics-gray">
          Cuadro 1. Grados de la cistitis hemorrágica (Droller)
        </p>

        <AccordionComponent article="hemorrhagic-cystitis" title="ETIOLOGÍA Y FACTORES DE RIESGO">
          <div>
            <p className="subtitle-purple">
              Cistitis hemorrágica de origen químico
            </p>
            <p>
              Varios agentes de quimioterapia pueden producir una cistitis
              hemorrágica; los dos más frecuentes son la{" "}
              <b>
                ciclofosfamida
                <sup>
                  <b>2,3</b>
                </sup>
              </b>
              y la
              <b> ifosfamida</b>.
              <sup>
                <b>4,5</b>
              </sup>{" "}
              La ciclofosfamida se utiliza para el tratamiento del linfoma B y
              de algunos tumores sólidos, y en la preparación antes de un TCMH.
              También se utiliza en el tratamiento de enfermedades
              autoinmunitarias, como la granulomatosis de Wegener, la artritis
              reumatoide y el lupus eritematoso.
              <sup>
                <b>6,7</b>
              </sup>
            </p>
            <p>
              La intensidad y recurrencia de la cistitis hemorrágica después de
              la quimioterapia difieren según el protocolo de tratamiento y la
              enfermedad. En un metaanálisis de 18 estudios comparativos, la
              frecuencia de la cistitis hemorrágica de grado III y IV era del
              7,4%.
              <sup>
                <b>8</b>
              </sup>{" "}
              <b>
                En la mayoría de los pacientes, los síntomas ceden en 2 semanas,
                pero, en el 10% de ellos, la cistitis hemorrágica evoluciona
                desfavorablemente
              </b>
              . Como se mencionó anteriormente, el riesgo de remoción de la
              vejiga (cistectomía) fue del 0,2%, según un estudio realizado por
              Garderet
              <sup>
                <b>9</b>
              </sup>{" "}
              con pacientes tratados con ciclofosfamida.{" "}
            </p>
            <p>
              Se deben tener presentes otras moléculas quimioterapéuticas como
              la mitomicina C, la epirrubicina, la instilación de BCG y otros
              tóxicos, como el ácido tiaprofénico,
              <sup>
                <b>10</b>
              </sup>{" "}
              la ketamina y el clordimeform
              <sup>
                <b>11</b>
              </sup>{" "}
              que producen una reacción inflamatoria, raramente asociado a la
              hematuria, con curso benigno resolviendo espontáneamente al
              suspender el agente responsable.
            </p>
            <p className="subtitle-purple">
              Cistitis hemorrágica radioinducida
            </p>
            <p>
              La radioterapia pélvica puede ser responsable de una reacción
              aguda y crónica vesical, y puede producir una cistitis
              hemorrágica.
              <sup>
                <b>12</b>
              </sup>{" "}
              El curso biológico de la enfermedad es más difícil de precisar y
              la posibilidad de generar una cistitis hemorrágica siempre
              dependerá de los esquemas de tratamiento utilizados y{" "}
              <b>
                el rango de latencia descrito para la aparición de sangrado es
                muy amplio a partir de las 8 semanas de tratamiento e incluso
                hasta 15 años después
              </b>{" "}
              de la terapia radiante.
            </p>
            <p>
              Tal como se mencionó anteriormente,{" "}
              <b>
                la cistitis actínica hemorrágica aparece en el 5-10% de los
                pacientes
              </b>
              .
              <sup>
                <b>13</b>
              </sup>{" "}
              Así mismo, pueden haber recurrencias variables que ameriten o no
              tratamiento intrahospitalario y pueden llevar al daño irreversible
              y progresivo de la función vesical, y secundariamente la
              repercusión sobre el tracto urinario superior. En el estudio de la
              EORTC BC 2001, con 360 casos de cáncer de vejiga infiltrantes,
              tratados con radioterapia o con asociación de radioterapia y
              quimioterapia, la toxicidad vesical por cistitis hemorrágica de
              grado III y IV estaba presente en el 16% de los pacientes.
              <sup>
                <b>14</b>
              </sup>
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="hemorrhagic-cystitis" title="DIAGNÓSTICO ETIOLÓGICO">
          <div>
            <p>
              Generalmente el diagnóstico etiológico es simple, pues se presenta
              en el marco de una enfermedad de base en tratamiento concomitante
              con quimio o radioterapia. También debe considerarse la cistitis
              hemorrágica en un contexto de infección bacteriana agravada por
              las características del paciente (diabetes, trombocitopenia).
            </p>
            <p>
              En el contexto de una enfermedad hematopoyética, la quimioterapia
              (ifosfamida o ciclofosfamida) o el transplante de células madre
              hematopoyéticas (TCMH), el diagnóstico etiológico es más difícil,
              porque{" "}
              <b>
                la cistitis hemorrágica puede ser el resultado de varios
                factores concomitantes
              </b>
              : infección vírica, que se busca mediante reacción en cadena de la
              polimerasa en la sangre y la orina (adenovirus, virus BK),
              trombocitopenia y reacción de injerto contra huésped (por las
              consecuencias de la inmunosupresión inducida).
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="hemorrhagic-cystitis" title="CISTOSCOPIA">
          <div>
            <p>
              Es un examen mandatorio no sólo desde el punto de vista
              diagnóstico, sino también terapéutico. Con esta finalidad doble
              nos permite:
            </p>
            <ul>
              <li>
                <p>
                  Eliminar los coágulos que mantienen el sangrado y realizar una
                  hemostasia, preferentemente por coagulación/vaporización
                  láser,
                  <sup>
                    <b>15</b>
                  </sup>{" "}
                  así como colocar una sonda de tres vías para irrigación,
                  lavado y drenaje vesical.
                </p>
              </li>
              <li>
                <p>
                  Descartar el diagnóstico de tumor de vejiga o de sangrado de
                  origen prostático.
                </p>
              </li>
              <li>
                <p>
                  Practicar biopsias y tomas de muestra bacteriológicas y
                  citológicas, identificación de virus y levaduras (Foto 1).
                </p>
              </li>
            </ul>

            <img
              className="img"
              src="/doctor/doctor-img003.png"
              alt="Img"
            />
            <p className="italics-gray">
              Foto 1. Cistoscopia de un paciente tratado con radioterapia
              pélvica por carcinoma de próstata (archivo personal*)
            </p>
          </div>
        </AccordionComponent>

        <AccordionBibliography title="BIBLIOGRAFIA">
          <div>
            <p className="bibliography-font">
              {" "}
              <b>1.</b> Svanborg Edén C, Hagberg L, Leffler H, Lomberg H. Recent
              progress in the understanding of the role of bacterial adhesion in
              the pathogenesis of urinary tract infection. Infection 1982;
              10:327–32.
            </p>
            <p className="bibliography-font">
              <b>2.</b> Russell SJ, Vowels MR, Vale T. Haemorrhagic cystitis in
              paediatric Bone Marrow Transplant patients: an association
              withinfective agents, GVHD and prior cyclophosphamide. Bone Marrow
              Transplant 1994; 13:533–9.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>3.</b> Sandoval C, Swift M. Treatment of lymphoid malignancies
              in patients with ataxia-telangiectasia. Med Pediatr Oncol1998;
              31:491–7.
            </p>
            <p className="bibliography-font">
              <b>4.</b> Klastersky J. Side effects of ifosfamide. Oncology
              2003;65 (Suppl. 2):7–10.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>5.</b> Lima MV. Histological changes in bladders of patients
              submitted to ifosfamide chemotherapy even with Mesna prophylaxis.
              Cancer Chemother Pharmacol 2007; 59:643–50.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>6.</b> Jou YC. Hyperbaric oxygen therapy for cyclophosphamide
              induced intractable refractory hemorrhagic cystitis in a systemic
              lupus erythematosus patient. J Chin Med Assoc 2008; 71:218–20.
            </p>
            <p className="bibliography-font">
              <b>7.</b> Talar-Williams C, Hijazi YM, Walther MM, Linehan WM,
              Hallahan CW, Lubensky I, et al. Cyclophosphamide-induced cystitis
              and bladder cancer in patients with Wegener granulomatosis. Ann
              Intern Med 1996; 124:477–84.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>8.</b> Shi-Xia X.Total body irradiation plus cyclophosphamide
              versus busulphan with cyclophosphamide as conditioning regimen for
              patients with leukemia undergoing allogeneic stem cell
              transplantation: a meta-analysis. Leuk Lymphoma 2010; 51:50–60.
            </p>
            <p className="bibliography-font">
              <b>9.</b> Garderet L, Bittencourt H, Sebe P, Kaliski A, Claisse
              JP, Espérou H, et al. Cystectomy for severe hemorrhagic cystitis
              in allogeneic stem cell transplant recipients. Transplantation
              2000; 70:1807–11.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>10.</b> Crawford ML, Waller PC, Wood SM. Severe cystitis
              associated with tiaprofenic acid. Br J Urol 1997; 79:578–84.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>11.</b> Folland DS. Acute hemorrhagic cystitis. Industrial
              exposure to the pesticide chlordimeform. JAMA 1978; 239:1052–5.
            </p>
            <p className="bibliography-font">
              <b>12.</b> Sandhu SS, Goldstraw M, Woodhouse CR. The management of
              haemorrhagic cystitis with sodium pentosan polysulphate. BJU Int
              2004; 94:845–7.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>13.</b> Denton AS, Clarke NW, Maher EJ. Non-surgical
              interventions for late radiation cystitis in patients who have
              received radical radiotherapy to the pelvis. Cochrane Database
              Syst Rev 2002;(3):CD001773.
            </p>
            <p className="bibliography-font">
              <b>14.</b> James ND. Radiotherapy with or without chemotherapy in
              muscle-invasive bladder cancer. N Engl J Med 2012;366: 1477–88.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>15.</b> Martinez DR. A novel approach for the treatment of
              radiation induced hemorrhagic cystitis with the GreenLightTM XPS
              Laser. Int Braz J Urol 2015; 41:584–7.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>
                * Material fotográfico perteneciente al archivo personal del Dr.
                Rubiano.
              </b>
            </p>
          </div>
        </AccordionBibliography>
      </div>
    </div>
  );
};

export default HemorrhagicCystitis2;
