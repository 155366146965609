import React from "react";

import AccordionBibliography from "../../../../components/doctorComponents/accordion/accordion-2";

const BasesOfChronicCystitisART = () => {
  return (
    <div className="container">
      <div className="inner-container">
        <p>
          En la comprensión de los fenómenos inflamatorios crónicos de la vejiga
          y desencadenados por diferentes agentes etiológicos se considera
          esencial la revisión del urotelio y sus diversas propiedades.
        </p>

        <p>
          El urotelio es un tejido multifuncional que{" "}
          <b>no sólo actúa como una barrera</b> entre el contenido del tracto
          urinario bajo y las capas subyacentes,{" "}
          <b>sino que actúa como un órgano sensorial</b> que procesa estos
          estímulos físicos y químicos del sistema nervioso aferente y el
          músculo liso vesical.
        </p>

        <img className="img" src="/doctor/propiedades-del-urotelio-xhdpi.png" alt="Img" />
        <p className="italics-gray">Figura 1. Propiedades del urotelio.<sup>1</sup></p>

        <p>
          El urotelio está involucrado en mecanismos sensoriales receptivos a
          estímulos térmicos, mecánicos o químicos y pueden liberar mediadores
          químicos de respuesta inflamatoria.
        </p>
        <p>
          Asimismo, la cercana vecindad a los nervios aferentes sugiere que las
          células uroteliales pueden ser objeto de la acción de
          neurotransmisores liberados por los nervios vesicales o al contrario
          sustancias químicas liberadas por las células epiteliales alteran la
          excitabilidad de los aferentes vesicales.
        </p>

        <div className="imgBox">

          <img className="img" src="/doctor/doctor-img005.png" alt="Img" />
        </div>
        <p className="italics-gray">
          Figura 2. Compromiso del urotelio en la disfunción vesical.<sup>2</sup>
        </p>

        <p>
          Se debe considerar la naturaleza de estos factores estimulantes que el
          urotelio puede procesar (TRANSDUCCIÓN) y como algunas patologías del
          tracto urinario bajo, incluidas la vejiga hiperactiva, la cistitis
          intersticial y el síndrome de vejiga dolorosa, las infecciones
          bacterianas y los efectos de la radiación ionizante o la quimioterapia
          están asociadas con alteraciones de este sistema sensorial.<sup>3</sup>
        </p>

        <img className="img" src="/doctor/module1-img2.png" alt="Img" />
        <p className="italics-gray">
          Figura 3. ¿Cómo afecta el urotelio la función vesical?3
        </p>

        <p>
          Varias patologías vesicales generan un impacto sobre la estructura y
          función del urotelio. Por ejemplo, en la Diabetes Mellitus,
          frecuentemente asociada con cistitis, existen cambios asociados con el
          estrés oxidativo y altos niveles de especies reactivas de oxígeno que,
          producen cambios en la permeabilidad y de la ultraestructura
          urotelial.
        </p>
        <p>
          El urotelio humano libera una serie de sustancias que tienen efecto
          depresor sobre la contractibilidad del músculo liso vesical (NO, PG y
          adenina nucleótidos), efecto no tan claro, y va a determinar la
          efectividad de los agentes terapéuticos intravesicales que modifican
          la función del tracto urinario inferior.
        </p>
        <b>
          Existen condiciones patológicas que afectan la FUNCIÓN SENSORIAL
          EPITELIAL:
        </b>

        <p>
          {" "}
          <b className="bold-purple">1) En la cistitis bacteriana,</b> los
          microrganismos activan vía óxido nítrico dependiente en el urotelio
          que incrementa la contractilidad vesical e inhibe la contractilidad
          ureteral (aumenta posibilidad de Reflujo Vesico-ureteral).
        </p>
        <p>
          <b className="bold-purple">
            2) Con la cistitis intersticial / síndrome de vejiga dolorosa (SVD),
          </b>
          el urotelio presenta múltiples cambios que incluyen la el incremento
          de la permeabilidad, alteración del flujo vascular, disminución en e-
          caderina e incremento de apoptosis urotelial.
        </p>
        <p>
          <b className="bold-purple">3) En la cistitis hemorrágica,</b> la
          radiación ionizante y los agentes quimioterapéuticos también ocasionan
          diversos cambios en la estructura urotelial afectando su función de
          barrera e incrementando la permeabilidad para metabolitos tóxicos a la
          pared vesical.
        </p>

        <AccordionBibliography title="BIBLIOGRAFIA">
          <div>
            <p className="bibliography-font">
              {" "}
              <b>1.</b> Lewis S., Everything you wanted to know about the
              bladder epithelium but were afraid to ask Am J Physiol Renal
              Physiol 278: F867- F874, 2000.
            </p>
            <p className="bibliography-font">
              <b>2. </b>Birder LA, Ruggieri M, Takeda M, Van Koeveringe G,
              Veltkamp S.A, Korstanje G, Parsons B, Fry C.H.(2012) How does the
              urothelium affect bladder function in health and disease?
              Neurourology and Urodynamics March, 31 (3) 293-299.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>3.</b>
              Birder L., De Groat W. (2007) Mechanism of Disease: involvement of
              urothelium in bladder dysfunction Nature Clinical Practice
              Urology. January; 4(1):46-54.{" "}
            </p>
          </div>
        </AccordionBibliography>
      </div>
    </div>
  );
};

export default BasesOfChronicCystitisART;
