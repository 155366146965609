import React from 'react'
import styles from '../../../../styles/patientStyles/components/RecommendationCard.module.css'

const RecommendationCard3 = () => {
  return (
    <>
      <div className={styles.recommendationBox1}>
        <div className={styles.recommendationImgBox}>
          <div style={{
            height: "70px",
            display: "flex"
          }} className={styles.numberBox}>
            <p className={styles.number}>
            <b className={styles.whiteB}>1</b>
            </p>
          </div>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art3-01.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className={styles.colorText}>
            Con el fin de obtener mejores resultados con ialuril ®Prefill, es
            necesario que usted{' '}
            <b className={`${styles.b}`}>
              retenga la orina por el mayor tiempo posible
            </b>
            , se recomienda como{' '}
            <b className={`${styles.b}`}>minimo 30 minutos</b>
          </p>
        </div>
      </div>

      <div className={styles.recommendationBox2}>
        <div className={styles.recommendationImgBox2}>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art3-02.png"
              alt="Info"
            />
          </div>
          <div style={{
            height: "70px",
            display: "flex"
          }} className={styles.numberBox2}>
            <p className={styles.number2}>
            <b className={styles.purpleB}>2</b>
            </p>
          </div>
        </div>
        <div>
          <p className={styles.whiteText}>
          <b className={`${styles.wb}`}>
              Realice cambios de posición luego del procedimiento
            </b>{' '}
            para que toda la vejiga se impregne con ialuril ®Prefill (sentado,
            acostado, boca arriba - abajo, de lado izquierdo y derecho)
          </p>
        </div>
      </div>

      <div className={styles.recommendationBox5}>
        <div className={styles.recommendationImgBox}>
          <div style={{
            height: "70px",
            display: "flex"
          }} className={styles.numberBox}>
            <p className={styles.number}>
            <b className={styles.whiteB}>3</b>
            </p>
          </div>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art3-03.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className={styles.colorText}>
            Recomendamos que{' '}
            <b className={`${styles.b}`}>limite el consumo de líquidos</b>{' '}
            durante las <b className={`${styles.b}`}>2 horas siguientes</b> a la
            instilación
          </p>
        </div>
      </div>

      <div className={styles.recommendationBox2}>
        <div className={styles.recommendationImgBox2}>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art3-04.png"
              alt="Info"
            />
          </div>
          <div style={{
            height: "70px",
            display: "flex"
          }} className={styles.numberBox2}>
            <p className={styles.number2}>
            <b className={styles.purpleB}>4</b>
            </p>
          </div>
        </div>
        <div>
          <p className={styles.whiteText}>
            Recuerde  <b className={`${styles.wb}`}>lavar sus manos</b> antes y
            despues de ir al baño
          </p>
        </div>
      </div>

      <div className={styles.recommendationBox3}>
        <div className={styles.recommendationImgBox}>
          <div style={{
            height: "70px",
            display: "flex"
          }} className={styles.numberBox}>
            <p className={styles.number}>
            <b className={styles.whiteB}>5</b>
            </p>
          </div>
          <div className={`${styles.imgBox2}`}>
            <img
              className={`${styles.imgRecomendation1}`}
              src="/patient/recomendaciones-art3-05.png"
              alt="Info"
            />
          </div>
        </div>
        <div>
          <p className={styles.colorText}>
            Recuerde{' '}
            <b className={`${styles.b}`}>mantener una adecuada higiene</b> de
            sus genitales
          </p>
        </div>
      </div>
    </>
  )
}

export default RecommendationCard3
