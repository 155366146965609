import ChartComponent from "../../doctorComponents/highcharts";
import Button3 from "../../doctorComponents/buttons/button-3";
import { Link } from "react-router-dom";
import { pieOptions } from "../../doctorComponents/highcharts/optionChart";
import { isRole } from "../../../services/doctorServices/userService";
import { RoleEnum } from "../../../constants/doctorConstants/enums";
import { useForm } from "react-hook-form";

const getPieOptions = (dashboardInfo) => {
  const optionss = {
    ...pieOptions,
    title: { text: "" },
  };

  if (!dashboardInfo) return optionss;

  const data = dashboardInfo.topPathologies.map((x) => ({
    y: Number(x.averageNumber),
    name: x.pathology,
  }));
  if (data[0]) {
    data[0].sliced = true;
    data[0].selected = true;
  }

  const series = [
    {
      name: "",
      colorByPoint: true,
      data: data,
    },
  ];

  return { ...optionss, series };
};

const PatientResume = ({
  dashboardInfo,
  patients,
  polls,
  repDashboardInfo,
}) => {
  const isRepresentative = isRole(RoleEnum.representative);

  const top100Films = [
    { id: 1, name: "Cédula de ciudadanía" },
    { id: 2, name: "Cédula de extranjería" },
    { id: 3, name: "Tarjeta de identidad" },
    { id: 4, name: "Otro" },
  ];

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { register, setValue, handleSubmit, formState, watch } = methods;

  return (
    <div className="file">
      {!isRepresentative && (
        <div>
          <div className="div-center">
            <p className="title-2">Top 3 patologías más frecuentes</p>
          </div>
          <ChartComponent options={getPieOptions(dashboardInfo)} />
        </div>
      )}

      {/*   {isRepresentative && (
        <div>
          <AutocompleteSelect
            name="doctorList"
            label="Seleccione un médico"
            options={top100Films}
            register={register}
            watch={watch}
            setValue={setValue}
          />
        </div>
      )} */}

      <div className="icon-container">
        <div className="file-segment">
          <div className="icon-background-A">
            <img className="file-icon" src="/doctor/name-icon.svg" alt="icon" />
          </div>

          {!isRepresentative && (
            <div className="file-info">
              <h1 className="file-title">Mis pacientes:</h1>
              <p className="file-name">{patients}</p>
            </div>
          )}

          {isRepresentative && (
            <div className="file-info">
              <h1 className="file-title">Mis médicos:</h1>
              <p className="file-name">{repDashboardInfo.quantity}</p>
            </div>
          )}
        </div>
        {isRepresentative && (
          <div className="file-segment-a">
            <div className="icon-background-B">
              <img
                className="file-icon"
                src="/patient/instillationTableGreen.svg"
                alt="icon"
              />
            </div>
            <div className="file-info">
              <h1 className="file-title">Pacientes con encuestas:</h1>
              <p className="file-name">{repDashboardInfo.patientWithSurveys}</p>
            </div>
          </div>
        )}
        <div className="file-segment">
          <div className="icon-background-D">
            <img
              className="file-icon"
              src="/doctor/polls-icon.svg"
              alt="icon"
            />
          </div>
          {!isRepresentative && (
            <div className="file-info">
              <h1 className="file-title">Encuestas:</h1>
              <p className="file-name">{polls}</p>
            </div>
          )}

          {isRepresentative && (
            <div className="file-info">
              <h1 className="file-title">Encuestas:</h1>
              <p className="file-name">{repDashboardInfo.surveys}</p>
            </div>
          )}
        </div>
      </div>
      {!isRepresentative && (
        <Link className="a-center" to="/doctor/patient-list">
          <Button3 label="Ver listado de mis pacientes" />
        </Link>
      )}

      {isRepresentative && (
        <Link className="a-center" to="/doctor/doctor-list">
          <Button3 label="Ver listado de mis médicos" />
        </Link>
      )}
    </div>
  );
};

export default PatientResume;
