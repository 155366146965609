import React from 'react'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core'
import styles from '../../../../styles/patientStyles/components/DialogConfirm.module.css' 

const DlgFirstInstillation = ({ children, ...props }) => {
  const { dlgOpen, handleRequestClose, onAcceptClick } = props
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={dlgOpen}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <div className={styles.container}>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <button
            className={styles.confirmButtonA}
            onClick={handleRequestClose}
          >
            <p className={styles.buttonContentA}>No</p>
          </button>
          <button className={styles.confirmButtonB} onClick={onAcceptClick}>
            <p className={styles.buttonContent}>Si</p>
          </button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default DlgFirstInstillation
