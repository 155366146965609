import { getUserInfo } from "./authService";

export const isRole = (role) => {
  const userInfo = localStorage.getItem("userInfo");
  if (!userInfo) return false;
  // return JSON.parse(userInfo).roles.indexOf(role) !== -1;
  const parsedUserInfo = JSON.parse(userInfo);
  if (!parsedUserInfo.roles || !Array.isArray(parsedUserInfo.roles))
    return false;
  return parsedUserInfo.roles.includes(role);
};

export const getRoleUser = () => {
  var userInfo = getUserInfo();
  if (!userInfo) return { roles: "" };

  var role = userInfo.roles[0];
  role = role ? role.toLowerCase() : "";

  return {
    role: role,
  };
};

export const getCountry = () => {
  const userInfo = getUserInfo();
  if (!userInfo || !userInfo.country) return {};
  return userInfo.country;
};
