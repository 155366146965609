import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import styles from "../../../../styles/patientStyles/components/DialogConfirm.module.css";
import { StyleButton4 } from "../../../../styles/DoctorStyles/ui/buttons";
import SelectOutlinedInput from "../form/outlined-inputs/select";
import {
  callApi,
  adminEditCycle,
} from "../../../../services/patientServices/apiList";

const EditCycle = ({ cycleId, instillationsList, callBack }) => {
  const [open, setOpen] = useState(false);

  const getNroInstillations = (list) => {
    var a = list.find((x) => x.state == 0);
    const result = numberA(a);
    var newA=0;
    if(result== undefined){
      var b = list.find((x) => x.state == 1);
      const resultB = numberA(b);
      if(resultB== undefined){
        newA = list.length;
      }else{
        newA = resultB;
      }
    }else{
      newA=result;
    }
    const nInstillationsStart = newA+1;
    const options = [];
    for (var x = nInstillationsStart<4?4:nInstillationsStart ; x <= 12; x++) {
      options.push(x);
    }
    return options.map((x) => ({ id: x, name: x + "" }));
  };

  const defaultValues = {
    nroInstillations: null,
  };
  const methods = useForm({ defaultValues });
  const { register, handleSubmit, formState, watch, setValue, trigger } =
    methods;
  const { errors } = formState;

  const registerOptions = {
    nroInstillations: { required: "seleccione una opción" },
    nroInstillationsMaintenance: { required: "seleccione una opción" },
  };

  const onClose = () => {
    setValue("nroInstillations", null);
    setOpen(false);
  };

  const numberA = (item) => {
    if(item == undefined){return;}
    return item.number;

  }

  const anySurveyFill = (list) => {
    var a = list.find((x) => x.type == 1);
    const result =  a == undefined;
    const result2 = cycleId > 0;
    return result && result2;
  };

  const onSubmit = handleSubmit((data) => {
    var cycle = {
      Id: cycleId,
      Number: data.nroInstillations,
    };
    callApi(
      () => adminEditCycle(cycle),
      () => {
        onClose();
        callBack();
      }
    );
  });

  return (
    <>
      {anySurveyFill(instillationsList) && (
        <>
          <StyleButton4
            onClick={() => {
              setOpen(true);
            }}
          >
            Editar # de instilaciones
          </StyleButton4>
          <Dialog
            open={open}
            keepMounted
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    width: "50px",
                  }}
                  src="/patient/error-icon.svg"
                  alt=""
                />
              </div>
            </DialogTitle>
            <DialogContent>
              <div>
                <h3>
                  Seleccione el nuevo número de instilaciones para presente
                  ciclo
                </h3>
                <FormProvider {...methods}>
                  <SelectOutlinedInput
                    name="nroInstillations"
                    label="Número de instilaciones formuladas"
                    placeholder="Seleccione número de instilaciones formuladas"
                    register={register}
                    registerOptions={registerOptions}
                    options={getNroInstillations(instillationsList)}
                    required
                    errors={errors}
                    value={watch("nroInstillations") ?? ""}
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className={"div-horizontal"}
                  >
                    <div className={styles.buttonBox}>
                      <button onClick={onClose} className={styles.buttonSmallW}>
                        <p className={styles.buttonContentW}>Cancelar</p>
                      </button>
                      <button onClick={onSubmit} className={styles.buttonSmall}>
                        <p className={styles.buttonContent}>Confirmar</p>
                      </button>
                    </div>
                  </div>
                </FormProvider>
              </div>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

export default EditCycle;
