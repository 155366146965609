import React from "react";
import BreadCrumbComp from "../../breadcrumb/index";
import { getCountry } from "../../../../services/doctorServices/userService";

const BreadcrumbsHeader = (props) => {
  const country = getCountry();

  return (
    <div>
      <div
        className="header-img"
        style={{
          background: `url("${props.img}") center no-repeat`,
          backgroundColor: "#9c9c9c",
        }}
      >
        <div
          style={{
            maxWidth: "576px",
            margin: "0 auto",
            height: "200px",
            display: "flex",
            justifyContent: "space-between",
          }}
          className="header-bread"
        >
          <BreadCrumbComp />
          <p className="header-subtitle">{props.subtitle}</p>
          <p className="header-register">
            {country === "CO" ? "Registro Invima 2014DM-0012306" : ""}
          </p>
        </div>
      </div>
      <p className="header-2-bread-title">{props.title}</p>
    </div>
  );
};

export default BreadcrumbsHeader;
//#9c9c9c
