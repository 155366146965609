import React, { useState } from "react";
import { CssFormControlField, useStyles } from "../../../../styles/DoctorStyles/ui/form";
import { OutlinedInput, InputLabel, FormHelperText } from "@material-ui/core";

const ConfirmPasswordOutlinedInput = ({
  name,
  label,
  labelwith,
  required,
  register,
  registerOptions,
  errors,
  passwordToCheck,
  onChange,
  ...remaining
}) => {
  const classes = useStyles();
  const [isMatch, setIsMatch] = useState(false);

  const registerOption =
    registerOptions && registerOptions[name]
      ? registerOptions[name]
      : undefined;

  let isError = false;
  let errorMessage = "";
  if (errors && errors.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errors[name].message;
  }

  return (
    <div>
      <CssFormControlField
        size="small"
        className={classes.root}
        variant="outlined"
      >
        <InputLabel
          className={classes.margin}
          htmlFor="outlined-adornment-password"
        >
          {label}
          {required ? <span style={{ color: "red" }}> *</span> : ""}
        </InputLabel>
        <OutlinedInput
          {...register(name, registerOption)}
          id="outlined-adornment-password_repeat"
          className={classes.margin}
          type={"password"}
          defaultValue=""
          error={isError}
          onChange={(e) => {
            passwordToCheck === e.target.value
              ? setIsMatch(true)
              : setIsMatch(false);
            onChange(e);
          }}
          {...remaining}
          labelWidth={labelwith}
        />
        <FormHelperText id={`${name}-helper-text`}>
          {errorMessage && (
            <>
              <img src="/doctor/error-icon.svg" alert="Error icon" alt="icon" />
              {errorMessage}
            </>
          )}
        </FormHelperText>
      </CssFormControlField>
    </div>
  );
};

export default ConfirmPasswordOutlinedInput;
