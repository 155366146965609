import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    callReloadAlerts: true
  }

const callReloadAlertsSource = createSlice({
  name: "callReloadAlertsSource",
  initialState,
  reducers: {
    setCallReloadAlertsSource: (state, action) => {
      state.callReloadAlerts = action.payload
    },
  },
});

export const {setCallReloadAlertsSource} = callReloadAlertsSource.actions
export const selectCallReloadAlerts = (state) => state.callReloadAlertsState.callReloadAlerts;
export default callReloadAlertsSource.reducer;
