import React from "react";
import PreRegisterBanner from "../../components/doctorComponents/banners/pre-register-banner";

const LoginLayout = (props) => {
  return (
    <div
      style={{
        background: "linear-gradient(to bottom left, #CF63CF, #9DE5FF)",
      }}
    >
      <div
        style={{
          backgroundImage: `url("${props.img}")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="main">
          <PreRegisterBanner />
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
