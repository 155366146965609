import React from 'react'
import PageOk from "../../../../components/patientComponents/component/pageOk"
import { GreenCheck, Text
} from '../../../../components/patientComponents/styled-components/reset-password/success/styled'

const SuccessResetPassword = () => {
    return (
        <PageOk>
            <GreenCheck src="/patient/tick-in-box.svg" alt="Green icon check" />
            <Text>
                Su contraseña ha sido actualizada exitosamente, ya puede ingresar a su cuenta
            </Text>
        </PageOk>
    )
}

export default SuccessResetPassword;
