import styled from 'styled-components'

export const HeaderLogo = styled.div`
    padding: 43px 16px 22px;
    background-color: #b16db3;
`

export const InnerContainer = styled.div`
    max-width: 576px;
    width: 100%;
    margin: 0 auto;
`

export const Logo = styled.img`
    max-width: 150px;
    width: 100%;
    display: block;
    margin: 0 auto 72px;
`

export const Title = styled.h1`
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    margin: 0 0 15px;
`

export const Text = styled.p`
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    margin: 0;
`

export const Form = styled.form`
    padding: 30px 16px 20px;
`

export const Subtitle = styled.h5`
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #343a40;
    margin: 0 0 12px;
`

export const ValidationList = styled.ul`
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #343a40;
    margin: 0;
    padding: 0;
    list-style: none;
`

export const ValidationItemList = styled.li`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 5px;

    &:last-child {
        margin: 0;
    }
`

export const IconStateValidation = styled.img`
    max-width: 14px;
    margin-right: 4px;
`

export const MarginSeparator = styled.hr`
    margin: ${(props) => props.margin};
    border: 0;
    unicode-bidi: unset;
`

export const RegisterButton = styled.button`
    display: block;
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    padding: 10px 16px;
    margin: 50px auto 40px;
    border-radius: 6px;
    border: 2px solid #b16db3;
    background-color: #b16db3;
    box-sizing: border-box;
    box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
`

export const LoginButton = styled.p`
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #6c757d;
    text-align: center;
    margin: 0 auto 50px;

    a {
        font-family: 'Source Sans Pro', sans-serif;
        color: #b16db3;
        margin-left: 4px;
    }
`
