import React from "react";
import {StyleButton2, useStyles} from "../../../../styles/DoctorStyles/ui/buttons"

const Button2 = (props) => {
  const classes = useStyles();

  return (
    <div>
      <StyleButton2 variant="contained" color="primary" className={classes.margin} {...props}>
        {props.label}
      </StyleButton2>
    </div>
  );
}

export default Button2;
