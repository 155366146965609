import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core'
import styles from '../../../../styles/patientStyles/components/DialogConfirm.module.css'

const DialogTreatmentFinished = (props) => {
  const { dlgOpen, handleRequestClose, onEndCycle } = props
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={dlgOpen}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <div className={styles.container}>
        <DialogContent>
          <div className={styles.container}>
            <img
              className={`${styles.img}`}
              src="/patient/stethoscope.svg"
              alt="warning"
            />
            <p className={styles.text}>
              Estimado paciente, ¿ha completado su tratamiento de instilaciones
              con ialuril ® Prefill?
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <div>
            <button
              className={styles.confirmButtonA}
              onClick={() => {
                onEndCycle(false)
                handleRequestClose()
              }}
            >
              <p className={styles.buttonContentA}>Aún no</p>
            </button>
            <button
              className={styles.confirmButtonB}
              onClick={() => {
                onEndCycle(true)
                handleRequestClose()
              }}
            >
              <p className={styles.buttonContent}>Sí, terminé</p>
            </button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default DialogTreatmentFinished
