import React, { useState } from "react";
import EpstFileCard from "../../../components/doctorComponents/epsFileCard";
import { isRole } from "../../../services/doctorServices/userService";
import { RoleEnum , SurveyChartType  } from "../../../constants/doctorConstants/enums";
import DoctorFile from "../../../components/doctorComponents/doctorFile/index";
import Progress from "../../../components/doctorComponents/Progress";
import { useSelector } from "react-redux";
import {selectEpsDetailReduce} from "../../../redux/epsDetailSlice";


const EpsDetails = () => {
  const epsDateReducer = useSelector(selectEpsDetailReduce);

  const isInstitution = isRole(RoleEnum.institution);
  const [loader, setLoader] = useState(false);
  const [currentEps, setCurrentEps] = useState(null);

  if (isInstitution) {
    return (
      <div className="container">
        <div className="inner-container">
          <EpstFileCard
            name={epsDateReducer.fullName}
            cicle={epsDateReducer.patientQuantity}
            instillation={epsDateReducer.surveyQuantity}
            setCurrentEps={setCurrentEps}
            epsId= {epsDateReducer.epsId}
          />
          <DoctorFile id={epsDateReducer.epsId} type={SurveyChartType.epsDetail} nameEps={epsDateReducer.fullName}/>
        </div>
        <Progress loader={loader} />
      </div>
    );
  }

};

export default EpsDetails;
