import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    "& .MuiPaginationItem-page": {
      background: "#E4C8E1",
      color: "#AE5CA4",
      "&:hover": {
        background: "#E9ECEF",
      },
    },
    "& .Mui-selected": {
      background: "#AE5CA4",
      color: "#FFFFFF",
    },
  },
}));

const PaginationComp = ({ shape="rounded", ...restProps }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Pagination shape={shape} {...restProps} />
    </div>
  );
};

export default PaginationComp;
