import React, { useEffect, useState } from "react";
import ColumnChart from "../../../components/doctorComponents/highcharts/columChart";
import BasicLine from "../../doctorComponents/highcharts/basicLine";
import { getQuestionTitle } from "../../../constants/doctorConstants/index";
import { useForm } from "react-hook-form";
import SelectOutlinedInput from "../../../components/doctorComponents/form/outlined-inputs/select";
import { isRole } from "../../../services/doctorServices/userService";
import { RoleEnum } from "../../../constants/doctorConstants/enums";
import ButtonXS from "../buttons/button-xs";
import PreviewReportPatient from "./previewReportPatient";

const getChartEvaOptions = (
  patientInfo,
  questionNumber,
  cycleId,
  isTotalTab
) => {
  const optionsBase = {
    chart: {
      type: "line", //'scatter',
    },
    title: {
      text: "",
    },
    xAxis: {
      labels: {
        y: 10,
      },
      title: {
        padding: 15,
        text: "Instilaciones",
      },
      categories: [],
    },
    legend: {
      enabled: false,
      reversed: false,
    },
    yAxis: {
      title: {
        text: "Niveles Escalas Visual Analoga",
      },
      labels: {
        formatter: function () {
          return this.value;
        },
      },
    },
    plotOptions: {
      marker: {
        enabled: false,
        symbol: "circle",
        radius: 2,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
    tooltip: {
      pointFormat: "Instilación: <b>{point.y}</b><br/>EVA: {point.x}",
    },
    series: [
      {
        name: "",
        data: [],
      },
    ],
    credits: {
      enabled: false,
    },
  };

  cycleId = isTotalTab ? cycleId : patientInfo.currentCycleId;

  if (!cycleId) return optionsBase;
  const answer = patientInfo.chartLineInfo.find(
    (x) => x.cycleId === cycleId && x.questionNumber === questionNumber
  );

  if (!answer) return optionsBase;

  const data = answer.data.map((x) => ({
    y: x ? x : 0,
    marker: {
      symbol: x ? (x < 1 ? "" : `url(/faces/eva-${x}.svg)`) : ``,
    },
  }));

  const options = {
    ...optionsBase,
    xAxis: {
      title: {
        text: "Instilaciones",
      },
      categories: answer.xCategories,
    },
    tooltip: {
      formatter: function () {
        return (
          "Instilación: <b>" +
          this.x +
          "<br><br/></b> EVA: <b>" +
          this.y +
          "</b>"
        );
      },
    },
    series: [
      {
        name: "",
        data: data,
      },
    ],
  };

  return options;
};

const getLineOptions = (patientInfo, questionNumber, cycleId, isTotalTab) => {
  const optionsBase = {
    chart: {
      type: "line",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [],
    },
    legend: {
      enabled: false,
      reversed: false,
    },
    yAxis: {
      labels: {
        formatter: function () {
          return this.value;
        },
      },
      title: {
        text: "",
      },
    },
    plotOptions: {
      marker: {
        enabled: false,
        symbol: "circle",
        radius: 2,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
    series: [
      {
        name: "",
        data: [],
      },
    ],
    credits: {
      enabled: false,
    },
  };

  cycleId = isTotalTab ? cycleId : patientInfo.currentCycleId;
  if (!cycleId) return optionsBase;
  const answer = patientInfo.chartLineInfo.find(
    (x) => x.cycleId === cycleId && x.questionNumber === questionNumber
  );

  if (!answer) return optionsBase;

  const options = {
    ...optionsBase,
    xAxis: {
      title: {
        text: "Instilaciones",
      },
      categories: answer.xCategories,
    },
    yAxis: {
      labels: {
        formatter: function () {
          return answer.yCategories[this.value];
        },
      },
      title: {
        text: "",
      },
    },
    tooltip: {
      formatter: function () {
        return (
          "La respuesta en la instilación <b>" +
          this.x +
          "<br><br/></b> fue <b>" +
          answer.yCategories[this.y] +
          "</b>"
        );
      },
    },
    series: [
      {
        name: "",
        data: answer.data,
      },
    ],
  };

  return options;
};

const getColumnSeriesOptions = (
  patientInfo,
  questionNumber,
  cycleId,
  isTotalTab
) => {
  var optionsBase = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    credits: {
      enabled: false,
    },
  };

  cycleId = isTotalTab ? cycleId : patientInfo.currentCycleId;
  if (!cycleId) return optionsBase;
  const answer = patientInfo.chartColumnInfo.find(
    (x) => x.cycleId === cycleId && x.questionNumber === questionNumber
  );

  if (!answer) return optionsBase;

  const yAxisTitle = "";
  const categories = answer.xCategories;
  const series = answer.series;
  const yCategories = ["No", "Si"];

  var options = {
    ...optionsBase,
    xAxis: {
      title: {
        text: "Instilaciones",
      },
      categories: categories,
      crosshair: true,
    },
    yAxis: {
      labels: {
        formatter: function () {
          return yCategories[this.value];
        },
      },
      title: {
        text: "",
      },
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:10px">Instilación {point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    series: series,
  };
  return options;
};

const getYesNoOptions = (patientInfo, questionNumber, cycleId, isTotalTab) => {
  var optionsBase = {
    chart: {
      type: "line",
    },
    title: {
      text: "",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };
  cycleId = isTotalTab ? cycleId : patientInfo.currentCycleId;

  if (!cycleId) return optionsBase;
  const answer = patientInfo.chartLineInfo.find(
    (x) => x.cycleId === cycleId && x.questionNumber === questionNumber
  );

  if (!answer) return optionsBase;
  const rtaSi = answer.dataYN.map((x) => (x ? 1 : 0));
  // const rtaNo = answer.dataYN.map((x) => (!x ? 1 : 0));

  const options = {
    ...optionsBase,
    xAxis: {
      title: {
        text: "Instilaciones",
      },
      categories: answer.xCategories,
    },
    yAxis: {
      title: {
        text: "",
      },
      tickInterval: 1,
      range: 1,
      max: 1,
      labels: {
        formatter: function () {
          return this.value === 0 ? "NO" : "SI";
        },
      },
    },
    tooltip: {
      formatter: function () {
        return (
          "Instilación: <b>" +
          this.x +
          "<br><br/></b> EVA: <b>" +
          this.y +
          "</b>"
        );
      },
    },
    series: [
      {
        name: "Si",
        data: rtaSi,
      },
    ],
    plotOptions: {
      series: {
        pointWidth: 15,
      },
      column: {
        stacking: "normal",
      },
    },
  };

  return options;
};

const getCycleName = (patientInfo, cycleId, isTotalTab) => {
  cycleId = isTotalTab ? cycleId : patientInfo.currentCycleId;

  if (!cycleId) return "";
  const answer = patientInfo.cycles.find((x) => x.id === cycleId);

  if (answer == null) {
    return "";
  }
  return answer.name;
};

const Tab1 = ({ patientInfo, questionNumber, isTotalTab }) => {
  const isInstitution = isRole(RoleEnum.institution);
  const [dlgPreview, setDlgPreview] = useState(false);

  const getSeries = (patientInfo, questionNumber) => {
    var summary = isTotalTab
      ? patientInfo.allCyclesSummary
      : patientInfo.cycleSummary;
    const answers = summary.filter((x) => x.questionNumber === questionNumber);
    const data = answers.map((x) => ({
      y: Number(x.percentage),
      name: x.text,
      quantity: x.quantity,
    }));
    if (data.length > 0) {
      data[0].sliced = true;
      data[0].selected = true;
    }

    const series = [
      {
        name: "Instilaciones",
        colorByPoint: true,
        data: data,
      },
    ];

    return series;
  };

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { register, watch, setValue } = methods;

  const pieAdditionalOptions = {
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format:
            "<b>{point.name}</b>: {point.percentage:.1f}% ({point.quantity})",
        },
        showInLegend: true,
      },
    },
  };

  const onDownload = async () => {
    setDlgPreview(true);
  };

  return (
    <div>
      {patientInfo && (
        <>
          {isTotalTab && (
            <>
              {!watch("cycleId") &&
                setValue("cycleId", patientInfo.cycles[0].id)}
              <SelectOutlinedInput
                name="cycleId"
                label="Seleccione el ciclo"
                options={patientInfo.cycles}
                register={register}
                registerOptions={{}}
                value={watch("cycleId")}
                required
              />
            </>
          )}
          {isInstitution && (
            <div className="div-center">
              <ButtonXS label="Descargar consolidado" onClick={onDownload} />
            </div>
          )}
        </>
      )}
      {questionNumber !== 2 && questionNumber !== 3 && questionNumber !== 4 && (
        <>
          <ColumnChart
            questionTitle={getQuestionTitle(questionNumber)}
            options={getYesNoOptions(
              patientInfo,
              questionNumber,
              watch("cycleId"),
              isTotalTab
            )}
          />
        </>
      )}
      {(questionNumber === 2 ||
        questionNumber === 3 ||
        questionNumber === 4) && (
        <>
          <p className="title-2">{getQuestionTitle(questionNumber)}</p>
          <BasicLine
            options={getLineOptions(
              patientInfo,
              questionNumber,
              watch("cycleId"),
              isTotalTab
            )}
          />
        </>
      )}
      {patientInfo && questionNumber === 1 && (
        <>
          <p className="title-2">
            {"Nivel de dolor según la Escala Visual Análoga (EVA)"}
          </p>
          <BasicLine
            options={getChartEvaOptions(
              patientInfo,
              11,
              watch("cycleId"),
              isTotalTab
            )}
          />
        </>
      )}
      {patientInfo && isInstitution && (
        <>
          <PreviewReportPatient
            open={dlgPreview}
            onClose={() => setDlgPreview(false)}
            currentCycleId={
              isTotalTab ? watch("cycleId") : patientInfo.currentCycleId
            }
            namePdf={patientInfo.patientDocument}
            type={4}
            patientInfo={patientInfo}
            cycleName={getCycleName(patientInfo, watch("cycleId"), isTotalTab)}
          />
        </>
      )}
    </div>
  );
};

export default Tab1;
