import React from "react";
import { Link } from "react-router-dom";

const PatientBox = (props) => {
  return (
    <Link to={props.href}>
      <div className="card-container">
        <img className="boxImg" src={props.img} alt="Box image cap" />
        <div className="card-container-title">
          <p className="title">{props.title}</p>
          <img className="card-icon" src="/doctor/arrow-1.svg" alt="Info" />
        </div>
      </div>
    </Link>
  );
};

export default PatientBox;
