import React from 'react'
import styles from '../../../styles/patientStyles/components/HeaderC.module.css'

import IalurilTextBox from './ialurilTextBox'

const HeaderC = (props) => {
  return (
    <div className={styles.headerBox}>
      <div className={styles.innerContainer}>
        {/* <Link href="/">
        <img className={styles.logo} src="/logo-white.svg" alt="ialuriApp Logo"/>
        </Link> */}
        <IalurilTextBox
          color="ligth"
          title={props.title}
          content={props.content}
        />
      </div>
    </div>
  )
}

export default HeaderC
