import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Button1 from "../../../components/doctorComponents/buttons/button-1";
import ModalPWA from "../../../components/doctorComponents/modalPWA/index";
import { PublicLocation } from "../../../services/common/locationService";

const Message = () => {
  const [publicCountry, setPublicCountry] = useState("Colombia");
  let history = useHistory();

  PublicLocation()
    .then((countryName) => {
      console.log("Country Name:", countryName);
      setPublicCountry(countryName);
    })
    .catch((error) => {
      console.error("Error:", error);
    });

  return (
    <>
      <div className="container">
        <div className="inner-container">
          <img
            className="ialurilLogoWhite2"
            src="/doctor/logo-white-menu.svg"
            alt="IalurilLogoWhite"
          />
          <p className="white-title">Estimado doctor (a)</p>

          <p className="white-font-1">
            Al igual que para usted, el objetivo de nuestra labor son los
            pacientes, por ese motivo hemos desarrollado la aplicación ialuril
            App.
          </p>
          <p className="white-font-1">
            Esta nueva plataforma busca informar y acompañar al especialista y
            sus pacientes proporcionándoles herramientas que permitan al
            paciente un mejor entendimiento, seguimiento y cuidado de su
            enfermedad empoderándolos en el manejo de la misma.
          </p>
          <p className="white-font-1">
            Queremos invitarlo a conocer ialuril App y a compartirla con sus
            pacientes, cuidadores y otros profesionales de la salud y así
            permitirnos acompañarlos en la educación de sus pacientes y el
            seguimiento permanente.
          </p>
          <p className="white-font-1">
            El contenido que usted y sus pacientes encontrara en Ialuril App no
            es promocional.
          </p>
          <p className="white-font-1">
            Este mensaje está dirigido exclusivamente a profesionales de la
            salud en {publicCountry == "Colombia" ? "Colombia" : "Costa Rica"}.
          </p>

          <p className="white-font-1">
            Todo evento adverso debe ser notificado. El formulario de
            notificación e información se encuentra disponible en{" "}
            {publicCountry == "Colombia" ? (
              <a
                href="https://www.seranestpharma.com/index.php/tecnovigilancia"
                target="_blank"
              >
                tecnovigilancia
              </a>
            ) : (
              <a
                href="https://www.seranestpharma.com/index.php/tecnovigilancia"
                target="_blank"
              ></a>
            )}
            {publicCountry == "Colombia"
              ? ". Los eventos adversos también pueden ser notificados a servicioalcliente@seranestpharma.com."
              : ". Los eventos adversos también pueden ser notificados a CR-CONTENT"}
          </p>
          <p className="white-font-1">
            {publicCountry == "Colombia"
              ? "Solicite mayor información científica de nuestros productos en nuestro servicio de información médica, a través del email: asesoriamedica@seranestpharma.com."
              : "CR-CONTENT"}
          </p>
          <br />
          <br />

          <div className="div-center">
            <Button1
              onClick={() => history.push("/doctor/login")}
              label="Iniciar sesión"
            />
            <br />

            <p className="white-font-1">
              ¿No tiene una cuenta?{" "}
              {/* <Link to="/doctor/register" className="white-font-underline"> */}
              <Link
                to="/selectCountry?app=doctor"
                className="white-font-underline"
              >
                Regístrese
              </Link>
            </p>
            <br />
            <br />
          </div>
        </div>
      </div>
      <ModalPWA />
    </>
  );
};

export default Message;
