import React from "react";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form } from "../../styled-components/register/styled";
import TextOutlinedInput from "../../../patientComponents/component/form/outlined-inputs/text";
import styles from "../../../../styles/patientStyles/components/ModalButtons.module.css";
import { getCountry } from "../../../../services/doctorServices/userService";

const AddDoctor = (props) => {
  const { open, onClose, onAddDoctor } = props;

  const methods = useForm();
  const { register, handleSubmit, formState, reset } = methods;
  const { errors } = formState;

  const registerOptions = {
    name: { required: "Campo obligatorio" },
    lastName: { required: "Campo obligatorio" }
  };

  const onSubmit = handleSubmit((data) => {
    const country = getCountry();

    const doctor = {
      name: data.name,
      lastName: data.lastName,
      countryId: country === "CO" ? 1 : 2
    };
    onAddDoctor(doctor, () => {
      reset({
        name: "",
        lastName: ""
      });
    });
  });

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Adicionar otro médicoaaa"}
      </DialogTitle>
      <DialogContent>
        <TextOutlinedInput
          name="name"
          label="Nombres"
          placeholder="Ingrese los nombres de su médico"
          type={"text"}
          register={register}
          registerOptions={registerOptions}
          required
          errors={errors}
        />
        <TextOutlinedInput
          name="lastName"
          label="Apellidos"
          placeholder="Ingrese los apellidos de su médico"
          type={"text"}
          register={register}
          registerOptions={registerOptions}
          required
          errors={errors}
        />
        <div className={styles.buttonBox}>
          <button className={styles.confirmButtonA} onClick={onClose}>
            <p className={styles.buttonContentA}>Cerrar</p>
          </button>
          <button className={styles.confirmButtonB} onClick={onSubmit}>
            <p className={styles.buttonContentB}>Agregar</p>
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddDoctor;
