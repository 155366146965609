import React, { useState, useEffect } from "react";
import Slider from "@material-ui/core/Slider";
import styled from "styled-components";
import { colorSatisfaction } from "./qualificationColors";

const valuetext = (value) => {
  return `${value}`;
};

const Qualification = (props) => {
  const { survey, isNextClick, onNextClick } = props;

  const [value, setValue] = useState(1);

  const saveAsync = async () => {
    const obj = { ...survey, question1_1: value };
    onNextClick(obj);
  };

  const loadData = () => {
    setValue(survey && survey.question1_1 ? survey.question1_1 : 1);
  };

  useEffect(() => {
    if (survey) loadData();
  }, [survey]);

  useEffect(async () => {
    if (isNextClick) await saveAsync();
  }, [isNextClick]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  var faceManagment = () => {
    let i = 0;
    let faceArray = [];
    for (i; i < 10; i++) {
      faceArray.push(
        <img
          style={{ width: "35px" }}
          src={`${colorSatisfaction[i].img}`}
          alt="face"
        ></img>
      );
    }
    return faceArray;
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div style={{ width: "max-content" }}>{faceManagment()}</div>
      <div style={{ width: "320px" }}>
        <StyledSlider
          value={value}
          onChange={handleChange}
          getAriaValueText={valuetext}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="on"
          step={1}
          marks
          min={1}
          max={10}
          ThumbComponent="div"
        />
      </div>
    </div>
  );
};

export default Qualification;

// Overwrite some component styles
//      background: url('${(props) => colorSatisfaction[props.value].img}') center
const StyledSlider = styled(Slider)`
  .MuiSlider-thumb {
    .MuiSlider-valueLabel {
        no-repeat;
      background-size: 36px;
      top: -30px;

      & > span {
        position: relative;
        top: 37px;
        border-radius: 50%;
        transform: none;
        background: transparent;

        & > span {
          font-family: 'Source Sans Pro', sans-serif;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          transform: none;
        }
      }
    }
  }
`;
