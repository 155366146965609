import React from "react";
import { useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { isRole } from "../../../services/doctorServices/userService";
import {
  RoleEnum,
  InstitutionListTypeEnum,
} from "../../../constants/doctorConstants/enums";
import { IconStateValidation } from "../../../components/patientComponents/styled-components/register/styled";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Button2 from "../../doctorComponents/buttons/button-2";
import { useDispatch } from "react-redux";
import TelegramIcon from "@material-ui/icons/Telegram";

import { setEpsDetailReduce } from "../../../redux/epsDetailSlice";

const PatientFileCard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isRepresentative = isRole(RoleEnum.representative);
  const isInstitution = isRole(RoleEnum.institution);

  const onClick = (link, item) => {
    if (
      isInstitution &&
      props.institutionListType == InstitutionListTypeEnum.epsList
    ) {
      dispatch(
        setEpsDetailReduce({
          fullName: item.fullName,
          epsId: item.epsId,
          patientQuantity: item.patientQuantity,
          surveyQuantity: item.surveyQuantity,
        })
      );
    }
    if (!isRepresentative && item.completedSurveys === 0) {
      NotificationManager.warning("El paciente no tiene encuestas finalizadas");
      return;
    }
    history.push({
      pathname: link,
      state: { detail: item },
    });
  };

  if (isRepresentative) {
    return (
      <div className="file">
        <div>
          <div className="file-segment">
            <div className="icon-background-A">
              <img
                className="file-icon"
                src="/doctor/name-icon.svg"
                alt="icon"
              />
            </div>
            <div className="file-info">
              <h1 className="file-title">Nombre del médico:</h1>
              <p className="file-name">{props.name} </p>
              <div>
                {!props.registered ? (
                  <a
                    className="a-end"
                    onClick={() => {
                      props.setDlgDoctorInv(true);
                      props.setCurrentDoctor({ ...props });
                    }}
                  >
                    <div title="Invitar Usuario">
                      <PersonAddIcon color="secundary" />
                    </div>
                  </a>
                ) : (
                  <div className={"container-horizontal-img"}>
                    <IconStateValidation src={"/patient/tick-green.svg"} />
                    <a
                      className="a-end"
                      onClick={() => {
                        props.setDlgDoctorInv(true);
                        props.setCurrentDoctor({ ...props });
                      }}
                    >
                      <div title="Invitar Usuario">
                        <TelegramIcon color="secundary" />
                      </div>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="file2Obj">
            <div className="fileobj">
              <div className="icon-background-B">
                <img
                  className="file-icon"
                  src="/doctor/instillation-icon.svg"
                  alt="icon"
                />
              </div>
              <div className="file-info">
                <h1 className="file-title">Cantidad de pacientes:</h1>
                <p className="file-name">{props.cicle}</p>
              </div>
            </div>
            <div className="fileobj">
              <div className="icon-background-B">
                <img
                  className="file-icon"
                  src="/patient/instillationTableGreen.svg"
                  alt="icon"
                />
              </div>
              <div className="file-info">
                <h1 className="file-title">
                  Cantidad de pacientes con encuestas:
                </h1>
                <p className="file-name">{props.patientsSurveyed}</p>
              </div>
            </div>
          </div>

          <div className="file-segment">
            <div className="icon-background-C">
              <img
                className="file-icon"
                src="/doctor/cicle-icon.svg"
                alt="icon"
              />
            </div>
            <div className="file-info">
              <h1 className="file-title">Cantidad de encuestas:</h1>
              <p className="file-name">{props.instillation}</p>
            </div>
          </div>
        </div>
        {props.link && (
          <a className="a-end" onClick={() => onClick(props.link, props.item)}>
            Ver detalles
          </a>
        )}
      </div>
    );
  }

  if (
    isInstitution &&
    props.institutionListType == InstitutionListTypeEnum.epsList
  ) {
    return (
      <div className="file">
        <div>
          <div className="file-segment">
            <div className="icon-background-A">
              <img
                className="file-icon"
                src={"/doctor/eps-orange.svg"}
                alt="icon"
              />
            </div>
            <div className="file-info">
              <h1 className="file-title">Nombre de la EPS:</h1>
              <p className="file-name">{props.name} </p>
            </div>
          </div>
          <div className="file-segment">
            <div className="icon-background-B">
              <img
                className="file-icon"
                src="/doctor/instillation-icon.svg"
                alt="icon"
              />
            </div>
            <div className="file-info">
              <h1 className="file-title">Cantidad de pacientes:</h1>
              <p className="file-name">{props.cicle}</p>
            </div>
          </div>
          <div className="file-segment">
            <div className="icon-background-C">
              <img
                className="file-icon"
                src="/doctor/cicle-icon.svg"
                alt="icon"
              />
            </div>
            <div className="file-info">
              <h1 className="file-title">Cantidad de encuestas:</h1>
              <p className="file-name">{props.instillation}</p>
            </div>
          </div>
        </div>
        {props.link && (
          <a className="a-end" onClick={() => onClick(props.link, props.item)}>
            Ver detalles
          </a>
        )}
      </div>
    );
  }

  return (
    <div className="file">
      <div>
        <div className="file-segment">
          <div className="icon-background-A">
            <img className="file-icon" src="/doctor/name-icon.svg" alt="icon" />
          </div>
          <div className="file-info">
            <h1 className="file-title">Nombre paciente:</h1>
            <p className="file-name">{props.name}</p>
          </div>
        </div>
        <div className="file-segment">
          <div className="icon-background-B">
            <img
              className="file-icon"
              src="/doctor/cicle-icon.svg"
              alt="icon"
            />
          </div>
          <div className="file-info">
            <h1 className="file-title">Ciclo actual:</h1>
            <p className="file-name">{props.cicle}</p>
          </div>
        </div>
        <div className="file-segment">
          <div className="icon-background-C">
            <img
              className="file-icon"
              src="/doctor/instillation-icon.svg"
              alt="icon"
            />
          </div>
          <div className="file-info">
            <h1 className="file-title">Instilación actual:</h1>
            <p className="file-name">
              {" "}
              {props.instillation == "" || props.instillation == null
                ? ""
                : "N°" + props.instillation}
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.link && (
          <Button2
            onClick={() => {
              onClick(props.link, props.item);
            }}
            label="Ver Detalles"
          />
        )}
      </div>
    </div>
  );
};

export default PatientFileCard;
