import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { articlesAccordionGTM } from "../../../../googleTagManager/doctor";

const Accordion = withStyles({
  root: {
    border: "#F5F5F5",
    borderRadius: "6px",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#F5F5F5",
    boxShadow: "0px 0px 5px rgba(52, 58, 64, 0.25)",
    borderRadius: "6px",
    marginTop: 10,
    marginBottom: 20,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  acordionArrow: {
    "&$expanded": {
      display: "none",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    display: "contents",
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const AccordionComponent = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div onClick={() => articlesAccordionGTM(props.article, props.title)}>
      <Accordion
        square
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={
            <img className="vector-arrow" src="/doctor/Vector1.svg" alt="Img" />
          }
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <div className="accordion-title">
            <p className="title-purple">{props.title}</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>{props.children}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionComponent;
