import React from "react";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import EmailFilledInput from "../../../components/doctorComponents/form/filled-inputs/email";
import Button1 from "../../../components/doctorComponents/buttons/button-1";
import {
  callApi,
  postRestorePassword,
} from "../../../services/doctorServices/apiService"

const RecoverPassword = () => {
  let history = useHistory();

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { register, handleSubmit, formState } = methods;
  const { errors } = formState;

  const registerOptions = {
    email: {
      required: "Debe ingresar un correo electrónico",
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: "Este correo electrónico no es válido",
      },
    },
  };
  const onSubmit = handleSubmit((data) => {
    const user = { email: data.email }
    callApi(
      () => postRestorePassword(user),
      () => {
        history.push('/doctor/recover-password-send')
      }
    )
  })

  return (
    <>
      <div className="container">
        <div className="inner-container">
          <div className="div-center">
            <img
              className="ialurilLogoWhite3"
              src="/doctor/logo-white-menu.svg"
              alt="IalurilLogoWhite"
            />
          </div>

          <p className="white-title">Restablecer contraseña</p>

          <p className="white-font-1">
            Ingrese su correo electrónico, a continuación recibirá un enlace
            para restablecer su contraseña
          </p>
          <FormProvider {...methods}>
            <form noValidate autoComplete="off">
              <EmailFilledInput
                name="email"
                label="Ingrese su correo electrónico"
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                required={true}
              />
            </form>
          </FormProvider>
          <div className="div-center">
            <Button1 label="Enviar" onClick={onSubmit}/>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoverPassword;
