import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/patientStyles/components/Footer.module.css";
import { getCountry } from "../../../services/patientServices/userService";

const Footer = () => {
  const country = getCountry();

  return (
    <div className={styles.footerBox}>
      <img
        src="/patient/footer-SerAnest-xxxhdpi.png"
        className={styles.img}
        alt="SerAnestPharmaLogo"
      />
      <Link to="/patient/privacyPolicy">
        <p className={styles.link}>Política de privacidad</p>
      </Link>
      <Link to="/patient/termsAndConditions">
        <p className={styles.link}>Términos y condiciones</p>
      </Link>
      <p className={styles.footerText}>Contáctenos</p>

      <div style={{ display: "flex", justifyContent: "center", width: '170px' }}>
        <img
          style={{ padding: "0 10px 0 0" }}
          src="/patient/contactFrame1.svg"
        />
        <p className={styles.footerText2}>
          {country == "CO" ? "Alba Amaya" : "Julieta Rodriguez"}
        </p>
      </div>
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          style={{ padding: "0 10px 0 0" }}
          src="/patient/contactFrame2.svg"
        />
        <p className={styles.footerText2}>{country == "CO" ? "310-815-3352" : "(506) 89749091"}</p>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          style={{ padding: "0 10px 0 0" }}
          src="/patient/contactFrame3.svg"
        />
        <p className={styles.footerText2}> {country == "CO" ? "info@paap.com" : "j.rodriguez@seranestpharma.com"}</p>
      </div>
      <p className={styles.footerText}>
        © 2021 SerAnestPharma. Todos los derechos reservados
      </p>
    </div>
  );
};

export default Footer;
