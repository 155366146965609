import React from "react";
import { CssTextField, useStyles } from "../../../../styles/DoctorStyles/ui/form";
import { FormHelperText } from "@material-ui/core";

const TextOutlinedInput = ({
  name,
  label,
  type = "text",
  required,
  register,
  registerOptions,
  errors,
  ...others
}) => {
  const classes = useStyles();

  const registerOption =
    registerOptions && registerOptions[name]
      ? registerOptions[name]
      : undefined;

  let isError = false;
  let errorMessage = "";
  if (errors && errors.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errors[name].message;
  }

  const handleInvalidDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const txtInput = register(name, registerOption);

  return (
    <form className={classes.root} noValidate>
      <CssTextField
        id={`text-field-${name}`}
        className={classes.margin}
        name={name}
        label={label}
        type={type}
        defaultValue=""
        variant="outlined"
        size="small"
        onChange={(e) => {
          txtInput.onChange(e); // method from hook form register
          //handleChange(e) // your method
        }}
        onBlur={txtInput.onBlur}
        inputRef={txtInput.ref}
        error={isError}
        {...others}
        onInvalid={handleInvalidDefault}
      />
      <FormHelperText id={`${name}-helper-text`}>
        {errorMessage && (
          <div className="div-horizontal-form">
            <img src="/doctor/error-icon.svg" className="error-icon" alert="Error icon" alt="icon" />
            {errorMessage}
          </div>
        )}
      </FormHelperText>
    </form>
  );
};

export default TextOutlinedInput;
