import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TableHeadStyle, TableBodyStyle} from "../../../constants/doctorConstants/enums"

const PurpleTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#AE5CA4",
    color: "#FFFFFF",
  },
  body: {
    fontSize: 14,
    color: "#343A40",
  },
}))(TableCell);

const WhiteTableCell = withStyles((theme) => ({
  head: {
    height: "32px",
    backgroundColor: "#EBD6E8",
    color: "#894381",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
  },
  body: {
    fontSize: 14,
    color: "#343A40",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#EBD6E8",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: "700",
  },
});

/* TABLE DATES */

const rowInfo = (info, style) => {
  if(!info || info.length == 0)
    return <></>;

  const dataCount = Object.keys(info[0]).length;
  var x = Array.apply(null, { length: dataCount + 1 })
    .map(Number.call, Number)
    .slice(1);
  return info.map((i) => {
    if (style === TableBodyStyle.interspersed) {
      return (
        <StyledTableRow>
          {x.map((number) => {
            return (
              <PurpleTableCell align="center">
                {i[number - 1 ]}
              </PurpleTableCell>
            );
          })}
        </StyledTableRow>
      );
    } else if (style === TableBodyStyle.white) {
      return (
        <TableRow>
          {x.map((number) => {
            return (
              <PurpleTableCell align="center">
                {i[number - 1]}
              </PurpleTableCell>
            );
          })}
        </TableRow>
      );
    }
  });
};

const rowHeadInfo = (info, style) => {
  if (style === TableHeadStyle.purple) {
    return info.map((column) => (
      <PurpleTableCell align="center">{column}</PurpleTableCell>
    ));
  } else if (style === TableHeadStyle.white) {
    return info.map((column) => (
      <WhiteTableCell align="center">{column}</WhiteTableCell>
    ));
  }
};

const CustomTable = ({headstyle, bodystyle, columns, rows}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>{rowHeadInfo(columns, headstyle)}</TableRow>
        </TableHead>
        <TableBody>{rowInfo(rows, bodystyle)}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
