import React from "react";

const EducationCard3 = () => {
  return (
    <>
      <div className="educationBoxTitle">
        <p className="whiteTextTitle">
          <b className="whiteBold">Verifique tener a mano:</b>
        </p>
      </div>
      <div className="educationCard1">
        <div className="imgBox3">
          <img className="imgEducation2" src="/doctor/edu-art3-01.png" alt="Info" />
        </div>
      </div>
      <div className="educationBox2">
        <p className="number">
          <b className="whiteBold">1.</b>
        </p>
        <p className="whiteText">
          La orden de aprobación vigente para el procedimiento de instilación
          intraversical
        </p>
      </div>
      <div className="educationCard1">
        <div className="imgBox3">
          <img className="imgEducation2" src="/doctor/edu-art3-02-06.png" alt="Info" />
        </div>
      </div>
      <div className="educationBox3">
        <p className="number">
          <b className="whiteBold">2.</b>
        </p>
        <p className="whiteText">
          La orden de aprobación del dispositivo médico ialuril ®Prefill
        </p>
      </div>
    </>
  );
};

export default EducationCard3;
