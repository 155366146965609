import React from "react";
import EducationCard2 from "../../../components/doctorComponents/articleCard/educationCard2";

const Education2 = () => {
  return (
    <>
      <div className="educationInfo-innerContainer1">
        <div className="educationInfo-imgContainer">
          <EducationCard2 />
        </div>
      </div>
    </>
  );
};

export default Education2;
