import React, { useState } from "react";
import TabsMenu from "./tabsMenu";
import PaginationComp from "../../../components/doctorComponents/pagination";

const PatientFile = ({ patientInfo }) => {
  const [questionNumber, setQuestionNumber] = useState(1);

  const handleChange = (event, value) => setQuestionNumber(value);

  return (
    <div className="container">
      <div className="file">
        <h1 className="title-1">CONSOLIDADO DE SINTOMATOLOGÍA</h1>

        <TabsMenu patientInfo={patientInfo} questionNumber={questionNumber} />

        <p className="bold-purple2">Pregunta #{questionNumber}</p>
        <div className="div-center">
          <PaginationComp count={6} onChange={handleChange} />
        </div>
      </div>
    </div>
  );
};

export default PatientFile;
