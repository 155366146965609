import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PatientFile from "../../../components/doctorComponents/patienFile/index";
import {
  callApi,
  getPatienInfo,
} from "../../../services/doctorServices/apiService";
import Progress from "../../../components/doctorComponents/Progress";
import HeaderWithMenu from "../../../components/patientComponents/component/headerWithMenu/index";

const PatientDetailsFP = () => {
  const location = useLocation();
  const [patientInfo, setPatientInfo] = useState(null);

  const [loader, setLoader] = useState(false);

  const [dlgDoctorInv, setDlgDoctorInv] = useState(false);
  const [currentDoctor, setCurrentDoctor] = useState(null);

  useEffect(() => {
    callApi(
      () => getPatienInfo(location.state.detail.patientId),
      setPatientInfo,
      setLoader
    );
  }, []);

  const getProp = (location, prop) => {
    const patientData = location.state.detail;
    if (!patientData) return "";
    return patientData[prop];
  };

  return (
    <>
      <HeaderWithMenu />
      <div className="container">
        <div className="inner-container">
          <PatientFile patientInfo={patientInfo} />
        </div>
        <Progress loader={loader} />
      </div>
    </>
  );
};

export default PatientDetailsFP;
