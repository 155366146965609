import Button3 from "../../../components/doctorComponents/buttons/button-3";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  callApi,
  getInstitutionReport,
} from "../../../services/doctorServices/apiService";
import { downloadFile } from "../../../services/common/fileService";
import SelectOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/select";
import DateOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/date";
import {
  Form,
  MarginSeparator,
} from "../../../components/patientComponents/styled-components/register/styled";
import dayjs from "dayjs";

import {
  ReportType,
} from "../../../constants/patienteConstants/enums";

const Report = () => {

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { register, setValue, handleSubmit, formState, watch } = methods;
  const { errors } = formState;

  const startDateValue = watch("startDate");
  const endDateValue = watch("endDate");

  const onSubmit = handleSubmit((data) => {
    const filter = {
      typeReport: data.typeReport,
      startDate: !data.startDate
        ? null
        : dayjs(data.startDate).format("YYYY-MM-DDTHH:mm:ss"),
      endDate: !data.endDate
        ? null
        : dayjs(data.endDate).format("YYYY-MM-DDTHH:mm:ss"),
    };
    if(filter.startDate > filter.endDate){
      return;
    }
    callApi(()=>getInstitutionReport(filter), (data) => downloadFile(data));
  });

  const registerOptions = {
    typeReport: { required: "seleccione una opción" },
    startDate: { required: "seleccione una fecha" },
    endDate: { required: "seleccione una fecha" },
  };

  return (
    <div className="file">
      <Form onSubmit={onSubmit}>
        <SelectOutlinedInput
          name="typeReport"
          label="Tipo de reporte"
          options={ReportType()}
          required
          register={register}
          registerOptions={registerOptions}
          errors={errors}
        />
        <MarginSeparator margin="15px 0 0" />

        <DateOutlinedInput
          name="startDate"
          label="Fecha Inicial"
          register={register}
          defaultValue={null}
          registerOptions={registerOptions}
          errors={errors}
          required
          onChange={(date) => setValue("startDate", date)}
          value={startDateValue ?? null}
        />
        <MarginSeparator margin="20px 0 0" />
        <DateOutlinedInput
          name="endDate"
          label="Fecha Final"
          register={register}
          defaultValue={null}
          registerOptions={registerOptions}
          errors={errors}
          required
          onChange={(date) => setValue("endDate", date)}
          value={endDateValue ?? null}
        />
        <Link className="a-center" onClick={onSubmit}>
          <Button3 label="Generar Reporte" />
        </Link>
      </Form>
    </div>
  );
};

export default Report;
