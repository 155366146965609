export const DocumentTypeList = () => [
  { id: 1, name: 'Cédula de ciudadanía' },
  { id: 2, name: 'Cédula de extranjería' },
  { id: 3, name: 'Tarjeta de identidad' },
  { id: 4, name: 'Otro' },
]

export const DocumentTypeListCR = () => [
  { id: 5, name: 'Cédula de identidad' },
  { id: 6, name: 'DIMEX' },
  { id: 7, name: 'DIDI' },
  { id: 8, name: 'Cédula Jurídica' },
  { id: 9, name: 'Cédula Residencia' },
  { id: 10, name: 'Permiso Trabajo' },
  { id: 11, name: 'Extranjero' },
  { id: 12, name: 'Pasaporte' },
]

export const GenreListInt = () => [
  { id: 1, name: 'Masculino' },
  { id: 2, name: 'Femenino' },
]

export const GenreList = () => [
  { value: '1', label: 'Masculino' },
  { value: '2', label: 'Femenino' },
]

export const YesNoList = () => [
  { value: 'true', label: 'Si' },
  { value: 'false', label: 'No' },
]

export const RegimeList = () => [
  { value: '1', label: 'Contributivo' },
  { value: '2', label: 'Subsidiado' },
]

export const AffiliationTypeList = () => [
  { id: 1, name: 'Cotizante' },
  { id: 2, name: 'Beneficiario' },
  { id: 3, name: 'Independiente' },
]

export const HealthProgramTypeList = () => [
  { id: 1, name: 'EPS' },
  { id: 2, name: 'Plan complementario' },
  { id: 3, name: 'Medicina prepagada' },
  { id: 4, name: 'Póliza en salud' },
]

export const HealthProgramTypeListCR = () => [
  { id: 5, name: 'Particular' },
  { id: 6, name: 'Póliza de salud' },
]

export const HealthProgramTypeEnum = {
  eps: 1,
  supplementalPlan: 2,
  prepaidMedicine: 3,
  healthPolicy: 4,
  particular: 5,
  healthPolicycr: 6,
}

export const ProcessStateList = () => [
  {
    id: 1,
    name: 'En proceso de autorización',
  },
  {
    id: 2,
    name: 'Tratamiento autorizado',
  },
  {
    id: 3,
    name: 'Tratamiento suspendido',
  },
  {
    id: 4,
    name: 'Radicado en dispensador',
  },
  {
    id: 5,
    name: 'Tratamiento entregado por dispensador',
  },
  {
    id: 6,
    name: 'Tratamiento iniciado',
  },
  {
    id: 7,
    name: 'Tratamiento finalizado',
  },
]

export const ProcessStateEnum = {
  inAuthorizationProcess: 1,
  authorizedTreatment: 2,
  treatmentSuspended: 3,
  radicadoOnDispenser: 4,
  deliveredPerDispenser: 5,
  treatmentStarted: 6,
  treatmentFinished: 7,
}

export const CauseofSuspension = () => [
  { id: 1, name: 'Directriz médica' },
  { id: 2, name: 'Reacción adversa' },
  { id: 3, name: 'Decisión del paciente' },
]

export const CauseofSuspensionEnum = {
  medicalGuideline: 1,
  adverseReaction: 2,
  patientDecision: 3,
}

export const PlaceAttentionList = () => [
  { id: 1, name: 'IPS' },
  { id: 2, name: 'Clínicas urológicas' },
  { id: 3, name: 'Consultorio médico particular' },
]
export const PlaceAttentionEnum = {
  ips: 1,
  urologicalClinics: 2,
  privateDoctorOffice: 3,
}

export const InstillationTypeEnum = {
  regular: 0,
  maintenance: 1,
  preInstillation: 2
}
export const InstillationStateEnum = {
  Pending: 0, //  blanco
  Planned: 1, //
  Executed: 2, // colo icono encuesta (verde)
  Indeterminate: 3, // gris
}

export const CycleStateEnum = {
  new: 0,
  current: 1,
  complete: 2,
}
export const SymptomTypeEnum = {
  general: 0,
  instillation: 1,
}

export const UserStateEnum = {
  active: 1,
  inactive: 2,
  pendingVerification: 3,
  preRegister: 4,
}

export const InstillationRegularAdvance = {
  first: 1,
  half: 2,
  last: 3,
}
export const RoleEnum = {
  patient: 0,
  admin: 1,
}


export const ReportType = () => [
  {
    id: 1,
    name: 'Pacientes con 6 instilaciones consecutivas',
  },
]