import React from "react";

import AccordionComponent from "../../../../components/doctorComponents/accordion/accordion-1";
import AccordionBibliography from "../../../../components/doctorComponents/accordion/accordion-2";

const BacterialCystitisART = () => {
  return (
    <div className="container">
      <div className="inner-container">
        <p>
          La causa más común, pero no es exclusiva de inflamación es la
          infección. El síndrome clínico característico de disuria de ardor,
          incremento en la frecuencia urinaria y urgencia miccional urinaria,
          puede o no asociarse con dolor; se denominan “síntomas irritativos del
          tracto urinario inferior", y característicamente la infección no
          compromete más allá de la vejiga.<sup>1</sup>
        </p>
        <p>
          En la <b>Bacteriruria asintomática</b>, a diferencia de este cuadro
          clínico sintomático, pueden aislarse bacterias con recuentos
          significativos en orina y en ausencia de síntomas asociados.
        </p>

        <ul>
          <li>
            {" "}
            <p>
              Es más común en los adultos mayores y 30 veces más frecuente en la
              mujer con una disminución progresiva de esta proporción con el
              fenómeno asociado al aumento de la edad.<sup>2</sup>
            </p>
          </li>
          <li>
            {" "}
            <p>
              El hombre mayor tiene una actividad bactericida reducida de las
              secreciones prostáticas y la hipertrofia prostática conduce a una
              retención de orina con la consecuente posibilidad de tener una
              infección urinaria.
            </p>
          </li>
          <li>
            {" "}
            <p>
              El tratamiento antimicrobiano de esta condición raramente está
              justificado y existe evidencia creciente que la presencia de
              ciertas cepas de bacterias o especies es protectora, evitando el
              ascenso sintomático de organismos más patógenos.<sup>3</sup>
            </p>
          </li>
          <li>
            {" "}
            <p>
              El tratamiento normalmente se recomienda en mujeres embarazadas,
              antes de procedimientos endourológicos y previo a un transplante
              renal.
            </p>
          </li>
        </ul>

        <p>
          La <b>cistitis crónica recurrente</b> puede presentarse cuando aparece
          un nuevo episodio sintomático de IVU posterior a la resolución del
          cuadro clínico previo de cistitis y puede reflejar una re-infección
          por otro germen o persistencia bacteriana del mismo:
        </p>

        <p>
          Típicamente se define como tres o más episodios de IVU en un período
          de 12 meses o un periodo de latencia inferior a 6 meses entre uno y
          otro episodio.
        </p>
        <p>
          Una vez que ha ocurrido la invasión bacteriana de las células
          uroteliales, las bacterias pueden replicarse rápidamente y formar
          colonias intracelulares que pueden eludir los mecanismos de defensa
          del organismo y permanecer indetectables por largos períodos, causando
          IVU recurrente.<sup>7</sup>
        </p>
        <p>
          Es importante definir si la es una <b>cistitis no complicada</b>, con
          un tracto urinario sin alteraciones estructurales y/o funcionales, o
          es una <b>cistititis complicada</b>, donde existen anomalías
          estructurales, funcionales del tracto urinario o una condición de
          inmunosupresión del paciente.
        </p>

        <AccordionComponent article="bacterial-cystitis" title="MECANISMO DE INFECCIÓN">
          <div>
            <p className="title-gray">Infección ascendente y cronicidad</p>

            <p>
              La vía de infección más común es el ascenso de bacterias desde la
              uretra hasta la vejiga: se considera que la patogénesis de la IVU
              no complicada en mujeres{" "}
              <b>
                comienza con la colonización de uropatógenos en la vagina o en
                zonas periuretrales
              </b>
              , áreas en las que su presencia no es común ya que habitualmente
              solo se encuentran en el intestino (<i>Figura 1</i>): en un
              estudio de cultivo del introito vaginal hecho en 140 mujeres se
              encontró una colonización significativamente más alta de{" "}
              <i>Escherichia coli</i> en las pacientes con IVU recurrente (35%
              frente a 11%; {"p<0,01"}).<sup>4</sup>
            </p>
            <p>
              Los principales agentes bacterianos de las infecciones urinarias{" "}
              <b>son las mismas bacterias que colonizan el intestino</b> y
              entran en el tracto urinario después de la colonización del área
              periuretral; una vez en la vejiga se multiplican y pueden ascender
              por el uréter hacia el tracto urinario superior y el riñón. Este
              evento inflamatorio puede cronificarse de acuerdo a condiciones
              patológicas propias del huésped con recurrencia o persistencia
              bacteriana, como se mencionó anteriormente.
            </p>

            <div className="imgBox">
              <img className="img" src="/doctor/doctor-img019.png" alt="Img" />
            </div>
            <p className="italics-gray">
              Figura 1. Patogénesis de la infección urinaria.<sup>8</sup>
            </p>

            <p className="title-gray">
              Factores del huésped en las infecciones del tracto urinario
            </p>

            <p className="subtitle-purple">Sexo</p>
            <p>
              En las mujeres, las infecciones urinarias son más comunes debido a
              su anatomía: la uretra es más corta y la proximidad relativa de la
              uretra al ano, permite una contaminación bacteriana más fácil.
              También se ha demostrado que otros factores aumentan el riesgo de
              infección del tracto urinario en mujeres, en particular las
              relaciones sexuales y el uso de espermicidas, que pueden afectar
              la flora vaginal disminuyendo los lactobacilos y permitiendo una
              mayor proporción de gramnegativos potencialmente patógenos, como
              la e. <i>Coli</i> que puede colonizar el tracto genital.
            </p>
            <p>
              En el climaterio, los niveles reducidos de estrógenos aumentan el
              riesgo de atrofia vaginal, que resulta en resequedad vaginal y
              aumento del pH, con la consecuente alteración de la flora nativa y
              reduciendo la proporción de “protectora” de lactobacilos.<sup>5</sup> Además,
              los niveles bajos de estrógenos están asociados con un incremento
              del residuo de orina post-miccional en la vejiga, que es un factor
              de riesgo para infección urinaria.
            </p>
            <p className="subtitle-purple">Predisposición genética </p>
            <p>
              Las infecciones urinarias recurrentes en mujeres son más comunes
              en aquellas con antecedentes familiares, lo que sugiere un factor
              genético asociado. Existen factores propios en el huésped que
              facilitan la protección contra la IVU: las barreras físicas (el
              flujo urinario unidireccional), proteínas que previenen la
              adherencia bacteriana y el sistema inmune particular que incluye
              la actividad de los neutrófilos. El polimorfismo genético está
              asociado a la infección urinaria recurrente: los receptores de
              IL-8 (CXCR1 y CXCR2), que desempeñan un papel fundamental en el
              reclutamiento de neutrófilos, se encuentran disminuidos en la IVU
              recurrente en niños (CXCR1) y se ha demostrado su expresión
              disminuida en mujeres con IVU recurrente en comparación con los
              controles sanos.<sup>6</sup>
            </p>
            <p className="subtitle-purple">Alteraciones anatomo-funcionales</p>
            <p>
              Como se menciona en la cistitis complicada, la alteración
              estructural y/o funcional de la vía urinaria predispone a IVU, por
              alteración de flujo unidireccional de la orina: ejemplos clásicos
              son la vejiga neurogénica, el reflujo vesico-ureteral, la
              urolitiasis o los cuerpos extraños (catéteres) en cuya superficie
              se forma el biofilm que evita la eliminación de bacterias
              adheridas a este material y previene una acción eficaz del sistema
              inmunológico.{" "}
            </p>
            <p className="subtitle-purple">
              Factores microbiológicos en infecciones del tracto urinario
            </p>
            <p>
              A pesar de la posibilidad que la infección del tracto urinario sea
              producida por múltiples microrganismos (Virus, Bacterias, Hongos y
              Parásitos), un número limitado de especies bacterianas son la
              causa de la gran mayoría de las infecciones urinarias;
              generalmente solo una especie está involucrada como infección
              "mono-microbiana". La especie arquetípica es la Escherichia coli,
              cuyas diferentes cepas producen afectación variable del tracto
              urinario. La Klebsiella pneumonie y el Proteus mirabilis ocupan un
              lugar importante en segundo plano, produciendo IVU en el tracto
              urinario afectado o con derivaciones urinarias. Pueden estar
              involucradas otras enterobacterias coliformes (Serratia,
              Citrobacter, Enterobacter), la Pseudomona, algunos patógenos gram
              positivos (Especies de Estafilococo, Enterococo, Estreptococo) y
              otros patógenos menos frecuentes como el Corynebacterium, la
              candida, el Mycobacterium TBC o los virus (BK, JC, Herpes,
              Citomegalovirus).{" "}
            </p>
          </div>
        </AccordionComponent>

        <AccordionBibliography title="BIBLIOGRAFIA">
          <div>
            <p className="bibliography-font">
              <b>1.</b> Walsh C, Collins T Pathophysiology of urinary tract
              infections Basic Science, 2020 Volume 38, issue 4, 191-196
              <b className="bibliography-purple">
                {" "}
                https://doi.org/10.1016/j.mpsur.2020.01.011
              </b>
            </p>
            <p className="bibliography-font">
              <b>2.</b> Bennett JE, Dolin R, Blaser MJ, eds. Mandell, Douglas,
              and Bennett’s Principles and practice of infectious diseases.
              Eighth edn. Philadelphia: Elsevier Saunders, 2015.
            </p>
            <p className="bibliography-font">
              <b>3.</b> Bonkat G, Bartoletti RR, Bruyere F, et al. EAU
              Guidelines on urological infections. 2019. Arnhem, the
              Netherlands: EAU Guidelines Office,{" "}
              <b className="bibliography-purple">
                http://uroweb.org/guideline/urological infections/
              </b>.
            </p>
            <p className="bibliography-font">
              <b>4.</b> Laupland, K. B., Ross, T., Pitout, J. D., Church, D. L.
              & Gregson, D. B. Community- onset urinary tract infections: a
              population- based assessment. Infection 35, 150–153 (2007).
            </p>
            <p className="bibliography-font">
              <b>5.</b> Luthje P, Hirschberg AL, Brauner A. Estrogenic action on
              innate defense mechanisms in the urinary tract. Mauritas 2014;
              77:32e6.
            </p>
            <p className="bibliography-font">
              <b> 6.</b> Godaly G, Ambite I, Svanborg C. Innate immunity and
              genetic determinants of urinary tract infection susceptibility.
              Curr Opin Infect Dis 2015; 28: 88e96.
            </p>
            <p className="bibliography-font">
              <b> 7.</b> Stamm, W. E. & Norrby, S. R. Urinary tract infections:
              disease panorama and challenges. J. Infect. Dis. 183, S1–S4
              (2001).
            </p>
            <p className="bibliography-font">
              <b> 8.</b> Sihra N, Goodman A, Zakri R, Sahai A, Malde S.
              Nonantibiotic prevention and management of recurrent urinary tract
              infection. Nat Rev Urol. 2018 Dec;15(12):750-776.
            </p>
          </div>
        </AccordionBibliography>
      </div>
    </div>
  );
};

export default BacterialCystitisART;
