import React from 'react'
import styled from 'styled-components'
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core'

const TextOutlinedInput = ({
  name,
  label,
  type = 'text',
  required,
  register,
  registerOptions,
  errors,
  ...remaining
}) => {
  const registerOption =
    registerOptions && registerOptions[name] ? registerOptions[name] : undefined

  let isError = false
  let errorMessage = ''
  if (errors && errors.hasOwnProperty(name)) {
    isError = true
    errorMessage = errors[name].message
  }

  const handleInvalidDefault = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
  const txtInput = register(name, registerOption)
  return (
    <StyledFormControl variant="outlined" fullWidth={true}>
      <StyledInputLabel htmlFor={`outlined-text-${name}`}>
        {label}
        {required ? <span style={{ color: 'red' }}> *</span> : ''}
      </StyledInputLabel>
      <StyledOutlinedInput
        //{...register(name, registerOption)}
        id={`outlined-text-${name}`}
        name={name}
        type={type}
        defaultValue=""
        onChange={(e) => {
          txtInput.onChange(e) // method from hook form register
          //handleChange(e) // your method
        }}
        onBlur={txtInput.onBlur}
        inputRef={txtInput.ref}
        error={isError}
        {...remaining}
        labelWidth={0}
        aria-describedby="email-helper-text"
        onInvalid={handleInvalidDefault}
      />
      <StyledFormHelperText id={`${name}-helper-text`}>
        {errorMessage && (
          <>
            <ErrorIcon src="/patient/error-icon.svg" alert="Error icon" />
            {errorMessage}
          </>
        )}
      </StyledFormHelperText>
    </StyledFormControl>
  )
}

export default TextOutlinedInput

// Overwriting with styled component

const StyledFormControl = styled(FormControl)`
    &.MuiFormControl-root {
        margin 18px 0 5px;
    }    
`

const StyledInputLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    color: #6c757d;

    &.Mui-focused {
      color: #6c757d;
    }
  }

  &.MuiInputLabel-outlined {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    transform: translate(10px, 10px) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(10px, -18px) scale(0.75);
    }
  }
`

const StyledOutlinedInput = styled(OutlinedInput)`
  &.MuiOutlinedInput-root {
    border-radius: 6px;

    &.Mui-focused fieldset {
      border: 1px solid #b16db3;
    }

    .MuiInputBase-input {
      padding: 11px 10px 10px;
    }
  }
`

const StyledFormHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #d62424;

    &.MuiFormHelperText-contained {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
`

const ErrorIcon = styled.img`
  max-width: 20px;  
  width: 100%;
  margin: 4px 5px 0 0;
  line-height: normal;
`
