import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as dayjs from "dayjs";
import { NotificationManager } from "react-notifications";
import FormHelperText from "@material-ui/core/FormHelperText";
import Footer from "../../../components/patientComponents/component/footer";
import IalurilTextBox from "../../../components/patientComponents/component/ialurilTextBox";
import InstillationItem from "../../../components/patientComponents/component/instillationItem";
import SelectOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/select";
import {
  Container,
  MarginSeparator,
} from "../../../components/patientComponents/styled-components/instillations/styled";
import DialogTreatmentFinished from "../../../components/patientComponents/component/instillations/dialogTreatmentFinished";
import DialogSurveyDateValidation from "../../../components/patientComponents/component/instillations/dialogSurveyDateValidation";
import AddMaintenance from "../../../components/patientComponents/component/instillations/addMaintenance";
import AddCycle from "../../../components/patientComponents/component/instillations/addCycle";
import DlgFirstInstillation from "../../../components/patientComponents/component/instillations/dlgFirstInstillation";
import styles from "../../../styles/patientStyles/components/DialogConfirm.module.css";
import HeaderWithMenu from "../../../components/patientComponents/component/headerWithMenu/index";
import Survey from "../survey";
import {
  callApi,
  getCycles,
  getInstillationsByCycle,
  postAddCycle,
  instillationPlanning,
  patchCycleAddMaintenance,
  patchEndCycle,
  AdminEndCycle,
} from "../../../services/patientServices/apiList";
import { getUserInfo } from "../../../services/patientServices/authService";
import {
  CycleStateEnum,
  InstillationStateEnum,
  InstillationTypeEnum,
} from "../../../constants/patienteConstants/enums";
import ModalEndCycle from "../../../components/patientComponents/component/instillations/modalEndCycle";
import ResetCycle from "../../../components/patientComponents/component/instillations/resetCycle";
import EditCycle from "../../../components/patientComponents/component/instillations/editCycle";
import InitializeCycle from "../../../components/patientComponents/component/instillations/initializeCycle";
import { DialogActions } from "@material-ui/core";

const getDate = (date) => dayjs(dayjs(date).format("YYYY-MM-DD"));

const getCurrentCycle = (cycleList) =>
  cycleList.find((x) => x.state === CycleStateEnum.current);

const getIdCurrentCycle = (cycleList) => {
  var x = cycleList.find((x) => x.state === CycleStateEnum.current);
  if (x == undefined) {
    return 0;
  }
  return x.id;
};

const isMaintenanceFinished = (instillations) => {
  const pending = instillations.filter(
    (x) =>
      x.type === InstillationTypeEnum.maintenance &&
      x.state !== InstillationStateEnum.Executed &&
      x.state !== InstillationStateEnum.Indeterminate
  );
  return pending.length === 0;
};

const confirmFinish = (cycle, instillations) => {
  if (cycle.state !== CycleStateEnum.current) return false;

  if (cycle.maintenanceNumber === 0 && instillations.length > 0) {
    const lastItem = instillations[instillations.length - 1];
    return lastItem.state === InstillationStateEnum.Executed;
  }
  return false;
};

const isMaintenance = (x) => x.type === InstillationTypeEnum.maintenance;
const getPrefix = (x) =>
  isMaintenance(x) ? "Inst. mantenimiento N° " : "Instilación N° ";
const formatInstillations = (data) => {
  return data.map((x) => ({ id: x.id, name: getPrefix(x) + x.number, ...x }));
};

const cycleOptions = [
  { id: -11, name: "Ciclo 1" },
  { id: -12, name: "Ciclo 2" },
  { id: -13, name: "Ciclo 3" },
  { id: -14, name: "Ciclo 4" },
];

const MsgConfirmFirstInstillation = ({ instillationNumber }) => {
  if (instillationNumber === 1) {
    return (
      <p className={styles.text}>
        Apreciado paciente, ¿está seguro que no se ha realizado otras
        instilaciones en su ciclo de tratamiento actual con{" "}
        <strong>ialuril ® Prefill</strong>?
      </p>
    );
  }
  return (
    <p className={styles.text}>
      Apreciado paciente, ¿está seguro que quiere iniciar con la{" "}
      <strong>Instilación N° {instillationNumber}</strong> en su ciclo de
      tratamiento actual?
    </p>
  );
};

const Instillations = () => {
  const daysMin = 3;
  const daysMax = 5;

  const [userInfo, setUserInfo] = useState(null);
  const [BtnEndCycle, setBtnEndCycle] = useState(true);
  const [cycleList, setCycleList] = useState([]);
  const [instillationsList, setInstillationsList] = useState([]);
  const [idSwitched, setIdSwitched] = useState(0);
  const [idSwitchedTemp, setIdSwitchedTemp] = useState(0);
  const [anyPlanned, setAnyPlanned] = useState(false);

  const [dlgOpen, setDlgOpen] = useState(false);
  const [dlgMsgDate, setDlgMsgDate] = useState(false);
  const [dlgSurveyDateOpen, setDlgSurveyDateOpen] = useState(false);
  const [dlgFirstOpen, setDlgFirstOpen] = useState(false);
  const [planningDate, setPlanningDate] = useState(undefined);
  const [addInstillationsMaintenance, setAddInstillationsMaintenance] =
    useState(null);
  const [surveyInstillationId, setSurveyInstillationId] = useState(null);
  const [instillationNumber, setInstillationNumber] = useState(null);
  const [resetSwitchId, setResetSwitchId] = useState(null);

  useEffect(() => setUserInfo(getUserInfo()), []);

  const defaultValues = {
    cycleId: null,
    nroInstillations: null,
  };
  const registerOptions = {
    cycleId: { required: "seleccione un ciclo" },
    nroInstillations: { required: "seleccione una opción" },
    nroInstillationsMaintenance: { required: "seleccione una opción" },
  };
  const methods = useForm({ defaultValues });
  const { register, handleSubmit, formState, watch, setValue, trigger } =
    methods;
  const { errors } = formState;

  const cycleIdValue = watch("cycleId");

  const loadCycles = (cycleId) => {
    callApi(
      getCycles,
      (data) => {
        const cycleExists = data.length !== 0;
        data = cycleExists ? data : cycleOptions;
        setCycleList(data);

        if (cycleId) setValue("cycleId", cycleId);
        else {
          const currentCycle = getCurrentCycle(data);
          if (currentCycle) setValue("cycleId", currentCycle.id);
        }
      }
    );
  };

  const defaultPlanninDate = (instillations) => {
    const maintenance = instillations.filter(
      (x) => x.type === InstillationTypeEnum.maintenance
    );
    if (maintenance.length == 0) return;

    const anyPlanned = maintenance.find((x) => x.planningDate !== null);
    if (!anyPlanned) {
      onDateClick(maintenance[0], dayjs(), true);
    }
  };

  const loadInstillations = (cycleId) => {
    const callBack = (instillations) => {
      const cycle = cycleList.find((x) => x.id === cycleId);
      if (cycle.maintenanceNumber === 0 && confirmFinish(cycle, instillations))
        setDlgOpen(true);
      else if (
        cycle.state === CycleStateEnum.current &&
        cycle.maintenanceNumber > 0 &&
        isMaintenanceFinished(instillations)
      ) {
        NotificationManager.warning("El ciclo ya finalizó");
        onEndCycle(true);
      }

      defaultPlanninDate(instillations);
    };

    callApi(
      () => getInstillationsByCycle(cycleId),
      (data) => {
        setInstillationsList(formatInstillations(data));
        const any = data.find((x) => x.planningDate);
        setAnyPlanned(any !== undefined);
        if (callBack) callBack(data);
      }
    );
  };

  const addCycle = (nroInstillations) => {
    var item = cycleList.find((x) => x.id === cycleIdValue);
    var cycle = {
      name: item.name,
      number: nroInstillations,
    };

    callApi(
      () => postAddCycle(cycle),
      (data) => {
        loadCycles(data.id);
        setValue("nroInstillations", null);
      }
    );
  };

  const addMaintenanceToCycle = (nroMaintenance) => {
    var cycle = {
      id: cycleIdValue,
      maintenanceNumber: nroMaintenance,
    };

    callApi(
      () => patchCycleAddMaintenance(cycle),
      () => {
        loadCycles(cycleIdValue);
        loadInstillations(cycleIdValue);
        setValue("nroInstillationsMaintenance", null);
        setAddInstillationsMaintenance(null);
      }
    );
  };

  const planning = (instillation, hideMsg) => {
    callApi(
      () => instillationPlanning(instillation),
      (data) => {
        setInstillationsList(formatInstillations(data));
        setPlanningDate(instillation.planningDate);
        setDlgMsgDate(!hideMsg);
      }
    );
  };

  const fnEndSurvey = () => {
    setInstillationNumber(null);
    setSurveyInstillationId(null);
    loadCycles(cycleIdValue);
    loadInstillations(cycleIdValue);
  };

  useEffect(() => loadCycles(), []);

  {
    /* ========================================================================================================================= */
  }

  useEffect(() => {
    if (cycleList) {
      const currentCycle = getCurrentCycle(cycleList);
      const optAddCycle = cycleList.find((x) => x.id === -1);
      const tempNewCycle = cycleList.find((x) => x.id === 0);
      if (
        !currentCycle &&
        !optAddCycle &&
        !tempNewCycle &&
        cycleList !== cycleOptions
      ) {
        setCycleList([...cycleList, { id: -1, name: "Adicionar otro ciclo" }]);
        setValue("cycleId", null);
      }
    }
  }, [cycleList]);

  const cycleChange = () => {
    if (cycleIdValue && cycleIdValue > -1) {
      loadInstillations(cycleIdValue);
    } else {
      setInstillationsList([]);
      setAddInstillationsMaintenance(null);
    }

    if (cycleIdValue && cycleIdValue === -1) {
      const cycles = cycleList.filter((x) => x.id !== -1);
      const nextNumber = Number(cycles[cycles.length - 1].name.substr(6)) + 1;

      var data = [...cycles, { id: 0, name: "Ciclo " + nextNumber }];
      setCycleList(data);
      setValue("cycleId", 0);
    }

    var currentOptCycle = cycleList.find((x) => x.id === cycleIdValue);
    setBtnEndCycle(
      currentOptCycle && currentOptCycle.state === 1 ? true : false
    );

    var currentOptCycle = cycleList.find((x) => x.id === cycleIdValue);
    setBtnEndCycle(
      currentOptCycle && currentOptCycle.state === 1 ? true : false
    );

    if (idSwitched) setIdSwitched(0);
  };

  useEffect(() => {
    cycleChange();
  }, [cycleIdValue]);

  const onDateClick = (instillation, date, hideMsg) => {
    const instillationPatch = {
      id: instillation.id,
      planningDate: dayjs(date).format("YYYY-MM-DDT00:00:00"),
    };
    planning(instillationPatch, hideMsg);
  };

  const isSurveyExpired = (planningDate) => {
    var limitDate = getDate(planningDate).add(7, "day");
    return getDate() > limitDate;
  };

  const onSurvey = (instillation) => {
    if (
      !instillation.planningDate ||
      instillation.state === InstillationStateEnum.Executed
    ) {
      if (instillation.state === InstillationStateEnum.Executed) {
        NotificationManager.warning("La encuesta ya fue diligenciada");
      }
      return;
    }

    if (
      getDate() < getDate(instillation.planningDate).add(3, "day") ||
      isSurveyExpired(instillation.planningDate)
    ) {
      setPlanningDate(instillation.planningDate);
      setDlgSurveyDateOpen(true);
      return;
    }
    setInstillationNumber(instillation.number);
    setSurveyInstillationId(instillation.id);
  };

  const switchChangeHandle = (instillation) => {
    setIdSwitched(instillation.id);
    setAnyPlanned(true);
  };
  const switchOnChange = (instillation, setSwitchValue) => (e) => {
    if (e.target.checked) {
      setInstillationNumber(instillation.number);
      setDlgFirstOpen(true);
      setIdSwitchedTemp(instillation.id);
    }
    setSwitchValue(e.target.checked);
  };

  const onEndCycle = (endCycle) => {
    const cycleId = cycleIdValue;
    setAddInstillationsMaintenance(endCycle === false);
    if (!endCycle) return;

    callApi(
      () => patchEndCycle(cycleId),
      () => {
        loadCycles(cycleId);
      }
    );
  };

  {
    /* ========================================================================================================================= */
  }

  const adminEndCycle = () => {
    const cycleId = cycleIdValue;
    callApi(
      () => AdminEndCycle(cycleId),
      () => {
        loadCycles(cycleId);
      }
    );
  };

  const getTextFirstCycle = (cycleId) => {
    switch (cycleId) {
      case -11:
        return "Inicio por primera vez para el manejo de su patología con ialuril ® Prefill.";
      case -12:
        return "Su médico tratante ha considerado realizar un tratamiento adicional después de las primeras instilaciones realizadas con ialuril ® Prefill.";
      case -13:
        return "Su médico tratante ha considerado por tercera vez, realizar un tratamiento adicional como la segunda vez con ialuril ® Prefill.";
      case -14:
        return "Su médico tratante ha considerado por cuarta vez, realizar un tratamiento adicional como la tercera vez con ialuril ® Prefill.";
      default:
        return "";
    }
  };

  const getNumberCurrentCycle = (list) => {
    const cycleCurrentNumber = getCurrentCycle(list);
    if (cycleCurrentNumber == undefined) {
      return;
    }
    return cycleCurrentNumber.number;
  };

  return (
    <>
       <HeaderWithMenu />
      {!surveyInstillationId && (
        <>
          <IalurilTextBox
            title="Instilaciones"
            content="Seleccione su instilación actual, registre la evolución de sus síntomas y programe su próxima instilación con ialuril ® Prefill."
          />
          {getNumberCurrentCycle(cycleList) === 0 ? (
            <>
              <div className={styles.closeCycleBtn}>
                <InitializeCycle
                  cycleId={cycleIdValue}
                  instillationsList={instillationsList}
                  callBack={cycleChange}
                  callBack2={loadCycles}
                />
              </div>
            </>
          ) : (
            <>
              <Container>
                <SelectOutlinedInput
                  name="cycleId"
                  label={
                    cycleIdValue < -10 || cycleOptions === cycleList
                      ? "Nuevo Ciclo"
                      : "Ciclo"
                  }
                  register={register}
                  registerOptions={registerOptions}
                  options={cycleList}
                  required
                  errors={errors}
                  value={cycleIdValue ?? ""}
                />
                {cycleList === cycleOptions && cycleIdValue && (
                  <FormHelperText error={false}>
                    {getTextFirstCycle(cycleIdValue)}
                  </FormHelperText>
                )}
                <div className="instillation-buttons">
                  <div className="instillation-buttons-inner">
                    {userInfo && userInfo.isRelogin === true && (
                      <div className={styles.closeCycleBtn}>
                        <ResetCycle
                          cycleId={cycleIdValue}
                          instillationsList={instillationsList}
                          callBack={cycleChange}
                        />
                      </div>
                    )}

                    <br />
                    {BtnEndCycle &&
                      ((userInfo && userInfo.isRelogin === true)) && (
                        <div className={styles.closeCycleBtn}>
                          <MarginSeparator />
                          <ModalEndCycle onEndCycle={adminEndCycle} />
                        </div>
                      )}
                  </div>
                  <br />
                  {watch("cycleId") == getIdCurrentCycle(cycleList) && (
                    <div className={styles.closeCycleBtn}>
                      <EditCycle
                        cycleId={cycleIdValue}
                        instillationsList={instillationsList}
                        callBack={cycleChange}
                      />
                    </div>
                  )}
                </div>

                <MarginSeparator />
                {cycleIdValue !== null &&
                  (cycleIdValue === 0 || cycleIdValue < -10) && (
                    <AddCycle
                      register={register}
                      registerOptions={registerOptions}
                      setValue={setValue}
                      errors={errors}
                      watch={watch}
                      trigger={trigger}
                      fnAddCycle={(nroInstillations) =>
                        addCycle(nroInstillations)
                      }
                    />
                  )}
                {(idSwitched === 0 || idSwitched || anyPlanned) &&
                  instillationsList
                    .filter((x) => !isMaintenance(x))
                    .map((x, index) => {
                      return (
                        <InstillationItem
                          key={index}
                          instillation={x}
                          onDateClick={onDateClick}
                          onSurvey={onSurvey}
                          switchOnChange={switchOnChange}
                          anyPlanned={anyPlanned}
                          idSwitched={idSwitched}
                          resetId={resetSwitchId}
                          daysMax={daysMax}
                        />
                      );
                    })}
                <DialogTreatmentFinished
                  dlgOpen={dlgOpen}
                  handleRequestClose={() => setDlgOpen(false)}
                  onEndCycle={onEndCycle}
                />
                {addInstillationsMaintenance && (
                  <>
                    <br></br>
                    <AddMaintenance
                      register={register}
                      registerOptions={registerOptions}
                      errors={errors}
                      watch={watch}
                      trigger={trigger}
                      fnAddMaintenance={(nroMaintenance) =>
                        addMaintenanceToCycle(nroMaintenance)
                      }
                    />
                  </>
                )}
                <br></br>
                {instillationsList
                  .filter((x) => isMaintenance(x))
                  .map((x, index) => {
                    return (
                      <InstillationItem
                        key={index}
                        instillation={x}
                        onDateClick={onDateClick}
                        onSurvey={onSurvey}
                        switchOnChange={switchOnChange}
                        anyPlanned={anyPlanned}
                        idSwitched={idSwitched}
                        daysMax={daysMax}
                      />
                    );
                  })}
                <DialogSurveyDateValidation
                  dlgOpen={dlgMsgDate}
                  handleRequestClose={() => setDlgMsgDate(false)}
                >
                  <div className={styles.container}>
                    <img
                      className={`${styles.img}`}
                      src="/patient/bell.svg"
                      alt="alert"
                    />
                    <p className={styles.text}>
                      Apreciado paciente lo invitamos a diligenciar la evolución
                      de su sintomatología con la instilación de ialuril ®
                      Prefill entre el próximo{" "}
                      <strong>
                        { dayjs(planningDate).add(daysMin, "day").format("DD/MM/YYYY") }
                      </strong>{" "}
                      y el{" "}
                      <strong>
                        { dayjs(planningDate).add(daysMax, "day").format("DD/MM/YYYY") }
                      </strong>
                      , con el fin de poder iniciar el seguimiento de su
                      evolución
                    </p>
                  </div>
                </DialogSurveyDateValidation>
                <DialogSurveyDateValidation
                  dlgOpen={dlgSurveyDateOpen}
                  handleRequestClose={() => setDlgSurveyDateOpen(false)}
                >
                  <div className={styles.container}>
                    <img
                      className={`${styles.img}`}
                      src="/patient/warning.svg"
                      alt="warning"
                    />

                    {!isSurveyExpired(planningDate) && (
                      <p className={styles.text}>
                        Parece que es muy pronto para estimar la evolución de
                        sus síntomas. Por favor diligencie la encuesta entre el{" "}
                        <strong>
                        { dayjs(planningDate).add(daysMin, "day").format("DD/MM/YYYY") }
                        </strong>{" "}
                        y el{" "}
                        <strong>
                        { dayjs(planningDate).add(daysMax, "day").format("DD/MM/YYYY") }
                        </strong>
                      </p>
                    )}
                    {isSurveyExpired(planningDate) && (
                      <p className={styles.text}>
                        Parece que el tiempo para diligenciar la encuesta ha
                        expirado {"("}
                        <strong>
                        { dayjs(planningDate).add(daysMin, "day").format("DD/MM/YYYY") }
                        </strong>
                        {" - "}
                        <strong>
                        { dayjs(planningDate).add(daysMax, "day").format("DD/MM/YYYY") }
                        </strong>
                        {")"}
                      </p>
                    )}
                  </div>
                </DialogSurveyDateValidation>
              </Container>
              <Footer />
            </>
          )}
        </>
      )}
      {surveyInstillationId && (
        <Survey
          instillationId={surveyInstillationId}
          fnEndSurvey={fnEndSurvey}
          instillationNumber={instillationNumber}
        />
      )}
      {/* ========================================================================================================================= */}
      <DlgFirstInstillation
        dlgOpen={dlgFirstOpen}
        handleRequestClose={() => {
          setDlgFirstOpen(false);
          setResetSwitchId(idSwitchedTemp);
        }}
        onAcceptClick={() => {
          setDlgFirstOpen(false);
          setIdSwitched(idSwitchedTemp);
          setAnyPlanned(true);
          onDateClick(
            instillationsList.find((x) => x.id === idSwitchedTemp),
            dayjs(),
            true
          );
          setIdSwitchedTemp(0);
        }}
      >
        <div className={styles.container}>
          <img
            className={`${styles.img}`}
            src="/patient/warning.svg"
            alt="warning"
          />
          <MsgConfirmFirstInstillation
            instillationNumber={instillationNumber}
          />
        </div>
      </DlgFirstInstillation>
    </>
  );
};

export default Instillations;
