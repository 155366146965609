import React from 'react'
import styles from '../../../styles/patientStyles/EducationInfo.module.css'

import HeaderWithMenu from '../../../components/patientComponents/component/headerWithMenu/index'
import BackButton from '../../../components/patientComponents/component/backButton'
import Footer from '../../../components/patientComponents/component/footer'
import EducationCard2 from '../../../components/patientComponents/component/Education/educationCard2'

const Education2 = () => {
  return (
    <>
      <div className={styles.innerContainer}>
        <HeaderWithMenu />
        <BackButton />
        <div className={`${styles.title}`}>
          Este procedimiento ¿quién lo cubre?
        </div>
        <div className={styles.imgContainer}>
          <EducationCard2 />
          {/*   <img
            className={`${styles.img}`}
            src="/educacion-art-1-01.jpg"
            alt="Info"
          /> */}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Education2
