import React, { useState, useRef } from "react";
import LaddaButton, { XL, SLIDE_UP } from "react-ladda";
import { useForm } from "react-hook-form";
import { useLocation, useHistory, Link } from "react-router-dom";
import { usePasswordValidation } from "../../../hook/patientHook/usePasswordValidation";
import {
  Form,
  HeaderLogo,
  Logo,
  Subtitle,
  Text,
  Title,
  ValidationList,
  ValidationItemList,
  IconStateValidation,
  MarginSeparator,
  RegisterButton,
  LoginButton,
  InnerContainer,
} from "../../../components/patientComponents/styled-components/register/styled";
import TextOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/text";
import EmailOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/email";
import PasswordOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/password";
import ConfirmPasswordOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/confirmPassword";
import SelectOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/select";
import RadioOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/radio";
import DateOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/date";
import CheckboxOutlinedInput from "../../../components/patientComponents/component/form/outlined-inputs/checkbox";
import {
  DocumentTypeList,
  DocumentTypeListCR,
  GenreList,
  UserStateEnum,
} from "../../../constants/patienteConstants/enums";
import dayjs from "dayjs";
import {
  callApi,
  postAddUser,
} from "../../../services/patientServices/apiList";
import Contact from "../../../components/patientComponents/component/Modals/contact";
import LaddaBtn from "../../../components/components/LaddaBtn";

const PatientRegister = () => {
  let history = useHistory();
  const location = useLocation();

  const [dlgContac, setDlgContact] = useState(false);
  const [laddaBtn, setLaddaBtn] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    firstPassword: "",
    secondPassword: "",
  });

  const searchParams = new URLSearchParams(location.search);

  const countryCode = searchParams.get("country");

  const handleClose = () => {
    setDlgContact(false);
  };

  const handleOpen = () => {
    setDlgContact(true);
  };

  const defaultValues = {};

  const methods = useForm({ defaultValues });
  const { register, handleSubmit, formState, watch, setValue } = methods;
  const { errors } = formState;

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] =
    usePasswordValidation({
      firstPassword: passwordValidation.firstPassword,
      secondPassword: passwordValidation.secondPassword,
    });

  const password = useRef({});
  password.current = watch("password", "");
  const legalValue = watch("legal");
  const birthdayValue = watch("birthday");

  const registerOptions = {
    firstnames: { required: "Debe ingresar al menos un nombre" },
    lastnames: { required: "Debe ingresar al menos un apellido" },
    selectId: {
      required: "Por favor seleccione un tipo de identificación",
    },
    numberId: { required: "Debe ingresar su número de identificación" },
    gender: { required: "Por favor seleccione un género" },
    email: {
      required: "Debe ingresar un correo electrónico",
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: "Este correo electrónico no es válido",
      },
    },
    password: {
      required: "Ingrese su contraseña",
      validate: {
        validLength: () => (validLength ? true : false),
        hasNumber: () => (hasNumber ? true : false),
        upperCase: () => (upperCase ? true : false),
        lowerCase: () => (lowerCase ? true : false),
        specialChar: () => (specialChar ? true : false),
      },
    },
    password_repeat: {
      validate: (value) =>
        value === password.current || "Las contraseñas no coinciden",
    },
    legal: {
      required:
        "Debe aceptar los Términos y Condiciones para completar el registro",
    },
  };

  const setFirst = (event) => {
    setPasswordValidation({
      ...passwordValidation,
      firstPassword: event.target.value,
    });
  };
  const setSecond = (event) => {
    setPasswordValidation({
      ...passwordValidation,
      secondPassword: event.target.value,
    });
  };

  const onSubmit = handleSubmit((data) => {
    setLaddaBtn(true);

    const obj = {
      name: data.firstnames,
      lastName: data.lastnames,
      documentType: data.selectId,
      document: data.numberId,
      genre: Number(data.gender),
      birthday: !data.birthday
        ? null
        : dayjs(data.birthday).format("YYYY-MM-DDTHH:mm:ss"),
      email: data.email,
      password: data.password,
      state: UserStateEnum.active,
      country: countryCode,
    };

    callApi(
      () => postAddUser(obj),
      () => {
        history.push("/patient/register/email-sent");
      }
    );
  });

  return (
    <>
      <HeaderLogo>
        <InnerContainer>
          <Logo src="/patient/logo-white.svg" alt="ialuriApp Logo" />
          <Title>Registro de usuario</Title>
          <Text>
            Ingrese los datos requeridos a continuación para crear su perfil en
            el aplicativo
          </Text>
        </InnerContainer>
      </HeaderLogo>

      <Form onSubmit={onSubmit}>
        <InnerContainer>
          <Subtitle>Información de usuario</Subtitle>
          <TextOutlinedInput
            name="firstnames"
            label="Nombres"
            type={"text"}
            register={register}
            registerOptions={registerOptions}
            errors={errors}
            required
          />

          <MarginSeparator margin="6px 0 0" />

          <TextOutlinedInput
            name="lastnames"
            label="Apellidos"
            type={"text"}
            required
            register={register}
            registerOptions={registerOptions}
            errors={errors}
          />

          <MarginSeparator margin="6px 0 0" />

          <SelectOutlinedInput
            name="selectId"
            label="Tipo de Identificación"
            options={countryCode == "CO" ? DocumentTypeList() : DocumentTypeListCR()}
            required
            register={register}
            registerOptions={registerOptions}
            errors={errors}
          />

          <MarginSeparator margin="6px 0 0" />

          <TextOutlinedInput
            name="numberId"
            label="Número de identificación"
            type={"number"}
            required
            register={register}
            registerOptions={registerOptions}
            errors={errors}
          />

          <MarginSeparator margin="20px 0 0" />

          <RadioOutlinedInput
            name="gender"
            label="Por favor seleccione su género"
            row
            options={GenreList()}
            required
            register={register}
            registerOptions={registerOptions}
            errors={errors}
          />

          <MarginSeparator margin="20px 0 0" />

          <DateOutlinedInput
            name="birthday"
            label="Fecha de Nacimiento"
            register={register}
            defaultValue={null}
            registerOptions={registerOptions}
            errors={errors}
            disableFuture={true}
            onChange={(date) => setValue("birthday", date)}
            value={birthdayValue ?? null}
          />

          <MarginSeparator margin="36px 0 0" />

          <Subtitle>Seguridad</Subtitle>

          <EmailOutlinedInput
            name="email"
            label="Correo electrónico"
            required={true}
            register={register}
            registerOptions={registerOptions}
            errors={errors}
          />

          <MarginSeparator margin="6px 0 0" />

          <PasswordOutlinedInput
            name="password"
            label="Nueva contraseña"
            required={true}
            register={register}
            registerOptions={registerOptions}
            errors={errors}
            onChange={setFirst}
          />

          <ValidationList>
            <ValidationItemList>
              <IconStateValidation
                src={
                  !validLength
                    ? "/patient/cross-red.svg"
                    : "/patient/tick-green.svg"
                }
              />
              Que sea entre 8 y 16 caracteres
            </ValidationItemList>
            <ValidationItemList>
              <IconStateValidation
                src={
                  !upperCase
                    ? "/patient/cross-red.svg"
                    : "/patient/tick-green.svg"
                }
              />
              Incluya al menos una letra mayúscula
            </ValidationItemList>
            <ValidationItemList>
              <IconStateValidation
                src={
                  !lowerCase
                    ? "/patient/cross-red.svg"
                    : "/patient/tick-green.svg"
                }
              />
              Incluya al menos una letra minúscula
            </ValidationItemList>
            <ValidationItemList>
              <IconStateValidation
                src={
                  !hasNumber
                    ? "/patient/cross-red.svg"
                    : "/patient/tick-green.svg"
                }
              />
              Incluya al menos un número
            </ValidationItemList>
            <ValidationItemList>
              <IconStateValidation
                src={
                  !specialChar
                    ? "/patient/cross-red.svg"
                    : "/patient/tick-green.svg"
                }
              />
              Incluya al menos un carácter especial, por ejemplo ! $ + – ( ) @
              &lt; &gt;
            </ValidationItemList>
          </ValidationList>

          <MarginSeparator margin="6px 0 0" />

          <ConfirmPasswordOutlinedInput
            name="password_repeat"
            label="Confirmar nueva contraseña"
            required={true}
            register={register}
            registerOptions={registerOptions}
            errors={errors}
            passwordToCheck={password.current}
            onChange={setSecond}
          />

          <MarginSeparator margin="18px 0 0" />

          <CheckboxOutlinedInput
            id="legal"
            name="legal"
            register={register}
            registerOptions={registerOptions}
            errors={errors}
            value={legalValue ?? false}
          >
            He leído y acepto la{" "}
            <Link to="/patient/privacyPolicy">Política de Privacidad</Link> y
            los{" "}
            <Link to="/patient/termsAndConditions">Términos y Condiciones</Link>
          </CheckboxOutlinedInput>

          <LaddaBtn onClick={onSubmit}>Registrarse</LaddaBtn>

          <LoginButton>
            ¿Ya tiene una cuenta?
            <Link to="/patient/login">Inicie sesión</Link>
          </LoginButton>

          <LoginButton>
            ¿Necesita ayuda?{" "}
            <u
              style={{
                cursor: "pointer",
                margin: "15px 0 0 0",
                fontSize: "13px",
                color: "#b16db3",
              }}
              onClick={handleOpen}
            >
              Contáctenos
            </u>
          </LoginButton>
        </InnerContainer>
      </Form>
      <Contact open={dlgContac} onClose={handleClose} />
    </>
  );
};

export default PatientRegister;
