import styled from 'styled-components'

export const Container = styled.div`
  max-width: 576px;
  width: 100%;
  margin: 0 auto;
  padding: 63px 16px 20px;
  box-sizing: border-box;
`

export const HeaderLogo = styled.div`
  padding: 13px 16px;
  background-color: #fff;
`

export const Logo = styled.img`
  max-width: 150px;
  width: 100%;
  display: block;
  margin: 0 auto 15px;
`

export const Title = styled.h1`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #6c757d;
  text-align: center;
  margin: 0 0 23px;
`

export const Form = styled.form`
  max-width: 100%;
`

export const MarginSeparator = styled.hr`
  margin: 8px 0 0;
  border: 0;
  unicode-bidi: unset;
`

export const LoginButton = styled.button`
  max-width: 100%;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 12px;
  margin: 45px auto 30px;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  background-color: #b16db3;
  box-shadow: 0px 7px 11px 0px #8445874d;
  box-sizing: border-box;
`

export const ForgotPassButton = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin: 0 auto 20px;

  a {
    color: #b16db3;
  }
`

export const RegisterButton = styled.p`
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #6c757d;
  text-align: center;
  margin: 0 auto 10px;

  a {
    font-family: 'Source Sans Pro', sans-serif;
    color: #b16db3;
    margin-left: 4px;
  }
`
