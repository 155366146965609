import React from "react";
import { useHistory, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Button2 from "../../doctorComponents/buttons/button-2";
import { useDispatch } from "react-redux";
import { setPatientInfo } from "../../../redux/patientInfoSlice.js";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { InstillationTypeEnum } from "../../../constants/patienteConstants/enums";

const PatientFileCard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onClick = (link, item) => {
    if (item.completedSurveys === 0) {
      NotificationManager.warning("El paciente no tiene encuestas finalizadas");
      return;
    }
    history.push({
      pathname: link,
      state: { detail: item },
    });
  };
  return (
    <div className="file">
      <div>
        <div
          className="file-segment"
          style={{ justifyContent: " space-between" }}
        >
          <div style={{ display: "flex" }}>
            <div className="icon-background-A">
              <img
                className="file-icon"
                src="/doctor/name-icon.svg"
                alt="icon"
              />
            </div>
            <div className="file-info">
              <h1 className="file-title">Nombre paciente:</h1>
              <p className="file-name">{props.name}</p>
            </div>
          </div>
          <IconButton
            aria-label="search"
            onClick={() => {
              props.setDlgAddPatient(true);
              props.setCurrentPatientId(props.item.patientId);
            }}
          >
            <EditIcon />
          </IconButton>
        </div>
        <div className="file-segment">
          <div className="icon-background-B">
            <img
              className="file-icon"
              src="/doctor/cicle-icon.svg"
              alt="icon"
            />
          </div>
          <div className="file-info">
            <h1 className="file-title">Ciclo actual:</h1>
            <p className="file-name">{props.cicle}</p>
          </div>
        </div>
        <div className="file-segment">
          <div className="icon-background-C">
            <img
              className="file-icon"
              src="/doctor/instillation-icon.svg"
              alt="icon"
            />
          </div>
          <div className="file-info">
            <h1 className="file-title">Instilación actual:</h1>
            <p className="file-name"> {props.instillation =="" || props.instillation == null? ""
              :(props.instillationType != InstillationTypeEnum.preInstillation ? "N°" : "")+ props.instillation}</p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.link && (
          <Button2
            onClick={() => {
              dispatch(setPatientInfo(props));
              onClick(props.link, props.item);  
            }}
            label="Ver Detalles"
          />
        )}
        <Link className="a-center" to={`/patient/instillations`}>
          <Button2
            label="Hacer encuesta"
            onClick={() => {
              dispatch(setPatientInfo(props));
            }}
          />
        </Link>
      </div>
    </div>
  );
};

export default PatientFileCard;
