import React from 'react'
import styles from '../../../styles/patientStyles/EducationInfo.module.css'
import HeaderWithMenu from '../../../components/patientComponents/component/headerWithMenu'
import BackButton from '../../../components/patientComponents/component/backButton'
import CautionCard from '../../../components/patientComponents/component/cautionCard'
import Footer from '../../../components/patientComponents/component/footer'
import RecommendationCard3 from '../../../components/patientComponents/component/Recommendations/recommendationCard3'

const Recommendation3 = () => {
  return (
    <>
      <div className={styles.innerContainer}>
        <HeaderWithMenu />
        <BackButton />
        <div className={`${styles.title}`}>
          Después de la instilación intravesical
        </div>
        <div className={styles.imgContainer}>
          <RecommendationCard3 />

          {/*   <img
            className={`${styles.img}`}
            src="/recomend-art-3-01.jpg"
            alt="Info"
          /> */}
        </div>
        <CautionCard
          text="Recuerde que el éxito del tratamiento es seguir
        las directrices dadas por su médico tratante."
        />
      </div>
      <Footer />
    </>
  )
}

export default Recommendation3
