import React from "react";
import { Link } from "react-router-dom";
import { articlesGTM } from "../../../googleTagManager/doctor";

const ArticleCard = (props) => {
  return (
    <div className="card">
      <div className="card-title">
        <img src="/doctor/ArticleLogo.svg" alt="Art" />
        <p>{props.article}</p>
      </div>
      <div className="card-content">
        <p>{props.title}</p>
        <Link
          to={props.link}
          onClick={() => articlesGTM(props.module, props.label)}
        >
          Leer artículo
        </Link>
      </div>
    </div>
  );
};

export default ArticleCard;
