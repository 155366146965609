import React from "react";
import Highcharts from "highcharts";
import Chart from "highcharts-react-official";

require("highcharts/modules/exporting")(Highcharts);

const ChartComponent = (props) => {
  return (
    <Chart highcharts={Highcharts} options={props.options} />
  );
};

export default ChartComponent;