import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    info: {}
  }

const epsDetailReduce = createSlice({
  name: "epsDetailReduce",
  initialState,
  reducers: {
    setEpsDetailReduce: (state, action) => {
      state.info = {...action.payload}
    },
  },
});

export const {setEpsDetailReduce} = epsDetailReduce.actions
export const selectEpsDetailReduce = (state) => state.epsDetailState.info;

export default epsDetailReduce.reducer;
