export const colorSatisfaction = {
  0: {
    color: '#8AC926',
    img: '/patient/faces/eva-0.svg',
  },
  1: {
    color: '#C5CA30',
    img: '/patient/faces/eva-1.svg',
  },
  2: {
    color: '#FFCA3A',
    img: '/patient/faces/eva-2.svg',
  },
  3: {
    color: '#FCB02C',
    img: '/patient/faces/eva-3.svg',
  },
  4: {
    color: '#F8961E',
    img: '/patient/faces/eva-4.svg',
  },
  5: {
    color: '#FC783E',
    img: '/patient/faces/eva-5.svg',
  },
  6: {
    color: '#FF595E',
    img: '/patient/faces/eva-6.svg',
  },
  7: {
    color: '#8F2D56',
    img: '/patient/faces/eva-7.svg',
  },
  8: {
    color: '#1982C4',
    img: '/patient/faces/eva-8.svg',
  },
  9: {
    color: '#4267AC',
    img: '/patient/faces/eva-9.svg',
  },
  10: {
    color: '#6A4C93',
    img: '/patient/faces/eva-10.svg',
  },
}
