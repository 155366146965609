import http from "./httpService";
import { NotificationManager } from "react-notifications";
const urlApi = process.env.REACT_APP_API_URL;

const fnError = (err, handleError) => {
  var error = "";
  if (err.response) {
    if (err.response.data && err.response.status === 400) {
      handleError(err);
      return;
    } else if (err.response.data) {
      error = err.response.data.error;
      console.log("error-response: ", err.response.data.error);
    } else {
      error = err.response;
      console.log("error-response: ", err.response);
    }
    //console.log("err.response",err.response)
  } else if (err.request) {
    error = err.request;
    console.log("error-request: ", err.request);
  } else {
    error = err;
    console.log("error-error: ", err);
  }

  if (handleError) {
    console.log("handleError: -> ", error);
    handleError(error);
  }
};

export const callActionApi = (apiFunc, setData, handleError, setLoader) => {
  const fetchData = async () => {
    try {
      await apiFunc()
        .then((response) => {
          if (response) {
            setData(response.data);
          }
        })
        .catch((err) => fnError(err, handleError))
        .finally(() => {
          if (setLoader) setLoader(false);
        });
    } catch (e) {
      console.log("error: ", e.message);
    }
  };
  if (setLoader) setLoader(true);
  fetchData();
};

export const callActionApiAsync = async (
  apiFunc,
  setData,
  handleError,
  setLoader
) => {
  if (setLoader) setLoader(true);
  try {
    await apiFunc()
      .then(async (response) => {
        if (response) {
          if (setData.constructor.name === "AsyncFunction")
            await setData(response.data);
          else setData(response.data);
        }
      })
      .catch((err) => fnError(err, handleError))
      .finally(() => {
        if (setLoader) setLoader(false);
      });
  } catch (e) {
    console.log("error: ", e.message);
  }
};

export const callApi = (apiFunc, setData, setLoader) => {
  callActionApi(
    apiFunc,
    setData,
    (error) => {
      if (typeof error === "string" && error) {
        NotificationManager.error(error);
      } else if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        var d = error.response.data;
        console.log("error", d.error ? d.error : d);
        if (error.response.data.error)
          NotificationManager.warning(error.response.data.error);
        else NotificationManager.warning(error.response.data);
      }
    }, //, (data) => dispatch(setLoader(data))
    (data) => {
      if (setLoader) setLoader(data);
    }
  );
};

export const callApiAsync = async (apiFunc, setData, setLoader) => {
  await callActionApiAsync(
    apiFunc,
    setData,
    (error) => {
      if (typeof error === "string") {
        NotificationManager.error(error);
      } else if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        NotificationManager.warning(error.response.data);
      }
    }, //, (data) => dispatch(setLoader(data))
    (data) => {
      if (setLoader) setLoader(data);
    }
  );
};

export const getToken = (data) => http.post(`${urlApi}/User/Login`, data);
export const postRegister = (data) =>
  http.post(`${urlApi}/User/Register`, data);
export const postVerifyEmail = (data) =>
  http.post(`${urlApi}/User/VerifyEmail`, data);
export const postRestorePassword = (user) =>
  http.post(`${urlApi}/User/RestorePassword`, user);
export const postResetPassword = (user) =>
  http.post(`${urlApi}/User/ResetPassword`, user);
export const getCodeIsValid = (code) =>
  http.get(`${urlApi}/User/CodeIsValid?code=` + code);
export const postChangePassword = (user) =>
  http.post(`${urlApi}/User/ChangePassword`, user);

export const getPatientList = () =>
  http.get(`${urlApi}/Patient/GetByCurrentDoctor`);
export const getPatientInstitutionList = () =>
  http.get(`${urlApi}/Institutions/GetByCurrentInstitution`);
export const getEpsInstitutionList = () =>
  http.get(`${urlApi}/Institutions/GetEpsByCurrentInstitution`);

export const getPatienInfo = (patientId) =>
  http.get(`${urlApi}/Patient/GetSummary?patientId=${patientId}`);
export const getDashboardInfo = () =>
  http.get(`${urlApi}/Pathology/GetTopPathology`);
export const getStateProvinces = (countryId) => http.get(`${urlApi}/StateProvince?countryId=${countryId}`);
export const getCities = (stateProvinceId) =>
  http.get(
    `${urlApi}/City/GetByStateProvince?stateProvinceId=${stateProvinceId}`
  );
export const getSpecialties = () => http.get(`${urlApi}/Specialty`);
export const postAddSpecialty = (data) =>
  http.post(`${urlApi}/Specialty`, data);
export const getDocumentTypes = (country) =>
  http.get(`${urlApi}/DocumentType?country=` + country);

export const getProfileInfo = () => http.get(`${urlApi}/Doctor/GetProfileInfo`);

export const getDoctorbyRep = () =>
  http.get(`${urlApi}/Representative/GetDashboardInfo`);

export const getDoctorList = () =>
  http.get(`${urlApi}/Representative/GetDoctorsByRep`);

export const updateProfile = (data) =>
  http.post(`${urlApi}/Doctor/UpdateProfile`, data);

export const postRepChartsFilter = (filterGraph) =>
  http.post(`${urlApi}/Representative/GetFilterGraphDoctor`, filterGraph);

export const postDownloadReport = (filterGraph) =>
  http.post(`${urlApi}/Downloadable/GetDownloadReport`, filterGraph);

export const GetDashboardInstitutionInfo = () =>
  http.get(`${urlApi}/Institutions/GetDashboardInstitutionInfo`);

export const getInstitutionPatienInfo = (filterGraph) =>
  http.post(`${urlApi}/Institutions/GetSummaryInstitutionInfo`, filterGraph);

export const GetCycleByPatient = (patientId) =>
  http.get(`${urlApi}/Cycle/GetCyclesByPatientId?patientId=${patientId}`);

export const GetDoctorList = (country) =>
  http.get(`${urlApi}/Institutions/GetDoctorList?countryId=` + country);

export const postAddPatient = (data) =>
  http.post(`${urlApi}/Institutions/PostAddPatient`, data);

export const getPatientInfo = (patientId) =>
  http.get(`${urlApi}/Institutions/GetPatientInfo?patientId=${patientId}`);

export const getPatientInstitutionFromEpsList = (data) =>
  http.post(`${urlApi}/Institutions/GetPatientsBy`, data);

export const getPatientCardFromInstitution = (patientId) =>
  http.get(
    `${urlApi}/Institutions/GetPatientCardFromInstitution?patientId=${patientId}`
  );

export const getMessageToDoc = () =>
  http.get(`${urlApi}/Representative/getMessageToDoc`);

export const getPatientToInstill = () =>
  http.get(`${urlApi}/Institutions/getPatientToInstill`);

export const postAddDoctorByInst = (data) =>
  http.post(`${urlApi}/Institutions/AddDoctor`, data);

export const AssociateUser = (data) =>
  http.post(`${urlApi}/Institutions/AssociateUser`, data);

export const getInstitutionReport = (data) =>
  http.post(`${urlApi}/Institutions/GetInstitutionReport`, data);

export const getNotifications = () =>
  http.get(`${urlApi}/Institutions/GetNotifications`);
