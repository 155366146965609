import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import dayjs from "dayjs";
import Box from "@material-ui/core/Box";
import DateOutlinedInput from "../../patientComponents/component/form/outlined-inputs/date";
import PaginationComp from "../../../components/doctorComponents/pagination";
import CustomChart from "../../../components/doctorComponents/highcharts";
import {
  callApiAsync,
  postRepChartsFilter,
  postDownloadReport,
} from "../../../services/doctorServices/apiService";
import ButtonXS from "../buttons/button-xs";
import {
  YesNoChart,
  QuestionChart,
  LineChart,
  OneLineChart,
} from "./optionsCharts";
import Autocomplete from "../../../components/doctorComponents/form/outlined-inputs/autocomplete";
import { isRole } from "../../../services/doctorServices/userService";
import { RoleEnum } from "../../../constants/doctorConstants/enums";
import PreviewReport from "./previewReport";



const getPropArray = (obj, prop) => {
  if (!obj) return [];
  return obj[prop];
};

const getQuestionTitle = (questionNumber) => {
  switch (questionNumber) {
    case 1:
      return "1. ¿Presenta dolor pélvico y/o ardor al orinar?";
    case 11:
      return "1.1 Nivel de dolor según la Escala Visual Análoga (EVA)";
    case 2:
      return "2. ¿Cuántas veces va a orinar durante el día?";
    case 3:
      return "3. ¿Cuántas veces va a orinar en la noche luego de haber conciliado el sueño?";
    case 4:
      return "4. ¿Cuándo siente el deseo de orinar debe ir rápidamente o puede aguantar?";
    case 5:
      return "5. ¿Ha presentado usted fiebre superior a 38 °C, asociado con alguno de los síntomas anteriores?";
    case 6:
      return "6. ¿Ha presentado usted sangrado en la orina asociado con alguno de los síntomas anteriores?";
    default:
      return "";
  }
};

const DoctorFile = ({ id, type, nameEps }) => {
  const isInstitution = isRole(RoleEnum.institution);
  const [totalInfo, setTotalInfo] = useState(null);
  const [totalGrahps, setTotalGrahps] = useState(null);
  const [cycleList, setCycleList] = useState([]);
  const [questionNumber, setQuestionNumber] = useState(1);
  const handleChange = (event, value) => setQuestionNumber(value);

  const defaultValues = {
    cycle: "Ciclo 1",
    startDate: null,
    endDate: null,
  };
  const methods = useForm({ defaultValues });
  const { register, handleSubmit, formState, watch, setValue } = methods;
  const { errors } = formState;

  const currentCycle = watch("cycle");
  const [dlgPreview, setDlgPreview] = useState(false);


  const loadDataAsync = async (filter) => {
    await callApiAsync(
      () => postRepChartsFilter(filter),
      (data) => {
        if (cycleList.length === 0) {
          var a = data.yesNoQuestion.map((x) => x.cycleName);
          var b = a.filter((v, i) => a.indexOf(v) === i);
          const cycles = b.map((x) => ({ id: x, name: x }));
          setCycleList(cycles);
        }
        setTotalInfo(data);
      }
    );
  };

  useEffect(() => {
    async function getInfoAsync() {
      const filter = {
        id,
        type,
        questionNumber,
        startDate: null,
        endDate: null,
      };
      await loadDataAsync(filter);
    }
    getInfoAsync();
  }, [id, type, questionNumber]);

  const onSubmit = handleSubmit(async (data) => {
    const filter = {
      id,
      type,
      questionNumber,
      startDate: !data.startDate
        ? null
        : dayjs(data.startDate).format("YYYY-MM-DDT00:00:00"),
      endDate: !data.endDate
        ? null
        : dayjs(data.endDate).format("YYYY-MM-DDT00:00:00"),
    };

    await loadDataAsync(filter);
  });

  const onDownload = handleSubmit(async (data) => {
    var cycleName = watch("cycle");
    const filter = {
      id,
      type,
      startDate: !data.startDate
        ? null
        : dayjs(data.startDate).format("YYYY-MM-DDT00:00:00"),
      endDate: !data.endDate
        ? null
        : dayjs(data.endDate).format("YYYY-MM-DDT00:00:00"),
      cycle: cycleName,
    };

    await callApiAsync(
      () => postDownloadReport(filter),
      (data) => {
        setTotalGrahps(data);
        setDlgPreview(true);
      }
    );
  });

  return (
    <div className="container">
      <div className="file">
        <h1 className="title-1">
          {isInstitution ? "CONSOLIDADO DE SINTOMATOLOGÍA " : "GRAFICAS"}
        </h1>
        <div>
          <FormProvider {...methods}>
            <Autocomplete
              name="cycle"
              label="Ciclo"
              options={cycleList}
              register={register}
              errors={errors}
              required
              watch={watch}
              setValue={setValue}
            />
            {!isInstitution && (
              <>
                <DateOutlinedInput
                  name="startDate"
                  label="Fecha Inicial"
                  register={register}
                  defaultValue={null}
                  errors={errors}
                  disableFuture={true}
                  onChange={(date) => setValue("startDate", date)}
                  value={watch("startDate") ?? null}
                />
                <br></br>
                <DateOutlinedInput
                  name="endDate"
                  label="Fecha Final"
                  register={register}
                  defaultValue={null}
                  errors={errors}
                  disableFuture={true}
                  onChange={(date) => setValue("endDate", date)}
                  value={watch("endDate") ?? null}
                />
              </>
            )}
          </FormProvider>{" "}
          <div className="div-center">
            <div className="file-segment">
              {!isInstitution && (
                <div className="div-center">
                  <ButtonXS label="Filtrar" onClick={onSubmit} />
                </div>
              )}
              {isInstitution && (
                <div className="div-center">
                  <ButtonXS
                    label="Descargar consolidado"
                    onClick={onDownload}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="div-center">
          <Box p={3}>
            <p className="title-2">{getQuestionTitle(questionNumber)}</p>
            {(questionNumber === 1 ||
              questionNumber === 5 ||
              questionNumber === 6) && (
              <CustomChart
                options={OneLineChart(
                  "",
                  "role.visitor",
                  null,
                  getPropArray(totalInfo, "yesNoQuestion"),
                  currentCycle,
                  questionNumber
                )}
              />
            )}
            {questionNumber === 1 && (
              <div className="mt-3">
                <p className="title-2">{getQuestionTitle(11)}</p>
                <CustomChart
                  options={LineChart(
                    "",
                    "role.visitor",
                    null,
                    getPropArray(totalInfo, "question"),
                    currentCycle,
                    11
                  )}
                />
              </div>
            )}
            {(questionNumber === 2 ||
              questionNumber === 3 ||
              questionNumber === 4) && (
              <div className="mt-3">
                <CustomChart
                  options={QuestionChart(
                    "",
                    "role.visitor",
                    null,
                    getPropArray(totalInfo, "question"),
                    currentCycle,
                    questionNumber
                  )}
                />
              </div>
            )}
          </Box>
          <PaginationComp count={6} onChange={handleChange} />
        </div>
      </div>
      <PreviewReport
        open={dlgPreview}
        onClose={() => setDlgPreview(false)}
        currentCycle={currentCycle}
        totalGrahps={totalGrahps}
        namePdf={nameEps == null ? "" : nameEps}
        type={type}
      />
    </div>
  );
};

export default DoctorFile;
