import React from "react";

import AccordionComponent from "../../../../components/doctorComponents/accordion/accordion-1";
import AccordionBibliography from "../../../../components/doctorComponents/accordion/accordion-2";
const InterstitialCystitisPainfulBladderSyndrome = () => {
  return (
    <div className="container">
      <div className="inner-container">
        <AccordionComponent article="interstitial-cystitis-painful-bladder-syndrome" title="DEFINICIÓN">
          <div>
            <p>
              Por su estrecha relación con el síndrome de vejiga dolorosa (SVD)
              se define como
              <i>
                "una sensación desagradable (dolor, presión, incomodidad) que se
                percibe como relacionada con la vejiga, asociada con síntomas
                del tracto urinario inferior de más de seis semanas, en ausencia
                de infección u otras causas identificables"
              </i>
              . (SUFU).
            </p>
            <p>
              Desde el punto de vista epidemiológico, debido a que no hay un
              marcador objetivo para establecer la presencia de CI / SVD, los
              estudios para definir su prevalencia son difíciles de llevar a
              cabo.
            </p>
            <p>
              Los datos referidos por la Asociación Americana de Urología (AUA)
              consideran márgenes variables
              <b> 6-11% (mujeres) y 2–5% (hombres)</b>: los síntomas son
              típicamente 2-3 veces más frecuentes en mujeres que en hombres. En
              la Asociación Europea de Urología (EUA), con una prevalencia no
              actualizada de 19% (2004) y sin información sobre la incidencia y
              los costos de diagnóstico y tratamiento.<sup><b>2</b></sup> Se mencionan los
              factores de riesgos más frecuentemente asociados genético,
              psicológico, trauma físico y endocrino (corticotrofinas y su
              actividad sobre los mastocitos).
            </p>
          </div>
        </AccordionComponent>

        <AccordionComponent article="interstitial-cystitis-painful-bladder-syndrome" title="FISIOPATOLOGÍA">
          <div>
            <p>
              En la cistitis intersticial (CI), la ruptura de la barrera
              urotelial puede iniciar una serie de eventos en la vejiga, que
              provocan síntomas y signos característicos. Específicamente, la
              disfunción epitelial conduce a la migración de solutos urinarios,
              en particular potasio, que despolarizan las terminales nerviosas y
              causan daño tisular.<sup><b>1</b></sup>
            </p>
            <p>
              La superficie urotelial (mucosa) de la vejiga está compuesta de
              glicosaminoglicanos (GAG) y proteoglicanos en el exterior de la
              superficie de la membrana apical de células de transición. Esta
              superficie mucosa junto con sus solutos contenidos, proporciona la
              interfaz inmediata entre la orina y la pared vesical.
            </p>
            <p>
              Como tal,{" "}
              <b>
                representa la barrera principal en la vejiga para controlar las
                interacciones con la orina
              </b>{" "}
              y es fundamental para prevenir una serie de estados patológicos
              como:
            </p>

            <ul>
              <li> la adherencia bacteriana e infección.</li>
              <li> la formación de cálculos.</li>
              <li> la cistitis por radiación.</li>
              <li> la carcinogénesis vesical.</li>
              <li> la cistitis intersticial (CI).</li>
            </ul>

            <p>
              Por esta razón es imperativo comprender los mecanismos biológicos
              por los cuales el moco ejerce su actividad, ¿qué sucede cuándo está
              defectuoso?, y lo más importante, ¿cómo restaurar su función?. (
              <i>Figura 1</i>)
            </p>

            <div className="imgBox">
              <img className="img" src="/doctor/doctor-img004.png" alt="Img" />
            </div>
            <p className="italics-gray">
              Figura 1. Composición de la pared vesical
            </p>

            <b>
              Existen cuatro factores determinantes en la función de la
              superficie mucosa:
            </b>

            <p>
              <b className="bold-purple">1)</b> Regulador de la permeabilidad de
              la pared vesical a los cationes urinarios (<i>Figura 2</i>).
            </p>
            <p>
              <b className="bold-purple">2)</b> El ión potasio (K <sup>+</sup>) juega un
              papel fundamental en la patogénesis de la Cistitis Intersticial (CI) por difusión en la
              pared vesical defectuosa.{" "}
            </p>
            <p>
              <b className="bold-purple">3)</b> Los compuestos heparinoides
              (heparina, pentosan polisulfato) reparan los defectos uroteliales
              inducidos experimentalmente. Este es un fundamento poderoso para
              las terapias de restauración epitelial.{" "}
            </p>
            <p>
              <b className="bold-purple">4)</b> Existe un factor citotóxico en
              la orina normal que es neutralizado por la proteína de
              Tamm-Horsfall (PTH) y la alteración en la estructura proteica
              puede ser el defecto primario asociado con CI.
            </p>

            <img className="img" src="/doctor/permeabilidad-de-la-pared-vesical-xhdpi.png" alt="Img" />
            <p className="italics-gray">
              Figura 2. Permeabilidad de la pared vesical{" "}
            </p>

            <p>
              Los diferentes factores relacionados como causa de la disfunción y
              daño de la pared vesical se mencionan en la (<i>figura 3</i>).<sup><b>3, 4</b></sup>
            </p>

            <img className="img" src="/doctor/doctor-img006.png" alt="Img" />
            <p className="italics-gray">
              Figura 3. Disfunción y daño de la pared vesical
            </p>

            <p>
              A partir de los efectos nocivos en la pared vesical se generan de
              manera recurrente señales al neuroeje que explican el fundamento
              del dolor pélvico crónico, la nocicepción, la hipersensibilidad
              periférica, y la sensibilización central. (<i>Figura 4</i>)<sup><b>5</b></sup>
            </p>

            <img className="img" src="/doctor/doctor-img008.png" alt="Img" />
            <p className="italics-gray">
              Figura 4. Estímulo crónico por daño urotelial{" "}
            </p>
          </div>
        </AccordionComponent>

        <AccordionBibliography title="BIBLIOGRAFIA">
          <div>
            <p className="bibliography-font">
              {" "}
              <b>1.</b> C. Lowell Parsons (2007). The Role of the Urinary
              Epithelium in the Pathogenesis of Interstitial
              Cystitis/Prostatitis/Urethritis. UROLOGY 69 (Suppl 4A): 9–16.
            </p>
            <p className="bibliography-font">
              <b>2.</b> Temml, C., et al. Prevalence and correlates for
              interstitial cystitis symptoms in women participating in a health
              screening project. Eur Urol, 2007. 51: 803.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>3.</b> Buffington, C.A. Comorbidity of interstitial cystitis
              with other unexplained clinical conditions. J Urol, 2004. 172:
              1242.
            </p>
            <p className="bibliography-font">
              <b>4.</b> Peters, K.M., et al. Are ulcerative and nonulcerative
              interstitial cystitis/painful bladder syndrome 2 distinct
              diseases? A study of coexisting conditions. Urology, 2011. 78:
              301.
            </p>
            <p className="bibliography-font">
              {" "}
              <b>5.</b> Melzack, R., et al. Central neuroplasticity and
              pathological pain. Ann N Y Acad Sci, 2001. 933: 157.
            </p>
          </div>
        </AccordionBibliography>
      </div>
    </div>
  );
};

export default InterstitialCystitisPainfulBladderSyndrome;
