import React, { useEffect } from 'react'
import styles from '../../../../styles/patientStyles/components/EducationCard.module.css'

const EducationCard1 = () => {
  return (
    <>
      <div className={styles.educationBox1}>
        <p className={styles.whiteText}>
          Es una tecnica <b style={{color: "#ffffff"}}>mínimamente invasiva</b> que consiste en la
          introducción de un catéter vesical (sonda) a través de la uretra, para
          la administración de una solución estéril (ialuril ®Prefill)
        </p>
      </div>
      <div className={styles.imgContainer}>
        <img
          className={`${styles.imgEducation}`}
          src="/patient/educacion-art1-01.png"
          alt="Info1"
        />
        <img
          className={`${styles.imgEducation}`}
          src="/patient/educacion-art1-02.png"
          alt="Info2"
        />
        <img
          className={`${styles.imgEducation}`}
          src="/patient/educacion-art1-03.png"
          alt="Info3"
        />
        <img
          className={`${styles.imgEducation}`}
          src="/patient/educacion-art1-04.png"
          alt="Info"
        />
      </div>
      <div className={styles.educationBox3}>
        <p className={styles.whiteText}>
          Se realiza <b style={{color: "#ffffff"}}>con anestesia local y de forma ambulatoria</b>, es
          decir, sin requerir hospitalización
        </p>
      </div>
    </>
  )
}

export default EducationCard1
