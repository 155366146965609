import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TextField, FormControl, InputLabel } from "@material-ui/core"; 


export const useStyles = makeStyles(() => ({
    root: {
      "& .MuiOutlinedInput-input": {
        background: "white",
        borderRadius: "4px",
        padding:"25px 0 10px 15px;"
      },
      "& .MuiOutlinedInput-adornedEnd": {
        background: "white",
      },
      display: "flex",
      flexWrap: "wrap",
      margin: "14.5px auto 7.5px auto",
    },
    margin: {
      margin: "15px auto 15px auto",
      width: "100%",
    },
    label: {
      margin: "15px auto 15px auto",
      width: "100%",
    },
  }));
  
export const CssTextField = withStyles({
    root: {
      "& label.Mui-focused": {
        color: "#CF63CE",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#894381",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#ADB5BD",
        },
        "&:hover fieldset": {
          borderColor: "#CF63CE",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#CF63CE",
        },
      },
    },
  })(TextField);

  export const CssFormControlField = withStyles({
    root: {
      "& .MuiFormLabel-root.Mui-focused": {
        color: "rgba(0, 0, 0, 0.54)",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#ADB5BD",
        },
        "&:hover fieldset": {
          borderColor: "#CF63CE",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#CF63CE",
        },
      },
    },
  })(FormControl);

  
export const CssInputLabel = withStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      margin: "15px auto 15px auto",
    },
  })(InputLabel);
  
  