import React from "react";
import PatientBox from "../../../components/doctorComponents/patientBox";

const Recommendations = () => {
  return (
    <div>
      <div className="box-education-container">
        <div className="box-education-inner-container">
          <PatientBox
            img="/doctor/recommendations-1.png"
            title="Antes de la instilación intravesical"
            href="/doctor/patient-recommendations/before-intravesical-instillation"
          />
          <PatientBox
            img="/doctor/recommendations-2.png"
            title="El día de la instilación intravesical"
            href="/doctor/patient-recommendations/the-day-of-intravesical-instillation"
          />
          <PatientBox
            img="/doctor/recommendations-3.png"
            title="Después de la instilación intravesical"
            href="/doctor/patient-recommendations/after-intravesical-instillation"
          />
        </div>
      </div>
    </div>
  );
};

export default Recommendations;
