import React, { useState } from "react";
import styled from "styled-components";

const LaddaBtn = ({ children, ...props }) => {
  const [disablebtn, setDisablebtn] = useState(false);

  const { disabled, onClick, ...otherProps } = props;

  /* const onRealClick = async (event) => {
    setExecuting(true)
    try {
      await onClick()
    } finally {
      setExecuting(false)
    }
  } */

  const onRealClick = (event) => {
    setDisablebtn(true);
    try {
      onClick();
    } catch {
      console.log("err");
    } finally {
      setTimeout(() => {
        setDisablebtn(false);
      }, 1800);
    }
  };

  return (
    <Ladda
      onClick={onRealClick}
      disabled={disablebtn || disabled}
      {...otherProps}
    >
      {children}
    </Ladda>
  );
};

export default LaddaBtn;

const Ladda = styled.button`
  cursor: pointer;
  display: block;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  padding: 10px 16px;
  margin: 50px auto 40px;
  border-radius: 6px;
  border: 2px solid #b16db3;
  background-color: #b16db3;
  box-sizing: border-box;
  box-shadow: 0px 7px 11px rgba(132, 69, 135, 0.3);
`;
